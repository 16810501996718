import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col } from "react-bootstrap";
import BooleanField from "components/Fields/BooleanField";
import { EntityBankAccountModel } from "models/view/EntityBankAccountModel";
import { getEntityBankAccountById } from "actions/entity";

type Props = {
  entityId: string,
  bankAccountId: string
}

function ViewEntityBankAccount(props: Props){
  const [loading, setLoading] = useState(true);
  const [genericErrors, setGenericErrors] = useState(null);
  const [entityBankAccount, setEntityBankAccount] = useState<EntityBankAccountModel>();

  useEffect(() => {
    setLoading(true);
    getEntityBankAccountById(props.entityId, props.bankAccountId).then((response) => {
      setEntityBankAccount(response.data)
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setLoading(false);
    });
  }, []);

  return(
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Bank Name"}
            value={entityBankAccount?.bankName ?? ""}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Bank Account Name"}
            value={entityBankAccount?.bankAccountName ?? ""}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Bank Sort Code"}
            value={entityBankAccount?.bankSortCode ?? ""}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Bank Account No."}
            value={entityBankAccount?.bankAccountNumber ?? ""}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Bank IBAN"}
            value={entityBankAccount?.bankIBAN ?? ""}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <BooleanField
            label={"Is Preferred"}
            value={entityBankAccount?.isPreferred ?? false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Description"}
            value={entityBankAccount?.description ?? ""}
          />
        </Col>
      </Row>
    </>
  );
}

export default ViewEntityBankAccount;
