import { GoGraph } from 'react-icons/go';
import {
  MdMoreTime,
  MdHistory,
  MdCheckCircleOutline,
  MdTrendingUp,
  MdOutlineCreditCard,
  MdAccountBalanceWallet,
} from 'react-icons/md';
import { IoWalletOutline } from "react-icons/io5";
import { HiHashtag } from 'react-icons/hi';
import { TbFileInvoice } from 'react-icons/tb';
import { SummaryBoxTypes } from "enums/SummaryBoxTypes";
import { formatCurrency } from "utils/misc";
import './style.scss';
import React from 'react';

type Props = {
  type: SummaryBoxTypes,
  title?: string,
  values: {
    value: number,
    label?: string,
    className?: string
  }[],
  className?: string,
  targetValue?: number,
  targetClassName?: string
}

export default function SummaryBox(props: Props) {
  let summaryBoxClass = `${props.className ?? 'lp-summary-box'}`;
  let summaryBoxTitle = '';
  let summaryBoxIcon = <></>;
  let summaryBoxCurrency = false;
  let summaryBoxTime = false;
  let summaryBoxDoughnut = false;
  let summaryBoxTarget = false;
  const sumValue = props.values.reduce((a, b) => a + b.value, 0);
  //const valueInPercentage = sumValue != 0 ? ((props.value ?? 0) / sumValue * 100).toFixed(0) : 0;

  switch(props.type) {
    case SummaryBoxTypes.EstimatedCost: {
      summaryBoxTitle = props.title ?? 'Estimated cost';
      summaryBoxIcon = <GoGraph />;
      summaryBoxCurrency = true;
      break;
    }
    case SummaryBoxTypes.FeeIncomeTargetForCurrentWeek: {
      summaryBoxTitle = props.title ?? 'Fee Income For Week';
      summaryBoxIcon = <MdMoreTime />;
      summaryBoxCurrency = true;
      summaryBoxTarget = true;
      break;
    }
    case SummaryBoxTypes.FeeIncomeDoughnut: {
      summaryBoxTitle = props.title ?? 'Fee Income';
      summaryBoxIcon = <MdMoreTime />;
      summaryBoxCurrency = true;
      summaryBoxDoughnut = true;
      break;
    }
    case SummaryBoxTypes.FeeIncomeDoughnutForFinancialYear: {
      summaryBoxTitle = props.title ?? 'Fee Income For Year';
      summaryBoxIcon = <MdMoreTime />;
      summaryBoxCurrency = true;
      summaryBoxDoughnut = true;
      break;
    }
    case SummaryBoxTypes.BilledFeeIncome: {
      summaryBoxClass += ' billed';
      summaryBoxTitle = props.title ?? 'Billed Fee Income value';
      summaryBoxIcon = <MdMoreTime />;
      summaryBoxCurrency = true;
      break;
    }
    case SummaryBoxTypes.UnBilledFeeIncome: {
      summaryBoxClass += ' unbilled';
      summaryBoxTitle = props.title ?? 'Unbilled Fee Income value';
      summaryBoxIcon = <MdMoreTime />;
      summaryBoxCurrency = true;
      break;
    }
    case SummaryBoxTypes.TimeDoughnut: {
      summaryBoxTitle = props.title ?? 'Time';
      summaryBoxIcon = <MdMoreTime />;
      summaryBoxTime = true;
      summaryBoxDoughnut = true;
      break;
    }
    case SummaryBoxTypes.TimeDoughnutForFinancialYear: {
      summaryBoxTitle = props.title ?? 'Time For Year';
      summaryBoxIcon = <MdMoreTime />;
      summaryBoxTime = true;
      summaryBoxDoughnut = true;
      break;
    }
    case SummaryBoxTypes.BilledTime: {
      summaryBoxClass += ' billed';
      summaryBoxTitle = props.title ?? 'Total billed time';
      summaryBoxIcon = <MdMoreTime />;
      summaryBoxTime = true;
      break;
    }
    case SummaryBoxTypes.UnbilledTime: {
      summaryBoxClass += ' unbilled';
      summaryBoxTitle = props.title ?? 'Total unbilled time';
      summaryBoxIcon = <MdHistory />;
      summaryBoxTime = true;
      break;
    }
    case SummaryBoxTypes.DisbursementsDoughnut: {
      summaryBoxTitle = props.title ?? 'Disbursements';
      summaryBoxIcon = <MdOutlineCreditCard />;
      summaryBoxCurrency = true;
      summaryBoxDoughnut = true;
      break;
    }
    case SummaryBoxTypes.BilledDisbursements: {
      summaryBoxClass += ' billed';
      summaryBoxTitle = props.title ?? 'Total billed disbursements';
      summaryBoxIcon = <MdOutlineCreditCard />;
      summaryBoxCurrency = true;
      break;
    }
    case SummaryBoxTypes.UnbilledDisbursements: {
      summaryBoxClass += ' unbilled';
      summaryBoxTitle = props.title ?? 'Total unbilled disbursements';
      summaryBoxIcon = <MdOutlineCreditCard />;
      summaryBoxCurrency = true;
      break;
    }
    case SummaryBoxTypes.UnpaidDisbursements: {
      summaryBoxClass += ' unpaid';
      summaryBoxTitle = props.title ?? 'Unpaid disbursements';
      summaryBoxIcon = <MdOutlineCreditCard />;
      summaryBoxCurrency = true;
      break;
    }
    case SummaryBoxTypes.IncidentalExpensesDoughnut: {
      summaryBoxTitle = props.title ?? 'Incidental Expenses';
      summaryBoxIcon = <IoWalletOutline />;
      summaryBoxCurrency = true;
      summaryBoxDoughnut = true;
      break;
    }
    case SummaryBoxTypes.BilledIncidentalExpenses: {
      summaryBoxClass += ' billed';
      summaryBoxTitle = props.title ?? 'Total billed incidental expenses';
      summaryBoxIcon = <IoWalletOutline />;
      summaryBoxCurrency = true;
      break;
    }
    case SummaryBoxTypes.UnbilledIncidentalExpenses: {
      summaryBoxClass += ' unbilled';
      summaryBoxTitle = props.title ?? 'Total unbilled incidental expenses';
      summaryBoxIcon = <IoWalletOutline />;
      summaryBoxCurrency = true;
      break;
    }
    case SummaryBoxTypes.TotalFiles: {
      summaryBoxClass += ' total-files';
      summaryBoxTitle = props.title ?? 'Total files';
      summaryBoxIcon = <MdCheckCircleOutline />;
      break;
    }
    case SummaryBoxTypes.Profit: {
      summaryBoxClass += ' profit';
      summaryBoxTitle = props.title ?? 'Total paid profit costs';
      summaryBoxIcon = <MdTrendingUp />;
      summaryBoxCurrency = true;
      break;
    }
    case SummaryBoxTypes.InvoicesDoughnut: {
      summaryBoxTitle = props.title ?? 'Invoices';
      summaryBoxIcon = <TbFileInvoice />;
      summaryBoxCurrency = true;
      summaryBoxDoughnut = true;
      break;
    }
    case SummaryBoxTypes.InvoiceParticipatingEntityDoughnut: {
      summaryBoxTitle = props.title ?? 'Invoice';
      summaryBoxIcon = <TbFileInvoice />;
      summaryBoxCurrency = true;
      summaryBoxDoughnut = true;
      break;
    }
    case SummaryBoxTypes.UnpaidInvoices: {
      summaryBoxClass += ' unpaid';
      summaryBoxTitle = props.title ?? 'Unpaid invoices';
      summaryBoxIcon = <TbFileInvoice />;
      summaryBoxCurrency = true;
      break;
    }
    case SummaryBoxTypes.UnpaidInvoicesCount: {
      summaryBoxClass += ' unpaid';
      summaryBoxTitle = props.title ?? 'Unpaid invoices';
      summaryBoxIcon = <HiHashtag />;
      break;
    }
    case SummaryBoxTypes.BalanceOnBusinessSide: {
      summaryBoxTitle = props.title ?? 'Balance on Business Side';
      summaryBoxIcon = <MdAccountBalanceWallet />;
      summaryBoxCurrency = true;
      break;
    }
    case SummaryBoxTypes.BalanceOnClientSide: {
      summaryBoxTitle = props.title ?? 'Balance on Client Side';
      summaryBoxIcon = <MdAccountBalanceWallet />;
      summaryBoxCurrency = true;
      break;
    }
    case SummaryBoxTypes.Balance: {
      summaryBoxTitle = props.title ?? 'Balance';
      summaryBoxIcon = <MdAccountBalanceWallet />;
      summaryBoxCurrency = true;
      break;
    }
    case SummaryBoxTypes.OpenMatters: {
      summaryBoxClass += ' open-matters';
      summaryBoxTitle = props.title ?? 'Open matters';
      summaryBoxIcon = <MdCheckCircleOutline />;
      break;
    }
  }

  const getInlineStyleForDoughnut = () => {
    if(sumValue == 0){
      return `var(--lp-secondary-200) 0% 100%`;
    }
    else {
      const obj: any = props.values.reduce((o: any, v, index) => {
        const valuePercentage = (v.value / sumValue * 100);
        if(index == 0) {
          return Object.assign(o, {
            'style': `var(--${v.className ?? 'value' + index}) 0% ${valuePercentage.toFixed(0)}%`,
            'tempSum': valuePercentage 
          });
        }
        else {
          const newTempSum = o.tempSum + valuePercentage;
          return Object.assign(o, {
            'style': o.style + `, var(--${v.className ?? 'value' + index}) ${o.tempSum.toFixed(0)}% ${newTempSum.toFixed(0)}%`,
            'tempSum': newTempSum 
          });
        }
      }, {});

      delete obj['tempSum'];
      return obj.style;
    }
  }

  const getInlineStyleForTargetDoughnut = () => {
    const targetValue = props.targetValue ?? 0;
    const maxPercentageValue = sumValue > targetValue ? sumValue : targetValue;
    if(targetValue == 0){
      return `var(--lp-secondary-200) 0% 100%`;
    }
    else {
      const obj: any = props.values.reduce((o: any, v, index) => {
        const valuePercentage = v.value / maxPercentageValue * 100;
        if(index == 0) {
          const toPercentage = valuePercentage > 100 ? 100 : valuePercentage;
          return Object.assign(o, {
            'style': `var(--${v.className ?? 'value' + index}) 0% ${toPercentage.toFixed(0)}%`,
            'tempSum': valuePercentage 
          });
        }
        else {
          if(o.tempSum < 100) {
            const newTempSum = o.tempSum + valuePercentage;
            const toPercentage = newTempSum > 100 ? 100 : newTempSum;
            return Object.assign(o, {
              'style': o.style + `, var(--${v.className ?? 'value' + index}) ${o.tempSum.toFixed(0)}% ${toPercentage.toFixed(0)}%`,
              'tempSum': newTempSum 
            });
          }
          else {
            return o;
          }
        }
      }, {});

      if(sumValue < targetValue) {
        const valuePercentage = sumValue / targetValue * 100;
        obj.style = obj.style + `, var(--${props.targetClassName ?? 'lp-warning'}) ${valuePercentage.toFixed(0)}% 100%`;
      }

      delete obj['tempSum'];
      return obj.style;
    }
  }

  return (
    <div className={summaryBoxClass}>
      <div className="title">{summaryBoxTitle}</div>
      {summaryBoxDoughnut && 
        <div className="lp-doughnut" 
          style={{'--lp-doughnut-gradient': `${getInlineStyleForDoughnut()}`} as React.CSSProperties} 
        />
      }
      {props.targetValue != undefined && summaryBoxTarget && 
        <div className="lp-doughnut" 
          style={{'--lp-doughnut-gradient': `${getInlineStyleForTargetDoughnut()}`} as React.CSSProperties} 
        />
      }
      {!summaryBoxDoughnut && !summaryBoxTarget &&
        <div className="icon">{summaryBoxIcon}</div>
      }
      <div className="text">
        {props.values.map((x: any, index: number) => (
          <div className="item" key={index}>
            {x.label && <span className={`label ${x.className}`}>{x.label}:</span>}
            <div className="value">
              {summaryBoxTime ? 
                <>
                  <span className="number">{~~(x.value / 60)}</span><span className="hours">h</span>
                  <span className="number">{(x.value % 60).toString().padStart(2, '0')}</span><span className="minutes">m</span>
                </>
                :
                <span className="number">{summaryBoxCurrency ? formatCurrency(x.value) : x.value}</span>
              }
            </div>
          </div>
        ))}
        {props.targetValue != undefined && summaryBoxTarget &&
          <div className="item">
            <span className={`label ${props.targetClassName ?? 'lp-warning'}`}>Target:</span>
            <div className="value">
              {summaryBoxTime ? 
                <>
                  <span className="number">{~~(props.targetValue / 60)}</span><span className="hours">h</span>
                  <span className="number">{(props.targetValue % 60).toString().padStart(2, '0')}</span><span className="minutes">m</span>
                </>
                :
                <span className="number">{summaryBoxCurrency ? formatCurrency(props.targetValue) : props.targetValue}</span>
              }
            </div>
          </div>
        }
      </div>
    </div>
  );
}
