import { forwardRef, Ref, useEffect, useImperativeHandle, useState } from "react";
import _ from 'lodash';
import { IFilterParams } from "ag-grid-community";

type Props = IFilterParams & {
  property: string,
}

export const BooleanFilter = forwardRef((props: Props, ref: Ref<any>) => {
  const [selectedValues, setSelectedValues] = useState<{id: string, name: string}[] | undefined>(undefined);

  useEffect(() => {
    props.filterChangedCallback();
  }, [selectedValues]);

  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params: any) {
        return selectedValues?.some((x: any) => x.id ==  String(_.get(params.data, props.property)));
      },

      isFilterActive() {
        return selectedValues != undefined && selectedValues.length > 0;
      },

      getModel() {
      },

      setSelectedValues
    }
  });

  return (
    <div style={{ display: "inline-block", width: "200px", height: "200px" }}>
      Use a floating filter!
    </div>
  )
});