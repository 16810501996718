import { create, test, enforce } from "vest";

export const validationSuite = create((data = {}) => {
  test('name', 'Name is required', () => {
    enforce(data.name).isNotEmpty();
    enforce(data.name).isString();
  });
  test('lteDepartmentId', 'Please select a Department', () => {
    enforce(data.lteDepartmentId).isNotEmpty();
    enforce(data.lteDepartmentId).isString();
  });
});