import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MatterRecordableEventModel } from "models/view/MatterRecordableEventModel";
import { getMatterRecordableEvent } from "actions/matter";
import { Col, Row } from "react-bootstrap";
import Field from "components/Fields/Field";
import Loader from "components/Loader/index";
import { DateFormat } from "utils/constants";
import moment from "moment";

type Props = {
  recordableEventId: string
}

export default function ViewMatterRecordableEvent(props: Props) {
  const [matterRecordableEvent, setMatterRecordableEvent] = useState<MatterRecordableEventModel | undefined>(undefined);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    getMatterRecordableEvent(id!, props.recordableEventId).then((response) => {
      setMatterRecordableEvent(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, [id]);

  return (
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Fee Earner"}
            value={matterRecordableEvent?.user?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Fee Earner Level"}
            value={matterRecordableEvent?.feeEarnerLevel?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Client"}
            value={matterRecordableEvent?.matterParticipatingEntity?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Date"}
            value={matterRecordableEvent?.date ? moment(matterRecordableEvent?.date).format(DateFormat.Moment) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Display Name"}
            value={matterRecordableEvent?.displayName}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Description"}
            value={matterRecordableEvent?.description}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Charging Scheme"}
            value={matterRecordableEvent?.chargingScheme?.name}
          />
        </Col>
      </Row>
      {/*<Row>
        <Col>
          <Field
            label={"Event Type"}
            value={matterRecordableEvent?.recordableEventType?.name}
          />
        </Col>
      </Row>*/}
    </>
  );
}
