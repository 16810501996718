import { enforce, test, create, omitWhen } from "vest";

export const getValidationSuite = (isMatterTemplate: boolean) => {
  return create((data = {}) => {
    test('name', 'Name is required', () => {
      enforce(data.name).isNotEmpty();
      enforce(data.name).isString();
    });

    test('typeId', 'Please select a Template Type', () => {
      enforce(data.typeId).isNotEmpty();
      enforce(data.typeId).isString();
    });

    test('file', 'Please select a file.', () => {
      enforce(data.file).isNotEmpty();
    });

    omitWhen(!data.file || data.file.length == 0, () => {
      test('file', 'File must be less than 200MB.', () => {
        enforce(data.file[0].size).lessThan(209715200);
      });
    });

    omitWhen(!isMatterTemplate, () => {
      test('matterTypeIds', 'Please select at least a Matter Type', () => {
        enforce(data.matterTypeIds).isArray();
        enforce(data.matterTypeIds).isNotEmpty();
      });
    });
  });
}