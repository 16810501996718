import { getDashboardSummaryInfo } from 'actions/user';
import { useEffect, useState } from 'react';
import Title from 'components/Title';
import SummaryBox from 'components/SummaryBox';
import SummaryBoxesSection from 'components/SummaryBoxesSection';
import { SummaryBoxTypes } from 'enums/SummaryBoxTypes';
import { DashboardSummaryInfoModel } from 'models/view/DashboardSummaryInfoModel';
import Loader from 'components/Loader';
import { getDateOnly } from 'utils/date';
import moment from "moment";

export default function DashboardSummaryInfo() {
  const [dashboardSummaryInfo, setDashboardSummaryInfo] = useState<DashboardSummaryInfoModel | undefined>(undefined);
  const [isSummaryInfoLoading, setIsSummaryInfoLoading] = useState<boolean>(false);
  const [genericErrorsForSummaryInfo, setGenericErrorsForSummaryInfo] = useState(undefined);
  const startOfCurrentWeek = getDateOnly(moment().startOf('week').toDate());
  
  useEffect(() => {
    loadMatterSummaryInfo();
  }, []);

  const loadMatterSummaryInfo = () => {
    setIsSummaryInfoLoading(true);
    getDashboardSummaryInfo(startOfCurrentWeek).then((response) => {
      setDashboardSummaryInfo(response.data);
    })
    .catch((error) => {
      setGenericErrorsForSummaryInfo(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsSummaryInfoLoading(false);
    });
  }

  return (
    <>
      <Title type="section" title="Overview"></Title>

      {isSummaryInfoLoading &&
        <div className="lp-summary-boxes-placeholder">
          <Loader inlineLoader />
        </div>
      }

      {genericErrorsForSummaryInfo && (
        <div className="lp-errors">
          {genericErrorsForSummaryInfo}
        </div>
      )}

      {dashboardSummaryInfo && 
        <SummaryBoxesSection>
          <div className="lp-summary-boxes-area">
            <SummaryBox
              type={SummaryBoxTypes.OpenMatters}
              values={[
                {
                  value: dashboardSummaryInfo.openMattersCount ?? 0
                }
              ]}
            />

            <SummaryBox
              type={SummaryBoxTypes.TimeDoughnutForFinancialYear}
              values={[
                {
                  value: dashboardSummaryInfo.totalBilledMinutesRecordableItemsForFY ?? 0,
                  label: "Billed",
                  className: "lp-primary"
                },
                {
                  value: dashboardSummaryInfo.totalUnbilledMinutesRecordableItemsForFY ?? 0,
                  label: "Unbilled",
                  className: "lp-warning"
                }
              ]}
            />

            <SummaryBox 
              type={SummaryBoxTypes.FeeIncomeDoughnutForFinancialYear}
              values={[
                {
                  value: dashboardSummaryInfo.totalBilledRecordableItemsForFY ?? 0,
                  label: "Billed",
                  className: "lp-primary"
                },
                {
                  value: dashboardSummaryInfo.totalUnbilledRecordableItemsForFY ?? 0,
                  label: "Unbilled",
                  className: "lp-warning"
                }
              ]}
            />

            <SummaryBox
              type={SummaryBoxTypes.FeeIncomeTargetForCurrentWeek}
              values={[
                {
                  value: dashboardSummaryInfo.totalRecordableItemsForCurrentWeek ?? 0,
                  label: "Recorded",
                  className: "lp-primary"
                }
              ]}
              targetValue={dashboardSummaryInfo.weeklyFeeIncomeTarget ?? 0}
              targetClassName={"lp-warning"}
            />

            <SummaryBox
              type={SummaryBoxTypes.UnBilledFeeIncome}
              values={[
                {
                  value: dashboardSummaryInfo.totalUnbilledRecordableItems ?? 0
                }
              ]}
            />

            <SummaryBox
              type={SummaryBoxTypes.UnpaidInvoicesCount}
              values={[
                {
                  value: dashboardSummaryInfo.unpaidInvoicesCount ?? 0
                }
              ]}
            />

            <SummaryBox
              type={SummaryBoxTypes.InvoicesDoughnut}
              values={[
                {
                  value: dashboardSummaryInfo.totalPaidInvoices ?? 0,
                  label: "Paid",
                  className: "lp-primary"
                },
                {
                  value: dashboardSummaryInfo.totalUnpaidInvoices ?? 0,
                  label: "Unpaid",
                  className: "lp-warning"
                },
                ...dashboardSummaryInfo.totalWriteOffInvoices ? [{
                  value: dashboardSummaryInfo.totalWriteOffInvoices ?? 0,
                  label: "Write-Off",
                  className: "lp-danger"
                }] : []
              ]}
            />
          </div>
        </SummaryBoxesSection>
      }
    </>
  );
}