import { create, test, enforce, omitWhen, each } from "vest";
import isInt from "validator/lib/isInt";
import isDecimal from "validator/lib/isDecimal";
import { ChargingBasisIds } from "enums/ChargingBasisIds";
import { CreateOrUpdateChargingRateLevelModel } from "models/create/CreateOrUpdateChargingRateLevelModel";
import { validateChargingRatePair } from "actions/chargingSchemes";

export const validationSuite = create((data = {}) => {
  enforce.extend({ isInt });
  enforce.extend({ isDecimal });

  test('activityId', 'Please select an activity .', () => {
    enforce(data.activityId).isNotEmpty();
    enforce(data.activityId).isString();
  });

  each(data.chargingRateLevelViewModels, (field: CreateOrUpdateChargingRateLevelModel, index: number) => {
    test(`chargingRateLevelViewModels.${index}.chargingBasisId`, 'Please select a Charging Basis', () => {
      enforce(field.chargingBasisId).isNotEmpty();
      enforce(field.chargingBasisId).isString();
    });

    test(`chargingRateLevelViewModels.${index}.feeEarnerLevelId`, 'Please select a Fee Earner Level', () => {
      enforce(field.feeEarnerLevelId).isNotEmpty();
      enforce(field.feeEarnerLevelId).isString();
    });

    test(`chargingRateLevelViewModels.${index}.chargeRate`, 'Charge Rate is required', () => {
      enforce(field.chargeRate?.toString()).isNotEmpty();
    });

    omitWhen(!field.chargeRate, () => {      
      test(`chargingRateLevelViewModels.${index}.chargeRate`, 'Charge Rate needs to be a number.', () => {
        enforce(field.chargeRate?.toString()).isDecimal();
      });
    });

    omitWhen(field.chargingBasisId != ChargingBasisIds.TimeId, () => {
      test(`chargingRateLevelViewModels.${index}.minutesPerUnit`, 'Minutes Per Unit is required.', () => {
        enforce(field.minutesPerUnit!.toString()).isNotEmpty();
      });
    });

    omitWhen((!field.minutesPerUnit || field.chargingBasisId != ChargingBasisIds.FixedId), () => {
      test(`chargingRateLevelViewModels.${index}.minutesPerUnit`, 'Minutes Per Unit needs to be a number >= 0.', () => {
        enforce(field.minutesPerUnit!.toString()).isInt().greaterThanOrEquals(0);
      });
    });

    omitWhen((!field.minutesPerUnit || field.chargingBasisId != ChargingBasisIds.TimeId), () => {
      test(`chargingRateLevelViewModels.${index}.minutesPerUnit`, 'Minutes Per Unit needs to be a number > 0.', () => {
        enforce(field.minutesPerUnit!.toString()).isInt().greaterThan(0);
      });
    });

    //memo caches the responses and doesn't run the callback function if
    //the value is cached already
    omitWhen((!field.feeEarnerLevelId || !data.activityId || data.activityId == ""), () => {
      test(`chargingRateLevelViewModels.${index}.feeEarnerLevelId`, 'Activity - Fee Earner Level pair must be unique', async () => {
        enforce((await validateChargingRatePair(
          {
            chargingSchemeId: data.chargingSchemeId,
            feeEarnerLevelId: field.feeEarnerLevelId == "allLevels" ? undefined : field.feeEarnerLevelId,
            activityId: data.activityId
          }
        )).data).isTruthy();
      })
    });

    test(`chargingRateLevelViewModels.${index}.feeEarnerLevelId`, 'Fee Earner Level is already selected', () => {
      var sameFeeEarnerLevelCount: number = data.chargingRateLevelViewModels
        .filter((x: any) => x.feeEarnerLevelId == field.feeEarnerLevelId).length;
      var firstIndex = data.chargingRateLevelViewModels.map((x: any) => x.feeEarnerLevelId)
        .indexOf(field.feeEarnerLevelId);

      //check if fee earner level is selected more than once and if the current index is the same as the first oen in order 
      //to only show the error message all occurences except the first one
      enforce(sameFeeEarnerLevelCount <= 1 || (sameFeeEarnerLevelCount > 1 && firstIndex == index)).isTruthy();
    });
  })
});