import { create, test, enforce } from "vest";

export const validationSuite = create((data = {}) => {
  test('dxExchange', 'DX Exchange is required', () => {
    enforce(data.dxExchange).isNotEmpty();
    enforce(data.dxExchange).isString();
  });

  test('dxNumber', 'DX Number is required', () => {
    enforce(data.dxNumber).isNotEmpty();
    enforce(data.dxNumber).isString();
  });
});