import { create, test, enforce } from "vest";

export const validationSuite = create((data = {}) => {
  test('bankName', 'Bank Name is required', () => {
    enforce(data.bankName).isNotEmpty();
    enforce(data.bankName).isString();
  });
  test('bankAccountName', 'Bank Account Name is required', () => {
    enforce(data.bankAccountName).isNotEmpty();
    enforce(data.bankAccountName).isString();
  });
  test('bankSortCode', 'Bank Sort Code is required', () => {
    enforce(data.bankSortCode).isNotEmpty();
    enforce(data.bankSortCode).isString();
  });
  test('bankSortCode', 'Bank Sort Code has to have format NN-NN-NN!', () => {
    enforce(data.bankSortCode).matches(/\d{2}-\d{2}-\d{2}/);
  });
  test('bankAccountNumber', 'Bank Account Number is required', () => {
    enforce(data.bankAccountNumber).isNotEmpty();
    enforce(data.bankAccountNumber).isString();
  });
  test('description', 'Description is required', () => {
    enforce(data.description).isNotEmpty();
    enforce(data.description).isString();
  });
});