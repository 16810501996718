import Tippy from "@tippyjs/react";
import { ICellRendererParams } from "ag-grid-community";
import "components/Grid/style.scss";
import { AvailabilityViewInterval } from "enums/AvailabilityViewInterval";
import { FreeBusyEventModel } from "models/view/FreeBusyScheduleModel";

export interface FreeBusyCellRendererParams extends ICellRendererParams {
  availabilityViewInterval: AvailabilityViewInterval,
  columnKey: string
}

export default function FreeBusyCellRenderer(props: FreeBusyCellRendererParams) {
  const content = (parseInt(props.availabilityViewInterval) <= parseInt(AvailabilityViewInterval.Hours2) && props.data[props.columnKey].subjectLocation.length < 5 && props.data[props.columnKey].subjectLocation.length > 0 ? 
    <div>
      Events:
      {
        props.data[props.columnKey].subjectLocation.map((x: FreeBusyEventModel, index: number) => 
          <div
            key={index}
          >
            {`${x.subject ? x.subject : "[No subject]"} at ${x.location ? x.location : "[No location]"}`}
          </div>
        )
      }
    </div> :
    <div>
      {
        `There ${props.data[props.columnKey].subjectLocation?.length == 1 ? "is" : "are"} ${props.data[props.columnKey].subjectLocation?.length} event${props.data[props.columnKey].subjectLocation?.length != 1 ? "s" : ""}.`
      }
    </div>);

  return (
    <Tippy
      content={content}
      className="lp-calendar-event-tooltip"
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      duration={100}
      placement="bottom-start"
      trigger="mouseenter"
    >
      <div className={`lp-cell-time-slot ${props.value.toLowerCase()}`}>
        {
         props.value
        }
      </div>
    </Tippy>
  );
}
