import { ColDef } from "ag-grid-community";
import { DateTimeFormatter } from "components/Grid/ValueFormatters/DateTimeFormatter";
import { dateComparator } from "components/Grid/Comparators/DateComparator";
import { MatterFileHistoryModel } from "models/view/MatterFileHistoryModel";

export const columnDefsFileHistoryDetail = () => {
  const columnDefs: ColDef<MatterFileHistoryModel>[] = [
    { field: 'description', headerName: "Description" },
    {
      field: 'modifiedDate',
      headerName: "Date",
      valueFormatter: DateTimeFormatter,
      filterParams: {
        comparator: dateComparator
      },
    },
    { field: 'modifiedBy', headerName: "Author", flex: 1 },
  ];

  return columnDefs;
}