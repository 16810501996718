export enum GridAdvancedFilterDateOptions {
  Today = 1,
  CurrentWeek = 2,
  PreviousWeek = 3,
  CurrentMonth = 4,
  PreviousMonth = 5,
  CurrentPrevious3Months = 6,
  CurrentPrevious6Months = 7,
  CurrentFinancialYear = 8,
  PreviousFinancialYear = 9,
  Custom = 10
}