import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useState } from "react";
import { MdAdd } from "react-icons/md";
import { columnDefs } from "./columnDefs";
import { Row, Col, Card, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Title from "components/Title/index";
import SlidingPanel from "components/SlidingPanel/index";
import CustomModal from "components/Modal/Modal";
import CreateTemplate from "../TemplateActions/CreateTemplate/CreateTemplate";
import { getMatterTemplates } from "actions/settings";

export default function MatterTemplates() {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const [genericErrors, setGenericErrors] = useState(null);

  const createTemplate = () => {
    slidingPanelActions.setSlidingPanel(
      {
        width: "55rem",
        isShown: true,
        title: "Create Template",
        children: <CreateTemplate 
          refreshTemplatesPage={loadGridData}
          isMatterTemplate
        />
      }
    );
  };

  const loadGridData = () => {
    getMatterTemplates().then((response) => {
      gridActions.setGridRowData(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    });
  }

  const onGridReady = useCallback(() =>
  {
    gridActions.setGridColumnDefs([...columnDefs]);
    loadGridData();
  }, []);

  return (
    <>
      <Row>
        <Col>
          <Title type="section" title={"Matter Templates"}>
            {genericErrors && (
              <div className="lp-errors">
                {genericErrors}
              </div>
            )}
            <Button onClick={createTemplate} className="btn-icon" variant="success">
              <MdAdd />
            </Button>
          </Title>
          <Card className="with-grid">
            <Card.Body>
              <Grid 
                onGridReady={onGridReady}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <SlidingPanel />
      
      <CustomModal />
    </>
  );
}
