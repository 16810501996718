import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { IoCloudUploadOutline, IoTrashOutline } from 'react-icons/io5';
import { formatFileSize } from "utils/misc";
import './style.scss';

type Props = {
  onChange?: (value: any) => any;
  className?: string;
  isMulti?: boolean;
  accept?: string
};

const checkIfFileAlreadyExists = (file: File, fileArray: File[]) => {
  return fileArray.some(x => x.size == file.size && x.name == file.name && x.lastModified == file.lastModified && x.type == file.type);
}

export default function Upload(props: Props) {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const onChangeInputFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(props.isMulti) {
      const filesToAdd: File[] = Array.from(e.target.files!).filter((file: File) => !checkIfFileAlreadyExists(file, selectedFiles));
      const resultedFiles: File[] = [...Array.from(selectedFiles), ...filesToAdd];
      setSelectedFiles(resultedFiles);
      if (props.onChange) {
        props.onChange(resultedFiles);
      }
    } else {
      const resultedFiles: File[] = [...Array.from(e.target.files!)];
      setSelectedFiles(resultedFiles);
      if(props.onChange) {
        props.onChange(resultedFiles);
      }
    }
  };

  const removeFile = (fileNameToBeDeleted: string) => {
    const newFileList: any = Array.from(selectedFiles!).filter(file => file.name !== fileNameToBeDeleted);
    setSelectedFiles(newFileList);
    if (props.onChange) {
      props.onChange(newFileList);
    }
  }

  return (
    <>
      <div className="lp-dropzone">
        <input
          id="fileDropRef"
          className={props.className}
          type="file"
          onChange={onChangeInputFiles}
          multiple={props.isMulti ?? false}
          accept={props.accept}
        />
        <IoCloudUploadOutline />
        <p>
          <strong>Drag & drop file(s)</strong>
          {' '}
          or
          {' '}
          <label htmlFor="fileDropRef">Browse</label>
        </p>
        {
          props.accept &&
          <p>
            Accepted files: {props.accept}
          </p>
        }
      </div>
      {selectedFiles?.length != undefined && selectedFiles.length > 0 && (
        <div className="lp-upload-list">
          {selectedFiles.map((x: File, index: number) => (
            <div className="lp-upload-list-item" key={index}>
              <span className="name">{x.name}</span>
              <span className="size">{x?.size ? formatFileSize(x?.size, 2) : "—"}</span>
              <Button className="btn-icon" variant="danger" onClick={() => removeFile(x.name)}>
                <IoTrashOutline />
              </Button>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
