import { ICellRendererParams } from "ag-grid-community";
import { BailStatusIds } from "enums/BailStatusIds";
import { RiShieldCheckLine } from "react-icons/ri";
import { GiHandcuffed } from "react-icons/gi";

export default function ParticipatingEntityNameCellRenderer(props: ICellRendererParams) {
  return (
    <div className="lp-handcuffed">
      {props.data?.bailStatus?.id === BailStatusIds.RemandedInCustodyId ? <GiHandcuffed /> : ''}
      {props.value}
      {props.data?.isDefaultClient && <RiShieldCheckLine className="default-icon" />}
    </div>
  );
}
