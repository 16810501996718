import Field from "components/Fields/Field";
import { LteUserModel } from "models/view/LteUserModel";
import moment from "moment";
import { DateFormat } from "utils/constants";
import { Card, Row, Col } from "react-bootstrap";

type Props = {
  user: LteUserModel | undefined
}

export default function ViewUserPersonalInfo(props: Props) {
  return (
    <Card>
      <Card.Body>
        <Row className="multiple">
          <Col sm={6} xxl={4}>
            <Field
              label={"First Name"}
              value={props.user?.firstName}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"Middle Name"}
              value={props.user?.middleName}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"Last Name"}
              value={props.user?.lastName}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"Last Name at Birth"}
              value={props.user?.lastNameAtBirth}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"Date of Birth"}
              value={props.user?.dateOfBirth ?
                moment(props.user?.dateOfBirth).format(DateFormat.Moment) : "—"}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"Sex"}
              value={props.user?.sex?.name}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"Gender Pronoun"}
              value={props.user?.genderPronoun?.name}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"Prefix"}
              value={props.user?.prefix}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"Suffix"}
              value={props.user?.suffix}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"Salutation"}
              value={props.user?.salutation}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"Ethnicity"}
              value={props.user?.ethnicity?.name}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"National Insurance No."}
              value={props.user?.nationalInsuranceNo}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"Regulator"}
              value={props.user?.regulator?.name}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"Admission Or Call Date"}
              value={props.user?.admissionOrCallDate ?
                moment(props.user?.admissionOrCallDate).format(DateFormat.Moment) : "—"}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"Regulator Registration Reference"}
              value={props.user?.regulatorReference}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"Practicing Certificate Notes"}
              value={props.user?.practicingCertificateNotes}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"Emergency Contact Name"}
              value={props.user?.emergencyContactName}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"Emergency Contact Phone"}
              value={props.user?.emergencyContactPhone}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"Emergency Contact Email"}
              value={props.user?.emergencyContactEmail}
            />
          </Col>
          <Col sm={6} xxl={4}>
            <Field
              label={"Relationship To Emergency Contact"}
              value={props.user?.relationshipToEmergencyContact}
            />
          </Col>
          <Col xxl={4}>
            <Field
              label={"Languages Spoken"}
              value={
                props.user?.languagesSpoken?.length == 0 ?
                  "—" :
                  props.user?.languagesSpoken?.map(a => a.name).join(", ")
              }
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
