import { Conflict } from "enums/Conflict";

type Props = {
  value: string
}

export default function ConflictFieldValue(props: Props) {

  const checkConflict = () =>{
    switch(props.value)
    {
      case Conflict.Fail:
        return(
          <span className="lp-chip conflict danger">Fail</span>
        );
      case Conflict.Pending:
        return(
          <span className="lp-chip conflict warning">Pending</span>
        );
      case Conflict.Pass:
        return(
          <span className="lp-chip conflict success">Pass</span>
        );
    }
  }

  return (
    <>
      {checkConflict()}
    </>
  );
}
