import SlidingPanel from "components/SlidingPanel/index";
import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect, useState } from "react";
import { getColumnDefs } from "./columnDefs";
import { Row, Col, Card } from "react-bootstrap";
import CustomModal from "components/Modal/Modal";
import SummaryBox from 'components/SummaryBox';
import { SummaryBoxTypes } from "enums/SummaryBoxTypes";
import {
  downloadMatterDisbursements,
  getMatterDisbursements,
  getMatterDisbursementsSummaryInfo,
  printMatterDisbursements
} from "actions/matter";
import Title from "components/Title/index";
import { GridIds } from "enums/GridIds";
import { GridOptionButtonDataModel } from "models/view/GridOptionButtonDataModel";
import Loader from "components/Loader/index";
import useNotificationActions from "actions/notification";
import SummaryBoxesSection from "components/SummaryBoxesSection";
import { GridOptionTypes } from "enums/GridOptionTypes";
import { MatterDisbursementModel } from "models/view/MatterDisbursementModel";
import { downloadFile, printPdf } from "utils/misc";
import { NotificationTypes } from "enums/NotificationTypes";
import { MatterDisbursementsSummaryInfoModel } from "models/view/MatterDisbursementsSummaryInfoModel";

type Props = {
  matterId: string,
  matterFileNumber: string,
  syncMatterFiles: Function,
  matterIsClosed?: boolean
}

export default function MatterDisbursementList(props: Props) {
  const gridActions = useGridActions();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string | undefined>(undefined);
  const [matterDisbursementsSummaryInfo, setMatterDisbursementsSummaryInfo] = useState<MatterDisbursementsSummaryInfoModel | undefined>(undefined);
  const [isSummaryInfoLoading, setIsSummaryInfoLoading] = useState<boolean>(false);
  const [genericErrorsForSummaryInfo, setGenericErrorsForSummaryInfo] = useState(undefined);

  const notificationActions = useNotificationActions();

  const onGridReady = useCallback(() =>
  {
    loadMatterDisbursementsGrid();
  }, [props.matterId]);

  useEffect(() => {
    loadMatterDisbursementsSummaryInfo();
  }, [props.matterId]);

  useEffect(() => {
    gridActions.setGridId(`${GridIds.MatterDisbursements}/${props.matterId}`);
  }, []);

  const loadMatterDisbursementsGrid = () => {
    const columnDefs = getColumnDefs(() => populateMatterDisbursements(props.matterId));
    gridActions.setGridColumnDefs([...columnDefs]);

    populateMatterDisbursements(props.matterId);
  }

  const populateMatterDisbursements = (matterId?: string) => {
    if(matterId) {
      getMatterDisbursements(props.matterId ?? '').then((response) => 
      {
        gridActions.setGridRowData(response.data);
      });
    }
    else {
      gridActions.setGridRowData([]);
    }
  };

  const loadMatterDisbursementsSummaryInfo = () => {
    if(props.matterId) {
      setIsSummaryInfoLoading(true);
      getMatterDisbursementsSummaryInfo(props.matterId).then((response) => {
        setMatterDisbursementsSummaryInfo(response.data);
      })
      .catch((error) => {
        setGenericErrorsForSummaryInfo(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsSummaryInfoLoading(false);
      });
    }
    else{
      setMatterDisbursementsSummaryInfo(undefined);
    }
  }

  const gridOptions: GridOptionButtonDataModel[] = [
    {
      type: GridOptionTypes.Download,
      callback: (rows: MatterDisbursementModel[]) => downloadDisbursements(rows)
    },
    {
      type: GridOptionTypes.Print,
      callback: (rows: MatterDisbursementModel[]) => printDisbursements(rows)
    }
  ];

  const downloadDisbursements = (rows: MatterDisbursementModel[]) => {
    setIsLoading(true);
    setLoadingText("Downloading...");

    downloadMatterDisbursements(props.matterId, rows.map(x => x.id)).then((response) => {
      downloadFile(response);
    })
    .catch((error) => {
      error.response.data.text().then((text: any) => {
        let responseData = undefined;
        try {
          responseData = JSON.parse(text);
        }
        catch(e) {
          responseData = undefined;
        }
        notificationActions.addNotificationMessage(
          {
            type: NotificationTypes.Error,
            title: "Download Error",
            body: `${responseData?.Message ?? "There was an error while downloading the files."}`,
            isDismissable: true
          }
        );
      });
    })
    .finally(() => {
      setIsLoading(false);
      setLoadingText(undefined);
    });
  }

  const printDisbursements = (rows: MatterDisbursementModel[]) => {
    setIsLoading(true);
    setLoadingText("Preparing printing document...");

    printMatterDisbursements(rows[0].matterId, rows.map(x => x.id)).then((response) => {
      printPdf(response.data);
    })
    .catch((error) => {
      //the response is Blob here, we need to parse it
      error.response.data.text().then((text: any) => {
        let responseData = undefined;
        try {
          responseData = JSON.parse(text);
        }
        catch(e) {
          responseData = undefined;
        }
        notificationActions.addNotificationMessage(
          {
            type: NotificationTypes.Error,
            title: "Print Error",
            body: `${responseData?.Message ?? "There was an error printing the file."}`,
            isDismissable: true
          }
        );
      });
    })
    .finally(() => {
      setIsLoading(false);
      setLoadingText(undefined);
    });
  }

  return (
    <>
      {isSummaryInfoLoading &&
        <div className="lp-summary-boxes-placeholder">
          <Loader inlineLoader />
        </div>
      }

      {genericErrorsForSummaryInfo && (
        <div className="lp-errors">
          {genericErrorsForSummaryInfo}
        </div>
      )}

      {matterDisbursementsSummaryInfo && 
        <SummaryBoxesSection>
          <div className="lp-summary-boxes-area">
            <SummaryBox
              type={SummaryBoxTypes.DisbursementsDoughnut}
              values={[
                {
                  value: matterDisbursementsSummaryInfo.totalBilled ?? 0,
                  label: "Billed",
                  className: "lp-primary",
                },
                {
                  value: matterDisbursementsSummaryInfo.totalUnbilled ?? 0,
                  label: "Unbilled",
                  className: "lp-warning"
                },
                ...matterDisbursementsSummaryInfo.totalWriteOff ? [{
                  value: matterDisbursementsSummaryInfo.totalWriteOff ?? 0,
                  label: "Write-Off",
                  className: "lp-danger"
                }] : []
              ]}
            />
            <SummaryBox
              type={SummaryBoxTypes.UnpaidDisbursements}
              values={[
                {
                  value: matterDisbursementsSummaryInfo.totalUnpaid ?? 0
                }
              ]}
            />
          </div>
        </SummaryBoxesSection>
      }
      
      {isLoading && <Loader text={loadingText} />}

      <Row>
        <Col>
          <Title
            type="section"
            title={<>Disbursements</>}
          >
          </Title>
          <Card className="with-grid">
            <Card.Body>
              <Grid 
                onGridReady={onGridReady}
                gridOptions={gridOptions}
                pagination={true}
                paginationPageSize={25}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <SlidingPanel/>

      <CustomModal />
      <iframe className={"lp-print-helper-hidden"} />
    </>
  );
}
