import React, { useEffect, useState } from "react";
import { MatterModel } from "models/view/MatterModel";
import QuickSearchPanel from "components/QuickSearchPanel/index";
import { SortOrderTypes } from "enums/SortOrderTypes";
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from "hooks/appSelector";
import { searchMatters } from "actions/matter";
import { useParams } from "react-router-dom";
import CreateMatterForm from "../../CreateMatter/CreateMatterForm";
import MatterQuickSearchPanelItem from "./MatterQuickSearchPanelItem";

function MatterQuickSearchPanel() {
  const [mattersList, setMattersList] = useState<MatterModel[]>([]);
  const [quickSearchPanelSortOrder, setQuickSearchPanelSortOrder] = useState<SortOrderTypes>(SortOrderTypes.None);
  const [quickSearchPanelGenericErrors, setQuickSearchPanelGenericErrors] = useState(null);
  const [isQuickSearchPanelLoading, setIsQuickSearchPanelLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const slidingPanelState = useAppSelector((state) => state.slidingPanel);

  const { id } = useParams();

  useEffect(() => {
    loadMattersList();
  }, [id]);

  const loadMattersList = (searchText?: string) => {
    if(id) {
      setIsQuickSearchPanelLoading(true);
      searchMatters(searchText)
        .then((response) => {
          setMattersList(response.data);
        })
        .catch((error) => {
          setQuickSearchPanelGenericErrors(error.response?.data?.Message ?? error);
        })
        .finally(() => {
          setIsQuickSearchPanelLoading(false);
        });
    }
  };

  const sortMattersList = (matters: MatterModel[], sortOrder: SortOrderTypes) => {
    if(!matters || matters.length == 0) {
      return matters;
    }

    const sortedMatters = [...matters];
    switch(sortOrder) {
      case SortOrderTypes.None: {
        return sortedMatters;
      }
      case SortOrderTypes.Ascending: {
        return sortedMatters.sort((a: MatterModel, b: MatterModel) => (a.fileNumber < b.fileNumber ? -1 : 1));
      }
      case SortOrderTypes.Descending: {
        return sortedMatters.sort((a: MatterModel, b: MatterModel) => (a.fileNumber > b.fileNumber ? -1 : 1));
      }
    }
  };

  const searchMattersList = (searchText: string) => {
    if(searchText.length > 0 && searchText.length < 3) {
      return;
    }
    else {
      loadMattersList(searchText);
    }
  };

  const addMatter = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: !slidingPanelState.isShown,
        title: "Add Matter",
        children: <CreateMatterForm />
      });
  };

  return (
    <QuickSearchPanel
        title="Matters"
        searchBar
        onSearch={(searchText: string) => searchMattersList(searchText)}
        onSort={(sortOrder: SortOrderTypes) => setQuickSearchPanelSortOrder(sortOrder)}
        onFilter={() => {}}
        onAdd={addMatter}
        isLoading={isQuickSearchPanelLoading}
        genericErrors={quickSearchPanelGenericErrors}
      >
        {sortMattersList(mattersList, quickSearchPanelSortOrder).map((x: MatterModel) => {
          return (
            <React.Fragment key={x.id}>
              <MatterQuickSearchPanelItem
                matter={x}
                selectedMatterId={id}
              />
            </React.Fragment>
          )
        })}
      </QuickSearchPanel>
  );
}

export default MatterQuickSearchPanel;
