import { useContext } from 'react';
import { Card, Accordion, AccordionContext } from 'react-bootstrap';
import CustomAccordionToggleButton from './CustomAccordionToggleButton';

type Props = {
  header: React.ReactNode,
  children: React.ReactNode,
  eventKey: string,
  cardHeaderClassName?: string,
  callback?: any
}

export default function CustomAccordionItem(props: Props) {
  const { activeEventKey } = useContext(AccordionContext);

  const isCurrentEventKey = activeEventKey === props.eventKey || activeEventKey?.includes(props.eventKey);

  return (
    <Card>
      <Card.Header className={`${isCurrentEventKey ? 'opened ' : ''} ${props.cardHeaderClassName ?? ""}`}>
        {props.header}
        <CustomAccordionToggleButton eventKey={props.eventKey} callback={props.callback}/>
      </Card.Header>
      <Accordion.Collapse eventKey={props.eventKey}>
        <Card.Body>
          {props.children}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}
