import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationMessageModel } from "models/NotificationMessageModel";

export type NotificationMessagesState = {
  notificationMessages: NotificationMessageModel[],
  newNotificationAdded: boolean
}

const initialState: NotificationMessagesState = {
  notificationMessages: [],
  newNotificationAdded: false
}

export const notificationSlice = createSlice({
  name: 'natificationMessages',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<NotificationMessageModel>) => {
      state.notificationMessages = [...state.notificationMessages, action.payload];
      state.newNotificationAdded = true;
    },
    removeNotification: (state, action: PayloadAction<number>) => {
      state.notificationMessages.splice(action.payload, 1);
      state.newNotificationAdded = false;
    },
    clearNotifications: (state) => {
      state.notificationMessages = [];
      state.newNotificationAdded = false;
    },
    resetNewNotificationAdded: (state) => {
      state.newNotificationAdded = false;
    }
  },
})

export const { addNotification, removeNotification, clearNotifications, resetNewNotificationAdded } = notificationSlice.actions;

export default notificationSlice.reducer;
