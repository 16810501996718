import { TemplateModel } from 'models/view/TemplateModel';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Title from 'components/Title/index';
import Field from 'components/Fields/Field';
import { MdAdd, MdDownload, MdEdit, MdSell } from 'react-icons/md';
import CreateTemplate from '../CreateTemplate/CreateTemplate';
import useSlidingPanelActions from 'actions/slidingPanel';
import moment from 'moment';
import { DateFormat } from 'utils/constants';
import EditTemplate from '../EditTemplate/EditTemplate';
import { downloadTemplate } from 'actions/settings';
import { NotificationTypes } from 'enums/NotificationTypes';
import useNotificationActions from 'actions/notification';
import getTemplate from 'utils/enums';
import { downloadFile } from 'utils/misc';
import EditTemplateTaxonomyTermsForm from '../EditTemplateTaxonomyTerms/EditTemplateTaxonomyTermsForm';

type Props = {
  template: TemplateModel,
  refreshTemplatesPage: Function
};

export default function ViewCardTemplate(props: Props) {
  const slidingPanelActions = useSlidingPanelActions();
  const notificationActions = useNotificationActions();

  const createTemplate = () => {
    slidingPanelActions.setSlidingPanel(
      {
        width: "55rem",
        isShown: true,
        title: "Create Template",
        children: <CreateTemplate 
          refreshTemplatesPage={props.refreshTemplatesPage}
          typeId={props.template.typeId}
        />
      }
    );
  };

  const editTemplate = () => {
    slidingPanelActions.setSlidingPanel(
      {
        width: "55rem",
        isShown: true,
        title: "Edit Template",
        children: <EditTemplate 
          submitCallback={props.refreshTemplatesPage}
          templateId={props.template.id}
        />
      }
    );
  };

  const editTemplateTaxonomyTerms = () => {
    slidingPanelActions.setSlidingPanel(
      {
        width: "55rem",
        isShown: true,
        title: "Edit Taxonomy Terms",
        children: <EditTemplateTaxonomyTermsForm
          templateId={props.template.id}
        />
      }
    );
  };

  const downloadTemplateCallback = () => {
    downloadTemplate(props.template.id).then((response) => {
      downloadFile(response);
    })
    .catch((error) => {
      error.response.data.text().then((text: any) => {
        const responseData = JSON.parse(text);
        notificationActions.addNotificationMessage(
          {
            type: NotificationTypes.Error,
            title: "Download Template Error",
            body: `${responseData.Message ?? "There was an error downloading the template."}`,
            isDismissable: true
          }
        );
      });
    });
  };

  return (
    <>
      <Col xxl={4} className="mt-4 mt-xxl-0">
        {props.template.lteId == undefined && (
          <>
            <Title
              type="section"
              title={getTemplate(props.template.typeId)}
            >
              <Button onClick={createTemplate} className="btn-icon" variant="success">
                <MdAdd />
              </Button>
            </Title>
          </>
        )}
        {props.template.lteId != undefined && (
          <>
            <Title type="section" title={props.template.type.name}>
              <Button onClick={editTemplateTaxonomyTerms} className="btn-icon" variant="secondary-400">
                <MdSell />
              </Button>
              <Button onClick={downloadTemplateCallback} className="btn-icon" variant="success">
                <MdDownload />
              </Button>
              <Button onClick={editTemplate} className="btn-icon" variant="primary">
                <MdEdit />
              </Button>
            </Title>
          </>
        )}
        <Card>
          <Card.Body>
            {props.template.lteId == undefined && (
              <Row>
                <div className="lp-empty list">
                  <div className="image"></div>
                  <div className="text">No template found!</div>
                </div>
              </Row>
            )}
            {props.template.lteId != undefined && (
              <Row className="multiple">
                <Col sm={6}>
                  <Field label={'Name'} value={props.template.name} />
                </Col>
                <Col sm={6}>
                  <Field
                    label={'Last update'}
                    value={moment(props.template.modifiedOn).format(DateFormat.MomentWithTime)}
                  />
                </Col>
                <Col xs={12}>
                  <Field
                    label={'Description'}
                    value={props.template.description}
                  />
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
