import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback } from "react";
import { columnDefs } from "./columnDefs";
import { Card } from "react-bootstrap";
import Title from "components/Title/index";
import { getDefaultAccounts } from "actions/settings";
import SlidingPanel from "components/SlidingPanel/index";
import CustomModal from "components/Modal/Modal";

export default function DefaultAccountList() {
  const gridActions = useGridActions();

  const onGridReady = useCallback(() =>
  {
    gridActions.setGridColumnDefs([...columnDefs]);
    getDefaultAccounts().then((response) => 
    {
      gridActions.setGridRowData(response.data as any)
    })
  }, []); 

  return (
    <>
      <Title type="section" title={"Default Accounts"}>
      </Title>
      <Card className="with-grid">
        <Card.Body>
          <Grid 
            onGridReady={onGridReady}
          />
        </Card.Body>
      </Card>

      <SlidingPanel />
      
      <CustomModal />
    </>
  );
}
