import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type SlidingPanelState = {
  isShown: boolean;
  title: string;
  children?: React.ReactNode;
  width?: string;
  onCancel?: Function;
}

const initialState: SlidingPanelState = {
  isShown: false,
  title: "",
  children: undefined,
  width: undefined,
  onCancel: undefined
}

export const slidingPanelSlice = createSlice({
  name: 'slidingPanel',
  initialState,
  reducers: {
    setSlidingPanelData: (state, action: PayloadAction<SlidingPanelState>) => {
      state.title = action.payload.title;
      state.isShown = action.payload.isShown;
      state.children = action.payload.children;
      state.width = action.payload.width;
      state.onCancel = action.payload.onCancel;
    },
    toggleSlidingPanel: (state) => {
      state.isShown = !state.isShown;
    },
    clearSlidingPanelData: (state) => {
      state.title = "";
      state.isShown = false;
      state.children = <></>;
      state.width = undefined;
      state.onCancel = undefined;
    },
  },
})

export const { setSlidingPanelData, toggleSlidingPanel, clearSlidingPanelData } = slidingPanelSlice.actions;

export default slidingPanelSlice.reducer;
