import { ColDef, ColGroupDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { dateComparator } from "components/Grid/Comparators/DateComparator";
import { MatterClientLedgerEntryModel } from "models/view/MatterClientLedgerEntryModel";
import { DateFormatter } from "components/Grid/ValueFormatters/DateFormatter";
import EditMatterClientLedgerEntryForm from "./EditMatterClientLedgerEntry/EditMatterClientLedgerEntryForm";
import store from "state/store";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";
import ViewAccountLedgerTransaction from "components/SlidingPanel/ViewAccountLedgerTransaction/ViewAccountLedgerTransaction";

const viewTransactionCallback = (rowData: MatterClientLedgerEntryModel) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        width: "70rem",
        title: "View Ledger Transaction",
        children: <ViewAccountLedgerTransaction
          accountLedgerTransactionId={rowData.accountLedgerTransactionId}
        />
      }
    )
  );
}

const editCallback = (rowData: MatterClientLedgerEntryModel) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Edit Client Ledger Entry",
        children: <EditMatterClientLedgerEntryForm 
          matterClientLedgerEntryId={rowData.accountLedgerTransactionEntryId}
          />
      }
    )
  );
}

export const getColumnDefs = (matterIsClosed: boolean, hideClientAccountColumns: boolean) => {
  const columnDefs: (ColDef<MatterClientLedgerEntryModel> | ColGroupDef<MatterClientLedgerEntryModel> | {excludeFromExport: boolean})[] = [
    { 
      excludeFromExport: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      suppressMenu: true,
      suppressSizeToFit: true,
      floatingFilter: false,
      resizable: false,
      sortable: false,
      minWidth: 48,
      maxWidth: 48,
      width: 48,
      lockPosition: true,
      pinned: 'left'
    },
    { 
      headerName: 'Date',
      field: 'accountLedgerTransactionDate',
      filter: 'agDateColumnFilter',
      sortable: false,
      suppressMenu: true,
      valueFormatter: DateFormatter,
      filterParams: {
        comparator: dateComparator
      },
      minWidth: 120
    },
    { 
      headerName: 'Details',
      field: 'accountLedgerTransactionDescription',
      filter: 'agTextColumnFilter',
      sortable: false,
      suppressMenu: true,
      minWidth: 400,
      flex: 1
    },
    { 
      headerName: 'Business Account',
      children: [
        {
          headerName: 'DR',
          field: 'businessSideDRAmount',
          filter: 'agNumberColumnFilter',
          valueFormatter: CurrencyFormatter,
          sortable: false,
          suppressMenu: true
        },
        {
          headerName: 'CR',
          field: 'businessSideCRAmount',
          filter: 'agNumberColumnFilter',
          valueFormatter: CurrencyFormatter,
          sortable: false,
          suppressMenu: true
        },
        {
          headerName: 'BAL',
          field: 'businessSideBalance',
          filter: 'agNumberColumnFilter',
          valueFormatter: CurrencyFormatter,
          sortable: false,
          suppressMenu: true
        }
      ]
    },
    { 
      headerName: 'Client Account',
      children: [
        {
          headerName: 'DR',
          field: 'clientSideDRAmount',
          filter: 'agNumberColumnFilter',
          valueFormatter: CurrencyFormatter,
          sortable: false,
          suppressMenu: true,
          hide: hideClientAccountColumns
        },
        {
          headerName: 'CR',
          field: 'clientSideCRAmount',
          filter: 'agNumberColumnFilter',
          valueFormatter: CurrencyFormatter,
          sortable: false,
          suppressMenu: true,
          hide: hideClientAccountColumns
        },
        {
          headerName: 'BAL',
          field: 'clientSideBalance',
          filter: 'agNumberColumnFilter',
          valueFormatter: CurrencyFormatter,
          sortable: false,
          suppressMenu: true,
          hide: hideClientAccountColumns
        }
      ]
    },
    { 
      headerName: 'Notes',
      field: 'accountLedgerTransactionEntryNotes',
      filter: 'agTextColumnFilter',
      sortable: false,
      suppressMenu: true
    },
    { 
      excludeFromExport: true,
      headerName: 'Actions',
      cellClass: 'lp-actions-cell',
      suppressMenu: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        if(matterIsClosed) {
          return { buttonsData: [
            {
              type: ActionButtonTypes.View,
              callback: () => viewTransactionCallback(params.data)
            }
          ]}
        }
        else {
          return { buttonsData: [
            {
              type: ActionButtonTypes.View,
              callback: () => viewTransactionCallback(params.data)
            },
            {
              type: ActionButtonTypes.Edit,
              callback: () => editCallback(params.data)
            }
          ]};
        }
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 145,
      maxWidth: 145,
      width: 145,
      lockPosition: true,
      pinned: 'right',
      resizable: false,
      hide: matterIsClosed
    }
  ];

  return columnDefs;
}