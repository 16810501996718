import { ColDef } from "ag-grid-community";
import store from "state/store";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import EditDefaultAccountForm from "./EditDefaultAccount/EditDefaultAccountForm";
import { DefaultAccountModel } from "models/view/DefaultAccountModel";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { getDefaultAccountTypesSummary } from "actions/settings";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";

const editCallback = (id: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Edit Default Account",
        children: <EditDefaultAccountForm defaultAccountId={id}/>
      }
    )
  );
}

export const columnDefs: (ColDef<DefaultAccountModel>| {excludeFromExport: boolean})[] = [
  { 
    excludeFromExport: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    suppressMenu: true,
    suppressSizeToFit: true,
    floatingFilter: false,
    resizable: false,
    sortable: false,
    minWidth: 48,
    maxWidth: 48,
    width: 48,
    lockPosition: true,
    pinned: 'left'
  },
  {
    headerName: 'Display Name',
    field: 'displayName',
    filter: 'agTextColumnFilter',
    minWidth: 120,
    lockPosition: true,
    pinned: 'left'
  },
  {
    headerName: 'Account Code',
    field: 'accountCode',
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
    minWidth: 120
  },
  {
    headerName: 'Xero Account Code',
    field: 'xeroAccountCode',
    filter: 'agTextColumnFilter',
    minWidth: 120
  },
  {
    headerName: 'Type',
    field: 'defaultAccountType.displayName',
    filter: DropdownFilter,
    filterParams: {
      property: 'defaultAccountTypeId',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: DropdownFloatingFilter,
    floatingFilterComponentParams: {
      endpointCall: getDefaultAccountTypesSummary
    },
    suppressMenu: true,
    minWidth: 220,
    flex: 1
  },
  {
    headerName: 'Is Active',
    field: 'isActive',
    filter: BooleanFilter,
    filterParams: {
      property: 'isActive',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: BooleanFloatingFilter,
    floatingFilterComponentParams: {
      values: [{
        id: 'true',
        name: 'Yes'
      },
      {
        id: 'false',
        name: 'No'
      },
    ]},
    minWidth: 100,
    width: 100,
    cellRenderer: CheckboxCellRenderer,
    valueFormatter: CheckboxFormatter
  },
  { 
    excludeFromExport: true,
    headerName: 'Actions',
    cellClass: 'lp-actions-cell',
    suppressMenu: true,
    cellRenderer: ActionsCellRenderer,
    cellRendererParams: (params: any) => {
      return { 
        buttonsData: [{
          type: ActionButtonTypes.Edit,
          callback: editCallback
        }]
      };
    },
    suppressSizeToFit: true,
    floatingFilter: false,
    sortable: false,
    minWidth: 145,
    maxWidth: 145,
    width: 145,
    lockPosition: true,
    pinned: 'right',
    resizable: false
  }
];
