export enum AccountLedgerTransactionTypes {
  Disbursement = 1,
  DisbursementPayment = 2,
  IncidentalExpense = 3,
  Invoice = 4,
  InvoicePayment = 5,
  ReceiveMoney = 6,
  FundsTransfer = 7,
  DisbursementWriteOff = 8,
  IncidentalExpenseWriteOff = 9,
  InvoiceWriteOff = 10,
  ReturnMoney = 11,
  InvoiceAbatement = 12
}