import { useEffect, useState } from "react";
import Title from 'components/Title';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TabPanel } from "react-tabs";
import { MatterModel } from "models/view/MatterModel";
import {
  createMatterFolderShortcut,
  deleteMatterInvoice,
  getDraftMatterInvoice,
  getMatterActivityHistory,
  getMatterById,
  getMatterDashboardEvents,
  syncMatterFiles
} from 'actions/matter';
import useSlidingPanelActions from 'actions/slidingPanel';
import ViewMatterDetails from "./ViewMatterDetails/ViewMatterDetails";
import { Button, Dropdown, Spinner } from 'react-bootstrap';
import { MdExpandMore, MdMoreTime, MdCheckCircle, MdAddCircleOutline } from 'react-icons/md';
import { BiImport } from 'react-icons/bi';
import { IoMdOptions } from 'react-icons/io';
import ActivityHistoryGrid from "./ActivityHistory/ActivityHistoryGrid/ActivityHistoryGrid";
import { useAppSelector } from 'hooks/appSelector';
import moment from "moment";
import { DateFormat } from "utils/constants";
import RecordTime from "../RecordTime/RecordTime";
import CreateMatterNoteForm from "../CreateMatterNote/CreateMatterNoteForm";
import store from "state/store";
import { GridIds } from "enums/GridIds";
import useGridActions from "actions/grid";
import useNotificationActions from "actions/notification";
import { NotificationTypes } from "enums/NotificationTypes";
import UploadMatterFilesForm from "../UploadMatterFilesForm/UploadMatterFilesForm";
import MatterQuickSearchPanel from "./MatterQuickSearchPanel/MatterQuickSearchPanel";
import TooltipIcon from 'components/TooltipIcon';
import ManualAddEmails from "../AddEmails/ManualAddEmails";
import CreateMatterDisbursementForm from "../CreateMatterDisbursement/CreateMatterDisbursementForm";
import CreateMatterIncidentalExpensesForm from "../CreateMatterIncidentalExpenses/CreateMatterIncidentalExpensesForm";
import CreateDraftEmail from "../CreateDraftEmail/CreateDraftEmail";
import MatterClientLedger from "./MatterClientLedger/MatterClientLedger";
import CreateMatterInvoiceForm from "../CreateMatterInvoice/CreateMatterInvoiceForm";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import EditMatterInvoiceForm from "./ActivityHistory/MatterInvoice/EditMatterInvoice/EditMatterInvoiceForm";
import CustomTabs from "components/CustomTabs";
import MatterAppointments from "./MatterAppointments/MatterAppointments";
import CreateMatterReceiveMoneyForm from "../CreateMatterReceiveMoney/CreateMatterReceiveMoneyForm";
import CreateMatterFundsTransferForm from "../CreateMatterFundsTransfer/CreateMatterFundsTransferForm";
import CreateCalendarEvent from "containers/Calendar/CreateCalendarEvent/CreateCalendarEvent";
import useWindowSize from "hooks/windowSize";
import ManualAddAppointments from "../AddAppointments/ManualAddAppointments";
import ReopenMatterForm from "../ReopenMatter/ReopenMatterForm";
import { FaLockOpen } from "react-icons/fa";
import { openUrlInNewtab } from "utils/misc";
import CreateMatterDocument from "../CreateMatterDocument/CreateMatterDocument";
import MatterIncidentalExpenseList from "./MatterIncidentalExpenses/MatterIncidentalExpenseList";
import MatterDisbursementList from "./MatterDisbursements/MatterDisbursementList";
import MatterRecordableItemList from "./MatterRecordableItems/MatterRecordableItemList";
import CreateMatterReturnMoneyForm from "../CreateMatterReturnMoney/CreateMatterReturnMoneyForm";

type State = {
  edit: boolean
}

function ViewMatter(){
  const location = useLocation();
  const state = location.state as State ?? undefined;
  const navigate = useNavigate();
  const [matter, setMatter] = useState<MatterModel | undefined>(undefined);
  const [matterFolderLastSyncDate, setMatterFolderLastSyncDate] = useState<Date | undefined>(undefined);
  const [edit, setEdit] = useState(state?.edit ?? false);
  const [startDate, setStartDate] = useState<Date>(moment().startOf('week').toDate());
  const [endDate, setEndDate] = useState<Date>(moment(startDate).add(3, 'month').endOf('week').toDate());
  
  const slidingPanelActions = useSlidingPanelActions();
  const slidingPanelState = useAppSelector((state) => state.slidingPanel);
  const [isSyncingMatterFiles, setIsSyncingMatterFiles] = useState<boolean>(false);
  const [syncMatterFilesSucceded, setSyncMatterFilesSucceded] = useState(false);
  const gridActions = useGridActions();
  const notificationActions = useNotificationActions();
  const modalActions = useModalActions();
  const [width, height] = useWindowSize();
  const user = useAppSelector((state) => state.user);

  const { id } = useParams();

  const loadMatterSummary = () => {
    getMatterById(id ?? '').then((response) => {
      setMatter(response.data);
      setMatterFolderLastSyncDate(response.data.storageFolderLastSyncDate);

      if(!response.data.isClosed) {
        //sync matter files
        matterFilesSync();
      }
    }).catch((error) => {
      if(error.response.status == 404 || error.response.status == 400) {
        navigate('/error404');
      }
    });
  };
  
  const recordTime = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: !slidingPanelState.isShown,
        title: "Record Time",
        width: "55rem",
        children: <RecordTime 
          matterId={id!}
          chargingSchemeId={matter?.chargingSchemeId ?? ""}
        />
      });
  };

  useEffect(() => {
    loadMatterSummary();
  }, [id]);

  const matterFilesSync = () => {
    setIsSyncingMatterFiles(true);
    syncMatterFiles(id ?? '').then((response) => {
      setMatterFolderLastSyncDate(response.data.storageFolderLastSyncDate);
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Success,
          title: "Sync files",
          body: "Operation finished",
          isDismissable: true
        }
      );
      setSyncMatterFilesSucceded(true);
      const gridState = store.getState().grid;
      if(id && gridState.id == `${GridIds.ActivityHistory}/${id}`) {
        getMatterActivityHistory(id!).then((response) => 
        {
          gridActions.setGridRowData(response.data);
        });
      }
    })
    .catch((error) => {
      setSyncMatterFilesSucceded(false);
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Error,
          title: "Sync Files Error",
          body: error.response?.data?.Message ?? error.message,
          isDismissable: true
        }
      );
    })
    .finally(() => {
      setIsSyncingMatterFiles(false);
    });
  };

  const addMatterNote = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: !slidingPanelState.isShown,
        title: "Create Matter Note",
        children: <CreateMatterNoteForm matterId={id!}/>
      }
    );
  };

  const addMatterDisbursement = () => {
    slidingPanelActions.setSlidingPanel(
      {
        width: "55rem",
        isShown: !slidingPanelState.isShown,
        title: "Create Matter Disbursement",
        children: <CreateMatterDisbursementForm matterId={id!}/>
      }
    );
  };

  const addMatterDocument = () => {
    if(matter) {
      slidingPanelActions.setSlidingPanel(
        {
          isShown: !slidingPanelState.isShown,
          title: "Create Matter Document",
          children: <CreateMatterDocument matterId={id!} matterTypeId={matter.matterTypeId}/>
        }
      );
    }
  };

  const addMatterIncidentalExpenses = () => {
    slidingPanelActions.setSlidingPanel(
      {
        width: "55rem",
        isShown: !slidingPanelState.isShown,
        title: "Create Matter Incidental Expenses",
        children: <CreateMatterIncidentalExpensesForm matterId={id!}/>
      }
    );
  };

  const addMatterInvoice = () => {
    const matterId = id!;
    getDraftMatterInvoice(matterId).then((response) => {
      const draftInvoiceId = response.data?.id;

      if(draftInvoiceId) {
        let modal: ModalState = {
          title: "Create info",
          body: 
            <>
              <p>There is a draft invoice on this matter with ref number: {response.data.invoiceRefNumber}.</p>
              <div>Do you want to edit it or delete it and create a new invoice?</div>
            </>,
          actionText: "Edit current Invoice",
          onAction: () => {
            modalActions.toggleModalShownStatus();
            slidingPanelActions.setSlidingPanel(
              {
                width: "70rem",
                isShown: !slidingPanelState.isShown,
                title: "Edit Matter Invoice",
                children: <EditMatterInvoiceForm matterId={matterId} matterInvoiceId={draftInvoiceId!} onSubmitCallback={matterFilesSync}/>
              }
            );
          },
          secondActionText: "Start new Invoice",
          onSecondAction: () => {
            modalActions.toggleModalLoading();
            deleteMatterInvoice(matterId, draftInvoiceId!).then(() => {
              matterFilesSync();

              slidingPanelActions.setSlidingPanel(
                {
                  width: "70rem",
                  isShown: !slidingPanelState.isShown,
                  title: "Create Matter Invoice",
                  children: <CreateMatterInvoiceForm matterId={matterId}/>
                }
              );
              modalActions.toggleModalShownStatus();
            })
            .catch((error) => {
              modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
            })
            .finally(() => {
              modalActions.toggleModalLoading();
            });
          },
          show: true
        }
        modalActions.setModal(modal);
      }
      else {
        slidingPanelActions.setSlidingPanel(
          {
            width: "70rem",
            isShown: !slidingPanelState.isShown,
            title: "Create Matter Invoice",
            children: <CreateMatterInvoiceForm matterId={matterId}/>
          }
        );
      }
    })
    .catch((error) => {
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Error,
          title: "Create Invoice Error",
          body: error.response?.data?.Message ?? error.message,
          isDismissable: true
        }
      );
    });
  };

  const uploadFiles = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: !slidingPanelState.isShown,
        title: "Upload Files",
        children: <UploadMatterFilesForm matterId={id!} syncMatterFiles={matterFilesSync}/>
      }
    );
  };

  const manualAddEmails = () => {
    slidingPanelActions.setSlidingPanel(
      {
        width: "70rem",
        isShown: !slidingPanelState.isShown,
        title: "Import Emails",
        children: <ManualAddEmails matterId={id!} onSubmitCallback={matterFilesSync}/>
      }
    );
  };

  const manualAddAppointments = () => {
    slidingPanelActions.setSlidingPanel(
      {
        width: "70rem",
        isShown: !slidingPanelState.isShown,
        title: "Import Appointments",
        children: <ManualAddAppointments matterId={id!}/>
      }
    );
  };

  const createDraftEmail = () => {
    slidingPanelActions.setSlidingPanel(
      {
        width: "50rem",
        isShown: !slidingPanelState.isShown,
        title: "Add Draft Email",
        children: <CreateDraftEmail matterId={id!} matterFileNumber={matter?.fileNumber ?? ""}/>
      }
    );
  };

  const createAppointment = () => {
    const userLocalTime = moment();
    const nearestLower30Minutes = userLocalTime.clone().startOf('hour').add(Math.floor(userLocalTime.minutes() / 30) * 30, 'minutes');
    const nearestUpper30Minutes = nearestLower30Minutes.clone().add(30, 'minutes');
    
    slidingPanelActions.setSlidingPanel(
      {
        width: "50rem",
        isShown: !slidingPanelState.isShown,
        title: "Add Appointment",
        children: <CreateCalendarEvent 
          startDate={nearestLower30Minutes.toDate()}
          endDate={nearestUpper30Minutes.toDate()}
          isAllDay={false}
          matterId={id}
          reloadEvents={reloadGridForAppointmentCreationCallback}
        />
      }
    )
  }
  
  const addMatterReceiveMoney = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: !slidingPanelState.isShown,
        title: "Create Receive Money",
        children: <CreateMatterReceiveMoneyForm matterId={id!}/>
      }
    );
  };

  const addMatterReturnMoney = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: !slidingPanelState.isShown,
        title: "Create Return Money",
        children: <CreateMatterReturnMoneyForm matterId={id!}/>
      }
    );
  };

  const addMatterFundsTransfer = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: !slidingPanelState.isShown,
        title: "Transfer Funds",
        children: <CreateMatterFundsTransferForm matterId={id!}/>
      }
    );
  };

  const reopenMatter = () => {
    if(matter) {
      slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Reopen Matter",
          children: <ReopenMatterForm matterId={id!} matterTypeId={matter.matterTypeId} setMatter={setMatter}/>
        }
      );
    }
  };

  const createMatterFolderShortcutAction = () => {
    const matterId = id!;
    
    let modal: ModalState = {
      title: "Create Matter Folder Shortcut",
      body: "Are you sure you want to create the shortcut to your OneDrive for the matter folder?",
      actionText: "Add",
      onAction: () => createFolderShortcut(matterId),  
      show: true
    }
    modalActions.setModal(modal);
  };

  const createFolderShortcut = (matterId: string) => {
    modalActions.toggleModalLoading();
    createMatterFolderShortcut(matterId).then(() => {
      modalActions.toggleModalShownStatus();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    }).finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  const reloadGridForAppointmentCreationCallback = () => {
    const gridState = store.getState().grid;
    if (gridState.id == `${GridIds.Appointments}/${id}`) {
      getMatterDashboardEvents(id!, startDate.toISOString(), endDate.toISOString()).then((response) => 
      {
        gridActions.setGridRowData(response.data);
      });
    }
  }

  return(
    <div className="lp-page-with-sidebar">
      <MatterQuickSearchPanel />

      <div className="lp-page-content">
        <Title type='page' 
          title={
            <>
              <strong key={0}>Matter </strong> <span key={1}>{matter?.fileNumberWithDisplayName}</span>
              <span className={`lp-sync-message${isSyncingMatterFiles ? ' syncing' : (syncMatterFilesSucceded ? ' succeded' : ' failed')}`}>
                {isSyncingMatterFiles && (
                  <>
                    <Spinner className="lp-spinner" animation="border" />
                    Syncing files
                  </>
                )}
                {!isSyncingMatterFiles && (
                  <>
                    {!syncMatterFilesSucceded && 
                      <TooltipIcon type="error" text="Error while syncing files" iconPosition="left" />
                    }
                    {syncMatterFilesSucceded && 
                      <MdCheckCircle />
                    } 
                    Last sync: {matterFolderLastSyncDate ?
                    moment(matterFolderLastSyncDate).format(DateFormat.MomentWithTime) : "—"}
                  </>
                )}
              </span>
            </>
          }>
          {!matter?.isClosed &&
            <>
              <Dropdown>
                <Dropdown.Toggle variant="secondary-400">
                  {width < 1600 ? <IoMdOptions /> : <MdExpandMore /> }
                  <span>More options</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => navigate(`/matter/${id}/taxonomy`)}>View Matter Taxonomy</Dropdown.Item>
                  {matter?.storageFolderWebUrl &&
                    <Dropdown.Item onClick={() => openUrlInNewtab(matter?.storageFolderWebUrl)}>View Matter Folder</Dropdown.Item>
                  }
                  {matter?.storageFolderWebUrl &&
                    <Dropdown.Item onClick={() => createMatterFolderShortcutAction()}>Add Matter Folder Shortcut to OneDrive</Dropdown.Item>
                  }
                  {user.lteHasClientBankAccounts && 
                    <Dropdown.Item onClick={addMatterReceiveMoney}>Receive Money</Dropdown.Item>
                  }
                  {user.lteHasClientBankAccounts && 
                    <Dropdown.Item onClick={addMatterReturnMoney}>Return Money</Dropdown.Item>
                  }
                  {user.lteHasClientBankAccounts && 
                    <Dropdown.Item onClick={addMatterFundsTransfer}>Transfer Funds</Dropdown.Item>
                  }
                  <Dropdown.Item onClick={() => matterFilesSync()}>Re-sync Matter Folder</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Button variant="primary" onClick={recordTime}>
                <MdMoreTime />
                <span>Record time</span>
              </Button>
        
              <Dropdown>
                <Dropdown.Toggle variant="secondary-400">
                  {width < 1600 ? <BiImport /> : <MdExpandMore /> }
                  <span>Import</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={uploadFiles}>Files</Dropdown.Item>
                  <Dropdown.Item onClick={manualAddEmails}>Mail Messages</Dropdown.Item>
                  <Dropdown.Item onClick={manualAddAppointments}>Appointments</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown>
                <Dropdown.Toggle variant="success">
                  {width < 1600 ? <MdAddCircleOutline /> : <MdExpandMore /> }
                  <span>Create</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={addMatterNote}>Activity History Note</Dropdown.Item>
                  <Dropdown.Item onClick={createAppointment}>Appointment</Dropdown.Item>
                  <Dropdown.Item onClick={addMatterDisbursement}>Disbursement</Dropdown.Item>
                  <Dropdown.Item onClick={addMatterDocument}>Document</Dropdown.Item>
                  <Dropdown.Item onClick={createDraftEmail}>Draft Email</Dropdown.Item>
                  <Dropdown.Item onClick={addMatterIncidentalExpenses}>Incidental Expense</Dropdown.Item>
                  <Dropdown.Item onClick={addMatterInvoice}>Invoice</Dropdown.Item>
                  <Dropdown.Item onClick={recordTime}>Recordable Event</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          }
          {matter?.isClosed &&
            <Button variant="secondary-400" onClick={reopenMatter}>
              <FaLockOpen />
              <span>Update Matter Stage</span>
            </Button>
          }
        </Title>
        
        <CustomTabs 
          tabList={[
            {
              name: 'Details',
              panel: <TabPanel>
                <ViewMatterDetails matter={matter} edit={edit} setMatter={setMatter}/>
              </TabPanel>
            },
            {
              name: 'Appointments',
              panel: <TabPanel>
                <MatterAppointments 
                  matterId={id!}
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              </TabPanel>
            },
            {
              name: 'Activity History',
              panel: <TabPanel>
                {matter &&
                  <ActivityHistoryGrid
                    matterId={matter.id}
                    matterFileNumber={matter.fileNumber!}
                    syncMatterFiles={matterFilesSync}
                    matterIsClosed={matter.isClosed}
                  />
                }
              </TabPanel>
            },
            {
              name: 'Client Ledger',
              panel: <TabPanel>
                {matter &&
                  <MatterClientLedger 
                    matterId={matter.id}
                    matterIsClosed={matter.isClosed}
                  />
                }
              </TabPanel>
            },
            {
              name: 'Recordable Items',
              panel: <TabPanel>
                {matter &&
                  <MatterRecordableItemList
                    matterId={matter.id}
                    matterFileNumber={matter.fileNumber!}
                    syncMatterFiles={matterFilesSync}
                    matterIsClosed={matter.isClosed}
                  />
                }
              </TabPanel>
            },
            {
              name: 'Incidental Expenses',
              panel: <TabPanel>
                {matter &&
                  <MatterIncidentalExpenseList
                    matterId={matter.id}
                    matterFileNumber={matter.fileNumber!}
                    syncMatterFiles={matterFilesSync}
                    matterIsClosed={matter.isClosed}
                  />
                }
              </TabPanel>
            },
            {
              name: 'Disbursements',
              panel: <TabPanel>
                {matter &&
                  <MatterDisbursementList
                    matterId={matter.id}
                    matterFileNumber={matter.fileNumber!}
                    syncMatterFiles={matterFilesSync}
                    matterIsClosed={matter.isClosed}
                  />
                }
              </TabPanel>
            },
          ]}
        />
      </div>
    </div>
  );
}

export default ViewMatter;
