import NotFoundImage from 'assets/images/lp-404.webp';
import { NavLink } from 'react-router-dom';
import './style.scss';

function PageNotFound() {
  return (
    <div className="lp-404-page">
      <div className="lp-404-wrapper">
        <img src={NotFoundImage} alt="LawPage 404 - Page Not Found" />
        <div className="lp-404-text">
          <h1>Ooops!</h1>
          <h4>Something's wrong here.</h4>
          <p>This is a 404 error which means you've clicked on a bad link or entered an invalid URL.</p>
          <NavLink to="/" className="btn btn-primary">GO TO DASHBOARD</NavLink>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
