import React from "react";
import Loader from "components/Loader/index";
import IconButtonGrid from "components/Buttons/IconButtonGrid";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { MdLock, MdOutlineLink } from "react-icons/md";
import { FaStar } from 'react-icons/fa';
import { ContactModel } from "models/view/ContactModel";
import { useAppSelector } from "hooks/appSelector"
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import './style.scss';

type Props = {
  listItems: any[],
  listButtons: Array<{type: ActionButtonTypes, callback?: Function}>,
  loadState?: boolean,
  children?: React.ReactNode,
  hideTypeColumn?: boolean,
  displayUnlinkButton?: boolean
}

export default function ListContact(props: Props) {
  const loggedInUser = useAppSelector((state) => state.user);
  return (
    <Card className="with-list contact-list">
      <Card.Header>{props.children}</Card.Header>
      {props.loadState && <Loader inlineLoader={props.loadState} />}
      <Card.Body>
        <ul className="lp-list-grid">
          {props.listItems.length == 0 && (
            <div className="lp-empty list">
              <div className="image"></div>
              <div className="text">No rows found!</div>
            </div>
          )}

          {props.listItems.map((x: ContactModel, index: number) => {
            return (
              <React.Fragment key={x.id + index.toString()}>
                <li>
                  <div className="lp-list-grid-item-name">
                    {x.contact}
                    {x.additionalInfo !== undefined && ' (Ext: ' + x.additionalInfo + ')'}
                    {x.isPreferred && <FaStar fill="var(--lp-warning)" />}
                    {x.systemOwned && <MdLock fill="var(--lp-secondary)" />}
                    {x.isLinked && 
                      <OverlayTrigger
                        key={"overlay" + x.id + index.toString()}
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-right">{"Linked from an entity"}</Tooltip>
                        }
                      >
                        <a href="#"><MdOutlineLink fill="var(--lp-success)" /></a>
                      </OverlayTrigger>
                    }
                  </div>
                  {!props.hideTypeColumn && 
                    <div className="lp-list-grid-item-type">
                      {x.type}
                    </div>
                  }
                  <div className="lp-list-grid-item-actions">
                    {props.listButtons.map((el: {type: ActionButtonTypes, callback?: Function}, index: number) => {
                      if(el.type === ActionButtonTypes.Unlink && !x.isLinked && !props.displayUnlinkButton){
                        return <React.Fragment key={index}></React.Fragment>;
                      }
                      //if system owned, button is EditOnlyIsPreferred and user has permission to full edit or => hide button
                      else if(el.type === ActionButtonTypes.EditOnlyIsPreferred && x.systemOwned &&
                        loggedInUser.userPermissions?.find(a => a == UserPermissionsNames.ManageSystemOwnedContacts) !== undefined && !x.isLinked) {
                        return <React.Fragment key={index}></React.Fragment>;
                      }
                      //if is not system owned, is not linked to entity and button is EditOnlyIsPreferred => hide button
                      else if(el.type === ActionButtonTypes.EditOnlyIsPreferred && !x.systemOwned && !x.isLinked) {
                        return <React.Fragment key={index}></React.Fragment>;
                      }
                      //if system owned, button is Edit or Delete and user doesn't have permission to full edit => hide button
                      else if((el.type === ActionButtonTypes.Edit || el.type === ActionButtonTypes.Delete) && x.systemOwned &&
                        (loggedInUser.userPermissions?.find(a => a == UserPermissionsNames.ManageSystemOwnedContacts) === undefined)) {
                        return <React.Fragment key={index}></React.Fragment>;
                      }
                      else if((el.type === ActionButtonTypes.Edit || el.type === ActionButtonTypes.Delete) && x.isLinked){
                        return <React.Fragment key={index}></React.Fragment>;
                      }
                      else {
                        return IconButtonGrid({
                          value: el.type !== ActionButtonTypes.Call && el.type !== ActionButtonTypes.Email ? x.id : x.contact,
                          type: el.type,
                          callback: () => {
                            if(el.type !== ActionButtonTypes.Call && el.type !== ActionButtonTypes.Email && el.callback) {
                              el.callback(x.id);
                            }
                            return;
                          }, 
                          id: x.id + index.toString()
                        });
                      }
                    })}
                  </div>
                </li>
              </React.Fragment>
            )
          })}
        </ul>
      </Card.Body>
    </Card>
  );
}
