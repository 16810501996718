import { ICellRendererParams } from "ag-grid-community";

export default function CheckboxCellRenderer(props: ICellRendererParams) {
  return (
    <>
      {props.value != undefined
      ? props.value 
        ?
          <span className="lp-chip boolean success">Yes</span>
        :
          <span className="lp-chip boolean secondary">No</span>
      :
        <></>
      }
    </>
  );
}
