export enum UserPermissionsNames {
  ViewLTEs = "View LawPage Trading Entities",
  EditLTEs = "Edit LawPage Trading Entities",
  EditOwnLTE = "Edit Own LawPage Trading Entity",
  EditUsers = "Edit Users",
  ManageChargingSchemes = "Manage Charging Schemes",
  EditUserLTEInfo = "Edit User LTE Info",
  ManageSystemOwnedContacts = "Manage System Owned Contacts",
  ManageLTESettings = "Manage LTE Settings",
  ManageDefaultSettings = "Manage Default Settings"
}