import { ICellRendererParams } from "ag-grid-community";
import './style.scss';
import RiskRatingFieldValue from "components/Fields/RiskRatingFieldValue";

export default function RiskRatingCellRenderer(props: ICellRendererParams) {

  return (
    <RiskRatingFieldValue value={props.value} />
  );
}
