import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col } from "react-bootstrap";
import { getMatterFeeEarnerById } from "actions/matter";
import { MatterFeeEarnerModel } from "models/view/MatterFeeEarnerModel";
import BooleanField from "components/Fields/BooleanField";
import ViewMatterFeeEarnerPreferredContacts from "../ViewMatterFeeEarnerPreferredContacts";
import ViewMatterFeeEarnerProfile from "../ViewMatterFeeEarnerProfile";

type Props = {
  matterFeeEarnerId: string
}

function ViewMatterFeeEarner(props: Props){
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const [matterFeeEarner, setMatterFeeEarner] = useState<MatterFeeEarnerModel>();

  useEffect(()=>{
    setIsLoading(true);
    getMatterFeeEarnerById(props.matterFeeEarnerId).then((response) => {
      setMatterFeeEarner(response.data)
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  },[])

  return(
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <ViewMatterFeeEarnerProfile 
            matterFeeEarner={matterFeeEarner!}
            showRole={false}
          />
        </Col>
      </Row>
      <div className="lp-entity-info">
        <Row>
          <Col>
            <Field
              label={"Fee Earner Level"}
              value={matterFeeEarner?.feeEarnerLevel?.name}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <BooleanField
              label={"Is Case Manager"}
              value={matterFeeEarner?.isCaseManager ?? false}
            />
          </Col>
        </Row>
      </div>
      {matterFeeEarner &&
        <Row>
          <Col>
            <ViewMatterFeeEarnerPreferredContacts matterFeeEarner={matterFeeEarner}/>
          </Col>
        </Row>
      }
    </>
  );
}

export default ViewMatterFeeEarner;
