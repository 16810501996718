import Title from "components/Title";
import { TabPanel } from "react-tabs";
import ActivityList from "./Activities/ActivityList";
import DefaultActivityList from "./DefaultActivities/DefaultActivityList";
import DefaultFeeEarnerLevelList from "./DefaultFeeEarnerLevels/DefaultFeeEarnerLevelList";
import FeeEarnerLevelList from "./FeeEarnerLevels/FeeEarnerLevelList";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import CustomTabs from "components/CustomTabs";

export default function ViewSettings() {
  const loggedInUser = useAppSelector((state) => state.user);

  return (
    <div className="lp-page-content">
      <Title type="page" title="Charging Scheme Settings"></Title>

      <CustomTabs 
        tabList={[
          ...(loggedInUser.userPermissions?.find(a => a.toLowerCase().trim() == UserPermissionsNames.ManageDefaultSettings.toLowerCase().trim()) ? [{
            name: 'Default Activities',
            panel: <TabPanel>
              <DefaultActivityList />
            </TabPanel>
          },
          {
            name: 'Default Fee Earner Levels',
            panel: <TabPanel>
              <DefaultFeeEarnerLevelList />
            </TabPanel>
          }] : []),
          ...(loggedInUser.userPermissions?.find(a => a.toLowerCase().trim() == UserPermissionsNames.ManageLTESettings.toLowerCase().trim()) ? [{
            name: 'Activities',
            panel: <TabPanel>
              <ActivityList />
            </TabPanel>
          },
          {
            name: 'Fee Earner Levels',
            panel: <TabPanel>
              <FeeEarnerLevelList />
            </TabPanel>
          }] : [])
        ]}
      />
    </div>
  );
}
