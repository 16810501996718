import { MatterActivityHistoryTypes } from "enums/MatterActivityHistoryTypes";
import moment from "moment";
import { DateFormat } from "utils/constants";

export const DateTimeFormatterForMatterActivityHistory = (params: any) => {
  if(params.data.activityType == MatterActivityHistoryTypes.RecordableEvent ||
    params.data.activityType == MatterActivityHistoryTypes.Disbursement || 
    params.data.activityType == MatterActivityHistoryTypes.IncidentalExpense || 
    params.data.activityType == MatterActivityHistoryTypes.Invoice) {
    return moment.utc(params.value).format(DateFormat.MomentWithTime);
  }
  else {
    return params.value ? moment(params.value).format(DateFormat.MomentWithTime) : "";
  }
};
