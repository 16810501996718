import { useEffect, useState } from "react";
import Loader from "components/Loader/index";
import Field from "components/Fields/Field";
import { Row, Col } from "react-bootstrap";
import { ChargingRateModel } from "models/view/ChargingRateModel";
import { getChargingRate } from "actions/chargingSchemes";
import { formatCurrency } from "utils/misc";

type Props = {
  chargingRateId: string
}

export default function ViewChargingRate(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [chargingRate, setChargingRate] = useState<ChargingRateModel | undefined>(undefined);
  const [loading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getChargingRate(props.chargingRateId!).then((response) => { 
      setChargingRate(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Activity"}
            value={chargingRate?.activity?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Fee Earner Level"}
            value={chargingRate?.feeEarnerLevel?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Charging Basis"}
            value={chargingRate?.chargingBasis?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Charged Rate"}
            value={chargingRate?.chargeRate ? formatCurrency(chargingRate?.chargeRate) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Minutes per Unit"}
            value={chargingRate?.minutesPerUnit}
          />
        </Col>
      </Row>
    </>
  );
}
