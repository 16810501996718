import useGridActions from "actions/grid";
import { useCallback, useEffect, useState } from "react";
import { columnDefs } from "./columnDefs";
import { Card, Col, Row } from "react-bootstrap";
import Title from "components/Title";
import DatePicker from "react-datepicker";
import SlidingPanel from "components/SlidingPanel";
import Loader from "components/Loader";
import { DateFormat } from "utils/constants";
import moment from "moment";
import { getDateOnly } from "utils/date";
import Grid from "components/Grid";
import { getDiaryManagerEvents } from "actions/lte";

export default function DiaryManagerCalendar() {
  const gridActions = useGridActions();
  const [genericErrors, setGenericErrors] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(
    moment().startOf('week').toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().endOf('week').toDate()
  );

  const setGridData = () => {
    setIsLoading(true);
    getDiaryManagerEvents(startDate.toISOString(), endDate.toISOString()).then((response) => 
    {
      gridActions.setGridRowData(response.data);
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }
  
  const onGridReady = useCallback(() =>
  {
    gridActions.setGridColumnDefs(columnDefs);
  }, []);

  useEffect(() => {
    if(startDate >= endDate) {
      setGenericErrors("Start Date cannot be after End Date");
      return;
    }
    setGenericErrors(undefined);
    setGridData();
  }, [startDate, endDate]);

  return (
    <div className="lp-page-content">
      <>
        <Title
          type="page"
          title="Diary Manager Appointments"
        >
        </Title>

        <SlidingPanel />

        {genericErrors && (
          <div className="lp-errors">
            {genericErrors}
          </div>
        )}

        <Card className="with-grid">
          {isLoading && <Loader inlineLoader />}
          
          <Card.Body>
            <div className="lp-calendar-date appointments">
              <span>From</span>
              <DatePicker 
                dateFormat={DateFormat.Datepicker}
                value={moment(startDate).format(DateFormat.Moment)}
                selected={startDate ? getDateOnly(startDate) : null}
                onChange={(date: Date) => {setStartDate(date)}}
                showMonthDropdown
                showYearDropdown
                autoComplete="off"
              />
              <span>to</span>
              <DatePicker 
                dateFormat={DateFormat.Datepicker}
                value={moment(endDate).format(DateFormat.Moment)}
                selected={endDate ? getDateOnly(endDate) : null}
                onChange={(date: Date) => setEndDate(date)}
                showMonthDropdown
                showYearDropdown
                autoComplete="off"
              />
            </div>
            <Grid
              onGridReady={onGridReady}
            />
          </Card.Body>
        </Card>
      </>
    </div>
  )
}
