import { validateDefaultEntityRoleName } from "actions/settings";
import { create, test, enforce } from "vest";

export const getValidationSuite = (id: string) => {
  return create((data = {}) => {
    test('name', 'Name is required', () => {
      enforce(data.name).isNotEmpty();
      enforce(data.name).isString();
    });

    test.memo('name', 'Name must be unique', async () => {
      enforce((await validateDefaultEntityRoleName(data.name, id)).data).isTruthy();
    }, [data.name]);
  });
}