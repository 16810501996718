import { Controller, useForm } from "react-hook-form";
import React, { Dispatch, useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { CreateOrUpdateLawPageTradingEntityModel } from "models/create/CreateOrUpdateLawPageTradingEntityModel";
import { useParams } from "react-router-dom";
import { LawPageTradingEntityModel } from "models/view/LawPageTradingEntityModel";
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import Loader from "components/Loader";
import { getLteTypes, updateLte } from "actions/lte";
import { getRegulators } from "actions/user";
import { removeEmptyFields } from "utils/form";
import CustomSelect from "components/Select/Select";
import { CompanyLteTypes } from "utils/constants";
import { getValidationSuite } from "./validationSuite";
import { useAppSelector } from "hooks/appSelector";
import { RegulatorTypeIds } from "enums/RegulatorTypeIds";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type Props = {
  lte: LawPageTradingEntityModel | undefined,
  setLte: Dispatch<React.SetStateAction<LawPageTradingEntityModel | undefined>>,
  setEdit: Dispatch<React.SetStateAction<boolean>>
}

export default function EditLawPageTradingEntitySummary(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id: lteId } = useParams();
  const user = useAppSelector((state) => state.user);

  const { register, reset, control, watch, handleSubmit, formState: { errors } } = useForm<CreateOrUpdateLawPageTradingEntityModel>({
    resolver: vestResolver(getValidationSuite(props.lte!))
  });

  useEffect(() => {
    if (props.lte) {
      let initialFields: CreateOrUpdateLawPageTradingEntityModel = {
        ...props.lte
      };
      reset(initialFields);
    }
  }, [props.lte]);

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateLawPageTradingEntityModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    updateLte(lteId ?? user.lawPageTradingEntityId!, data).then((response) => {
      props.setLte(response.data);
      props.setEdit(false);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <Card>
      {isLoading && <Loader inlineLoader />}

      <Card.Body>
        {genericErrors && (
          <div className="lp-errors">
            {genericErrors}
          </div>
        )}

        <Form onSubmit={onSubmit}>
          <Row className="multiple">
            <Form.Group as={Col} sm={6} xxl={3} controlId="legalName">
              <Form.Label className="required">Legal Name</Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.legalName?.message ? 'invalid' : ''}`}
                {...register("legalName", {shouldUnregister: true})}
              />
              <Form.Text className="lp-error">
                {errors?.legalName?.message && (errors.legalName.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={3} controlId="tradingName">
              <Form.Label className="required">Trading Name</Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.tradingName?.message ? 'invalid' : ''}`}
                {...register("tradingName", {shouldUnregister: true})}
              />
              <Form.Text className="lp-error">
                {errors?.tradingName?.message && (errors.tradingName.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={3} controlId="lawPageTradingEntityTypeId">
              <Form.Label className="required">Type</Form.Label>
              <Controller
                control={control}
                name="lawPageTradingEntityTypeId"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="lawPageTradingEntityTypeId"
                    inputRef={ref}
                    className={`lp-select${errors?.lawPageTradingEntityTypeId?.message ? ' invalid' : ''}`}
                    endpointCall={getLteTypes}
                    value={value}
                    onChange={val => onChange(val?.id ?? null)}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.lawPageTradingEntityTypeId?.message && (errors.lawPageTradingEntityTypeId.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={3} controlId="code">
              <Form.Label className="required">Code</Form.Label>
              <Form.Control
                type="text"
                disabled
                className={`${errors?.code?.message ? 'invalid' : ''}`}
                {...register("code", {shouldUnregister: true})}
              />
              <Form.Text className="lp-error">
                {errors?.code?.message && (errors.code.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={3} controlId="companyRegistrationNo">
              <Form.Label className={`${CompanyLteTypes.includes(watch('lawPageTradingEntityTypeId')) ? "required" : ""}`}>
                Company Registration No.
              </Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.companyRegistrationNo?.message ? 'invalid' : ''}`}
                {...register("companyRegistrationNo", {shouldUnregister: true})}
              />
              <Form.Text className="lp-error">
                {errors?.companyRegistrationNo?.message && (errors.companyRegistrationNo.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={3} controlId="regulatorId">
              <Form.Label className="required">Regulator</Form.Label>
              <Controller
                control={control}
                name="regulatorId"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="regulatorId"
                    inputRef={ref}
                    className={`lp-select${errors?.regulatorId?.message ? ' invalid' : ''}`}
                    endpointCall={getRegulators}
                    value={value}
                    onChange={val => onChange(val?.id ?? null)}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.regulatorId?.message && (errors.regulatorId.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={3} controlId="regulatorReference">
              <Form.Label
                className={`${(watch("regulatorId") && watch("regulatorId") != RegulatorTypeIds.UnregulatedId) ? " required" : ""}`}
              >
                Regulator Registration Reference
              </Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.regulatorReference?.message ? 'invalid' : ''}`}
                {...register("regulatorReference", {shouldUnregister: true})}
              />
              <Form.Text className="lp-error">
                {errors?.regulatorReference?.message && (errors.regulatorReference.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={3} controlId="vatRegistrationNo">
              <Form.Label>VAT Registration No.</Form.Label>
              <Form.Control
                type="text"
                {...register("vatRegistrationNo", {shouldUnregister: true})}
              />
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={3} controlId="entityUrl">
              <Form.Label>Entity URL</Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.entityUrl?.message ? 'invalid' : ''}`}
                {...register("entityUrl", {shouldUnregister: true})}
              />
              <Form.Text className="lp-error">
                {errors?.entityUrl?.message && (errors.entityUrl.message)}
              </Form.Text>
            </Form.Group>

            {user.userPermissions?.includes(UserPermissionsNames.EditLTEs) &&
              <Form.Group as={Col} sm={6} xxl={3} controlId="isActive">
                <Form.Label>Is Active</Form.Label>
                <Controller
                  control={control}
                  name="isActive"
                  shouldUnregister={true}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Form.Check
                      type="switch"
                      id="isActive">
                      <Form.Check.Input
                        className="form-check-input"
                        ref={ref}
                        checked={value}
                        onChange={(ev: any) => { onChange(ev.target.checked)}}
                      />
                    </Form.Check>
                  )}
                />
              </Form.Group>
            }
          </Row>

          <Row>
            <Form.Group className="d-flex justify-content-between">
              <Button variant="success" type="submit">Update</Button>
              <Button variant="secondary-400" onClick={() => props.setEdit(false)}>Cancel</Button>
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
