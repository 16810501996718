import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { setUser, removeUser, UserState, setUserMsalTokens } from 'state/userSlice';
import { setHeader, removeHeader, get, put, post, del } from 'utils/request';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionRequiredAuthError, BrowserAuthError, InteractionStatus } from '@azure/msal-browser';
import { loginParams } from 'msal/authConfig';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import axios from 'axios';
import { UserInfoModel } from "models/view/UserInfoModel";
import store from "state/store";
import { LteUserModel } from "models/view/LteUserModel";
import { CreateOrUpdateLteUserModel } from "models/create/CreateOrUpdateLteUserModel";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { UpdateUserPersonalInfoModel } from "models/update/UpdateUserPersonalInfoModel";
import { UpdateUserLteInfoModel } from "models/update/UpdateUserLteInfoModel";
import { isMobile } from 'react-device-detect';
import { ValidateFieldModel } from "models/view/ValidateFieldModel";
import { DashboardSummaryInfoModel } from "models/view/DashboardSummaryInfoModel";
import { MatterModel } from "models/view/MatterModel";
import { MatterInvoiceModel } from "models/view/MatterInvoiceModel";
import { MatterEmailModel } from "models/view/MatterEmailModel";

export interface LoginResponse {
  username: string,
  token: string,
}

interface JwtPayloadForIdToken extends JwtPayload {
  preferred_username: string;
  name?: string;
}

const BASE_API_URL = process.env.REACT_APP_API_URL;

const request = axios.create({
  baseURL: BASE_API_URL,
});

const useUserActions = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch()
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const login = async (): Promise<any> => {
    if (inProgress !== InteractionStatus.None) {
      return;
    }

    // display the log in popup window
    let idToken = null;
    try {
      idToken = await instance.acquireTokenSilent({
        ...loginParams,
        account: accounts[0]
      });
    } catch (silentTokenError) {
      // check if the previous request has failed because we need to
    // display an authorization window (first login or when the app scopes changes)
      if (silentTokenError instanceof InteractionRequiredAuthError
        || silentTokenError instanceof BrowserAuthError) {
        // fallback to interaction when silent call fails
        if(isMobile){
          try{
            await instance.loginRedirect({
              ...loginParams,
              prompt: 'select_account'
            });
          } catch (err) {
            throw (err);
          }
        }
        else {
          idToken = await instance.loginPopup({
            ...loginParams,
            prompt: 'select_account'
          });
        }
        
      } else {
        throw (silentTokenError);
      }
    }

    if(idToken) {
      const loginData = { idToken: idToken.idToken };
      const loginHeaders = {
        headers: {
          'Authorization': `Bearer ${idToken.idToken}`,
        },
      };
      const loginResponse = await request.post("/userManagement/login", loginData, loginHeaders);

      const apiData = loginResponse.data;
      const apiJwt = apiData.jwtToken;

      const deserializedApiToken = jwtDecode<any>(decodeURIComponent(apiJwt));

      setHeader('X-Authorization', `${idToken.idToken}`);
      setHeader('Authorization', `Bearer ${apiJwt}`);
      localStorage.setItem('msalIdToken', idToken.idToken);
      localStorage.setItem('apiJwt', apiJwt); 
      localStorage.setItem('id', apiData.userId);

      const loggedUser: UserState = {
        email: apiData.email,
        msalIdToken: idToken.idToken,
        msalAccessToken: idToken.accessToken,
        apiToken: apiJwt,
        firstName: apiData.firstName,
        lastName: apiData.lastName,
        displayName: apiData.displayName,
        userId: apiData.userId,
        lawPageTradingEntityId: apiData.lawPageTradingEntityId,
        hasInfo: apiData.hasInfo,
        isFeeEarner: apiData.isFeeEarner,
        isDiaryManager: apiData.isDiaryManager,
        userRole: apiData.userRole,
        userPermissions: deserializedApiToken.Permission,
        lteHasClientBankAccounts: apiData.lteHasClientBankAccounts
      };

      dispatch(setUser({
        ...loggedUser
      }));

      navigate('/');
    }
  }

  const acquireUserTokenSilent = async (): Promise<any> => {
    // display the log in popup window
    let idToken = null;
    try {
      idToken = await instance.acquireTokenSilent({
        ...loginParams,
        account: accounts[0]
      });
    } catch (silentTokenError) {
    }

    if(idToken) {
      const loginData = { idToken: idToken.idToken };
      const loginHeaders = {
        headers: {
          'Authorization': `Bearer ${idToken.idToken}`,
        },
      };
      const loginResponse = await request.post("/userManagement/login", loginData, loginHeaders);

      const apiData = loginResponse.data;
      const apiJwt = apiData.jwtToken;

      const deserializedApiToken = jwtDecode<any>(decodeURIComponent(apiJwt));

      setHeader('X-Authorization', `${idToken.idToken}`);
      setHeader('Authorization', `Bearer ${apiJwt}`);
      localStorage.setItem('msalIdToken', idToken.idToken);
      localStorage.setItem('apiJwt', apiJwt); 
      localStorage.setItem('id', apiData.userId);

      const loggedUser: UserState = {
        email: apiData.email,
        msalIdToken: idToken.idToken,
        msalAccessToken: idToken.accessToken,
        apiToken: apiJwt,
        firstName: apiData.firstName,
        lastName: apiData.lastName,
        displayName: apiData.displayName,
        userId: apiData.userId,
        lawPageTradingEntityId: apiData.lawPageTradingEntityId,
        hasInfo: apiData.hasInfo,
        isFeeEarner: apiData.isFeeEarner,
        isDiaryManager: apiData.isDiaryManager,
        userRole: apiData.userRole,
        userPermissions: deserializedApiToken.Permission,
        lteHasClientBankAccounts: apiData.lteHasClientBankAccounts
      };

      dispatch(setUser({
        ...loggedUser
      }));

      navigate('/');
    }
  }

  const restoreUser = async (): Promise<boolean> => {
    const storedMsalIdToken = localStorage.getItem('msalIdToken');
    const storedApiJwt = localStorage.getItem("apiJwt");
    
    if (storedMsalIdToken) {
      const storedIdToken = jwtDecode<JwtPayloadForIdToken>(decodeURIComponent(storedMsalIdToken));
      const { preferred_username } = storedIdToken;
      const { exp: storedIdTokenExp } = storedIdToken;
      const dateNow = new Date();

      const account = instance.getAccountByUsername(preferred_username);
      if (!account){
        deleteUserInfoFromSorage();
        return false;
      }
      
      const forceRefresh = (storedIdTokenExp ?? 0) < dateNow.getTime() / 1000;
      try {
        const msalToken = await instance.acquireTokenSilent({
          ...loginParams,
          forceRefresh: forceRefresh,
          account: account
        });
        
        const msalExpirationDate = msalToken.expiresOn;
        if (msalExpirationDate && dateNow > msalExpirationDate) {
          deleteUserInfoFromSorage();
          return false;
        }

        const graphToken = jwtDecode<JwtPayloadForIdToken>(decodeURIComponent(msalToken.idToken));
        const { exp: graphExp } = graphToken;
        if (graphExp && graphExp < dateNow.getTime() / 1000) {
          deleteUserInfoFromSorage();
          return false;
        }
        localStorage.setItem('msalIdToken', msalToken.idToken);
        dispatch(setUserMsalTokens({
          msalIdToken: msalToken.idToken,
          msalAccessToken: msalToken.accessToken,
        }));

        let refreshApiToken = false;
        if(storedApiJwt){
          const apiToken = jwtDecode<JwtPayloadForIdToken>(decodeURIComponent(storedApiJwt ?? ""));
          const { exp: apiTokenExp } = apiToken;

          if(msalToken.idToken !== storedMsalIdToken || (apiTokenExp && apiTokenExp < dateNow.getTime() / 1000)) {
            refreshApiToken = true;
          }
        }
        else {
          refreshApiToken = true;
        }

        if(refreshApiToken){
          const loginData = { idToken: msalToken.idToken };
          const loginHeaders = {
            headers: {
              'Authorization': `Bearer ${msalToken.idToken}`,
            },
          };
          const loginResponse = await request.post("/userManagement/login", loginData, loginHeaders);

          const apiData = loginResponse.data;
          const apiJwt = apiData.jwtToken;

          localStorage.setItem('apiJwt', apiJwt);
        }

        const apiJwt = localStorage.getItem("apiJwt");

        setHeader('X-Authorization', `${msalToken.idToken}`);
        setHeader('Authorization', `Bearer ${apiJwt}`);

        if(!store.getState().user.userId || refreshApiToken)
        {
          const userResponse = await getUserInfo();

          let deserializedApiToken = undefined;
          if(apiJwt) {
            deserializedApiToken = jwtDecode<any>(decodeURIComponent(apiJwt));
          }

          const loggedUser: UserState = {
            email: userResponse.data.email || undefined,
            msalIdToken: msalToken.idToken,
            msalAccessToken: msalToken.accessToken,
            apiToken: apiJwt || undefined,
            firstName: userResponse.data.firstName || undefined,
            lastName: userResponse.data.lastName || undefined,
            displayName: userResponse.data.displayName || undefined,
            userId: userResponse.data.id || undefined,
            lawPageTradingEntityId: userResponse.data.lawPageTradingEntityId || undefined,
            isFeeEarner: userResponse.data.isFeeEarner || undefined,
            hasInfo: userResponse.data.hasInfo || undefined,
            userRole: userResponse.data.userRole || undefined,
            isDiaryManager: userResponse.data.isDiaryManager || userResponse.data.managedDiaries || undefined,
            userPermissions: deserializedApiToken.Permission || undefined,
            lteHasClientBankAccounts: userResponse.data.lteHasClientBankAccounts || false
          };

          dispatch(setUser({
            ...loggedUser
          }));
        }
      } catch (error) {
        deleteUserInfoFromSorage();
        return false;
      }
      
      return true;
    }

    deleteUserInfoFromSorage();
    return false;
  }
  
  const isLoggedIn = async () => {
    const storedMsalIdToken = localStorage.getItem('msalIdToken');
    const storedApiJwt = localStorage.getItem("apiJwt");
    const dateNow = new Date();

    if(!storedMsalIdToken && !storedApiJwt) {
      localStorage.clear();
      return false;
    }

    if (storedMsalIdToken) {
      const storedIdToken = jwtDecode<JwtPayloadForIdToken>(decodeURIComponent(storedMsalIdToken));
      const { preferred_username } = storedIdToken;

      const account = instance.getAccountByUsername(preferred_username);
      if (!account){
        deleteUserInfoFromSorage();
        return false;
      }
      
      const msalToken = await instance.acquireTokenSilent({
        ...loginParams,
        forceRefresh: false,
        account: account
      });

      const msalExpirationDate = msalToken.expiresOn;
      if (msalExpirationDate && dateNow > msalExpirationDate) {
        deleteUserInfoFromSorage();
        return false;
      }

      const graphToken = jwtDecode<JwtPayloadForIdToken>(decodeURIComponent(msalToken.idToken));
      const { exp: graphExp } = graphToken;
      if (graphExp && graphExp < dateNow.getTime() / 1000) {
        deleteUserInfoFromSorage();
        return false;
      } 
    }

    if (storedApiJwt) {
      const apiJwt = jwtDecode<JwtPayloadForIdToken>(decodeURIComponent(storedApiJwt));
      const { exp: graphExp } = apiJwt;
      if (graphExp && graphExp < dateNow.getTime() / 1000) {
        deleteUserInfoFromSorage();
        return false;
      } 
    }

    return true;
  }

  const deleteUserInfoFromSorage = () => {
    removeHeader('X-Authorization');
    removeHeader('Authorization');

    dispatch(removeUser());
    localStorage.clear();
  }

  const setNewUserData = (user: UserInfoModel) => {
    const loggedUser: UserState = {
      ...store.getState().user,
      firstName: user.firstName,
      lastName: user.lastName,
      displayName: user.displayName,
      hasInfo: user.hasInfo,
      isFeeEarner: user.isFeeEarner
    };

    dispatch(setUser({
      ...loggedUser
    }));
  }

  const logout = async () => {
    if (isAuthenticated) {
      try{
        await instance.logoutRedirect({
          onRedirectNavigate: (url) => {
              // Return false if you would like to stop navigation after local logout
              return false;
          }
        });
      } catch (error: any) {
        throw new Error("Something has gone wrong while logging out. " + error?.errorMessage);
      }
    }

    deleteUserInfoFromSorage();
  }

  const getUserTheme = (): string => {
    let theme = 'dark';
    if (localStorage.getItem('theme') && localStorage.getItem('theme') === 'dark') {
      if (!document.documentElement.classList.contains('dark')) {
        document.documentElement.classList.add('dark');
      }
    } else if (!localStorage.getItem('theme') && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      if (!document.documentElement.classList.contains('dark')) {
        document.documentElement.classList.add('dark');
      }
      localStorage.setItem('theme', 'dark');
    } else {
      if (document.documentElement.classList.contains('dark')) {
        document.documentElement.classList.remove('dark');
      }
      localStorage.setItem('theme', 'light');
      theme = 'light';
    }
    return theme;
  }

  const toggleUserTheme = () => {
    const theme = getUserTheme();
    localStorage.setItem('theme', theme === 'light' ? 'dark' : 'light');
    getUserTheme();
  }

  return {
    login,
    acquireUserTokenSilent,
    restoreUser,
    isLoggedIn,
    setNewUserData,
    logout,
    getUserTheme,
    toggleUserTheme,
  };

};

const getRegulators = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/regulator/summary');
  return response;
}

const getUserInfo = async (): Promise<any> => {
  const response = await get<UserInfoModel>('/management/user/myprofile');
  return response;
}

const getAllUsers = async (id: string): Promise<any> => {
  const response = await get<UserInfoModel[]>(`/management/lte/${id}/user`);
  return response;
}

const updateUserProfileInfo = async (id: string, data: UpdateUserPersonalInfoModel): Promise<any> => {
  const response = await put<UpdateUserPersonalInfoModel, UserInfoModel>(`/management/user/${id}/personalInfo`, data);
  return response;
}

const updateUserWelcomeInfo = async (id: string, data: UpdateUserPersonalInfoModel): Promise<any> => {
  const response = await put<UpdateUserPersonalInfoModel, UserInfoModel>(`/management/user/${id}/welcomeInfo`, data);
  return response;
}

const updateUserLteInfo = async (id: string, data: UpdateUserLteInfoModel): Promise<any> => {
  const response = await put<UpdateUserLteInfoModel, UserInfoModel>(`/management/user/${id}/lteInfo`, data);
  return response;
}

const editLteUser = async (id:string, data: CreateOrUpdateLteUserModel): Promise<any> => {
  const response = await put<CreateOrUpdateLteUserModel, LteUserModel>(`/management/user/${id}`, data);
  return response;
}

const createLteUser = async (data: CreateOrUpdateLteUserModel): Promise<any> => {
  const response = await post<CreateOrUpdateLteUserModel, LteUserModel>(`/management/user`, data);
  return response;
}

const validateLteUserEmail = async (email: string, userId?: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/user/email/validate`, {
    "value": email,
    "currentId": userId
  });
  return response;
}

const changeUserStatus = async (id: string): Promise<any> => {
  const response = await post(`/management/user/${id}/changeStatus`, undefined);
  return response;
}

const getLteUser = async (id: string): Promise<any> => {
  const response = await get<LteUserModel>(`/management/user/${id}/summaryInfo`);
  return response;
}

const getLteUserWithEntity = async (id: string): Promise<any> => {
  const response = await get<LteUserModel>(`/management/user/${id}`);
  return response;
}

const deleteLteUser = async (id: string): Promise<any> => {
  const response = await del(`management/user/${id}`);
  return response;
}

const getUserRoles = async (lteId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/userrole/summary?lteId=${lteId}`);
  return response;
}

const getUserPermissions = async (lteId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/userpermission/summary?lteId=${lteId}`);
  return response;
}

const getEmploymentRelationshipType = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/employmentrelationshiptype/summary`);
  return response;
}

const getUserSummaryForLte = async (lteId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/lte/${lteId}/user/summary`);
  return response;
}

const getAllFeeEarnersFromLte = async (lteId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/lte/${lteId}/feeEarner/summary`);
  return response;
}

const syncEmails = async (apiToken?: string, accessToken?: string): Promise<any> => {
  if(apiToken && accessToken) {
    const response = fetch(`${process.env.REACT_APP_API_URL}/management/user/syncEmails`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${apiToken}`, 
        'X-Authorization': `${accessToken}`, 
      },
      keepalive: true,
    });

    return response;
  }
  const response = await get(`/management/user/syncEmails`);
  return response;
}

const getEmailsForManualSaving = async (matterId: string, page: string, msalAccessToken: string, emailFolder?: string, keyword?: string): Promise<any> => {
  setHeader("X-Authorization", msalAccessToken);
  let url = `/management/matter/${matterId}/userEmail?page=${page}`;
  if(emailFolder) {
    url += `&emailFolder=${emailFolder}`
  }
  if(keyword) {
    url += `&keyword=${keyword}`
  }
  const response = await get(url);
  return response;
}

const getEmailsForDashboard = async (pageNumber: number, pageSize: number, msalAccessToken: string, emailFolder?: string, keyword?: string): Promise<any> => {
  setHeader("X-Authorization", msalAccessToken);
  let url = `/management/user/email?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  if(emailFolder) {
    url += `&emailFolder=${emailFolder}`
  }
  if(keyword) {
    url += `&keyword=${keyword}`
  }
  const response = await get(url);
  return response;
}

const getEmailFolders = async (msalAccessToken: string): Promise<any> => {
  setHeader("X-Authorization", msalAccessToken);
  const response = await get('/management/user/emailFolders/summary');
  return response;
}

const sendEmailsForManualSaving = async (matterId: string, data: string[], msalAccessToken: string): Promise<any> => {
  setHeader("X-Authorization", msalAccessToken);
  const response = await post<string[], any>(`/management/matter/${matterId}/userEmail/save`, data);
  return response;
}

const getAppointments = async (matterId: string, page: string, startDate: string, endDate: string): Promise<any> => {
  const response = await get(`/management/matter/${matterId}/userAppointment?page=${page}&startDate=${startDate}&endDate=${endDate}`);
  return response;
}

const sendAppointmentsForManualSaving = async (matterId: string, data: string[]): Promise<any> => {
  const response = await post<string[], any>(`/management/matter/${matterId}/userAppointment/save`, data);
  return response;
}

const getUserEmailById = async (emailGraphId: string, msalAccessToken: string): Promise<any> => {
  setHeader("X-Authorization", msalAccessToken);
  const response = await get<MatterEmailModel>(`/management/user/email/${emailGraphId}`);
  return response;
}

const createReplyDraftUserEmail = async (emailGraphId: string, msalAccessToken: string): Promise<any> => {
  setHeader("X-Authorization", msalAccessToken);
  const response = await get<DropDownOptionModel[]>(`/management/user/email/${emailGraphId}/reply`);
  return response;
}

const createReplyAllDraftUserEmail = async (emailGraphId: string, msalAccessToken: string): Promise<any> => {
  setHeader("X-Authorization", msalAccessToken);
  const response = await get<DropDownOptionModel[]>(`/management/user/email/${emailGraphId}/replyAll`);
  return response;
}

const createForwardDraftUserEmail = async (emailGraphId: string, msalAccessToken: string): Promise<any> => {
  setHeader("X-Authorization", msalAccessToken);
  const response = await get<DropDownOptionModel[]>(`/management/user/email/${emailGraphId}/forward`);
  return response;
}

const getDashboardSummaryInfo = async (startOfCurrentWeek: Date): Promise<any> => {
  const response = await get<DashboardSummaryInfoModel>(`/management/user/dashboardSummaryInfo?startOfCurrentWeek=${startOfCurrentWeek.toISOString()}`);
  return response;
}

const getMattersForUser = async (take: number): Promise<any> => {
  const response = await get<MatterModel[]>(`/management/user/matter?take=${take}`);
  return response;
}

const getMatterInvoicesForUser = async (take: number): Promise<any> => {
  const response = await get<MatterInvoiceModel[]>(`/management/user/matterInvoice?take=${take}`);
  return response;
}

export { 
  useUserActions, 
  getRegulators,
  getUserInfo,
  getAllUsers,
  updateUserProfileInfo,
  updateUserWelcomeInfo,
  updateUserLteInfo,
  createLteUser,
  validateLteUserEmail,
  changeUserStatus,
  editLteUser,
  getUserRoles,
  getUserPermissions,
  getLteUser,
  getLteUserWithEntity,
  deleteLteUser,
  getEmploymentRelationshipType,
  getUserSummaryForLte,
  getAllFeeEarnersFromLte,
  syncEmails,
  getEmailsForManualSaving,
  getEmailsForDashboard,
  sendEmailsForManualSaving,
  getAppointments,
  sendAppointmentsForManualSaving,
  getUserEmailById,
  createReplyDraftUserEmail,
  createReplyAllDraftUserEmail,
  createForwardDraftUserEmail,
  getEmailFolders,
  getDashboardSummaryInfo,
  getMattersForUser,
  getMatterInvoicesForUser
};
