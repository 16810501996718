import { ChargingRatesWithSpecificActivitiesModel } from "models/create/ChargingRatesWithSpecificActivitiesModel";
import { CreateChargingRateModel } from "models/create/CreateChargingRateModel";
import { CreateOrUpdateChargingRateLevelModel } from "models/create/CreateOrUpdateChargingRateLevelModel";
import { CreateOrUpdateChargingSchemeModel } from "models/create/CreateOrUpdateChargingSchemeModel";
import { CreateOrUpdateRecordableEventTypeToChargingRateType } from "models/create/CreateOrUpdateRecordableEventTypeToChargingRateModel";
import { ValidateChargingRateFields } from "models/create/ValidateChargingRateFields";
import { ChargingRateModel } from "models/view/ChargingRateModel";
import { ChargingSchemeModel } from "models/view/ChargingSchemeModel";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { RecordableEventTypeToChargingRateType } from "models/view/RecordableEventTypeToChargingRateModel";
import { ValidateFieldModel } from "models/view/ValidateFieldModel";
import { del, get, post, put } from "utils/request";

const getChargingSchemes = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/chargingscheme/summary');
  return response;
}

const getCompleteChargingSchemes = async (): Promise<any> => {
  const response = await get<ChargingSchemeModel[]>('/management/chargingscheme');
  return response;
}

const getSummaryChargingRates = async (chargingSchemeId: string): Promise<any> => {
  const response = await get<ChargingRateModel[]>(`/management/chargingScheme/${chargingSchemeId}/chargingRates/summary`);
  return response;
}

const getSummaryChargingRatesFromActivity = async (chargingSchemeId: string, data: ChargingRatesWithSpecificActivitiesModel): Promise<any> => {
  const response = await post<ChargingRatesWithSpecificActivitiesModel, DropDownOptionModel[]>(`/management/chargingScheme/${chargingSchemeId}/activity/chargingRates/summary`, data);
  return response;
}

const getCompleteChargingRates = async (chargingSchemeId: string): Promise<any> => {
  const response = await get<ChargingRateModel[]>(`/management/chargingScheme/${chargingSchemeId}/chargingRates`);
  return response;
}

const getChargingRate = async (id: string): Promise<any> => {
  const response = await get<ChargingRateModel>(`/management/chargingRate/${id}`);
  return response;
}

const getChargingSchemesFromLte = async (lteId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/lte/${lteId}/chargingscheme/summary`);
  return response;
}

const getChargingScheme = async (id: string): Promise<any> => {
  const response = await get<ChargingSchemeModel>(`/management/chargingScheme/${id}`);
  return response;
}

const getChargingBasis = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/chargingBasis/summary`);
  return response;
}

const validateChargingSchemeDisplayName = async (name: string, chargingSchemeId?: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/chargingScheme/displayName/validate`, {
    "value": name,
    "currentId": chargingSchemeId
  });
  return response;
}

const addChargingScheme = async (data: CreateOrUpdateChargingSchemeModel): Promise<any> => {
  const response = await post<CreateOrUpdateChargingSchemeModel, ChargingSchemeModel>(`/management/chargingScheme`, data);
  return response;
}

const copyChargingScheme = async (sourceChargingSchemeId: string, data: CreateOrUpdateChargingSchemeModel): Promise<any> => {
  const response = await post<CreateOrUpdateChargingSchemeModel, ChargingSchemeModel>(`/management/chargingScheme/${sourceChargingSchemeId}/copy`, data);
  return response;
}

const addChargingRate = async (data: CreateChargingRateModel): Promise<any> => {
  const response = await post<CreateChargingRateModel, ChargingRateModel>(`/management/chargingRate`, data);
  return response;
}

const validateChargingRatePair = async (data: ValidateChargingRateFields): Promise<any> => {
  const response = await post<ValidateChargingRateFields, boolean>(`/management/chargingRate/chargingRateCombination/validate`, data);
  return response;
}

const updateChargingScheme = async (id: string, data: CreateOrUpdateChargingSchemeModel): Promise<any> => {
  const response = await put<CreateOrUpdateChargingSchemeModel, ChargingSchemeModel>(`/management/chargingScheme/${id}`, data);
  return response;
}

const updateChargingRate = async (id: string, data: CreateOrUpdateChargingRateLevelModel): Promise<any> => {
  const response = await put<CreateOrUpdateChargingRateLevelModel, ChargingRateModel>(`/management/chargingRate/${id}`, data);
  return response;
}

const changeChargingSchemeStatus = async (chargingSchemeId: string): Promise<any> => {
  const response = await post(`/management/chargingScheme/${chargingSchemeId}/changeStatus`, undefined);
  return response;
}

const deleteChargingScheme = async (id: string): Promise<any> => {
  const response = await del(`management/chargingScheme/${id}`);
  return response;
}

const deleteChargingRate = async (id: string): Promise<any> => {
  const response = await del(`management/chargingRate/${id}`);
  return response;
}

const getAllRecordableEventTypes = async (): Promise<any> => {
  const response = await get<DropDownOptionModel>(`management/recordableEventType/summary`);
  return response;
}

const getAllRecordableEventTypeToChargingRate = async (chargingSchemeId: string): Promise<any> => {
  const response = await get<RecordableEventTypeToChargingRateType[]>(`management/chargingScheme/${chargingSchemeId}/recordableEventType`);
  return response;
}

const addLinksToRecordableEventTypeToChargingRate = async (data: CreateOrUpdateRecordableEventTypeToChargingRateType): Promise<any> => {
  const response = await post<CreateOrUpdateRecordableEventTypeToChargingRateType,
  RecordableEventTypeToChargingRateType>(`management/recordableEventTypeToChargingRate`, data);
  return response;
}

const deleteSingleRecordableEventTypeToChargingRate = async (id: string): Promise<any> => {
  const response = await del(`management/recordableEventTypeToChargingRate/${id}`);
  return response;
}

const deleteAllRecordableEventTypeToChargingRate = async (chargingSchemeId: string, recordableEventTypeId: string): Promise<any> => {
  const response = await del(`management/chargingScheme/${chargingSchemeId}/recordableEventType/${recordableEventTypeId}`);
  return response;
}

const checkIfActivityIsInAnyChargingRate = async (chargingSchemeId: string, data: ValidateFieldModel): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/chargingscheme/${chargingSchemeId}/activity/validate`, data);
  return response;
}

export { 
  getChargingSchemes,
  getChargingScheme,
  getChargingBasis,
  getCompleteChargingSchemes,
  getSummaryChargingRates,
  getSummaryChargingRatesFromActivity,
  getCompleteChargingRates,
  getChargingRate,
  validateChargingRatePair,
  getChargingSchemesFromLte,
  validateChargingSchemeDisplayName,
  addChargingScheme,
  copyChargingScheme,
  addChargingRate,
  updateChargingScheme,
  updateChargingRate,
  changeChargingSchemeStatus,
  deleteChargingScheme,
  deleteChargingRate,
  getAllRecordableEventTypes,
  getAllRecordableEventTypeToChargingRate,
  addLinksToRecordableEventTypeToChargingRate,
  deleteSingleRecordableEventTypeToChargingRate,
  deleteAllRecordableEventTypeToChargingRate,
  checkIfActivityIsInAnyChargingRate
};