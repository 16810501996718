import { useEffect, useState } from "react";
import { LawPageTradingEntityModel } from "models/view/LawPageTradingEntityModel";
import Title from "components/Title";
import { TabPanel } from "react-tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getLteById } from "actions/lte";
import LteUserList from "containers/LawPageTradingEntity/ViewLawPageTradingEntity/LteUsers/LteUserList";
import ViewLawPageTradingEntityDetails from "./LawPageTradingEntityDetails/ViewLawPageTradingEntityDetails";
import { useAppSelector } from "hooks/appSelector";
import ViewLteSettings from "./LteSettings/ViewLteSettings";
import CustomTabs from "components/CustomTabs";

type State = {
  edit: boolean
}

export default function ViewLawPageTradingEntity() {
  const [lte, setLte] = useState<LawPageTradingEntityModel | undefined>(undefined);
  const location = useLocation();
  const state = location.state as State ?? undefined;
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);
  const { id } = useParams();

  useEffect(
    () => {
      getLteById(id ?? user.lawPageTradingEntityId!).then((response) => {
        if(response.data.tradingName === "") {
          response.data.tradingName = response.data.legalName;
        }
        setLte(response.data);
      }).catch((error) => {
        if(error.response.status == 404 || error.response.status == 400) {
          navigate('/error404');
        }
      });
    }
  , [id]);

  return (
    <div className="lp-page-content">
      <Title type="page" title={[<strong key={0}>View </strong>, <span key={1}>{lte?.legalName}</span>]}></Title>
      <CustomTabs 
        tabList={[
          {
            name: 'Details',
            panel: <TabPanel>
              <ViewLawPageTradingEntityDetails
                lte={lte}
                setLte={setLte}
                edit={state?.edit}
              />
            </TabPanel>
          },
          {
            name: 'Users',
            panel: <TabPanel>
              <LteUserList
                lte={lte ?? undefined}
              />
            </TabPanel>
          },
          {
            name: 'Settings',
            panel: <TabPanel>
              <ViewLteSettings
                lteId={id ?? user.lawPageTradingEntityId!}
              />
            </TabPanel>
          }
        ]}
      />
    </div>
  );
}
