import { ICellRendererParams } from "ag-grid-community";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import _ from 'lodash';

export default function DropDownOptionListCellRenderer(props: ICellRendererParams) {

  return (
    <>
      {props.value && props.value.length > 0 &&
        _.orderBy(props.value, x => x.name, "asc").map((x: DropDownOptionModel, index: number) => 
          <span key={index}>{x.name}{index+1 != props.value.length && ","} </span>
        )
      }
    </>
  );
}
