import { useState } from "react";
import Title from "components/Title";
import { deleteEntityAddress } from "actions/entity";
import { Button } from 'react-bootstrap';
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { MdAdd } from "react-icons/md";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState } from "state/modalSlice";
import LinkEntityAddressForm from "./LinkEntityAddress/LinkEntityAddressForm";
import ListContact from "components/List/ListContact";
import { EntityAddressModel } from "models/view/EntityAddressModel";
import { formatAddress } from "utils/address";

type EntityLinkType = 'ownedBy' | 'owns';

type Props = {
  entityId: string,
  linkedEntityId: string,
  linkedEntityAddresses: EntityAddressModel[],
  linkType: EntityLinkType,
  edit: boolean
}

export default function LinkedEntityAddressList(props: Props) {
  const [linkedEntityAddresses, setLinkedEntityAddresses] = useState<EntityAddressModel[]>(props.linkedEntityAddresses);
  const [isLoadingEntityAddresses, setIsLoadingEntityAddresses] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();

  const openLinkEntityAddressSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Link Address',
      children: <LinkEntityAddressForm 
                  toEntityId={props.linkType == 'ownedBy' ? props.entityId : props.linkedEntityId}
                  fromEntityId={props.linkType == 'ownedBy' ? props.linkedEntityId : props.entityId}
                  submitCallback={linkEntityAddressCallback} />
    });
  };

  const linkEntityAddressCallback = (newEntityAddress: EntityAddressModel) => {
    setIsLoadingEntityAddresses(true);
    setLinkedEntityAddresses([...linkedEntityAddresses, newEntityAddress]);
    setIsLoadingEntityAddresses(false);
  };

  const unlinkCallbackEntityAddress = (id: string) => {
    setIsLoadingEntityAddresses(true);
    modalActions.toggleModalLoading();
    const entityId = props.linkType == 'ownedBy' ? props.entityId : props.linkedEntityId;
    deleteEntityAddress(entityId, id, false).then(() => {
      setLinkedEntityAddresses(linkedEntityAddresses.filter(x => x.entityId != entityId || x.addressId != id));
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingEntityAddresses(false);
      modalActions.toggleModalLoading();
      modalActions.toggleModalShownStatus();
    });
  };

  const unlinkEntityAddressModal = (id: string) => {
    let modal: ModalState = {
      title: "Unlink confirmation",
      body: "Are you sure you want to unlink this address?",
      actionText: "Unlink",
      actionVariant: "danger",
      onAction: () => unlinkCallbackEntityAddress(id),
      show: true
    }
    modalActions.setModal(modal);
   };

  return (
    <>
      <Title type="section" title={"Addresses"}>
        <Button onClick={openLinkEntityAddressSlidingPanel} className="btn-icon" variant="success">
          <MdAdd />
        </Button>
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <ListContact
        listItems={linkedEntityAddresses.map((x: EntityAddressModel) => 
          ({
            id: x.addressId,
            contact: formatAddress(x?.address),
            type: x.address?.addressType?.name,
            isPreferred: x.isPreferred,
            systemOwned: x.address?.systemOwned
          })
        ) ?? []}
        listButtons={
          [
            {
              type: ActionButtonTypes.Unlink,
              callback: (id: string) => unlinkEntityAddressModal(id)
            }
          ]
        }
        loadState={isLoadingEntityAddresses}
        displayUnlinkButton
      >
        <>
          <div>{"Address"}</div>
          <div>{"Type"}</div>
          <div></div>
        </>
      </ListContact>
    </>
  );
}
