import useNotificationActions from 'actions/notification';
import { syncEmails } from 'actions/user';
import { NotificationTypes } from 'enums/NotificationTypes';
import { useAppSelector } from 'hooks/appSelector';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import DashboardCalendar from './DashboardCalendar';
import DashboardSummaryInfo from './DashboardSummaryInfo';
import DashboardMatters from './DashboardMatters';
import DashboardMatterInvoices from './DashboardMatterInvoices';
import DashboardUserEmails from './DashboardUserEmails';
import SlidingPanel from "components/SlidingPanel";
import './style.scss';

function Dashboard() {
  const user = useAppSelector((state) => state.user);
  const notificationActions = useNotificationActions();

  const synchronizeEmails = () => {
    //hack to avoid the clearNotifications from the NotificationMessages
    setTimeout(() => {
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Success,
          title: "Email Sync Started",
          body: "Emails for your User are being synced.",
          isDismissable: true
        }
      );
    }, 1);

    syncEmails(user.apiToken, user.msalAccessToken).then((response) => {
      if(!response.ok) {
        //turn ReadableStream into JSON 
        response.json().then((data: any) => {
          notificationActions.addNotificationMessage(
            {
              type: NotificationTypes.Error,
              title: "Email Sync Error",
              body: data.Message ?? "Emails for your User have not been synced due to an error.",
              isDismissable: true
            }
          );
        });
        return;
      }
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Success,
          title: "Email Sync Successful",
          body: "Emails for your User have been synced successfully.",
          isDismissable: true
        }
      );
    }).catch((error) => {
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Error,
          title: "Email Sync Error",
          body: "Emails for your User have not been synced due to an error.",
          isDismissable: true
        }
      );
    });
  };

  useEffect(() => {
    synchronizeEmails();
  }, []);

  return (
    <div className="lp-page-content lp-dashboard-page">
      <Row>
        <Col>
          <DashboardSummaryInfo />
        </Col>
      </Row>
      <Row>
        <Col xxl={8} className="mb-4 mb-xxl-0">
          <DashboardCalendar />
        </Col>
        <Col xxl={4}>
          <DashboardUserEmails />
        </Col>
      </Row>
      <Row>
        <Col xxl={6} className="mb-4 mb-xxl-0">
          <DashboardMatters />
        </Col>
        <Col xxl={6}>
          <DashboardMatterInvoices />
        </Col>
      </Row>

      <SlidingPanel />
    </div>
  );
}

export default Dashboard;
