import { useEffect, useState } from "react";
import { EntityModel } from "models/view/EntityModel";
import Title from "components/Title";
import { TabPanel } from "react-tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getEntityById } from "actions/entity";
import ViewEntityDetails from "./ViewEntityDetails/ViewEntityDetails";
import ViewLinkedEntities from "./ViewLinkedEntities/ViewLinkedEntities";
import EntityLedger from "./EntityLedger/EntityLedger";
import CustomTabs from "components/CustomTabs";

type State = {
  edit: boolean
}

export default function ViewEntity() {
  const [entity, setEntity] = useState<EntityModel | undefined>(undefined);
  const location = useLocation();
  const state = location.state as State ?? undefined;
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    getEntityById(id!).then((response) => {
      setEntity(response.data);
    }).catch((error) => {
      if(error.response.status == 404 || error.response.status == 400) {
        navigate('/error404');
      }
    });
  }, [id]);

  return (
    <div className="lp-page-content">
      <Title type="page" title={[<strong key={0}>View </strong>, <span key={1}>{entity?.displayName}</span>]}></Title>
      
      <CustomTabs 
        tabList={[
          {
            name: 'Details',
            panel: <TabPanel>
              <ViewEntityDetails
                entity={entity}
                setEntity={setEntity}
                edit={state?.edit}
              />
            </TabPanel>
          },
          {
            name: 'Linked Entities',
            panel: <TabPanel>
              {entity && entity.id && 
                <ViewLinkedEntities 
                  entity={entity}
                  edit={state?.edit}
                />
              }
            </TabPanel>
          },
          {
            name: 'Ledger',
            panel: <TabPanel>
              {entity && 
                <EntityLedger 
                  entityId={entity.id}
                />
              }
            </TabPanel>
          }
        ]}
      />
    </div>
  );
}
