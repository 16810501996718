import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { getColumnDefs } from "./columnDefs";
import { Card, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Title from "components/Title/index";
import SlidingPanel from "components/SlidingPanel/index";
import CustomModal from "components/Modal/Modal";
import CreateTaxonomyTermForm from "./CreateTaxonomyTerm/CreateTaxonomyTermForm";
import CustomSelect from "components/Select/Select";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { getLteTaxonomiesSummary, getLteTaxonomyById, getLteTaxonomyTerms } from "actions/taxonomy";

export default function TaxonomyTermList() {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const [taxonomies, setTaxonomies] = useState<DropDownOptionModel[]>([]);
  const [isLoadingTaxonomies, setIsLoadingTaxonomies] = useState<boolean>(false);
  const [selectedTaxonomyId, setSelectedTaxonomyId] = useState<string>();

  const onGridReady = useCallback(() =>
  {
    if(!selectedTaxonomyId) {
      const columnDefs = getColumnDefs(selectedTaxonomyId ?? '', false, () => reloadTaxonomyTerms(selectedTaxonomyId));
      gridActions.setGridColumnDefs([...columnDefs]);
      gridActions.setGridRowData([]);
    }
    else {
      const promises: Promise<any>[] = [];
      promises.push(getLteTaxonomyById(selectedTaxonomyId));
      promises.push(getLteTaxonomyTerms(selectedTaxonomyId));

      Promise.all(promises).then(([taxonomyResponse, taxonomyTermsResponse]) => {
        const columnDefs = getColumnDefs(selectedTaxonomyId!, taxonomyResponse.data.isHierarchical, () => reloadTaxonomyTerms(selectedTaxonomyId));
        gridActions.setGridColumnDefs([...columnDefs]);
        gridActions.setGridRowData(taxonomyTermsResponse.data as any);
      }).catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      });
    }
  }, [selectedTaxonomyId]); 

  useEffect(() => {
    setIsLoadingTaxonomies(true);
    getLteTaxonomiesSummary(true).then((response) => {
      setTaxonomies(response.data);
      if(response.data[0]) {
        setSelectedTaxonomyId(response.data[0].id);
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingTaxonomies(false);
    });
  }, []);

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Add Taxonomy Term",
        children: <CreateTaxonomyTermForm 
            taxonomyId={selectedTaxonomyId!}
            onSubmitCallback={() => reloadTaxonomyTerms(selectedTaxonomyId)}
          />
      });
  }

  const onTaxonomyChange = (value: string) => {
    setSelectedTaxonomyId(value);
  }

  const reloadTaxonomyTerms = (taxonomyId?: string) => {
    if(taxonomyId) {
      getLteTaxonomyTerms(taxonomyId)
        .then((response) => 
        {
          gridActions.setGridRowData(response.data);
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
        });
    }
    else {
      gridActions.setGridRowData([]);
    }
  }

  return (
    <>
      <Title type="section" title={"Taxonomy Terms"}>
        {selectedTaxonomyId && 
          <Button onClick={toggleSlidingPanel} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-grid">
        <Card.Body>
          <div className="lp-client-ledger-select">
            <div className="lp-label">
              Taxonomy:
            </div>
            <CustomSelect
              id="taxonomyId"
              options={taxonomies}
              isLoading={isLoadingTaxonomies}
              onChange={val => onTaxonomyChange(val?.id ?? null)}
              value={selectedTaxonomyId}
            />
          </div>

          <Grid 
            onGridReady={onGridReady}
          />
        </Card.Body>
      </Card>

      <SlidingPanel />
      
      <CustomModal />
    </>
  );
}
