import { ColDef } from "ag-grid-community";
import store from "state/store";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { setRowData } from "state/gridSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { changeActivityStatus, deleteActivity, getActivityTypes } from "actions/settings";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import EditActivityForm from "./EditActivity/EditActivityForm";
import { ActivityModel } from "models/view/ActivityModel";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";

const editCallback = (id: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Edit Activity",
        children: <EditActivityForm activityId={id}/>
      }
    )
  );
}

const changeStatusModal = (id: string) => {
  let bodyMessage: React.ReactNode;
  const gridState = store.getState().grid;
  if (!gridState.rowData.find((x: ActivityModel) => x.id == id).active) {
    bodyMessage = "Are you sure you want to activate this Activity?";
  } else {
    bodyMessage = "Are you sure you want to deactivate this Activity?";
  }
  let modal: ModalState = {
    title: "Confirmation",
    body: bodyMessage,
    onAction: () => changeStatus(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
}

const changeStatus = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  changeActivityStatus(id).then(() => {
    let index: number = gridState.rowData.findIndex((x: any) => x.id == id);
    let newEntry: ActivityModel = { ...gridState.rowData[index], active: !gridState.rowData[index].active };
    let newArray: Array<any> = [...gridState.rowData];
    newArray[index] = newEntry;
    store.dispatch(setRowData(newArray));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Activity?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteActivity(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const columnDefs: (ColDef<ActivityModel>| {excludeFromExport: boolean})[] = [
  { 
    excludeFromExport: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    suppressMenu: true,
    suppressSizeToFit: true,
    floatingFilter: false,
    resizable: false,
    sortable: false,
    minWidth: 48,
    maxWidth: 48,
    width: 48,
    lockPosition: true,
    pinned: 'left'
  },
  {
    headerName: 'Code',
    field: 'code',
    filter: 'agTextColumnFilter',
    lockPosition: true,
    pinned: 'left',
    suppressSizeToFit: true,
    minWidth: 120,
    width: 120
  },
  {
    headerName: 'Name',
    field: 'name',
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Description',
    field: 'description',
    filter: 'agTextColumnFilter',
    flex: 1
  },
  {
    headerName: 'Type',
    field: 'activityType.name',
    filter: DropdownFilter,
    filterParams: {
      property: 'activityTypeId',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: DropdownFloatingFilter,
    floatingFilterComponentParams: {
      endpointCall: getActivityTypes
    },
    minWidth: 220
  },
  {
    headerName: 'Is Active',
    field: 'active',
    filter: BooleanFilter,
    filterParams: {
      property: 'active',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: BooleanFloatingFilter,
    floatingFilterComponentParams: {
      values: [{
        id: 'true',
        name: 'Yes'
      },
      {
        id: 'false',
        name: 'No'
      },
    ]},
    minWidth: 100,
    width: 100,
    cellRenderer: CheckboxCellRenderer,
    valueFormatter: CheckboxFormatter
  },
  { 
    excludeFromExport: true,
    headerName: 'Actions',
    cellClass: 'lp-actions-cell',
    cellRendererSelector: (params) => {
      if (params.data?.active) {
        return {
          component: ActionsCellRenderer,
          params: {
            buttonsData: [
              {
                type: ActionButtonTypes.Edit,
                callback: editCallback
              },
              {
                type: ActionButtonTypes.Deactivate,
                callback: changeStatusModal
              },
              {
                type: ActionButtonTypes.Delete,
                callback: deleteModal
              }
            ]
          }
        }
      } else {
        return {
          component: ActionsCellRenderer,
          params: {
            buttonsData: [
              {
                type: ActionButtonTypes.Edit,
                callback: editCallback
              },
              {
                type: ActionButtonTypes.Activate,
                callback: changeStatusModal
              },
              {
                type: ActionButtonTypes.Delete,
                callback: deleteModal
              }
            ]
          }
        }
      }
    },
    suppressSizeToFit: true,
    floatingFilter: false,
    sortable: false,
    minWidth: 145,
    maxWidth: 145,
    width: 145,
    lockPosition: true,
    pinned: 'right',
    resizable: false
  }
];
