import { validateMatterTaxonomyDisplayName } from "actions/taxonomy";
import { create, test, enforce } from "vest";

export const getValidationSuite = (matterId: string, taxonomyId: string) => {
  return create((data = {}) => {
    test('displayName', 'Display Name is required', () => {
      enforce(data.displayName).isNotEmpty();
      enforce(data.displayName).isString();
    });

    test.memo('displayName', 'Display Name must be unique', async () => {
      enforce((await validateMatterTaxonomyDisplayName(matterId, data.displayName, taxonomyId)).data).isTruthy();
    }, [data.displayName]);
  });
}