import { AddressModel } from "models/view/AddressModel";

function formatAddress(address?: AddressModel, linkedEntityDisplyName?: string) {  
  return (linkedEntityDisplyName !== undefined && linkedEntityDisplyName.length > 0 ? linkedEntityDisplyName + ', ' : '') +
    (address?.addressLine1 !== undefined && address?.addressLine1?.length > 0 ? address?.addressLine1 + ', ' : '') +
    (address?.addressLine2 !== undefined && address?.addressLine2?.length > 0 ? address?.addressLine2 + ', ' : '') +
    (address?.addressLine3 !== undefined && address?.addressLine3?.length > 0 ? address?.addressLine3 + ', ' : '') +
    (address?.city !== undefined && address?.city?.length > 0 ? address?.city + ', ' : '') +
    (address?.county !== undefined && address?.county?.length > 0 ? address?.county + ', ' : '') +
    (address?.state !== undefined && address?.state?.length > 0 ? address?.state + ', ' : '') +
    (address?.country?.name !== undefined && address?.country?.name?.length > 0 ? address?.country?.name + ', ' : '') +
    (address?.postCode !== undefined && address?.postCode?.length > 0 ? address?.postCode : '');
}

export {
  formatAddress
};
