import { DropDownOptionModel } from 'models/view/DropDownOptionModel';

export default function ColoredChip(props: DropDownOptionModel) {
  return (
    <div className="lp-select-color-option">
      <span className={props.name.toLowerCase()} />
      {props.name}
    </div>
  );
}
