import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useUserActions } from 'actions/user';
import Logo from 'assets/images/lp-logo-light-horiz.webp';
import './style.scss';

function AuthLayout() {
  const [theme, setTheme] = useState('');
  const userActions = useUserActions();
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  useEffect(() => {
    setTheme(userActions.getUserTheme());
  }, []);

  const changeTheme = () => {
    userActions.toggleUserTheme();
    setTheme(userActions.getUserTheme());
  };

  return (
    <div className="lp-auth-page">
      <section className="row">
        <div className="lp-auth-page-left col-12 col-xl-3 col-xxl-5"></div>
        <div className="lp-auth-page-right col-12 col-xl-9 col-xxl-7">
          <div className="lp-theme-toggle" onClick={changeTheme} />
          <div className="mobile-logo">
            <img src={Logo} alt="LawPage Logo" />
          </div>
          <div className="lp-auth-page-wrapper">
            <Outlet />
          </div>
          <div className="lp-copyright">
            &copy;
            {' '}
            {getCurrentYear()}
            {' LawPage. All Rights Reserved.'}
          </div>
        </div>
      </section>
    </div>
  );
}

export default AuthLayout;
