import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Row, Col, Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { getEntityPhoneNumberById, getPreferredEntityPhoneNumber, updateEntityPhoneNumber } from "actions/entity";
import { CreateOrUpdateEntityPhoneNumberModel } from "models/create/CreateOrUpdateEntityPhoneNumberModel";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import Field from "components/Fields/Field";
import BooleanField from "components/Fields/BooleanField";
import useModalActions from 'actions/modal';
import { MdWarning } from 'react-icons/md';
import { ModalState } from 'state/modalSlice';

type Props = {
  entityId: string,
  phoneNumberId?: string,
  submitCallback?: Function
}

export default function EditEntityPhoneNumberOnlyIsPreferredForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const loggedInUser = useAppSelector((state) => state.user);
  const modalActions = useModalActions();

  useEffect(() => {
    if(props.phoneNumberId)
    {
      setIsLoading(true);
      getEntityPhoneNumberById(props?.entityId ?? '', props.phoneNumberId)
        .then( (entityPhoneNumberResponse) => {
          const initialState = {
            phoneNumberId: entityPhoneNumberResponse.data.phoneNumberId,
            phoneNumber: entityPhoneNumberResponse.data.phoneNumber.number,
            entityId: entityPhoneNumberResponse.data.entityId,
            isPreferred: entityPhoneNumberResponse.data.isPreferred,
            systemOwned: entityPhoneNumberResponse.data.phoneNumber.systemOwned,
            extension: entityPhoneNumberResponse.data.phoneNumber.extension,
            phoneTypeId: entityPhoneNumberResponse.data.phoneNumber.phoneType?.id ?? '',
            phoneTypeName: entityPhoneNumberResponse.data.phoneNumber.phoneType?.name ?? ''
          }
          reset(initialState);
        }
      )
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
  }, []);

  const { reset, control, watch, handleSubmit } = useForm<CreateOrUpdateEntityPhoneNumberModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: CreateOrUpdateEntityPhoneNumberModel) {
    setIsLoading(true);
    if(data.isPreferred == true) {
      getPreferredEntityPhoneNumber(props.entityId).then((response) => {
        let bodyMessage: React.ReactNode = <></>;
        if(response.data?.phoneNumberId && response.data?.phoneNumberId != props.phoneNumberId) {
          bodyMessage = <div className="lp-modal-warning">
            <MdWarning />This Phone Number is set as preferred which means that he will replace the current one.<br/>
            Are you sure you want to do that? If not, uncheck the Is Preferred checkbox.
          </div>;
        }
        else {
          updatePhoneNumber(data, false);
          return;
        }
        
        let modal: ModalState = {
          title: "Update confirmation",
          body: bodyMessage,
          actionText: "Update",
          onAction: () => updatePhoneNumber(data),
          show: false,
          onClose: () => setIsLoading(false)
        }
        modalActions.setModal(modal);
        modalActions.toggleModalShownStatus();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        setIsLoading(false);
      });
    }
    else {
      updatePhoneNumber(data, false);
    }
  }

  function updatePhoneNumber(data: CreateOrUpdateEntityPhoneNumberModel, fromModal: boolean = true) {
    if(fromModal)
    {
      modalActions.toggleModalLoading();
    }

    data.entityId = props.entityId;
    data.shouldUpdateAllEntities = false;
    removeEmptyFields(data);
    updateEntityPhoneNumber(data.phoneNumberId!, props?.entityId, data).then(() => {
      if(props.submitCallback) {
        props.submitCallback();
      }
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error: any) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
      if(fromModal)
      {
        modalActions.toggleModalLoading();
        modalActions.toggleModalShownStatus();
      }
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit}>
        <Row>
          <Col>
            <Field
              label={"Phone Number"}
              value={watch('phoneNumber')}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Field
              label={"Phone Type"}
              value={watch('phoneTypeName')}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Field
              label={"Extension"}
              value={watch('extension')}
            />
          </Col>
        </Row>

        <Row className="mt-4 mb-4">
          <Form.Group as={Col} controlId="isPreferred">
            <Form.Label>Is Preferred</Form.Label>
            <Controller
              control={control}
              name="isPreferred"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="isPreferred">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>

          {loggedInUser.userPermissions?.find(a => a == UserPermissionsNames.ManageSystemOwnedContacts) &&
            <Col>
              <BooleanField
                label={"System Owned"}
                value={watch('systemOwned')}
              />
            </Col>
          }
        </Row>

        <Form.Group className="d-flex justify-content-between">
          <Button variant="success" type="submit">Update</Button>
          <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
        </Form.Group>
      </Form>
    </>
  );
}
