import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { columnDefs } from "./columnDefs";
import { Card, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Title from "components/Title/index";
import { checkIfActivityIsInAnyChargingRate, getCompleteChargingRates } from "actions/chargingSchemes";
import CreateChargingRate from "containers/ChargingScheme/CreateChargingRate/CreateChargingRate";
import { ChargingSchemeModel } from "models/view/ChargingSchemeModel";
import { GridOptionButtonDataModel } from "models/view/GridOptionButtonDataModel";
import { GridOptionTypes } from "enums/GridOptionTypes";
import CreateRecordableEventTypeToChargingRate from "../ConfigureRecordableEventTypes/CreateRecordableType/CreateRecordableEventTypeToChargingRate";
import { useParams } from "react-router-dom";
import { ChargingRateModel } from "models/view/ChargingRateModel";
import TooltipIcon from "components/TooltipIcon";
import { useAppSelector } from "hooks/appSelector";

type Props = {
  chargingScheme: ChargingSchemeModel
}

export default function ChargingRatesList(props: Props) {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const [receivedEmailActivityInChargingRates, setReceivedEmailActivityInChargingRates] = useState<boolean>(false);
  const [sentEmailActivityInChargingRates, setSentEmailActivityInChargingRates] = useState<boolean>(false);
  const grid = useAppSelector((state) => state.grid);

  const onGridReady = useCallback(() =>
  {
    gridActions.setGridColumnDefs([...columnDefs]);
  }, []);

  useEffect(() => {
    getCompleteChargingRates(props.chargingScheme.id).then((response) => 
    {
      gridActions.setGridRowData(response.data as any)
    });
  }, [props.chargingScheme]);

  useEffect(() => {
    if(props.chargingScheme)
    {
      checkIfActivityIsInAnyChargingRate(props.chargingScheme?.id, {
        value: props.chargingScheme?.receivedEmailActivityId
      }).then((response) => {
        setReceivedEmailActivityInChargingRates(response.data);
      });

      checkIfActivityIsInAnyChargingRate(props.chargingScheme?.id, {
        value: props.chargingScheme?.sentEmailActivityId
      }).then((response) => {
        setSentEmailActivityInChargingRates(response.data);
      });
    }
  }, [props.chargingScheme?.receivedEmailActivityId, props.chargingScheme?.sentEmailActivityId, grid.rowData]);

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Add Charging Rate",
        children: <CreateChargingRate
          chargingScheme={props.chargingScheme}
        />
      });
  }

  const { id } = useParams();

  const gridOptions: GridOptionButtonDataModel[] = [
    {
      type: GridOptionTypes.AddChargingRateToRecordableEventType,
      callback: (rows: ChargingRateModel[]) => addChargingRatesToRecordableEvent(rows)
    },
  ];

  const addChargingRatesToRecordableEvent = (rows: ChargingRateModel[]) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Add to Recordable Event Type',
      children: <CreateRecordableEventTypeToChargingRate
        chargingSchemeId={id!}
        chargingRates={rows.map(x => x.id)}
      />
    });
  };

  return (
    <>
      <Title type="section" title={
          <>
          Charge Rates
          {(!receivedEmailActivityInChargingRates || !sentEmailActivityInChargingRates) &&
            <TooltipIcon type="error" text={`${!receivedEmailActivityInChargingRates ? "Received" : ""}${!receivedEmailActivityInChargingRates && !sentEmailActivityInChargingRates ? ", " : ""}${!sentEmailActivityInChargingRates ? "Sent" : ""} ${!receivedEmailActivityInChargingRates && !sentEmailActivityInChargingRates ? "Email Activities are" : "Email Activity is"} not assigned to any Charging Rate.`} />
          }
          </>
        }>
        <Button onClick={toggleSlidingPanel} className="btn-icon" variant="success">
          <MdAdd />
        </Button>
      </Title>
      <Card className="with-grid">
        <Card.Body>
          <Grid 
            gridOptions={gridOptions}
            onGridReady={onGridReady}
          />
        </Card.Body>
      </Card>
    </>
  );
}
