import Loader from "components/Loader/index";
import IconButtonGrid from "components/Buttons/IconButtonGrid";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { Card } from "react-bootstrap";
import './style.scss';
import React from "react";

type Props = {
  listItems: any[],
  listButtons: Array<{type: ActionButtonTypes, callback: Function, id?: string}>,
  onClick?: Function,
  selected?: string,
  loadState?: boolean
  children: React.ReactNode
}

export default function List(props: Props) {
  return (
    <Card className="with-list">
      <Card.Header>{props.children}</Card.Header>
      {props.loadState && <Loader inlineLoader={props.loadState} />}
      <Card.Body>
        <ul className="lp-list-grid">
          {props.listItems.length == 0 && (
            <div className="lp-empty list">
              <div className="image"></div>
              <div className="text">No rows found!</div>
            </div>
          )}

          {props.listItems.map((x: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <li className={x.id == props.selected ? "lp-list-grid-selected" : ""}>
                  <div
                    className="lp-list-grid-item-name"
                    onClick={() => props.onClick?.(x.id)}
                  >
                    {x.name}
                  </div>
                  <div className="lp-list-grid-item-actions">
                    {
                      props.listButtons.map((el: {type: ActionButtonTypes, callback: Function, id?: string}, index: number) => 
                        IconButtonGrid({...el, callback: () => {
                          el.callback(x.id)
                        }, id: el.id + index.toString()})
                      )
                    }
                  </div>
                </li>
              </React.Fragment>
            )
          })}
        </ul>
      </Card.Body>
    </Card>
  );
}
