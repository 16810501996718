export enum AccountKinds {
  InterestPayable = 1,
  AgencyExpenses = 2,
  IncidentalExpensesNotYetBilledToClient = 3,
  DisbursementsNotYetBilledToClient = 4,
  AccountsReceivable = 5,
  ClientControlAccount = 6,
  DesignatedDepositClientControlAccount = 7,
  ProfessionalFees = 8,
  HmrcVAT = 9,
  BadDebts = 10,
  PettyCash = 11,
  EmployeeMatterRelatedExpenses = 12,
  AccountsPayable = 13,
  BusinessCashAccount = 14,
  ClientCashAccount = 15,
  DesignatedDepositCashAccount = 16
}