import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { getEntityDocumentExchange, getPreferredEntityDocumentExchange, updateEntityDocumentExchange } from "actions/entity";
import { CreateOrUpdateEntityDocumentExchangeModel } from "models/create/CreateOrUpdateEntityDocumentExchangeModel";
import { removeEmptyFields } from "utils/form";
import Field from "components/Fields/Field";
import useModalActions from 'actions/modal';
import { MdWarning } from 'react-icons/md';
import { ModalState } from 'state/modalSlice';

type Props = {
  entityId: string,
  documentExchangeId: string,
  submitCallback?: Function
}

export default function EditEntityDocumentExchangeOnlyIsPreferredForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();

  useEffect(() => {
    setIsLoading(true);
    getEntityDocumentExchange(props.documentExchangeId).then( (response) => {
      reset(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const {reset, control, watch, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateEntityDocumentExchangeModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateEntityDocumentExchangeModel) {
    setIsLoading(true);
    if(data.isPreferred == true) {
      getPreferredEntityDocumentExchange(props.entityId).then((response) => {
        let bodyMessage: React.ReactNode = <></>;
        if(response.data?.id && response.data?.id != props.documentExchangeId) {
          bodyMessage = <div className="lp-modal-warning">
            <MdWarning />This Document Exchange is set as preferred which means that he will replace the current one.<br/>
            Are you sure you want to do that? If not, uncheck the Is Preferred checkbox.
          </div>;
        }
        else {
          updateDX(data, false);
          return;
        }
        
        let modal: ModalState = {
          title: "Update confirmation",
          body: bodyMessage,
          actionText: "Update",
          onAction: () => updateDX(data),
          show: false,
          onClose: () => setIsLoading(false)
        }
        modalActions.setModal(modal);
        modalActions.toggleModalShownStatus();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        setIsLoading(false);
      });
    }
    else {
      updateDX(data, false);
    }
  }

  function updateDX(data: CreateOrUpdateEntityDocumentExchangeModel, fromModal: boolean = true) {
    if(fromModal)
    {
      modalActions.toggleModalLoading();
    }

    removeEmptyFields(data);
    data.entityId = props.entityId;
    updateEntityDocumentExchange(props.documentExchangeId, data).then((response) => {
      if(props.submitCallback) {
        props.submitCallback();
      }
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
      if(fromModal)
      {
        modalActions.toggleModalLoading();
        modalActions.toggleModalShownStatus();
      }
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit}>
        <Row>
          <Col>
            <Field
              label={"DX Exchange"}
              value={watch('dxExchange')}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Field
              label={"DX Number"}
              value={watch('dxNumber')}
            />
          </Col>
        </Row>

        <Form.Group className="mt-4 mb-4" controlId="isPreferred">
          <Form.Label>Is Preferred</Form.Label>
          <Controller
            control={control}
            name="isPreferred"
            shouldUnregister={true}
            render={({field: { onChange, value, name, ref }}) => (
              <Form.Check 
                type="switch"
                id="isPreferred">
                  <Form.Check.Input
                    className= "form-check-input"
                    ref={ref}
                    checked={value ?? false}
                    onChange={(ev: any) => onChange(ev.target.checked)}
                  />
              </Form.Check>
            )}
          />
          <Form.Text className="lp-error">
            {errors?.isPreferred?.message && (errors.isPreferred.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="d-flex justify-content-between">
          <Button variant="success" type="submit">Update</Button>
          <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
        </Form.Group>
      </Form>
    </>
  );
}
