import { NotificationTypes } from "enums/NotificationTypes";
import { useAppSelector } from "hooks/appSelector";
import { useCallback, useEffect, useState } from "react";
import { Button, Toast, ToastContainer } from "react-bootstrap";
import { NotificationMessageModel } from "models/NotificationMessageModel";
import { MdCheckCircle, MdError, MdInfo, MdWarning } from "react-icons/md";
import { useLocation } from "react-router-dom";
import useNotificationActions from "actions/notification";
import useSlidingPanelActions from "actions/slidingPanel";
import NotificationSlidingPanel from "./NotificationSlidingPanel";
import './style.scss';

type ButtonState = {
  type: string,
  icon: JSX.Element,
  text: string
}

function NotificationMessages() {
  const notifications = useAppSelector((state) => state.notification);
  const slidingPanel = useAppSelector((state) => state.slidingPanel);

  const [buttonState, setButtonState] = useState<ButtonState>({
    type: "success",
    icon: <MdCheckCircle />,
    text: "Everything looks fine."
  });
  const [isToastShown, setToastShown] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const notificationActions = useNotificationActions();
  const slidingPanelActions = useSlidingPanelActions();

  const location = useLocation();

  const toggleSlidingPanel = () => {
    setIsHovering(false);
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Notifications",
        children: <NotificationSlidingPanel />
      });
  };

  useEffect(() => {
    notificationActions.clearNotificationMessages();
  }, [location]);

  useEffect(() => {
    changeText();
    if (notifications.notificationMessages.length > 0 && notifications.newNotificationAdded) {
      setToastShown(true);
    }
  }, [notifications]);

  const changeText = useCallback(() => {
    if (notifications.notificationMessages.length == 0) {
      setButtonState({
        type: "success",
        icon: <MdCheckCircle />,
        text: "Everything looks fine!"
      });
      return;
    }

    if (!notifications.notificationMessages.some((x: NotificationMessageModel) => x.type === NotificationTypes.Error)) {
      if (notifications.notificationMessages.some((x: NotificationMessageModel) => x.type === NotificationTypes.Warning)) {
        setButtonState({
          type: "warning",
          icon: <MdWarning />,
          text: `You have ${notifications.notificationMessages.length} ${notifications.notificationMessages.length > 1 ? 'messages' : 'message'}.`
        });
        return;
      }
      if (notifications.notificationMessages.some((x: NotificationMessageModel) => x.type === NotificationTypes.Info)) {
        setButtonState({
          type: "info",
          icon: <MdInfo />,
          text: `You have ${notifications.notificationMessages.length} ${notifications.notificationMessages.length > 1 ? 'messages' : 'message'}.`
        });
        return;
      }
      if (notifications.notificationMessages.some((x: NotificationMessageModel) => x.type === NotificationTypes.Success)) {
        setButtonState({
          type: "success",
          icon: <MdCheckCircle />,
          text: `You have ${notifications.notificationMessages.length} ${notifications.notificationMessages.length > 1 ? 'messages' : 'message'}.`
        });
        return;
      }
    }

    setButtonState({
      type: "danger",
      icon: <MdError />,
      text: `You have ${notifications.notificationMessages.length} ${notifications.notificationMessages.length > 1 ? 'messages' : 'message'}.`
    });
  }, [notifications]);

  return (
    <>
      {(!slidingPanel.isShown && (
        !isToastShown ? ( notifications.notificationMessages.length > 0 &&
          (
          <Button
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onClick={toggleSlidingPanel}
            variant={buttonState.type}
            className="lp-notification-btn"
          >
            {!isHovering ? <>{buttonState.icon} <strong>{notifications.notificationMessages.length}</strong></> : buttonState.text}
          </Button>
          )
        ) : (
          <ToastContainer className="p-3" position="bottom-end">
            <Toast bg={notifications.notificationMessages[notifications.notificationMessages.length - 1]?.type ?? ""} delay={5000} autohide show={isToastShown} onClose={() => {setToastShown(false); notificationActions.resetNewNotificationAddedFlag();}}>
              <Toast.Header>{notifications.notificationMessages[notifications.notificationMessages.length - 1]?.title ?? ""}</Toast.Header>
              <Toast.Body>{notifications.notificationMessages[notifications.notificationMessages.length - 1]?.body ?? ""}</Toast.Body>
            </Toast>
          </ToastContainer>
        ))
      )}
    </>
  );
}

export default NotificationMessages;
