import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import CustomSelect from "components/Select/Select";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { TaxonomyTermModel } from "models/view/TaxonomyTermModel";
import { getLteTaxonomies, getLteTaxonomyTermsSummary } from "actions/taxonomy";
import { TaxonomyModel } from "models/view/TaxonomyModel";
import React from "react";
import CustomTreeSelect from "components/TreeSelect/CustomTreeSelect";
import TreeSelect from "rc-tree-select";
import { getTemplateTaxonomyTerms, updateTemplateTaxonomyTerms } from "actions/settings";
import { UpdateTemplateTaxonomyTermsModel } from "models/update/UpdateTemplateTaxonomyTermsModel";

type Props = {
  templateId: string
}

export default function EditTemplateTaxonomyTermsForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [taxonomies, setTaxonomies] = useState<TaxonomyModel[]>([]);
  const slidingPanelActions = useSlidingPanelActions();

  const {reset, control, handleSubmit, formState: {errors}} = useForm<any>({
    resolver: vestResolver(validationSuite)
  });

  useEffect(() => {
    setIsLoading(true);
    const promises = [
      getLteTaxonomies(undefined, true),
      getTemplateTaxonomyTerms(props.templateId)
    ];

    Promise.all(promises)
      .then(([taxonomiesResponse, templateTaxonomyTermsResponse]) => {
        setTaxonomies(taxonomiesResponse.data);
        
        let initialState: any = {};
        taxonomiesResponse.data.forEach(function (taxonomy: TaxonomyModel) {
          if(taxonomy.isMultiSelect) {
            if(taxonomy.isHierarchical) {
              initialState[`taxonomy-${taxonomy.id}`] = templateTaxonomyTermsResponse.data
                .filter((x: TaxonomyTermModel) => x.taxonomyId == taxonomy.id)
                .map((x: TaxonomyTermModel) => {return {label: x.displayName, value: x.id}});
            }
            else {
              initialState[`taxonomy-${taxonomy.id}`] = templateTaxonomyTermsResponse.data
                .filter((x: TaxonomyTermModel) => x.taxonomyId == taxonomy.id).map((x: TaxonomyTermModel) => x.id);
            }
          }
          else {
            const [currentValue] = templateTaxonomyTermsResponse.data
              .filter((x: TaxonomyTermModel) => x.taxonomyId == taxonomy.id).map((x: TaxonomyTermModel) => x.id);
            initialState[`taxonomy-${taxonomy.id}`] = currentValue;
          }
        });

        reset(initialState);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
      
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: any) {
    setIsLoading(true);
    removeEmptyFields(data);
    const requestBody: UpdateTemplateTaxonomyTermsModel = {
      taxonomyTermIds: []
    };

    Object.keys(data).forEach(function (key: string) {
      const value = Reflect.get(data, key);
      if(Array.isArray(value)) {
        if(value.length > 0 && typeof value[0] === 'object') {
          requestBody.taxonomyTermIds = requestBody.taxonomyTermIds.concat(value.map(x => x.value));
        }
        else {
          requestBody.taxonomyTermIds = requestBody.taxonomyTermIds.concat(value);
        }
      }
      else {
        requestBody.taxonomyTermIds = requestBody.taxonomyTermIds.concat([value]);
      }
    });

    updateTemplateTaxonomyTerms(props.templateId, requestBody)
      .then((response) => {
        slidingPanelActions.clearSlidingPanel();
        reset();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit}>
        {taxonomies.map((x: TaxonomyModel, index: number) => {
          return (
            <React.Fragment key={index}>
              <Form.Group className="mb-4" controlId={`taxonomy-${x.id}`}>
                <Form.Label>{x.displayName}</Form.Label>
                <Controller
                  control={control}
                  name={`taxonomy-${x.id}`}
                  shouldUnregister={true}
                  render={({field: { onChange, value, name, ref }}) => (
                    <>
                      {x.isHierarchical ? 
                        <CustomTreeSelect
                          id={`taxonomy-${x.id}`}
                          className={`lp-select w-100${errors?.parentTaxonomyTermId?.message ? ' invalid' : ''}`}
                          endpointCall={() => getLteTaxonomyTermsSummary(x.id)}
                          value={value}
                          onChange={(val: any) => onChange(val ?? null)}
                          dropdownMatchSelectWidth
                          treeDefaultExpandAll
                          treeCheckable={x.isMultiSelect}
                          showCheckedStrategy={x.isMultiSelect ? TreeSelect.SHOW_ALL : undefined}
                          treeCheckStrictly={x.isMultiSelect}
                          maxTagCount={4}
                          maxTagTextLength={25}
                        />
                      :
                        <CustomSelect
                          id={`taxonomy-${x.id}`}
                          inputRef={ref}
                          endpointCall={() => getLteTaxonomyTermsSummary(x.id)}
                          isMulti={x.isMultiSelect}
                          isClearable
                          value={value}
                          onChange={val => {
                            x.isMultiSelect ? 
                              (onChange(val?.map((x: { id: any; }) => x.id) ?? null))
                            : 
                              (onChange(val?.id ?? null))
                          }}
                        />
                      }
                    </>
                    
                  )}
                />
              </Form.Group>
            </React.Fragment>
          )
        })}

        <Form.Group className="d-flex justify-content-between">
          <Button variant="success" type="submit">Update</Button>
          <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
        </Form.Group>
      </Form>
    </>
  );
}
