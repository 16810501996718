import { validateEntityRoleName } from "actions/settings";
import { create, test, enforce } from "vest";

export const validationSuite = create((data = {}) => {
  test('name', 'Name is required', () => {
    enforce(data.name).isNotEmpty();
    enforce(data.name).isString();
  });

  test.memo('name', 'Name must be unique', async () => {
    enforce((await validateEntityRoleName(data.name)).data).isTruthy();
  }, [data.name]);
});