import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Row, Col, Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import CustomSelect from "components/Select/Select";
import Loader from "components/Loader";
import { getValidationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { useAppSelector } from "hooks/appSelector";
import { getMatterFeeEarnerById, updateMatterFeeEarner } from "actions/matter";
import { CreateOrUpdateMatterFeeEarnerModel } from "models/create/CreateOrUpdateMatterFeeEarnerModel";
import { getFeeEarnerLevelsSummaryForLte } from "actions/settings";
import { MatterFeeEarnerModel } from "models/view/MatterFeeEarnerModel";
import ViewMatterFeeEarnerProfile from "../ViewMatterFeeEarnerProfile";
import { MdWarning } from "react-icons/md";
import { ModalState } from "state/modalSlice";
import BooleanField from "components/Fields/BooleanField";
import { getLteUser } from "actions/user";
import useModalActions from "actions/modal";

type Props = {
  matterId?: string,
  matterFeeEarnerId?: string,
  submitCallback?: Function,
  isCurrentCaseManager?: boolean
}

export default function EditMatterFeeEarnerForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [matterFeeEarner, setMatterFeeEarner] = useState<MatterFeeEarnerModel>();
  const [isFeeEarner, setIsFeeEarner] = useState<boolean>(true);
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(() => {
    if(props.matterFeeEarnerId)
    {
      setIsLoading(true);
      getMatterFeeEarnerById(props?.matterFeeEarnerId!)
        .then( (responseMatterFeeEarner) => {
          getLteUser(responseMatterFeeEarner.data.userId).then((responseUser) => {
            setIsFeeEarner(responseUser.data.isFeeEarner);

            setMatterFeeEarner(responseMatterFeeEarner.data);
            const initialState = {
              matterId: props.matterId,
              userId: responseMatterFeeEarner.data?.userId ?? '',
              feeEarnerLevelId: responseMatterFeeEarner.data?.feeEarnerLevelId ?? '',
              isCaseManager: responseMatterFeeEarner.data?.isCaseManager ?? false
            }
            reset(initialState);
          })
          .catch((error) => {
            setGenericErrors(error.response?.data?.Message ?? error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
        }
      )
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        setIsLoading(false);
      });
    }
  }, []);

  const { reset, control, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateMatterFeeEarnerModel>({
    resolver: vestResolver(getValidationSuite(isFeeEarner))
  });

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: CreateOrUpdateMatterFeeEarnerModel) {
    setIsLoading(true);
    if(data.isCaseManager == true && !(props.isCurrentCaseManager ?? false)) {
      var bodyMessage: React.ReactNode = <div className="lp-modal-warning">
        <MdWarning />This Fee Earner is set as Case Manager which means that he will replace the current one.<br/>
        Are you sure you want to do that? If not, uncheck the Is Case Manager checkbox.
      </div>;
      let modal: ModalState = {
        title: "Edit confirmation",
        body: bodyMessage,
        actionText: "Update",
        onAction: () => updateFeeEarner(data),
        show: true,
        onClose: () => setIsLoading(false)
      }
      modalActions.setModal(modal);
    }
    else {
      updateFeeEarner(data, false);
    }
  }

  function updateFeeEarner(data: CreateOrUpdateMatterFeeEarnerModel, fromModal: boolean = true) {
    if(fromModal)
    {
      modalActions.toggleModalLoading();
    }

    removeEmptyFields(data);
    data.matterId = props.matterId!;
    updateMatterFeeEarner(props.matterFeeEarnerId!, data)
      .then((response) => {
        if(props.submitCallback) {
          props.submitCallback(response.data);
        }
        slidingPanelActions.clearSlidingPanel();
        reset();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
        if(fromModal)
        {
          modalActions.toggleModalLoading();
          modalActions.toggleModalShownStatus();
        }
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}
      
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-4" controlId={`userId`}>
          <ViewMatterFeeEarnerProfile 
            matterFeeEarner={matterFeeEarner!}
            showRole={false}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="feeEarnerLevelId">
          <Form.Label className={`${isFeeEarner ? "required" : ""}`}>Fee Earner Level</Form.Label>
          <Controller
            control={control}
            name="feeEarnerLevelId"
            shouldUnregister={true}
            render={({field: { onChange, value, ref }}) => (
              <CustomSelect
                id="feeEarnerLevelId"
                inputRef={ref}
                className={`lp-select${errors?.feeEarnerLevelId?.message ? ' invalid' : ''}`}
                endpointCall={() => getFeeEarnerLevelsSummaryForLte(loggedInUser.lawPageTradingEntityId!)}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
                isClearable={!isFeeEarner}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.feeEarnerLevelId?.message && (errors.feeEarnerLevelId.message)}
          </Form.Text>
        </Form.Group>

        <Row className="mb-4">
          <Form.Group as={Col} controlId="isCaseManager">
            {!(matterFeeEarner?.isCaseManager ?? false) && 
              <>
                <Form.Label>Is Case Manager</Form.Label>
                <Controller
                  control={control}
                  name="isCaseManager"
                  shouldUnregister={true}
                  render={({field: { onChange, value, name, ref }}) => (
                    <Form.Check 
                      type="switch"
                      id="isCaseManager">
                        <Form.Check.Input
                          className= "form-check-input"
                          ref={ref}
                          checked={value}
                          onChange={(ev: any) => onChange(ev.target.checked)}
                        />
                    </Form.Check>
                  )}
                />
              </>
            }
            {(matterFeeEarner?.isCaseManager ?? false) && 
              <BooleanField
                label={"Is Case Manager"}
                value={matterFeeEarner?.isCaseManager ?? false}
              />
            }
          </Form.Group>
        </Row>

        <Form.Group className="d-flex justify-content-between">
          <Button variant="success" type="submit">Update</Button>
          <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
        </Form.Group>
      </Form>
    </>
  );
}
