import { create, test, enforce, omitWhen } from "vest";
import isInt from "validator/lib/isInt";
import isDecimal from "validator/lib/isDecimal";
import { ChargingBasisIds } from "enums/ChargingBasisIds";

export const validationSuite = create((data = {}) => {
  enforce.extend({ isInt });
  enforce.extend({ isDecimal });

  omitWhen((!data.minutesPerUnit || data.minutesPerUnit == "" || data.chargingBasisId != ChargingBasisIds.FixedId), () => {
    test('minutesPerUnit', 'Minutes Per Unit needs to be a number >= 0', () => {
      enforce(data.minutesPerUnit!.toString()).isInt().greaterThanOrEquals(0);
    });
  });

  omitWhen((!data.minutesPerUnit || data.minutesPerUnit == "" || data.chargingBasisId != ChargingBasisIds.TimeId), () => {
    test('minutesPerUnit', 'Minutes Per Unit needs to be a number > 0', () => {
      enforce(data.minutesPerUnit!.toString()).isInt().greaterThan(0);
    });
  });

  omitWhen(data.chargingBasisId != ChargingBasisIds.TimeId, () => {
    test('minutesPerUnit', 'Minutes Per Unit is required', () => {
      enforce(data.minutesPerUnit!.toString()).isNotEmpty();
    });
  });

  omitWhen(!data.chargeRate, () => {
    test('chargeRate', 'Charge Rate needs to be a number', () => {
      enforce(data.chargeRate?.toString()).isDecimal();
    });
  });

  test('chargingBasisId', 'Please select a Charging Basis', () => {
    enforce(data.chargingBasisId).isNotEmpty();
    enforce(data.chargingBasisId).isString();
  });

  test('chargeRate', 'Charge Rate is required', () => {
    enforce(data.chargeRate.toString()).isNotEmpty();
  });
});