import SlidingPanel from "components/SlidingPanel/index";
import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect, useState } from "react";
import { getColumnDefs } from "./columnDefs";
import { Row, Col, Card } from "react-bootstrap";
import CustomModal from "components/Modal/Modal";
import SummaryBox from 'components/SummaryBox';
import { SummaryBoxTypes } from "enums/SummaryBoxTypes";
import { getMatterRecordableItemsForMatter, getMatterRecordableItemsSummaryInfo } from "actions/matter";
import Title from "components/Title/index";
import { GridIds } from "enums/GridIds";
import Loader from "components/Loader/index";
import SummaryBoxesSection from "components/SummaryBoxesSection";
import { MatterRecordableItemsSummaryInfoModel } from "models/view/MatterRecordableItemsSummaryInfoModel";

type Props = {
  matterId: string,
  matterFileNumber: string,
  syncMatterFiles: Function,
  matterIsClosed?: boolean
}

export default function MatterRecordableItemList(props: Props) {
  const gridActions = useGridActions();
  const [matterRecordableItemsSummaryInfo, setMatterRecordableItemsSummaryInfo] = useState<MatterRecordableItemsSummaryInfoModel | undefined>(undefined);
  const [isSummaryInfoLoading, setIsSummaryInfoLoading] = useState<boolean>(false);
  const [genericErrorsForSummaryInfo, setGenericErrorsForSummaryInfo] = useState(undefined);

  const onGridReady = useCallback(() =>
  {
    loadMatterRecordableItemsGrid();
  }, [props.matterId]);

  useEffect(() => {
    loadMatterRecordableItemsSummaryInfo();
  }, [props.matterId]);

  useEffect(() => {
    gridActions.setGridId(`${GridIds.MatterRecordableItems}/${props.matterId}`);
  }, []);

  const loadMatterRecordableItemsGrid = () => {
    const columnDefs = getColumnDefs(() => populateMatterRecordableItems(props.matterId));
    gridActions.setGridColumnDefs([...columnDefs]);

    populateMatterRecordableItems(props.matterId);
  }

  const populateMatterRecordableItems = (matterId?: string) => {
    if(matterId) {
      getMatterRecordableItemsForMatter(props.matterId ?? '').then((response) => 
      {
        gridActions.setGridRowData(response.data);
      });
    }
    else {
      gridActions.setGridRowData([]);
    }
  };

  const loadMatterRecordableItemsSummaryInfo = () => {
    if(props.matterId) {
      setIsSummaryInfoLoading(true);
      getMatterRecordableItemsSummaryInfo(props.matterId).then((response) => {
        setMatterRecordableItemsSummaryInfo(response.data);
      })
      .catch((error) => {
        setGenericErrorsForSummaryInfo(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsSummaryInfoLoading(false);
      });
    }
    else{
      setMatterRecordableItemsSummaryInfo(undefined);
    }
  }

  return (
    <>
      {isSummaryInfoLoading &&
        <div className="lp-summary-boxes-placeholder">
          <Loader inlineLoader />
        </div>
      }

      {genericErrorsForSummaryInfo && (
        <div className="lp-errors">
          {genericErrorsForSummaryInfo}
        </div>
      )}

      {matterRecordableItemsSummaryInfo && 
        <SummaryBoxesSection>
          <div className="lp-summary-boxes-area">
            <SummaryBox
              type={SummaryBoxTypes.TimeDoughnut}
              values={[
                {
                  value: matterRecordableItemsSummaryInfo.totalBilledMinutes ?? 0,
                  label: "Billed",
                  className: "lp-primary"
                },
                {
                  value: matterRecordableItemsSummaryInfo.totalUnbilledMinutes ?? 0,
                  label: "Unbilled",
                  className: "lp-warning"
                }
              ]}
            />

            <SummaryBox 
              type={SummaryBoxTypes.FeeIncomeDoughnut}
              values={[
                {
                  value: matterRecordableItemsSummaryInfo.totalBilled ?? 0,
                  label: "Billed",
                  className: "lp-primary"
                },
                {
                  value: matterRecordableItemsSummaryInfo.totalUnbilled ?? 0,
                  label: "Unbilled",
                  className: "lp-warning"
                }
              ]}
            />
          </div>
        </SummaryBoxesSection>
      }

      <Row>
        <Col>
          <Title
            type="section"
            title={<>Recordable Items</>}
          >
          </Title>
          <Card className="with-grid">
            <Card.Body>
              <Grid 
                onGridReady={onGridReady}
                pagination={true}
                paginationPageSize={25}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <SlidingPanel/>

      <CustomModal />
    </>
  );
}
