import { MatterInvoiceStatusesIds } from "enums/MatterInvoiceStatusesIds";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";

type Props = {
  value: DropDownOptionModel
}

export default function InvoiceStatusFieldValue(props: Props) {

  const checkInvoiceStatus = () => {
    switch(props.value.id)
    {
      case MatterInvoiceStatusesIds.DraftId:
        return 'warning';
      case MatterInvoiceStatusesIds.AwaitingPaymentId:
        return 'danger';
      case MatterInvoiceStatusesIds.PartPaidId:
        return 'partial';
      case MatterInvoiceStatusesIds.PaidId:
        return 'success';
    }
  }

  return (
    <>
      <span className={`lp-chip invoice-status ${checkInvoiceStatus()}`}>{props.value.name}</span>
    </>
  );
}
