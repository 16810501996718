import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import CustomSelect from "components/Select/Select";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { createMatterNote, getMatterActivityHistory, getNoteTypes } from "actions/matter";
import { CreateOrUpdateMatterNoteModel } from "models/create/CreateOrUpdateMatterNoteModel";
import useGridActions from "actions/grid";
import store from "state/store";
import { GridIds } from "enums/GridIds";

type Props = {
  matterId: string
}

export default function CreateMatterNoteForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();

  const {reset, control, register, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateMatterNoteModel>({
    resolver: vestResolver(validationSuite)
  });

  const gridActions = useGridActions();

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: CreateOrUpdateMatterNoteModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    createMatterNote(props.matterId, data)
      .then((response) => {
        const gridState = store.getState().grid;
        if(gridState.id == `${GridIds.ActivityHistory}/${props.matterId}`) {
          getMatterActivityHistory(props.matterId ?? '').then((response) => 
          {
            gridActions.setGridRowData(response.data);
          });
        }
        slidingPanelActions.clearSlidingPanel();
        reset();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-4" controlId="note">
          <Form.Label className="required">Note</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            className={`${errors?.note?.message ? 'invalid' : ''}`}
            {...register("note", {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.note?.message && (errors.note.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="noteTypeId">
          <Form.Label className="required">Type</Form.Label>
          <Controller
            control={control}
            name="noteTypeId"
            shouldUnregister={true}
            render={({field: { onChange, value, name, ref }}) => (
              <CustomSelect
                id="noteTypeId"
                inputRef={ref}
                className={`lp-select${errors?.noteTypeId?.message ? ' invalid' : ''}`}
                endpointCall={getNoteTypes}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.noteTypeId?.message && (errors.noteTypeId.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="d-flex justify-content-between">
          <Button variant="success" type="submit">Create</Button>
          <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
        </Form.Group>
      </Form>
    </>
  );
}
