import { useEffect, useState } from 'react';
import Title from 'components/Title';
import { useNavigate, Link } from 'react-router-dom';
import { MdArrowOutward } from 'react-icons/md';
import { MatterInvoiceModel } from 'models/view/MatterInvoiceModel';
import ListMatterInvoices from 'components/List/ListMatterInvoices';
import ViewMatterInvoice from 'containers/Matter/ViewMatter/ActivityHistory/MatterInvoice/ViewMatterInvoice/ViewMatterInvoice';
import useSlidingPanelActions from 'actions/slidingPanel';
import { getMatterInvoicesForUser } from 'actions/user';

export default function DashboardMatterInvoices() {
  const [matterInvoices, setMatterInvoices] = useState<MatterInvoiceModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(undefined);
  const navigate = useNavigate();
  const slidingPanelActions = useSlidingPanelActions();

  useEffect(() => {
    loadMatterInvoices();
  }, []);

  const loadMatterInvoices = () => {
    setIsLoading(true);
    getMatterInvoicesForUser(10).then((response) => {
      setMatterInvoices(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const viewMatterInvoice = (id: string, matterId: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      width: "55rem",
      title: "View Invoice",
      children: <ViewMatterInvoice 
          matterId={matterId}
          matterInvoiceId={id}
          showActions
          onMarkAsDraftCallback={loadMatterInvoices}
          onDeleteCallback={loadMatterInvoices}
          onEditCallback={loadMatterInvoices}
          onRecordPaymentCallback={loadMatterInvoices}
        />
    });
  };

  return (
    <>
      <Title type="section" title="Recent Invoices">
        <Link to="/invoice/all" rel="noopener noreferrer" className="link">
          View all
          <MdArrowOutward />
        </Link>
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <ListMatterInvoices
        listItems={matterInvoices}
        listButtons={
          [
            /*{
              type: ActionButtonTypes.View,
              callback: (id: string) => viewMatterInvoice(id)
            }*/
          ]
        }
        loadState={isLoading}
      />
    </>
  );
}