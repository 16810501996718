import Title from "components/Title";
import { TabPanel } from "react-tabs";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import CustomTabs from "components/CustomTabs";
import DefaultEntityRoleList from "./DefaultEntityRoles/DefaultEntityRoleList";
import EntityRoleList from "./EntityRoles/EntityRoleList";

export default function ViewSettings() {
  const loggedInUser = useAppSelector((state) => state.user);

  return (
    <div className="lp-page-content">
      <Title type="page" title="Address Book Settings"></Title>

      <CustomTabs 
        tabList={[
          ...(loggedInUser.userPermissions?.find(a => a.toLowerCase().trim() == UserPermissionsNames.ManageDefaultSettings.toLowerCase().trim()) ? [{
            name: 'Default Entity Roles',
            panel: <TabPanel>
              <DefaultEntityRoleList />
            </TabPanel>
          }] : []),
          ...(loggedInUser.userPermissions?.find(a => a.toLowerCase().trim() == UserPermissionsNames.ManageLTESettings.toLowerCase().trim()) ? [{
            name: 'Entity Roles',
            panel: <TabPanel>
              <EntityRoleList />
            </TabPanel>
          }] : [])
        ]}
      />
    </div>
  );
}
