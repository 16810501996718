import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Loader from "components/Loader";
import { getAccountLedgerTransactionById, getAccountLedgerTransactionEntriesForTransaction } from "actions/matter";
import { AccountLedgerTransactionEntryModel } from "models/view/AccountLedgerTransactionEntryModel";
import Field from "components/Fields/Field";
import { AccountLedgerTransactionModel } from "models/view/AccountLedgerTransactionModel";
import moment from "moment";
import { DateFormat } from "utils/constants";
import ListAccountLedgerTransactionEntries from "components/List/ListAccountLedgerTransactionEntries";
import { AccountDisplayOn } from "enums/AccountDisplayOn";

type Props = {
  accountLedgerTransactionId: string,
  showMatterInfo?: boolean
}

export default function ViewAccountLedgerTransaction(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accountLedgerTransaction, setAccountLedgerTransaction] = useState<AccountLedgerTransactionModel>();
  const [accountLedgerTransactionEntries, setAccountLedgerTransactionEntries] = useState<AccountLedgerTransactionEntryModel[]>([]);

  useEffect(() => {
    setIsLoading(true);

    const promises = [
      getAccountLedgerTransactionById(props.accountLedgerTransactionId),
      getAccountLedgerTransactionEntriesForTransaction(props.accountLedgerTransactionId)
    ];

    Promise.all(promises)
      .then(([transactionResponse, transactionEntriesResponse]) => {
        setAccountLedgerTransaction(transactionResponse.data);
        setAccountLedgerTransactionEntries(transactionEntriesResponse.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });      
  }, []);

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Date"}
            value={accountLedgerTransaction?.date ? moment(accountLedgerTransaction?.date).format(DateFormat.Moment) : "—"}
          />
        </Col>
        {props.showMatterInfo && 
          <Col>
            <Field
              label={"Matter"}
              value={accountLedgerTransaction?.matter?.name ?? "—"}
            />
          </Col>
        }
      </Row>
      <Row>
        <Col>
          <Field
            label={"Client"}
            value={accountLedgerTransaction?.matterParticipatingEntity?.name ?? "—"}
          />
        </Col>
        <Col>
          <Field
            label={"Display Name"}
            value={accountLedgerTransaction?.displayName}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Field
            label={"Description"}
            value={accountLedgerTransaction?.description}
          />
        </Col>
      </Row>

      {accountLedgerTransactionEntries.some((x) => x.account?.displayOn == AccountDisplayOn.BusinessLedger ||
        x.account?.displayOn == AccountDisplayOn.ClientLedgerBusinessSide ) &&
        <>
          <div className="lp-color-title primary full-width-no-bottom-padding mt-4">Business Ledger</div>
          <ListAccountLedgerTransactionEntries entries={accountLedgerTransactionEntries.filter((x) => 
            x.account?.displayOn == AccountDisplayOn.BusinessLedger || x.account?.displayOn == AccountDisplayOn.ClientLedgerBusinessSide)} />
        </>
      }
      {accountLedgerTransactionEntries.some((x) => x.account?.displayOn == AccountDisplayOn.ClientLedgerClientSide) &&
        <>
          <div className="lp-color-title primary full-width-no-bottom-padding mt-4">Client Ledger</div>
          <ListAccountLedgerTransactionEntries entries={accountLedgerTransactionEntries.filter((x) => x.account?.displayOn == AccountDisplayOn.ClientLedgerClientSide)} />
        </>
      }
    </>
  );
}