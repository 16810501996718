import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from 'containers/App/App';
import reportWebVitals from './reportWebVitals';
import { store } from './state/store'
import { Provider } from 'react-redux'
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './msal/authConfig';
import { LicenseManager } from  'ag-grid-enterprise';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY ?? "");

root.render(
  <BrowserRouter>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
