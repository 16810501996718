import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import Loader from "components/Loader";
import { updateLteCalendarSettings } from "actions/lte";
import { removeEmptyFields } from "utils/form";
import { getValidationSuite } from "./validationSuite";
import { UpdateLteCalendarSettingsModel } from "models/update/UpdateLteCalendarSettingsModel";
import { LteCalendarSettingsModel } from "models/view/LteCalendarSettingsModel";
import { useAppSelector } from "hooks/appSelector";
import { LteIds } from "enums/LteIds";

type Props = {
  lteId: string,
  lteCalendarSettings: LteCalendarSettingsModel | undefined,
  submitCallbackFn: Function,
  cancelCallbackFn: Function,
  isLoading: boolean
}

export default function EditLteCalendarSettingsSummary(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(props.isLoading);
  const user = useAppSelector((state) => state.user);

  const { register, reset, control, handleSubmit, formState: { errors } } = useForm<UpdateLteCalendarSettingsModel>({
    resolver: vestResolver(getValidationSuite())
  });

  useEffect(() => {
    if (props.lteCalendarSettings) {
      let initialFields: UpdateLteCalendarSettingsModel = {
        calendarTenantId: props.lteCalendarSettings.calendarTenantId ?? '',
        diaryManagerEmail: props.lteCalendarSettings.diaryManagerEmail ?? '',
        calendarManagedByLawPage: props.lteCalendarSettings.calendarManagedByLawPage ?? false,
        hasAccessToLawPageRooms: props.lteCalendarSettings.hasAccessToLawPageRooms ?? false
      };
      reset(initialFields);
    }
  }, [props.lteCalendarSettings]);

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: UpdateLteCalendarSettingsModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    updateLteCalendarSettings(props.lteId, data).then((response) => {
      if(props.submitCallbackFn){
        props.submitCallbackFn(response.data);
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const onCancelClick = () => {
    if(props.cancelCallbackFn){
      props.cancelCallbackFn();
    }
  };

  return (
    <Card>
      {isLoading && <Loader inlineLoader />}

      <Card.Body>
        {genericErrors && (
          <div className="lp-errors">
            {genericErrors}
          </div>
        )}

        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-4" controlId="calendarTenantId">
            <Form.Label className="required">Calendar Tenant ID</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.calendarTenantId?.message ? 'invalid' : ''}`}
              {...register("calendarTenantId", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.calendarTenantId?.message && (errors.calendarTenantId.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-4" controlId="diaryManagerEmail">
            <Form.Label className="required">User Account for LTE Diary</Form.Label>
            <Form.Control
              type="email"
              className={`${errors?.diaryManagerEmail?.message ? 'invalid' : ''}`}
              {...register("diaryManagerEmail", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.diaryManagerEmail?.message && (errors.diaryManagerEmail.message)}
            </Form.Text>
          </Form.Group>

          {
            props.lteId != LteIds.LawPageLteId &&
            <Form.Group className="mb-4" controlId="calendarManagedByLawPage">
              <Form.Label>Managed by LawPage</Form.Label>
              <Controller
                control={control}
                name="calendarManagedByLawPage"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <Form.Check 
                    type="switch"
                    id="calendarManagedByLawPage">
                      <Form.Check.Input
                        className= "form-check-input"
                        ref={ref}
                        checked={value}
                        onChange={(ev: any) => onChange(ev.target.checked)}
                      />
                  </Form.Check>
                )}
              />
            </Form.Group>
          }

          <Form.Group className="mb-4" controlId="hasAccessToLawPageRooms">
            <Form.Label>Has Access To LawPage Rooms</Form.Label>
            <Controller
              control={control}
              name="hasAccessToLawPageRooms"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="hasAccessToLawPageRooms">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>

          <Row>
            <Form.Group className="d-flex justify-content-between">
              <Button variant="success" type="submit">Update</Button>
              <Button variant="secondary-400" onClick={onCancelClick}>Cancel</Button>
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
