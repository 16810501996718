import { deleteTemplate, downloadTemplate, getMatterTemplateTypes, getMatterTemplates } from "actions/settings";
import { DropdownArraySearchFilter } from "components/Grid/GridFilters/Filters/DropdownArraySearchFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { ColDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { TemplateModel } from "models/view/TemplateModel";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import store from "state/store";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { addNotification } from "state/notificationSlice";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { setRowData } from "state/gridSlice";
import EditTemplate from "../TemplateActions/EditTemplate/EditTemplate";
import { downloadFile } from 'utils/misc';
import { NotificationTypes } from "enums/NotificationTypes";
import EditTemplateTaxonomyTermsForm from "../TemplateActions/EditTemplateTaxonomyTerms/EditTemplateTaxonomyTermsForm";

const editCallback = (id: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        width: "55rem",
        title: "Edit Template",
        children: <EditTemplate 
          templateId={id}
          submitCallback={reloadGridData}
          isMatterTemplate
        />
      }
    )
  );
}

const editTaxonomyTermsCallback = (id: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        width: "55rem",
        title: "Edit Taxonomy Terms",
        children: <EditTemplateTaxonomyTermsForm 
          templateId={id}
        />
      }
    )
  );
}

const reloadGridData = () => {
  getMatterTemplates().then((response) => 
  {
    store.dispatch(setRowData(response.data));
  });
}

const downloadCallback = (id: string) => {
  downloadTemplate(id).then((response) => {
    downloadFile(response);
  })
  .catch((error) => {
    error.response.data.text().then((text: any) => {
      const responseData = JSON.parse(text);
      addNotification(
        {
          type: NotificationTypes.Error,
          title: "Download Template Error",
          body: `${responseData.Message ?? "There was an error downloading the template."}`,
          isDismissable: true
        }
      );
    });
  });
}

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Template?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteTemplate(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const columnDefs: (ColDef<TemplateModel> | {excludeFromExport: boolean})[] = [
  { 
    excludeFromExport: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    suppressMenu: true,
    suppressSizeToFit: true,
    floatingFilter: false,
    resizable: false,
    sortable: false,
    minWidth: 48,
    maxWidth: 48,
    width: 48,
    lockPosition: true,
    pinned: 'left'
  },
  {
    headerName: 'Name',
    field: 'name',
    filter: 'agTextColumnFilter',
    lockPosition: true,
    pinned: 'left',
    suppressSizeToFit: true,
    minWidth: 120,
    flex: 1
  },
  {
    headerName: 'Description',
    field: 'description',
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Type',
    field: 'type.name',
    filter: DropdownArraySearchFilter,
    filterParams: {
      property: 'typeId',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: DropdownFloatingFilter,
    floatingFilterComponentParams: {
      endpointCall: getMatterTemplateTypes
    },
    minWidth: 220
  },
  { 
    excludeFromExport: true,
    headerName: 'Actions',
    cellClass: 'lp-actions-cell',
    suppressMenu: true,
    cellRenderer: ActionsCellRenderer,
    cellRendererParams: (params: any) => {
      return { buttonsData: [
        {
          type: ActionButtonTypes.Edit,
          callback: editCallback
        },
        {
          type: ActionButtonTypes.Download,
          callback: downloadCallback
        },
        {
          type: ActionButtonTypes.EditTaxonomyTerms,
          callback: editTaxonomyTermsCallback
        },
        {
          type: ActionButtonTypes.Delete,
          callback: deleteModal
        }
      ]};
    },
    suppressSizeToFit: true,
    floatingFilter: false,
    sortable: false,
    minWidth: 145,
    maxWidth: 145,
    width: 145,
    lockPosition: true,
    pinned: 'right',
    resizable: false
  }
];
