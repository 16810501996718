import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Row, Col, Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { getMatterActivityHistory, getMatterFileById, updateMatterFileDetails } from "actions/matter";
import moment from "moment";
import { UpdateMatterFileDetailsModel } from "models/update/UpdateMatterFileDetailsModel";
import Field from "components/Fields/Field";
import BooleanField from "components/Fields/BooleanField";
import { MatterFileModel } from "models/view/MatterFileModel";
import { DateFormat } from "utils/constants";
import { formatFileSize } from "utils/misc";
import store from "state/store";
import { GridIds } from "enums/GridIds";
import useGridActions from "actions/grid";

type Props = {
  matterId: string,
  fileId: string
}

export default function EditMatterFileDetailsForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const [matterFile, setMatterFile] = useState<MatterFileModel>();
  const gridActions = useGridActions();

  const {reset, register, handleSubmit, formState: {errors}} = useForm<UpdateMatterFileDetailsModel>({
    resolver: vestResolver(validationSuite)
  });

  useEffect(() => {
    setIsLoading(true);
    getMatterFileById(props.matterId, props.fileId).then((response) => {
      setMatterFile(response.data);
      let initialState: UpdateMatterFileDetailsModel = {
        description: response.data.description,
        additionalNote: response.data.additionalNote
      };

      reset(initialState);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
      
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: UpdateMatterFileDetailsModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    updateMatterFileDetails(props.matterId, props.fileId, data)
      .then((response) => {
        const gridState = store.getState().grid;
        if (gridState.id == `${GridIds.ActivityHistory}/${props.matterId}`) {
          getMatterActivityHistory(props.matterId).then((response) => {
            gridActions.setGridRowData(response.data);
          });
        }

        slidingPanelActions.clearSlidingPanel();
        reset();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-4" controlId="description">
          <Form.Label className="required">Description</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.description?.message ? 'invalid' : ''}`}
            {...register(`description`, {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.description?.message && (errors.description?.message)}
          </Form.Text>
        </Form.Group>

        <Row>
          <Col>
            <Field
              label={"File Name"}
              value={matterFile?.name}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"File Path"}
              value={matterFile?.pathToFile}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <BooleanField
              label={"Is Deleted"}
              value={matterFile?.isDeletedOnStorage ?? false}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"Created On"}
              value={matterFile?.storageCreatedDate ?
                moment(matterFile?.storageCreatedDate).format(DateFormat.MomentWithTime) : "—"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"Created By"}
              value={matterFile?.storageCreatedBy}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"Last Modified On"}
              value={matterFile?.storageLastModifiedDate ?
                moment(matterFile?.storageLastModifiedDate).format(DateFormat.MomentWithTime) : "—"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"Last Modified By"}
              value={matterFile?.storageLastModifiedBy}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"Size"}
              value={matterFile?.size ? formatFileSize(matterFile?.size, 2) : "—"}
            />
          </Col>
        </Row>

        <Form.Group className="mt-4 mb-4" controlId="additionalNote">
          <Form.Label>Additional Note</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            className={`${errors?.additionalNote?.message ? 'invalid' : ''}`}
            {...register(`additionalNote`, {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.additionalNote?.message && (errors.additionalNote.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="d-flex justify-content-between">
          <Button variant="success" type="submit">Update</Button>
          <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
        </Form.Group>
      </Form>
    </>
  );
}
