import { ColDef } from "ag-grid-community";
import store from "state/store";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import EditAccountTypeForm from "./EditAccountType/EditAccountTypeForm";
import { AccountTypeModel } from "models/view/AccountTypeModel";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { getAccountTypeCategoriesSummary } from "actions/settings";

const editCallback = (id: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Edit Account Type",
        children: <EditAccountTypeForm accountTypeId={id}/>
      }
    )
  );
}

export const columnDefs: (ColDef<AccountTypeModel>| {excludeFromExport: boolean})[] = [
  { 
    excludeFromExport: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    suppressMenu: true,
    suppressSizeToFit: true,
    floatingFilter: false,
    resizable: false,
    sortable: false,
    minWidth: 48,
    maxWidth: 48,
    width: 48,
    lockPosition: true,
    pinned: 'left'
  },
  {
    headerName: 'Display Name',
    field: 'displayName',
    filter: 'agTextColumnFilter',
    minWidth: 120
  },
  {
    headerName: 'Category',
    field: 'accountTypeCategory.displayName',
    filter: DropdownFilter,
    filterParams: {
      property: 'accountTypeCategoryId',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: DropdownFloatingFilter,
    floatingFilterComponentParams: {
      endpointCall: getAccountTypeCategoriesSummary
    },
    suppressMenu: true,
    minWidth: 220,
    flex: 1
  },
  { 
    excludeFromExport: true,
    headerName: 'Actions',
    cellClass: 'lp-actions-cell',
    suppressMenu: true,
    cellRenderer: ActionsCellRenderer,
    cellRendererParams: (params: any) => {
      return { 
        buttonsData: [{
          type: ActionButtonTypes.Edit,
          callback: editCallback
        }]
      };
    },
    suppressSizeToFit: true,
    floatingFilter: false,
    sortable: false,
    minWidth: 145,
    maxWidth: 145,
    width: 145,
    lockPosition: true,
    pinned: 'right',
    resizable: false
  }
];
