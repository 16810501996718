import { ColDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import store from "state/store";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { deleteMatterIncidentalExpense, getMatterActivityHistory } from "actions/matter";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { setRowData } from "state/gridSlice";
import ViewMatterIncidentalExpense from "../MatterIncidentalExpense/ViewMatterIncidentalExpense/ViewMatterIncidentalExpense";
import EditMatterIncidentalExpenseForm from "../MatterIncidentalExpense/EditMatterIncidentalExpense/EditMatterIncidentalExpenseForm";
import { GridIds } from "enums/GridIds";
import { MatterIncidentalExpenseModel } from "models/view/MatterIncidentalExpenseModel";
import { DateFormatter } from "components/Grid/ValueFormatters/DateFormatter";
import MatterIncidentalExpenseNameCellRenderer from "components/Grid/MatterIncidentalExpenseNameCellRenderer";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";
import MatterIncidentalExpenseWriteOffForm from "../MatterIncidentalExpense/MatterIncidentalExpenseWriteOff/MatterIncidentalExpenseWriteOffForm";

const viewCallback = (rowData: MatterIncidentalExpenseModel) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "View Incidental Expense",
        children: <ViewMatterIncidentalExpense matterId={rowData.matterId} matterIncidentalExpenseId={rowData.id!} />
      }
    )
  );
}

const editCallback = (rowData: MatterIncidentalExpenseModel) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        width: "55rem",
        title: "Edit Incidental Expense",
        children: <EditMatterIncidentalExpenseForm
            matterId={rowData.matterId}
            matterIncidentalExpenseId={rowData.id!}
          />
      }
    )
  );
}

const deleteModal = (rowData: MatterIncidentalExpenseModel) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Matter Incidental Expense?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(rowData.matterId, rowData.id!),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
}

const deleteCallback = (matterId: string, incidentalExpenseId: string) => {
  deleteMatterIncidentalExpense(matterId, incidentalExpenseId).then(() => {
    const gridState = store.getState().grid;
    store.dispatch(toggleModalLoadingState());
    if (gridState.id == `${GridIds.ActivityHistory}/${matterId}`) {
      getMatterActivityHistory(matterId ?? '').then((response) => {
        store.dispatch(setRowData(response.data));
        store.dispatch(toggleModal());
      })
      .catch((error) => {
        store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
      })
      .finally(() => {
        store.dispatch(toggleModalLoadingState());
      });
    }
  });
}

const writeOffCallback = (rowData: MatterIncidentalExpenseModel) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Incidental Expense Write-Off",
        children: <MatterIncidentalExpenseWriteOffForm
            matterId={rowData.matterId}
            matterIncidentalExpenseId={rowData.id!}
          />
      }
    )
  );
}

export const getColumnDefsIncidentalExpenseDetail = (matterIsClosed: boolean) => {
  const columnDefs: ColDef<MatterIncidentalExpenseModel>[] = [
    { field: 'date', headerName: "Date", valueFormatter: DateFormatter },
    { field: 'displayName', cellRenderer: MatterIncidentalExpenseNameCellRenderer, headerName: "Name", flex: 1 },
    {
      headerName: 'Is Billed',
      field: 'isBilled',
      minWidth: 100,
      width: 100,
      cellRenderer: CheckboxCellRenderer
    },
    { field: 'grossValue', headerName: "Gross Value", valueFormatter: CurrencyFormatter },
    { field: 'netValue', headerName: "Net Value", valueFormatter: CurrencyFormatter },
    { field: 'vatValue', headerName: "VAT Value", valueFormatter: CurrencyFormatter },
    { field: 'writeOffAmount', headerName: "Write-Off Amount", valueFormatter: CurrencyFormatter },
    { field: 'grossValueOnInvoice', headerName: "Invoiced Gross Value", valueFormatter: CurrencyFormatter },
    { field: 'vatValueOnInvoice', headerName: "Invoiced VAT Value", valueFormatter: CurrencyFormatter },
    { 
      headerName: 'Actions',
      cellClass: 'lp-actions-cell',
      suppressMenu: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return { buttonsData: [
          {
            type: ActionButtonTypes.View,
            callback: () => viewCallback(params.data)
          },
          ...(params.data.isBilled || matterIsClosed ? [] : [{
            type: ActionButtonTypes.Edit,
            callback: () => editCallback(params.data)
          }]),
          ...(params.data.isBilled || matterIsClosed ? [] : [{
            type: ActionButtonTypes.WriteOff,
            callback: () => writeOffCallback(params.data)
          }]),
          ...(params.data.isBilled || matterIsClosed ? [] : [{
            type: ActionButtonTypes.Delete,
            callback: () => deleteModal(params.data)
          }])
        ]};
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 145,
      maxWidth: 145,
      width: 145,
      lockPosition: true,
      pinned: 'right',
      resizable: false
    }
  ];

  return columnDefs;
}