import { RegulatorTypeIds } from "enums/RegulatorTypeIds";
import { create, test, enforce, omitWhen } from "vest";
import isDate from "validator/lib/isDate";
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";
import { checkIfValidPhoneNumber } from "utils/form";

export const validationSuite = create((data = {}, currentField) => {
  enforce.extend({isDate});

  test('firstName', 'First Name is required', () => {
    enforce(data.firstName).isNotEmpty();
    enforce(data.firstName).isString();
  });

  test('lastName', 'Last Name is required', () => {
    enforce(data.lastName).isNotEmpty();
    enforce(data.lastName).isString();
  });

  test('salutation', 'Salutation is required', () => {
    enforce(data.salutation).isNotEmpty();
    enforce(data.salutation).isString();
  });

  test('sexId', 'Please select a Sex', () => {
    enforce(data.sexId).isNotEmpty();
    enforce(data.sexId).isString();
  });

  test('genderPronounId', 'Please select a Pronoun', () => {
    enforce(data.genderPronounId).isNotEmpty();
    enforce(data.genderPronounId).isString();
  });

  test('regulatorId', 'Please select a Regulator', () => {
    enforce(data.regulatorId).isNotEmpty();
    enforce(data.regulatorId).isString();
  });

  omitWhen(data.regulatorId == undefined || data.regulatorId == RegulatorTypeIds.UnregulatedId, () => {
    test('regulatorReference', 'Regulator Registration Reference is required', () => {
      enforce(data.regulatorReference).isNotEmpty();
      enforce(data.regulatorReference).isString();
    });

    test('admissionOrCallDate', 'Admission Or Call Date is required', () => {
      enforce(data.admissionOrCallDate).isDate({strictMode: false});
    });
  });

  omitWhen(!data.nationalInsuranceNo,
    () => {
      test('nationalInsuranceNo', 'National Insurance No. must match PP-NNNNNN-S patern', () => {
        enforce(data.nationalInsuranceNo).matches('([A-Z]{2})-(\\d{6})-([A-D])');
      });
    }
  );

  test('emergencyContactName', 'Emergency Contact Name is required', () => {
    enforce(data.emergencyContactName).isNotEmpty();
    enforce(data.emergencyContactName).isString();
  });

  enforce.extend({isMobilePhone});

  test('emergencyContactPhone', 'Emergency Contact Phone is required.', () => {
    enforce(data.emergencyContactPhone).isNotEmpty();
    enforce(data.emergencyContactPhone).isString();
  });

  test('emergencyContactPhone', 'Emergency Contact Phone is not in the correct format.', () => {
    enforce(data.emergencyContactPhone).condition(value => checkIfValidPhoneNumber(value) || isMobilePhone(value))
  });
  
  test('relationshipToEmergencyContact', 'Relationship To Emergency Contact is required', () => {
    enforce(data.relationshipToEmergencyContact).isNotEmpty();
    enforce(data.relationshipToEmergencyContact).isString();
  });

  enforce.extend({isEmail})

  omitWhen(!data.emergencyContactEmail, () => {
    test('emergencyContactEmail', 'Emergency Contact Email doesn\'t have the correct format.', () => {
      enforce(data.emergencyContactEmail).isEmail();
    });
  })
});