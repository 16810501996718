import {
  getMatterDisbursementById,
  getMatterDisbursementPayments
} from "actions/matter";
import Loader from "components/Loader/index";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { DateFormat } from "utils/constants";
import Field from "components/Fields/Field";
import { formatCurrency } from "utils/misc";
import { MdRemoveRedEye } from "react-icons/md";
import moment from "moment";
import _ from 'lodash';
import { MatterDisbursementModel } from "models/view/MatterDisbursementModel";
import { MatterDisbursementPaymentModel } from "models/view/MatterDisbursementPaymentModel";
import useSlidingPanelActions from "actions/slidingPanel";
import CreatePaymentForm from "containers/BillsToPay/CreatePayment/CreatePaymentForm";
import { useAppSelector } from "hooks/appSelector";
import ViewPayment from "containers/BillsToPay/ViewPayment/ViewPayment";

type Props = {
  matterId: string,
  matterDisbursementId: string,
  onPaymentsChangedCallback?: Function
}

export default function ViewMatterDisbursementPayments(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const genericErrorsRef = useRef<HTMLDivElement>(null);
  const [matterDisbursement, setMatterDisbursement] = useState<MatterDisbursementModel>();
  const [payments, setPayments] = useState<MatterDisbursementPaymentModel[]>([]);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if(genericErrorsRef.current)
    {
      genericErrorsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [genericErrors]);

  const loadData = () => {
    setIsLoading(true);

    const promises = [
      getMatterDisbursementById(props.matterId, props.matterDisbursementId),
      getMatterDisbursementPayments(props.matterId, props.matterDisbursementId)
    ];

    Promise.all(promises)
      .then(([matterDisbursementResponse, matterDisbursementPaymentsResponse]) => {
        setMatterDisbursement(matterDisbursementResponse.data);
        setPayments(matterDisbursementPaymentsResponse.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const viewPayment = (paymentId: string) => {
    //close View Matter Disbursement Payments sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open View Payment sliding panel having onCancel event to reopen 
    //View Matter Disbursement Payments sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      width: "55rem",
      title: "View Payment",
      children: <ViewPayment
        paymentId={paymentId}
        onDeletePaymentCallback={onSubmitAddNewPaymentOrDeletePayment}
      />,
      onCancel: onCancelAddNewPaymentOrDeletePayment
    });
  }

  function onClickAddNewPayment() {
    if(matterDisbursement) {
      //close View Matter Disbursement Payments sliding panel
      slidingPanelActions.clearSlidingPanel();
      //open Make Payment sliding panel having onCancel event to reopen 
      //View Matter Disbursement Payments sliding panel
      slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        width: "55rem",
        title: "Make Payment",
        children: <CreatePaymentForm
          disbursements={[matterDisbursement]}
          onSubmitCallback={onSubmitAddNewPaymentOrDeletePayment}
        />,
        onCancel: onCancelAddNewPaymentOrDeletePayment
      });
    }
  }

  function onSubmitAddNewPaymentOrDeletePayment() {
    //open back View Matter Disbursement Payments sliding panel
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState,
      onCancel: props.onPaymentsChangedCallback
    });
  }

  function onCancelAddNewPaymentOrDeletePayment() {
    //open back View Matter Disbursement Payments sliding panel
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState
    });
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div ref={genericErrorsRef} className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Form.Group as={Col}>
          <Field
            label={"Display Name"}
            value={matterDisbursement?.displayName}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} xs={12} sm={6} className="mb-4 mb-sm-0">
          <Field
            label={"Gross Value"}
            value={formatCurrency(matterDisbursement?.grossValue)}
          />
        </Form.Group>
        <Form.Group as={Col} xs={12} sm={6} className="mb-4">
          <Field
            label={"Amount Due"}
            value={formatCurrency(matterDisbursement?.amountDue)}
          />
        </Form.Group>
      </Row>

      {(matterDisbursement?.amountDue ?? 0) > 0 && !matterDisbursement?.matter?.isClosed &&
        <Button variant="success" onClick={onClickAddNewPayment} className="mb-4">Add new payment</Button>
      }

      <div className="lp-color-title secondary full-width">Payments</div>
      <div className="lp-box-list auto">
        {payments.length == 0 &&
          <div className="lp-list-empty">No payments found</div>
        }
        {payments.length > 0 &&
          _.orderBy(payments, x => x.payment?.date, "desc").map((x: MatterDisbursementPaymentModel, index: number) => (
            <div key={x.id} className="lp-box-list-item">
              {!matterDisbursement?.matter?.isClosed && 
                <Button
                  variant="secondary-400"
                  onClick={() => viewPayment(x.payment?.id!)}
                  className="delete-item btn-icon"
                >
                  <MdRemoveRedEye />
                </Button>
              }

              <span className="type-icon date">
                {moment(x?.payment?.date).format(DateFormat.Moment) + " - " + x.payment?.paymentReference}
              </span>

              <div className="lp-box-list-full-row">
                <Field label={'Paid From'} value={x.payment?.paidFromAccount?.name} />
              </div>

              <div className="lp-box-list-full-row">
                <Field label={"Amount Paid"} value={formatCurrency(x.amountPaid)} />
              </div>
            </div>
          ))
        }
        </div>
    </>
  );
}
