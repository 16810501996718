import { DropDownOptionWithColorModel } from "models/view/DropDownOptionWithColorModel";
import { Dispatch, SetStateAction } from "react";
import { ToolbarProps, Navigate, View, Views } from "react-big-calendar";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { MdExpandMore, MdChevronLeft, MdChevronRight } from "react-icons/md";
import { DateFormat } from "utils/constants";
import { getDateOnly } from "utils/date";
import { getCalendarFreeBusyStatusDropdownValues } from 'utils/misc';

type Props = ToolbarProps & {
  setView: Dispatch<SetStateAction<View>>
}

export default function Toolbar(props: Props) {
  const changeDate = (date: Date) => {
    props.onNavigate(Navigate.DATE, date);
  };

  const changeView = (view: string) => {
    props.onView(view as View);
    props.setView(view as View);
  }

  return (
    <div className="lp-calendar-toolbar">
      <div className="lp-calendar-btns">
        <div className="lp-calendar-legend">
          <Dropdown>
            <Dropdown.Toggle variant="secondary-200">
              <MdExpandMore />
              Legend
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {getCalendarFreeBusyStatusDropdownValues().map((x: DropDownOptionWithColorModel, index: number) => <Dropdown.Item key={index} className={x.color}>{x.name}</Dropdown.Item>)}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="lp-calendar-nav">
          <div onClick={() => props.onNavigate(Navigate.PREVIOUS)}>
            <MdChevronLeft />
          </div>
          <div onClick={() => props.onNavigate(Navigate.TODAY)}>
            Today
          </div>
          <div onClick={() => props.onNavigate(Navigate.NEXT)}>
            <MdChevronRight />
          </div>
        </div>
      </div>

      <div className="lp-calendar-date">
        <DatePicker 
          dateFormat={DateFormat.Datepicker}
          value={props.label}
          selected={props.date ? getDateOnly(props.date) : null}
          onChange={(date: Date) => changeDate(date)}
          showMonthDropdown
          showYearDropdown
          autoComplete="off"
        />
      </div>

      <div className="lp-calendar-view">
        <div onClick={() => changeView(Views.DAY)} className={props.view == Views.DAY ? "selected" : ""}>
          Day
        </div>
        <div onClick={() => changeView(Views.WEEK)} className={props.view == Views.WEEK ? "selected" : ""}>
          Week
        </div>
        <div onClick={() => changeView(Views.MONTH)} className={props.view == Views.MONTH ? "selected" : ""}>
          Month
        </div>
      </div>
    </div>
  );
}