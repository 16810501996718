import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Row, Col, Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import CustomSelect from "components/Select/Select";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { addLteAddress, getPreferredLteAddress } from "actions/lte";
import { CreateOrUpdateLteAddressModel } from "models/create/CreateOrUpdateLteAddressModel";
import { AddressModel } from "models/view/AddressModel";
import { getAddressSuggestions, getAddressTypes } from "actions/address";
import { getCountries } from "actions/country";
import { formatAddress } from "utils/address";
import { useAppSelector } from "hooks/appSelector"
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import useModalActions from 'actions/modal';
import { MdWarning } from 'react-icons/md';
import { ModalState } from 'state/modalSlice';

type Props = {
  lteId: string,
  submitCallback?: Function
}

export default function CreateLteAddressForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingAddressSuggestions, setIsLoadingAddressSuggestions] = useState<boolean>(false);
  const [addressSuggestions, setAddressSuggestions] = useState<AddressModel[]>([]);
  const slidingPanelActions = useSlidingPanelActions();
  const loggedInUser = useAppSelector((state) => state.user);
  const modalActions = useModalActions();

  const {register, reset, control, setValue, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateLteAddressModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: CreateOrUpdateLteAddressModel) {
    setIsLoading(true);
    if(data.isPreferred == true) {
      getPreferredLteAddress(props.lteId).then((response) => {
        let bodyMessage: React.ReactNode = <></>;
        if(response.data?.addressId) {
          bodyMessage = <div className="lp-modal-warning">
            <MdWarning />This Address is set as preferred which means that he will replace the current one.<br/>
            Are you sure you want to do that? If not, uncheck the Is Preferred checkbox.
          </div>;
        }
        else {
          addAddress(data, false);
          return;
        }
        
        let modal: ModalState = {
          title: "Add confirmation",
          body: bodyMessage,
          actionText: "Add",
          onAction: () => addAddress(data),
          show: false,
          onClose: () => setIsLoading(false)
        }
        modalActions.setModal(modal);
        modalActions.toggleModalShownStatus();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        setIsLoading(false);
      });
    }
    else {
      addAddress(data, false);
    }
  }

  function addAddress(data: CreateOrUpdateLteAddressModel, fromModal: boolean = true) {
    if(fromModal)
    {
      modalActions.toggleModalLoading();
    }

    removeEmptyFields(data);
    data.lawPageTradingEntityId = props.lteId;
    addLteAddress(props?.lteId, data).then((response) => {
      if(props.submitCallback) {
        props.submitCallback(response.data);
      }
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
      if(fromModal)
      {
        modalActions.toggleModalLoading();
        modalActions.toggleModalShownStatus();
      }
    });
  }

  const onChangeAddress = (e: any) => {
    setIsLoadingAddressSuggestions(true);
    setAddressSuggestions([]);
    if(e.target.value && e.target.value !== '') {
      getAddressSuggestions(e.target.value, props?.lteId)
        .then((response) => {
          setAddressSuggestions(response.data);
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
        })
        .finally(() => {
          setIsLoadingAddressSuggestions(false);
        });
    }
  }

  const onChangeAddressSuggestion = (id?: string) => {
    const selectedAddressSuggestion = addressSuggestions.find(x => x.id === id);
    if(selectedAddressSuggestion) {
      setValue("addressLine1", selectedAddressSuggestion?.addressLine1 ?? '');
      setValue("addressLine2", selectedAddressSuggestion?.addressLine2 ?? '');
      setValue("addressLine3", selectedAddressSuggestion?.addressLine3 ?? '');
      setValue("city", selectedAddressSuggestion?.city ?? '');
      setValue("county", selectedAddressSuggestion?.county ?? '');
      setValue("state", selectedAddressSuggestion?.state ?? '');
      setValue("countryId", selectedAddressSuggestion?.country?.id ?? '');
      setValue("addressTypeId", selectedAddressSuggestion?.addressType?.id ?? '');
      setValue("systemOwned", selectedAddressSuggestion?.systemOwned ?? false);
      setValue("isPreferred", false);
    }
    else {
      setValue("addressLine1", '');
      setValue("addressLine2", '');
      setValue("addressLine3", '');
      setValue("city", '');
      setValue("county", '');
      setValue("state", '');
      setValue("countryId", '');
      setValue("addressTypeId", '');
      setValue("systemOwned", false);
      setValue("isPreferred", false);
    }
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}
      
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-4" controlId="postCode">
          <Form.Label className="required">Post Code</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.postCode?.message ? 'invalid' : ''}`}
            {...register("postCode", {shouldUnregister: true})}
            onChange={onChangeAddress}
          />
          <Form.Text className="lp-error">
            {errors?.postCode?.message && (errors.postCode.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="addressId">
          <Form.Label>Address Suggestions</Form.Label>
          <Controller
            control={control}
            name="addressId"
            shouldUnregister={true}
            render={({field: { onChange, value, ref }}) => (
              <CustomSelect
                id="addressId"
                options={addressSuggestions}
                getOptionLabel={(x: AddressModel) => formatAddress(x)}
                getOptionValue={(x: AddressModel) => x.id}
                value={value}
                onChange={val => {onChange(val?.id ?? null); onChangeAddressSuggestion(val?.id);}}
                isLoading={isLoadingAddressSuggestions}
                isClearable
              />
            )}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="addressTypeId">
          <Form.Label className="required">Address Type</Form.Label>
          <Controller
            control={control}
            name="addressTypeId"
            shouldUnregister={true}
            render={({field: { onChange, value, ref }}) => (
              <CustomSelect
                id="addressTypeId"
                inputRef={ref}
                className={`lp-select${errors?.addressTypeId?.message ? ' invalid' : ''}`}
                endpointCall={() => getAddressTypes()}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.addressTypeId?.message && (errors.addressTypeId.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="addressLine1">
          <Form.Label className="required">Address Line 1</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.addressLine1?.message ? 'invalid' : ''}`}
            {...register("addressLine1", {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.addressLine1?.message && (errors.addressLine1.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="addressLine2">
          <Form.Label>Address Line 2</Form.Label>
          <Form.Control
            type="text"
            {...register("addressLine2", {shouldUnregister: true})}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="addressLine3">
          <Form.Label>Address Line 3</Form.Label>
          <Form.Control
            type="text"
            {...register("addressLine3", {shouldUnregister: true})}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="city">
          <Form.Label>City</Form.Label>
          <Form.Control
            type="text"
            {...register("city", {shouldUnregister: true})}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="county">
          <Form.Label>County</Form.Label>
          <Form.Control
            type="text"
            {...register("county", {shouldUnregister: true})}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="state">
          <Form.Label>State</Form.Label>
          <Form.Control
            type="text"
            {...register("state", {shouldUnregister: true})}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="countryId">
          <Form.Label className="required">Country</Form.Label>
          <Controller
            control={control}
            name="countryId"
            shouldUnregister={true}
            render={({field: { onChange, value, ref }}) => (
              <CustomSelect
                id="countryId"
                inputRef={ref}
                className={`lp-select${errors?.countryId?.message ? ' invalid' : ''}`}
                endpointCall={() => getCountries()}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
                menuPlacement="top"
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.countryId?.message && (errors.countryId.message)}
          </Form.Text>
        </Form.Group>

        <Row className="mb-4">
          <Form.Group as={Col} controlId="isPreferred">
            <Form.Label>Is Preferred</Form.Label>
            <Controller
              control={control}
              name="isPreferred"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="isPreferred">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>

          {loggedInUser.userPermissions?.find(a => a == UserPermissionsNames.ManageSystemOwnedContacts) &&
            <Form.Group as={Col} controlId="systemOwned">
              <Form.Label>System Owned</Form.Label>
              <Controller
                control={control}
                name="systemOwned"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <Form.Check 
                    type="switch"
                    id="systemOwned">
                      <Form.Check.Input
                        className= "form-check-input"
                        ref={ref}
                        checked={value}
                        onChange={(ev: any) => onChange(ev.target.checked)}
                      />
                  </Form.Check>
                )}
              />
            </Form.Group>
          }
        </Row>

        <Form.Group className="d-flex justify-content-between">
          <Button variant="success" type="submit">Create</Button>
          <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
        </Form.Group>
      </Form>
    </>
  );
}
