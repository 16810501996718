import Field from "components/Fields/Field";
import { Card, Row, Col } from "react-bootstrap";
import { LteCalendarSettingsModel } from "models/view/LteCalendarSettingsModel";
import Loader from "components/Loader/index";
import FieldWithUrl from "components/Fields/FieldWithUrl";
import BooleanField from "components/Fields/BooleanField";
import { useAppSelector } from "hooks/appSelector";
import { LteIds } from "enums/LteIds";

type Props = {
  lteId: string,
  lteCalendarSettings: LteCalendarSettingsModel | undefined,
  isLoading: boolean
}

export default function ViewLteCalendarSettingsSummary(props: Props) {
  return (
    <>
      <Card>
        {props.isLoading && <Loader inlineLoader />}
        <Card.Body>
          <Row>
            <Col>
              <Field
                label={"Calendar Tenant ID"}
                value={props.lteCalendarSettings?.calendarTenantId}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                label={"User Account for LTE Diary"}
                value={props.lteCalendarSettings?.diaryManagerEmail}
              />
            </Col>
          </Row>
          {
            props.lteId != LteIds.LawPageLteId &&
            <Row>
              <Col>
                <BooleanField
                  label={"Managed by LawPage"}
                  value={props.lteCalendarSettings?.calendarManagedByLawPage!}
                />
              </Col>
            </Row>
          }
          <Row>
            <Col>
              <BooleanField
                label={"Has Access to LawPage Rooms"}
                value={props.lteCalendarSettings?.hasAccessToLawPageRooms ?? false}
              />
            </Col>
          </Row>
          {props.lteCalendarSettings?.adminConsentUrl &&
            <Row>
              <Col>
                <FieldWithUrl
                  label={"Admin Consent URL"}
                  url={props.lteCalendarSettings?.adminConsentUrl}
                  urlDisplayText={"Admin Consent URL"}
                  canBeCopied
                  openUrlInNewTab
                />
              </Col>
            </Row>
          }
        </Card.Body>
      </Card>
    </>
  );
}
