import { vestResolver } from "@hookform/resolvers/vest";
import useSlidingPanelActions from "actions/slidingPanel";
import { CreateOrUpdateChargingSchemeModel } from "models/create/CreateOrUpdateChargingSchemeModel";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { addChargingScheme } from "actions/chargingSchemes";
import Loader from "components/Loader/index";
import { Button, Form } from "react-bootstrap";

export default function CreateChargingScheme() {
  const [isLoading, setIsLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const navigate = useNavigate();

  const slidingPanelActions = useSlidingPanelActions();

  const { register, handleSubmit, control, reset, formState: { errors } } = useForm<CreateOrUpdateChargingSchemeModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateChargingSchemeModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    addChargingScheme(data).then((response) => {
      setIsLoading(false);
      navigate(`/chargingScheme/${response.data.id}`);
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-4" controlId="displayName">
          <Form.Label className="required">Display Name</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.displayName?.message ? 'invalid' : ''}`}
            {...register("displayName", {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.displayName?.message && (errors.displayName.message)}
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-4" controlId="minutesPerUnit">
          <Form.Label className="required">Minutes per Unit</Form.Label>
          <Form.Control
            type="number"
            className={`${errors?.minutesPerUnit?.message ? 'invalid' : ''}`}
            {...register("minutesPerUnit", {shouldUnregister: true})}
            min="0"
            onWheel={e => e.currentTarget.blur()}
          />
          <Form.Text className="lp-error">
            {errors?.minutesPerUnit?.message && (errors.minutesPerUnit.message)}
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-4" controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            {...register("description", {shouldUnregister: true})}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="active">
          <Form.Label>Active</Form.Label>
          <Controller
            control={control}
            name="active"
            shouldUnregister={true}
            render={({field: { onChange, value, name, ref }}) => (
              <Form.Check 
                type="switch"
                id="active">
                  <Form.Check.Input
                    className= "form-check-input"
                    ref={ref}
                    checked={value ?? false}
                    onChange={(ev: any) => onChange(ev.target.checked)}
                  />
              </Form.Check>
            )}
          />
        </Form.Group>

        <Form.Group className="d-flex justify-content-between">
          <Button variant="success" type="submit">Create</Button>
          <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
        </Form.Group>
      </Form>
    </>
  );
}