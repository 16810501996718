import { Dispatch, useState } from "react";
import { EntityModel } from "models/view/EntityModel";
import Title from "components/Title";
import ViewEntitySummary from "./ViewEntitySummary/ViewEntitySummary";
import EditEntitySummary from "./EditEntitySummary/EditEntitySummary";
import { Row, Col, Button } from 'react-bootstrap';
import { MdEdit } from "react-icons/md";
import EntityNoteList from "components/EntityNotes/EntityNoteList";
import EntityDocumentExchangeList from "components/EntityDocumentExchanges/EntityDocumentExchangeList";
import EntityEmailList from "components/EntityEmails/EntityEmailList";
import SlidingPanel from "components/SlidingPanel/index";
import CustomModal from "components/Modal/Modal";
import EntityPhoneNumberList from "components/EntityPhoneNumbers/EntityPhoneNumberList";
import EntityAddressList from "components/EntityAddresses/EntityAddressList";
import EntityBankAccountList from "./EntityBankAccounts/EntityBankAccountList";

type Props = {
  entity: EntityModel | undefined,
  edit: boolean,
  setEntity: Dispatch<React.SetStateAction<EntityModel | undefined>>
}

export default function ViewEntityDetails(props: Props) {
  const [edit, setEdit] = useState<boolean>(props.edit ?? false);

  return (
    <>
      <Row>
        <Col>
          <Title type="section" title={"Summary"}>
            {edit !== true &&
              <Button variant="primary" className="btn-icon" onClick={() => setEdit(true)}>
                <MdEdit />
              </Button>
            }
          </Title>
          {props.entity &&
            <>
              {edit === true ? (
                <EditEntitySummary
                  entity={props.entity}
                  setEntity={props.setEntity}
                  setEdit={setEdit}
                />
              ) : (
                <ViewEntitySummary
                  entity={props.entity}
                />
              )}
            </>
          }
        </Col>
      </Row>
      <Row>
        <Col xxl={6}>
          <EntityPhoneNumberList
            entityId={props.entity?.id}
            edit={edit}
          />
        </Col>
        <Col xxl={6} className="mt-4 mt-xxl-0">
          <EntityEmailList
            entityId={props.entity?.id}
            edit={edit}
          />
        </Col>
      </Row>
      <Row>
        <Col xxl={6}>
          <EntityAddressList
            entityId={props.entity?.id}
            edit={edit}
          />
        </Col>
        <Col xxl={6} className="mt-4 mt-xxl-0">
          <EntityDocumentExchangeList 
            entityId={props.entity?.id}
            edit={edit}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EntityNoteList 
            entityId={props.entity?.id}
            edit={edit}
          />
        </Col>
      </Row>

      {props.entity?.id &&
        <Row>
          <Col>
            <EntityBankAccountList
              entityId={props.entity?.id!}
            />
          </Col>
        </Row>
      }
      
      <SlidingPanel />
      <CustomModal />
    </>
  );
}
