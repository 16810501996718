import Field from "components/Fields/Field";
import { Card, Row, Col } from "react-bootstrap";
import { ChargingSchemeModel } from "models/view/ChargingSchemeModel";
import BooleanField from "components/Fields/BooleanField";

type Props = {
  chargingScheme?: ChargingSchemeModel
}

function ViewChargingSchemeSummary(props: Props) {
  return (
    <Card>
      <Card.Body>
        <Row className="multiple">
          <Col sm={6} lg={3}>
            <Field
              label={"Display Name"}
              value={props.chargingScheme?.displayName}
            />
          </Col>
          <Col sm={6} lg={3}>
            <Field
              label={"Minutes per Unit"}
              value={props.chargingScheme?.minutesPerUnit}
            />
          </Col>
          <Col sm={6} lg={3}>
            <Field
              label={"Received Email Activity"}
              value={props.chargingScheme?.receivedEmailActivity?.name}
            />
          </Col>
          <Col sm={6} lg={3}>
            <Field
              label={"Sent Email Activity"}
              value={props.chargingScheme?.sentEmailActivity?.name}
            />
          </Col>
          <Col sm={6} xxl={3}>
            <BooleanField
              label={"Is Active"}
              value={props.chargingScheme?.active ?? false}
            />
          </Col>
          <Col xs={12}>
            <Field
              label={"Description"}
              value={(!props.chargingScheme?.description || props.chargingScheme?.description == "") ? "—" : props.chargingScheme?.description}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default ViewChargingSchemeSummary;
