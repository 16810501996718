import { ICellRendererParams } from "ag-grid-community";
import { MatterActivityHistorySubtypes } from "enums/MatterActivityHistorySubtypes";
import { MdMoreTime, MdOutlineTextsms, MdOutlineAttachEmail, MdOndemandVideo, MdOutlineNoteAlt, MdOutlineMarkEmailUnread, MdOutlineCreditCard } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import { GrDocumentCsv } from 'react-icons/gr';
import { SiMicrosoftexcel, SiMicrosoftonenote, SiMicrosoftpowerpoint, SiMicrosoftword } from 'react-icons/si';
import { BsFileEarmarkPdf, BsCalendar4Event, BsFileText } from 'react-icons/bs';
import { IoDocumentOutline, IoImageOutline, IoWalletOutline } from 'react-icons/io5';
import { AiOutlineHtml5, AiOutlineFileZip, AiOutlineFileProtect } from 'react-icons/ai';
import { TbMailForward, TbFileInvoice } from 'react-icons/tb';
import { MatterActivityHistoryTypes } from "enums/MatterActivityHistoryTypes";
import TooltipIcon from 'components/TooltipIcon';

export default function MatterActivityHistoryColumnWithSubtypeIconCellRenderer(props: ICellRendererParams) {

  const getActivitySubtypeIcon = (subtype: number) => {
    switch(subtype) { 
      case MatterActivityHistorySubtypes.RecordableEvent: { 
         return <MdMoreTime fill="var(--lp-recordable-event)" />;
      }
      case MatterActivityHistorySubtypes.UserNote: { 
        return <MdOutlineNoteAlt fill="var(--lp-user-note)" />;
      }
      case MatterActivityHistorySubtypes.SystemNote: { 
        return <AiOutlineFileProtect fill="var(--lp-system-note)" />;
      }
      case MatterActivityHistorySubtypes.WordFile: { 
        return <SiMicrosoftword fill="var(--lp-word)" />;
      }
      case MatterActivityHistorySubtypes.PDFFile: { 
        return <BsFileEarmarkPdf fill="var(--lp-pdf)" />;
      }
      case MatterActivityHistorySubtypes.ExcelFile: { 
        return <SiMicrosoftexcel fill="var(--lp-excel)" />;
      }
      case MatterActivityHistorySubtypes.CSVFile: { 
        return <GrDocumentCsv fill="var(--lp-csv)" />;
      }
      case MatterActivityHistorySubtypes.PowerPointFile: { 
        return <SiMicrosoftpowerpoint fill="var(--lp-powerpoint)" />;
      }
      case MatterActivityHistorySubtypes.TextFile: { 
        return <BsFileText fill="var(--lp-text-file)" />;
      }
      case MatterActivityHistorySubtypes.Image: { 
        return <IoImageOutline color="var(--lp-image)" />;
      }
      case MatterActivityHistorySubtypes.AudioVideo: { 
        return <MdOndemandVideo fill="var(--lp-audio-video)" />;
      }
      case MatterActivityHistorySubtypes.Archive: { 
        return <AiOutlineFileZip fill="var(--lp-archive)" />;
      }
      case MatterActivityHistorySubtypes.EmailFile: { 
        return <MdOutlineAttachEmail fill="var(--lp-email)" />;
      }
      case MatterActivityHistorySubtypes.HtmlFile: { 
        return <AiOutlineHtml5 fill="var(--lp-html)" />;
      }
      case MatterActivityHistorySubtypes.OneNoteFile: { 
        return <SiMicrosoftonenote fill="var(--lp-onenote)" />;
      }
      case MatterActivityHistorySubtypes.OtherFile: { 
        return <IoDocumentOutline color="var(--lp-other)" />;
      }
      case MatterActivityHistorySubtypes.ReceivedMail: { 
        return <MdOutlineMarkEmailUnread color="var(--lp-received-mail)" />;
      }
      case MatterActivityHistorySubtypes.SentMail: { 
        return <TbMailForward color="var(--lp-sent-mail)" />;
      }
      case MatterActivityHistorySubtypes.Sms: { 
        return <MdOutlineTextsms fill="var(--lp-sms)" />;
      }
      case MatterActivityHistorySubtypes.WhatsApp: { 
        return <FaWhatsapp fill="var(--lp-whatsapp)" />;
      }
      case MatterActivityHistorySubtypes.Appointment: { 
        return <BsCalendar4Event fill="var(--lp-appointment)" />;
      }
      case MatterActivityHistorySubtypes.Disbursement: { 
        return <MdOutlineCreditCard fill="var(--lp-disbursement)" />;
      }
      case MatterActivityHistorySubtypes.IncidentalExpense: {
        return <IoWalletOutline color="var(--lp-incidental-expense)" />;
      }
      case MatterActivityHistorySubtypes.Invoice: {
        return <TbFileInvoice color="var(--lp-invoice)" />;
      }
      default: { 
        return <></>;
      }
    }
  }

  return (
    <>
      {getActivitySubtypeIcon(props.data.activitySubtype)} 
      {props.data.activityType == MatterActivityHistoryTypes.RecordableEvent && props.data.recordableEventRequiresReview && 
        <TooltipIcon type="error" text="Need to be updated because it was copied/moved from a different matter" iconPosition="left" />
      }
      {props.data.activityType == MatterActivityHistoryTypes.RecordableEvent && props.data.recordableEventHasNoClient && 
        <TooltipIcon type="error" text="No client" iconPosition="left" />
      }
      {props.data.activityType == MatterActivityHistoryTypes.RecordableEvent && props.data.recordableEventHasIncidentalExpensesWithNoFiles && 
        <TooltipIcon type="error" text="Has Incidental Expenses with no proofs of purchase" iconPosition="left" />
      }
      {props.data.activityType == MatterActivityHistoryTypes.Disbursement && props.data.disbursementHasNoFiles && 
        <TooltipIcon type="error" text="No invoice attached" iconPosition="left" />
      }
      {props.data.activityType == MatterActivityHistoryTypes.Disbursement && props.data.disbursementHasNoClient && 
        <TooltipIcon type="error" text="No client" iconPosition="left" />
      }
      {props.data.activityType == MatterActivityHistoryTypes.Disbursement && props.data.disbursementIsNotPaid && 
        <TooltipIcon type="warning" text="Unpaid" iconPosition="left" />
      }
      {props.data.activityType == MatterActivityHistoryTypes.IncidentalExpense && props.data.incidentalExpenseHasNoFiles && 
        <TooltipIcon type="error" text="No proofs of purchase" iconPosition="left" />
      }
      {props.data.activityType == MatterActivityHistoryTypes.IncidentalExpense && props.data.incidentalExpenseHasNoClient && 
        <TooltipIcon type="error" text="No client" iconPosition="left" />
      }
      {props.data.activityType == MatterActivityHistoryTypes.File && 
        (props.data.fileIsAttachedToDisbursements?.length > 0 || props.data.fileIsAttachedToIncidentalExpenses?.length > 0) && 
        <TooltipIcon type="linked" text={'Attached to: ' + [...props.data.fileIsAttachedToDisbursements, ...props.data.fileIsAttachedToIncidentalExpenses].join(', ')} iconPosition="left" />
      }

      <span className={props.data.isFileDeleted ? 'lp-activity-deleted' : ''}>{props.value}</span>
    </>
  );
}
