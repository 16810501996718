import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { ParticipatingEntityModel } from "models/view/ParticipatingEntityModel";
import { getParticipatingEntityById } from "actions/matter";
import { getPreferredContacts } from "actions/entity";
import { EntityPreferredContactModel } from "models/view/EntityPreferredContactModel";
import { EntityEmailModel } from "models/view/EntityEmailModel";
import { MdEmail, MdLocationPin, MdPhone } from "react-icons/md";
import { EntityPhoneNumberModel } from "models/view/EntityPhoneNumberModel";
import { formatAddress } from "utils/address";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Row, Col, Accordion } from 'react-bootstrap';
import { getInitialsForName } from "utils/form";
import moment from "moment";
import { DateFormat } from "utils/constants";
import BooleanField from "components/Fields/BooleanField";

type Props = {
  participatingEntityId: string
}

function ViewParticipatingEntity(props: Props){
  const [loading, setLoading] = useState(true);
  const [genericErrors, setGenericErrors] = useState(null);
  const [participatingEntity, setParticipatingEntity] = useState<ParticipatingEntityModel>();
  const [preferredContacts, setPreferredContacts] = useState<EntityPreferredContactModel>();

  useEffect(()=>{
    setLoading(true);
    getParticipatingEntityById(props.participatingEntityId).then((responseParticipatingEntity) => {
      getPreferredContacts(responseParticipatingEntity?.data?.entityId ?? '').then((responsePreferredContacts) => {
        setParticipatingEntity(responseParticipatingEntity.data);
        setPreferredContacts(responsePreferredContacts.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setLoading(false);
      });
    })
  }, []);

  const getInvoiceDueDateSettingString = () => {
    if(!participatingEntity?.invoiceDueDateSettingTypeId) {
      return "—";
    }
    else {
      return `${participatingEntity?.invoiceDueDateSettingNumber ?? "0"} ${participatingEntity?.invoiceDueDateSettingType?.name}`; 
    }
  };

  return(
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <div className="lp-entity-profile">
        <div className="lp-entity-photo">
          {getInitialsForName(participatingEntity?.entity?.displayName)}
          <span className={`lp-entity-status${participatingEntity?.isDefaultClient ? ' active' : ' inactive' }`}>
            {participatingEntity?.isDefaultClient ? <FaCheck /> : <FaTimes />}
          </span>
        </div>
        <div className="lp-entity-name">
          {participatingEntity?.entity.displayName}
        </div>
        <div className="lp-entity-role">
          {participatingEntity?.entityRole?.name}
        </div>
      </div>

      <div className="lp-entity-info">
        {participatingEntity?.entityMatterReference &&
          <Row>
            <Col>
              <Field
                label={"Matter Reference"}
                value={participatingEntity?.entityMatterReference ?? ""}
              />
            </Col>
          </Row>
        }
        {participatingEntity?.entity?.humanBeingDetail?.dateOfBirth &&
          <Row>
            <Col>
              <Field
                label={"Date Of Birth"}
                value={moment(participatingEntity?.entity?.humanBeingDetail?.dateOfBirth).format(DateFormat.Moment)}
              />
            </Col>
          </Row>
        }
        {participatingEntity?.entity?.humanBeingDetail?.prisonNumber &&
          <Row>
            <Col>
            <Field
                  label={"Prison Number"}
                  value={participatingEntity?.entity?.humanBeingDetail?.prisonNumber ?? ""}
                />
            </Col>
          </Row>
        }

        <Row>
          <Col>
            <BooleanField
              label={"Is Invoicing Party"}
              value={participatingEntity?.isInvoicingParty ?? false}
            />
          </Col>
        </Row>

        {participatingEntity?.isInvoicingParty && 
          <>
            <Row>
              <Col>
                <Field
                  label={"Purchase Order Reference"}
                  value={participatingEntity?.purchaseOrderReference}
                />
              </Col>
            </Row>
            <Row>
            <Col>
              <Field
                label={"Invoice Due Date"}
                value={getInvoiceDueDateSettingString()}
              />
            </Col>
          </Row>
          </>
        }

        {participatingEntity?.notes &&
          <Row>
            <Col>
              <Field
                label={"Notes"}
                value={participatingEntity?.notes ?? ""}
              />
            </Col>
          </Row>
        }
      </div>

      <div className="lp-entity-contact">
        {((preferredContacts?.entityEmails?.length ?? 0) == 0 && 
          (preferredContacts?.entityPhoneNumbers?.length ?? 0) == 0 &&
          (preferredContacts?.entityAddresses?.length ?? 0) == 0) &&
          <div className="lp-list-empty">No preferred contact info found.</div>
        }
        {preferredContacts?.entityEmails?.map((entityEmail: EntityEmailModel, index: number) => 
          <div className="lp-entity-contact-item" key={entityEmail.id}>
            <MdEmail />
            <span className="email">{entityEmail?.email}</span>
          </div>
        )}

        {preferredContacts?.entityPhoneNumbers?.map((entityPhoneNumber: EntityPhoneNumberModel, index: number) => 
          <div className="lp-entity-contact-item" key={entityPhoneNumber.phoneNumberId}>
            <MdPhone />
            {entityPhoneNumber?.phoneNumber?.number}
          </div>
        )}

        {preferredContacts?.entityAddresses?.map((entityAddress: any, index: number) => 
          <div className="lp-entity-contact-item" key={entityAddress.addressId}>
            <MdLocationPin />
            {formatAddress(entityAddress?.address, entityAddress?.sourceEntityAddress?.entity?.displayName)}
          </div>
        )}
      </div>

      {participatingEntity?.bailStatus !== undefined &&
        <div className="lp-entity-bails">
          <div className="lp-entity-bail-status">
            <div className="lp-entity-bail-status-title">Bail Status</div>
            <div className="lp-entity-bail-status-content">
              <div className="lp-entity-bail-status-value">{participatingEntity?.bailStatus?.name ?? ""}</div>
              <div className="lp-entity-bail-status-date">
                {participatingEntity?.custodyTimeLimitExpiryDate ? moment(participatingEntity?.custodyTimeLimitExpiryDate).format(DateFormat.Moment) : ''}
              </div>
            </div>
          </div>
          {(participatingEntity?.matterCriminalBailConditions !== undefined && participatingEntity?.matterCriminalBailConditions?.length > 0) &&
            <div className="lp-entity-bail-condition">
              <div className="lp-entity-bail-condition-title">Bail conditions</div>
              <Accordion>
                {participatingEntity?.matterCriminalBailConditions?.map((x: any, index: number) => {
                  return (
                    <Accordion.Item eventKey={`${index}`} key={x.id}>
                      <Accordion.Header>{x?.bailCondition?.name ?? ''}</Accordion.Header>
                      <Accordion.Body>{x?.additionalText ?? ''}</Accordion.Body>
                    </Accordion.Item>
                  )
                })}
              </Accordion>
            </div>
          }
        </div>
      }
    </>
  );
}

export default ViewParticipatingEntity;
