import Loader from "components/Loader/index";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { DateFormat } from "utils/constants";
import Field from "components/Fields/Field";
import { formatCurrency } from "utils/misc";
import moment from "moment";
import _ from 'lodash';
import { MatterDisbursementPaymentModel } from "models/view/MatterDisbursementPaymentModel";
import useSlidingPanelActions from "actions/slidingPanel";
import { PaymentModel } from "models/view/PaymentModel";
import { deletePayment, getPaymentById, getPaymentMatterDisbursementPayments } from "actions/lte";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";
import CustomModal from "components/Modal/Modal";
import { getPreferredEntityBankAccount } from "actions/entity";
import { EntityBankAccountModel } from "models/view/EntityBankAccountModel";

type Props = {
  paymentId: string,
  onDeletePaymentCallback?: Function
}

export default function ViewPayment(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const genericErrorsRef = useRef<HTMLDivElement>(null);
  const [payment, setPayment] = useState<PaymentModel>();
  const [disbursementPayments, setDisbursementPayments] = useState<MatterDisbursementPaymentModel[]>([]);
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();
  const [supplierName, setSupplierName] = useState<string>();
  const [supplierBankAccount, setSupplierBankAccount] = useState<EntityBankAccountModel | undefined>(undefined);
  
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if(genericErrorsRef.current)
    {
      genericErrorsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [genericErrors]);

  const loadData = () => {
    setIsLoading(true);

    const promises = [
      getPaymentById(props.paymentId),
      getPaymentMatterDisbursementPayments(props.paymentId)
    ];

    Promise.all(promises)
      .then(([paymentResponse, matterDisbursementPaymentsResponse]) => {
        setPayment(paymentResponse.data);
        setDisbursementPayments(matterDisbursementPaymentsResponse.data);

        var supplierId = paymentResponse.data.supplierId;
        setSupplierName(paymentResponse.data.supplier?.name);
        
        if(supplierId) {
          setIsLoading(true);
          getPreferredEntityBankAccount(supplierId).then((response) => {
            setSupplierBankAccount(response.data);
          })
          .catch((error) => {
            setGenericErrors(error.response?.data?.Message ?? error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
        }
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function onClickDelete() {
    setIsLoading(true);
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Payment?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.paymentId),
      onClose: () => {setIsLoading(false);},
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();  
  }

  const deleteCallback = (paymentId: string) => {
    modalActions.toggleModalLoading();
    deletePayment(paymentId).then(() => {
      slidingPanelActions.clearSlidingPanel();
      modalActions.toggleModalShownStatus(); 

      props.onDeletePaymentCallback && props.onDeletePaymentCallback();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div ref={genericErrorsRef} className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Date"}
            value={payment?.date ? moment(payment?.date).format(DateFormat.Moment) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Form.Group as={Col} xs={12} sm={6}>
          <Field
            label={"Paid From"}
            value={payment?.paidFromAccount?.name}
          />
        </Form.Group>

        <Form.Group as={Col} xs={12} sm={6}>
          <Field
            label={"Payment Method"}
            value={payment?.paymentMethod?.name}
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} xs={12} sm={6}>
          <Field
            label={"Supplier"}
            value={supplierName}
          />
        </Form.Group>

        <Form.Group as={Col} xs={12} sm={6}>
          <Field
            label={"Bank Account"}
            value={supplierBankAccount?.bankAccountName}
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} xs={12} sm={6}>
          <Field
            label={"Bank Sort Code"}
            value={supplierBankAccount?.bankSortCode}
          />
        </Form.Group>

        <Form.Group as={Col} xs={12} sm={6}>
          <Field
            label={"Bank Account Number"}
            value={supplierBankAccount?.bankAccountNumber}
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group className="mb-4">
          <Field
            label={"Total Amount Paid"}
            value={formatCurrency(payment?.totalAmountPaid)}
          />
        </Form.Group>
      </Row>

      <div className="lp-color-title secondary full-width">Disbursement Payments</div>
      <div className="lp-box-list auto">
        {disbursementPayments.length == 0 &&
          <div className="lp-list-empty">No payments found</div>
        }
        {disbursementPayments.length > 0 &&
          _.orderBy(disbursementPayments, x => x.matterDisbursement?.displayName, "desc").map((x: MatterDisbursementPaymentModel, index: number) => (
            <div key={x.id} className="lp-box-list-item">
              <span className="type-icon date">
                {moment(x.matterDisbursement?.date).format(DateFormat.Moment) + " - " + x.matterDisbursement?.displayName}
              </span>

              <Field
                label={"Amount Paid"}
                value={formatCurrency(x.amountPaid)}
              />
            </div>
          ))
        }
        </div>

        <Row>
          <Col className="d-flex flex-row-reverse justify-content-between">
            <Button onClick={onClickDelete} variant="danger">
              Delete
            </Button>
          </Col>
        </Row>

        <CustomModal />
    </>
  );
}
