import { useCallback, useEffect, useState } from 'react';
import CustomCalendar from 'components/Calendar/CustomCalendar';
import { Button, Card } from 'react-bootstrap';
import Title from 'components/Title';
import moment from 'moment';
import { MdArrowOutward, MdOutlineAdd, MdRefresh } from 'react-icons/md';
import { CalendarEventModel } from 'models/CalendarEventModel';
import useSlidingPanelActions from 'actions/slidingPanel';
import { getCalendarView } from 'actions/lte';
import { View, Views } from 'react-big-calendar';
import ViewCalendarEvent from 'containers/Calendar/ViewCalendarEvent/ViewCalendarEvent';
import { useNavigate, Link } from "react-router-dom";
import ManualAddAppointments from 'containers/Matter/AddAppointments/ManualAddAppointments';

export default function DashboardCalendar() {
  const [events, setEvents] = useState<CalendarEventModel[]>([]);
  const slidingPanelActions = useSlidingPanelActions();
  const [startDate, setStartDate] = useState<Date>(moment().startOf('week').toDate());
  const [endDate, setEndDate] = useState<Date>(moment().endOf('week').toDate());
  const [loading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const [view, setView] = useState<View>(Views.WEEK);
  const navigate = useNavigate();

  const getEventsForInterval = useCallback(() => {
    if(startDate != null && endDate != null) {
      setIsLoading(true);
      const userTimeZone = moment.tz.guess();
      getCalendarView(startDate.toISOString(), endDate.toISOString(), userTimeZone).then((response) => {
        setEvents(response.data);
        //clear previous errors
        setGenericErrors(null);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    getEventsForInterval();
  }, [getEventsForInterval]);

  const onRangeChange = (range: Date[] | { start: Date; end: Date }) => {
    setIsLoading(true);
    setEvents([]);
  
    if (Array.isArray(range)) {
      setStartDate(range[0]);
      if (range.length === 1) {
        const newEndDate = moment(range[0]).add(1, 'day');
        setEndDate(newEndDate.toDate());
      } else {
        setEndDate(moment(range[range.length - 1]).add(1, 'day').toDate());
      }
    } else {
      setStartDate(range.start);
      setEndDate(moment(range.end).add(1, 'day').toDate());
    }
  };
  
  const handleEventClick = (event: any) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'View Calendar Event',
      width: "50rem",
      children: <ViewCalendarEvent eventData={event} />
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getEventsForInterval();
    }, 300000);

    return () => clearInterval(interval);
  }, [getEventsForInterval]);

  const addAppointmentsToMatter = () => {
    slidingPanelActions.setSlidingPanel(
      {
        width: "70rem",
        isShown: true,
        title: "Import Appointments To Matter",
        children: <ManualAddAppointments />
      }
    );
  }

  return (
    <>
      <Title type="section" title="Calendar">
        {
          <>
            <div onClick={addAppointmentsToMatter} className="link">
            Import appointments to matter
            <MdOutlineAdd />
          </div>
            <Link to="/calendar" rel="noopener noreferrer" className="link">
              View full calendar
              <MdArrowOutward />
            </Link>
            <Button onClick={getEventsForInterval} className="btn-icon" variant="primary">
              <MdRefresh />
            </Button>
          </>
        }
      </Title>

      {genericErrors && <div className="lp-errors calendar-errors">{genericErrors}</div>}

      <Card className="with-calendar dashboard">
        <Card.Body>
          <CustomCalendar
            handleEventClick={handleEventClick}
            onRangeChange={onRangeChange}
            events={events}
            isLoading={loading}
            view={view}
            setView={setView}
          />
        </Card.Body>
      </Card>
    </>
  );
}
