import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col, Button } from "react-bootstrap";
import { deleteMatterIncidentalExpense, getMatterIncidentalExpenseById } from "actions/matter";
import moment from "moment";
import { DateFormat } from "utils/constants";
import ListSlidingPanel from "components/List/ListSlidingPanel";
import _ from 'lodash';
import { MatterIncidentalExpenseModel } from "models/view/MatterIncidentalExpenseModel";
import BooleanField from "components/Fields/BooleanField";
import { formatCurrency } from "utils/misc";
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";
import CustomModal from "components/Modal/Modal";
import { useAppSelector } from "hooks/appSelector";
import EditMatterIncidentalExpenseForm from "../EditMatterIncidentalExpense/EditMatterIncidentalExpenseForm";
import MatterIncidentalExpenseWriteOffForm from "../MatterIncidentalExpenseWriteOff/MatterIncidentalExpenseWriteOffForm";
import { ActionButtonTypes } from "enums/ActionButtonTypes";

type Props = {
  matterId: string,
  matterIncidentalExpenseId: string,
  showActions?: boolean,
  onDeleteCallback?: Function,
  onEditCallback?: Function,
  onWriteOffCallback?: Function
}

function ViewMatterIncidentalExpense(props: Props){
  const [isLoading, setIsLoading] = useState(true);
  const [matterIncidentalExpense, setMatterIncidentalExpense] = useState<MatterIncidentalExpenseModel>();
  const [genericErrors, setGenericErrors] = useState(null);
  const modalActions = useModalActions();
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);

  useEffect(() => {
    setIsLoading(true);
    getMatterIncidentalExpenseById(props.matterId, props.matterIncidentalExpenseId).then((response) => {
      setMatterIncidentalExpense(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  },[]);

  function onClickDelete() {
    setIsLoading(true);
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Matter Incidental Expense?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.matterIncidentalExpenseId),
      onClose: () => {setIsLoading(false);},
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();  
  }

  const deleteCallback = (matterId: string, matterIncidentalExpenseId: string) => {
    modalActions.toggleModalLoading();
    deleteMatterIncidentalExpense(matterId, matterIncidentalExpenseId).then(() => {
      slidingPanelActions.clearSlidingPanel();
      modalActions.toggleModalShownStatus(); 

      props.onDeleteCallback && props.onDeleteCallback();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      setIsLoading(false);
    });
  };

  function onClickEdit() {
    //close View Matter Incidental Expense sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open Edit Matter Incidental Expense sliding panel having onCancel event to reopen 
    //Edit Matter Incidental Expense sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      width: "55rem",
      title: "Edit Incidental Expense",
      children: <EditMatterIncidentalExpenseForm
        matterId={props.matterId}
        matterIncidentalExpenseId={props.matterIncidentalExpenseId}
        onSubmitCallback={onSubmitEditMatterIncidentalExpense}
      />,
      onCancel: onCancelEditMatterIncidentalExpense
    });
  }

  function onSubmitEditMatterIncidentalExpense() {
    //open back View Matter Incidental Expense sliding panel with onEditCallback on onCancel event
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState,
      onCancel: props.onEditCallback
    });
  }

  function onCancelEditMatterIncidentalExpense() {
    //open back View Matter Incidental Expense sliding panel
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState
    });
  }

  function onClickWriteOff() {
    //close View Matter Incidental Expense sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open View Matter Incidental Expense WriteOff sliding panel having onCancel event to reopen 
    //View Matter Incidental Expense sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Incidental Expense Write-Off",
      children: <MatterIncidentalExpenseWriteOffForm
          matterId={props.matterId}
          matterIncidentalExpenseId={props.matterIncidentalExpenseId}
          onSubmitCallback={onSubmitMatterIncidentalExpenseWriteOff}
          onDeleteCallback={onSubmitMatterIncidentalExpenseWriteOff}
        />,
      onCancel: onCancelMatterIncidentalExpenseWriteOff
    });
  }

  function onSubmitMatterIncidentalExpenseWriteOff() {
    //open back View Matter Incidental Expense sliding panel with onWriteOffCallback on onCancel event
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState,
      onCancel: props.onWriteOffCallback
    });
  }

  function onCancelMatterIncidentalExpenseWriteOff() {
    //open back View Matter Incidental Expense sliding panel
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState
    });
  }

  return(
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Fee Earner"}
            value={matterIncidentalExpense?.user?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Client"}
            value={matterIncidentalExpense?.matterParticipatingEntity?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Date"}
            value={matterIncidentalExpense?.date ? moment(matterIncidentalExpense?.date).format(DateFormat.Moment) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Display Name"}
            value={matterIncidentalExpense?.displayName}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Description"}
            value={matterIncidentalExpense?.description}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Gross Value"}
            value={matterIncidentalExpense?.grossValue ? formatCurrency(matterIncidentalExpense?.grossValue) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"VAT Value"}
            value={matterIncidentalExpense?.vatValue ? formatCurrency(matterIncidentalExpense?.vatValue) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Net Value"}
            value={matterIncidentalExpense?.netValue ? formatCurrency(matterIncidentalExpense?.netValue) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <BooleanField
            label={"Paid By User's Own Funds"}
            value={matterIncidentalExpense?.paidByUserOwnFunds ?? false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <BooleanField
            label={"Is Billed"}
            value={matterIncidentalExpense?.isBilled ?? false}
          />
        </Col>
      </Row>
      {matterIncidentalExpense?.writeOffAmount &&
        <>
          <Row>
            <Col>
              <Field
                label={"Write-Off Date"}
                value={matterIncidentalExpense?.writeOffDate ? moment(matterIncidentalExpense?.writeOffDate).format(DateFormat.Moment) : "—"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                label={"Write-Off Amount"}
                value={matterIncidentalExpense?.writeOffAmount ? formatCurrency(matterIncidentalExpense?.writeOffAmount) : "—"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                label={"Write-Off Description"}
                value={matterIncidentalExpense?.writeOffDescription}
              />
            </Col>
          </Row>
        </>
      }

      <Row>
        <Col>
          <div className="departments">
            <ListSlidingPanel
              listItems={_.orderBy(matterIncidentalExpense?.files?.map(x => ({id: x.id, name: x.name, webUrl: x.webUrl})) ?? [], x => x.name.toLowerCase())}
              listButtons={[{
                type: ActionButtonTypes.OpenFile
              }]}
            >
              Proofs of Purchase
            </ListSlidingPanel>
          </div>
        </Col>
      </Row>

      {props.showActions && !matterIncidentalExpense?.matter?.isClosed &&
        <Row>
          <Col className="d-flex flex-row-reverse justify-content-between">
            {!matterIncidentalExpense?.isBilled &&
              <Button onClick={onClickDelete} variant="danger">
                Delete
              </Button>
            }
            {!matterIncidentalExpense?.isBilled &&
              <Button onClick={onClickWriteOff} variant="secondary-400">
                Write-Off
              </Button>
            }
            {!matterIncidentalExpense?.isBilled && !matterIncidentalExpense?.writeOffAmount &&
              <Button onClick={onClickEdit} variant="secondary-400">
                Edit
              </Button>
            }
          </Col>
        </Row>
      }

      <CustomModal />
    </>
  );
}

export default ViewMatterIncidentalExpense;
