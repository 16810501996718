import { useCallback } from 'react';
import { MdAddCircle } from 'react-icons/md';
import Grid from "components/Grid";
import Title from 'components/Title';
import SlidingPanel from 'components/SlidingPanel';
import { Card, Button } from 'react-bootstrap';
import useGridActions from 'actions/grid';
import CreateMatterForm from 'containers/Matter/CreateMatter/CreateMatterForm';
import { columnDefs } from './columnDefs';
import { getMatters } from 'actions/matter';
import CustomModal from 'components/Modal/Modal';
import useSlidingPanelActions from 'actions/slidingPanel';
import { useAppSelector } from 'hooks/appSelector';

function MatterList() {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const slidingPanelState = useAppSelector((state) => state.slidingPanel);

  const onGridReady = useCallback(() =>
  {
    gridActions.setGridColumnDefs([...columnDefs]);
    getMatters().then((response) => 
    {
      gridActions.setGridRowData(response.data);
    })
  }, []);

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: !slidingPanelState.isShown,
        title: "Add Matter",
        children: <CreateMatterForm />
      });
  };

  return (
    <div className="lp-page-content">
      <Title
        title="Matters"
        type='page'
      >
        <Button variant="success" onClick={toggleSlidingPanel}>
          <MdAddCircle />
          Add Matter
        </Button>
      </Title>

      <SlidingPanel />

      <Card className="with-grid">
        <Card.Body>
          <Grid 
            onGridReady={onGridReady}
          />
        </Card.Body>
      </Card>

      <CustomModal />
    </div>
  );
}

export default MatterList;
