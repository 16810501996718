import { enforce, test, create } from "vest";
import isDate from "validator/lib/isDate";

export const validationSuite = create((data = {}) => {
  enforce.extend({ isDate });

  test('date', 'Please select a Date', () => {
    enforce(data.date).isDate({ strictMode: false });
  });

  test('amount', 'Amount is required', () => {
    enforce(data.amount).isNotEmpty();
  });

  test('amount', 'Amount needs to be a number > 0', () => {
    enforce(data.amount).greaterThan(0);
  });

  test('amount', `Amount needs to be a number <= ${data.maxAmountPossible}`, () => {
    enforce(data.amount).lessThanOrEquals(data.maxAmountPossible);
  });

  test('description', 'Description is required', () => {
    enforce(data.description).isNotEmpty();
    enforce(data.description).isString();
  });
});