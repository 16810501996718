import { useEffect, useState } from "react";
import Title from "components/Title";
import { deleteEntityNote, getEntityNotes } from "actions/entity";
import { Button } from 'react-bootstrap';
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { MdAdd } from "react-icons/md";
import List from "components/List/List";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { EntityNoteModel } from "models/view/EntityNoteModel";
import { ModalState } from "state/modalSlice";
import CreateEntityNoteForm from "./CreateEntityNote/CreateEntityNoteForm";
import EditEntityNoteForm from "./EditEntityNote/EditEntityNoteForm";

type Props = {
  entityId?: string,
  edit: boolean
}

export default function EntityNoteList(props: Props) {
  const [entityNotes, setEntityNotes] = useState<any[]>();
  const [isLoadingEntityNotes, setIsLoadingEntityNotes] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();

  const getAllEntityNotes = () => {
    setIsLoadingEntityNotes(true);
    getEntityNotes(props.entityId!).then((response) => {
      const mappedNotes = response.data.map((n: EntityNoteModel) => ({id: n.id, name: n.note}));
      setEntityNotes(mappedNotes);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingEntityNotes(false);
    });
  }

  useEffect(() =>
  {
    if(props.entityId) {
      getAllEntityNotes();
    }
  }, [props.entityId]);

  const openCreateEntityNoteSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Create Note',
      children: <CreateEntityNoteForm entityId={props.entityId ?? ''} submitCallback={getAllEntityNotes} />
    });
  };

  const openEditEntityNoteSlidingPanel = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Edit Note',
      children: <EditEntityNoteForm
                  entityId={props.entityId ?? ''}
                  noteId={id}
                  submitCallback={getAllEntityNotes}
                />
    });
  };

  const deleteCallbackEntityNote = (id: string) => {
    modalActions.toggleModalLoading();
    deleteEntityNote(id).then(() => {
      getAllEntityNotes();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      modalActions.toggleModalShownStatus();
    });
  };

  const deleteEntityNoteModal = (id: string) => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this note?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallbackEntityNote(id),
      show: true
    }
    modalActions.setModal(modal);
   };

  return (
    <>
      <Title type="section" title={"Notes"}>
        <Button onClick={openCreateEntityNoteSlidingPanel} className="btn-icon" variant="success">
          <MdAdd />
        </Button>
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}
      
      <List
        listItems={entityNotes ?? []}
        listButtons={
          [
            {
              type: ActionButtonTypes.Edit,
              callback: (id: string) => openEditEntityNoteSlidingPanel(id),
              id: props.entityId ?? ""
            },
            {
              type: ActionButtonTypes.Delete,
              callback: (id: string) => deleteEntityNoteModal(id),
              id: props.entityId ?? ""
            }
          ]
        }
        loadState={isLoadingEntityNotes}
      >
        <div>{"Notes"}</div>
      </List>
    </>
  );
}
