import { create, test, enforce, omitWhen } from "vest";

export const getValidationSuite = (isFeeEarner: boolean) => {
  return create((data = {}) => {
    test('userId', 'Please select a Fee Earner', () => {
      enforce(data.userId).isNotEmpty();
      enforce(data.userId).isString();
    });

    omitWhen(!isFeeEarner, () => {
      test('feeEarnerLevelId', 'Please select a Fee Earner Level', () => {
        enforce(data.feeEarnerLevelId).isNotEmpty();
        enforce(data.feeEarnerLevelId).isString();
      });
    })
  });
}