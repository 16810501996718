import isEmail from "validator/lib/isEmail";
import isUUID from "validator/lib/isUUID";
import { create, test, enforce } from "vest";

export const getValidationSuite = () => {
  return create((data = {}) => {
    enforce.extend({isUUID});
    enforce.extend({isEmail});

    test('calendarTenantId', 'Calendar Tenant ID is required', () => {
      enforce(data.calendarTenantId).isNotEmpty();
      enforce(data.calendarTenantId).isString();
    });

    test('calendarTenantId', 'Calendar Tenant ID must be a GUID', () => {
      enforce(data.calendarTenantId).isUUID();
    });

    test('diaryManagerEmail', 'User Account for LTE Diary needs to be a valid email address.', () => {
      enforce(data.diaryManagerEmail).isNotEmpty();
      enforce(data.diaryManagerEmail).isEmail();
    });
  });
}