import useSlidingPanelActions from "actions/slidingPanel";
import SlidingPanel from 'components/SlidingPanel';
import Title from "components/Title/index";
import { Button, Card } from "react-bootstrap";
import { MdAddCircle } from "react-icons/md";
import Grid from "components/Grid/index";
import { useCallback } from "react";
import CustomModal from "components/Modal/Modal";
import { getCompleteChargingSchemes } from "actions/chargingSchemes";
import useGridActions from "actions/grid";
import { columnDefs } from "./columnDefs";
import CreateChargingScheme from "./CreateChargingScheme/CreateChargingScheme";

export default function ChargingSchemeList() {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Add Charging Scheme",
        children: <CreateChargingScheme />
      });
  };

  const onGridReady = useCallback(() =>
  {
    gridActions.setGridColumnDefs([...columnDefs]);
    getCompleteChargingSchemes().then((response) => 
    {
      gridActions.setGridRowData(response.data);
    })
  }, []);

  return (
    <div className="lp-page-content">
      <Title
        type="page"
        title="Charging Schemes"
      >
        <Button variant="success" onClick={toggleSlidingPanel}>
          <MdAddCircle />
          Add Charging Scheme
        </Button>
      </Title>

      <SlidingPanel/>
      
      <Card className="with-grid">

        <Card.Body>
          <Grid
            onGridReady={onGridReady}
          />
        </Card.Body>
      </Card>

      <CustomModal />
    </div>
  );
}
