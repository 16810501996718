import { enforce, test, create, omitWhen } from "vest";
import isDate from "validator/lib/isDate";
import { MatterInvoiceParticipatingEntityModel } from "models/view/MatterInvoiceParticipatingEntityModel";

export const validationSuite = create((data = {}) => {
  enforce.extend({ isDate });

  test('date', 'Please select a Date', () => {
    enforce(data.date).isDate({ strictMode: false });
  });

  test('amount', 'Amount is required', () => {
    enforce(data.amount).isNotEmpty();
  });

  test('amount', 'Amount needs to be a number > 0', () => {
    enforce(data.amount).greaterThan(0);
  });

  test('amount', `Amount needs to be a number <= ${data.maxAmountPossible}`, () => {
    enforce(data.amount).lessThanOrEquals(data.maxAmountPossible);
  });

  test('description', 'Description is required', () => {
    enforce(data.description).isNotEmpty();
    enforce(data.description).isString();
  });

  omitWhen(!data.forSingleClient, () => {
    test('matterParticipatingEntityId', 'Please select a Client', () => {
      enforce(data.matterParticipatingEntityId).isNotEmpty();
      enforce(data.matterParticipatingEntityId).isString();
    });

    test('amount', `Amount exceeds client amount on the invoice`, () => {
      const matterInvoiceParticipatingEntity = data.matterInvoiceParticipatingEntities?.find((x: MatterInvoiceParticipatingEntityModel) => x.matterParticipatingEntityId == data.matterParticipatingEntityId);
      enforce(data.amount).lessThanOrEquals(matterInvoiceParticipatingEntity.invoicedTotalGrossValue ?? 0);
    });
  });
});