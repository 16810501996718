import { create, test, enforce, omitWhen, each } from "vest";
import isDate from "validator/lib/isDate";
import isEmail from "validator/lib/isEmail";
import { CalendarRecurrencePatternTypes } from "enums/CalendarRecurrencePatternTypes";

export const getValidationSuite = (isNoEndEvent: boolean) => {
  return create((data = {}) => {
    enforce.extend({isDate});
    enforce.extend({isEmail});

    test('title', 'Title is required', () => {
      enforce(data.title).isNotEmpty();
    });

    test('startDate', 'Start Date is not a valid date', () => {
      enforce(data.startDate).isDate({strictMode: false});
    });

    test('endDate', 'End Date is not a valid date', () => {
      enforce(data.endDate).isDate({strictMode: false});
    });

    omitWhen(!data.isAllDay, () => {
      test('endDate', 'End Date should be after or same day as Start Date', () => {
        const calculatedMinutesDif = data.endDate.getTime() - data.startDate.getTime();
        enforce(calculatedMinutesDif).greaterThanOrEquals(0);
      });
    });

    omitWhen(data.isAllDay, () => {
      test('endDate', 'End Date should be after Start Date', () => {
        const calculatedMinutesDif = data.endDate.getTime() - data.startDate.getTime();
        enforce(calculatedMinutesDif).greaterThan(0);
      });
    });

    omitWhen(!data.requiredAttendees, () => {
      each(data.requiredAttendees, (field: string, index: number) => {
        test(`requiredAttendees`, 'One of the Email Addresses is not valid.', () => {
          enforce(field).isEmail();
        });
      });
    });

    omitWhen(!data.optionalAttendees, () => {
      each(data.optionalAttendees, (field: string, index: number) => {
        test(`optionalAttendees`, 'One of the Email Addresses is not valid.', () => {
          enforce(field).isEmail();
        });
      });
    });

    omitWhen(data.calendarRecurrence.recurrencePatternType == CalendarRecurrencePatternTypes.NoRepeat, () => {
      test(`calendarRecurrence.interval`, 'Interval needs to be a number > 0.', () => {
        enforce(data.calendarRecurrence.interval).isNumeric().greaterThanOrEquals(1);
      });

      omitWhen(isNoEndEvent, () => {
        test(`calendarRecurrence.endDate`, 'End Date is not a valid date.', () => {
          enforce(data.calendarRecurrence.endDate).isDate({strictMode: false});
        });

        test('calendarRecurrence.endDate', 'End Date should be after Start Date', () => {
          const calculatedMinutesDif = data.calendarRecurrence.endDate.getTime() - data.startDate.getTime();
          enforce(calculatedMinutesDif).greaterThan(0);
        });
      });
    });
  });
}