import { SimpleUserModel } from "models/view/SimpleUserModel";

function getUserNameInitials(user?: SimpleUserModel) {  
  if(user?.firstName && user?.lastName) {
    return `${user?.firstName?.charAt(0).toUpperCase()}${user?.lastName?.charAt(0).toUpperCase()}`
  } else if(user?.displayName) {
    return `${user?.displayName?.slice(0, 2).toUpperCase()}`;
  }
  else {
    return `${user?.email?.slice(0, 2).toUpperCase()}`;
  }
}

function getUserName(user?: SimpleUserModel) {  
  if(user?.firstName && user?.lastName) {
    return `${user?.firstName} ${user?.lastName}`
  } else if(user?.displayName) {
    return user?.displayName;
  }
  else {
    return user?.email;
  }
}

export {
  getUserNameInitials,
  getUserName
};
