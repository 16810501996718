import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import SlidingPanel from "components/SlidingPanel/index";
import CustomModal from "components/Modal/Modal";
import { TemplateModel } from "models/view/TemplateModel";
import { getLTETemplates } from "actions/settings";
import ViewCardTemplate from "containers/Settings/TemplateSettings/TemplateActions/ViewCardTemplate/ViewCardTemplate";
import Loader from "components/Loader";
import { LTETemplateTypeIds } from "enums/LTETemplateTypeIds";

//this is to ensure the same order for the templates, everytime
const sortByTypeIdAscending = (a: TemplateModel, b: TemplateModel) => {
  if (a.typeId < b.typeId) {
    return -1;
  }
  if (a.typeId > b.typeId) {
    return 1;
  }
  return 0;
}

export default function LTETemplates() {
  const [templates, setTemplates] = useState<TemplateModel[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [genericErrors, setGenericErrors] = useState(null);

  const loadLTETemplates = () => {
    setIsLoading(true);
    getLTETemplates().then((response) => {
      const existingTypes = response.data.map((x: TemplateModel) => x.typeId);
      const missingTypes = Object.values(LTETemplateTypeIds).filter(type => !existingTypes.includes(type));

      if(missingTypes.length > 0) {
        response.data = response.data.concat(missingTypes.map((x: string) => ({
          typeId: x
        })));
      }
      setTemplates(response.data.sort(sortByTypeIdAscending));
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    loadLTETemplates();
  }, []);

  return (
    <>
    {isLoading && <Loader inlineLoader />}

    {genericErrors && (
      <div className="lp-errors">
        {genericErrors}
      </div>
    )}

      <Row className="templates">
        {templates?.map((x: TemplateModel, index: number) => {
          return <ViewCardTemplate 
            key={index}
            template={x}
            refreshTemplatesPage={loadLTETemplates}
          />;
        })}
      </Row>

      <SlidingPanel />
      
      <CustomModal />
    </>
  );
}
