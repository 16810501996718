import { useContext } from 'react';
import { Button, useAccordionButton, AccordionContext } from 'react-bootstrap';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

type Props = {
  eventKey: string,
  callback?: any
}

export default function CustomAccordionToggleButton(props: Props) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(props.eventKey, () =>
    props.callback && props.callback(props.eventKey)
  );
  
  const isCurrentEventKey = activeEventKey === props.eventKey || activeEventKey?.includes(props.eventKey);
  
  return (
    <Button variant="link" onClick={decoratedOnClick} className="btn-icon">
      {isCurrentEventKey ? <MdExpandLess /> : <MdExpandMore />}
    </Button>
  );
}
