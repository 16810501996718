import { create, test, enforce, omitWhen } from "vest";
import isDate from "validator/lib/isDate";

export const validationSuite = create((data = {}) => {
  enforce.extend({ isDate });

  test('userId', 'Please select a Fee Earner', () => {
    enforce(data.userId).isNotEmpty();
    enforce(data.userId).isString();
  });

  test('matterParticipatingEntityId', 'Please select a Client', () => {
    enforce(data.matterParticipatingEntityId).isNotEmpty();
    enforce(data.matterParticipatingEntityId).isString();
  });

  test('date', 'Please select a Date', () => {
    enforce(data.date).isDate({ strictMode: false });
  });

  test('displayName', 'Display Name is required', () => {
    enforce(data.displayName).isNotEmpty();
    enforce(data.displayName).isString();
  });

  test('description', 'Description is required', () => {
    enforce(data.description).isNotEmpty();
    enforce(data.description).isString();
  });

  test('grossValue', 'Gross Value is required', () => {
    enforce(data.grossValue).isNotEmpty();
  });

  test('grossValue', 'Gross Value needs to be a number > 0', () => {
    enforce(data.grossValue).greaterThan(0);
  });

  omitWhen(!data.vatValue, () => {
    test('vatValue', 'VAT Value needs to be a number > 0 or left blank', () => {
      enforce(data.vatValue).greaterThan(0);
    });
  });

  test('grossValue', 'Gross Value needs to be higher than VAT Value', () => {
    var netValue = data.grossValue - (data.vatValue ?? 0);
    enforce(netValue).greaterThan(0);
  });
});