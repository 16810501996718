import { AddressModel } from "models/view/AddressModel";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { LinkedAddressModel } from "models/view/LinkedAddressModel";
import { get } from "utils/request";

const getAddressSuggestions = async (postCode: string, lteId?: string, entityId?: string): Promise<any> => {
  let endpoint = `/management/address/search?postCode=${postCode}`;
  if(lteId){
    endpoint += `&lteId=${lteId}`;
  }
  if(entityId){
    endpoint += `&entityId=${entityId}`;
  }
  const response = await get<AddressModel[]>(endpoint);
  return response;
}

const checkIfAddressIsLinked =
  async (addressId: string, lteId?: string, entityId?: string): Promise<any> => {
  let endpoint = `/management/address/check?addressId=${addressId}`;
  if(lteId){
    endpoint += `&lteId=${lteId}`;
  }
  if(entityId){
    endpoint += `&entityId=${entityId}`;
  }
  const response = await get<LinkedAddressModel[]>(endpoint);
  return response;
}

const getAddressTypes = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/addressType/summary');
  return response;
}

export { 
  getAddressSuggestions,
  checkIfAddressIsLinked,
  getAddressTypes
};
