import Loader from "components/Loader/index";
import './style.scss';
import React from "react";
import { formatCurrency } from "utils/misc";
import { AccountLedgerTransactionEntryModel } from "models/view/AccountLedgerTransactionEntryModel";
import { AccountIncreaseRepresentedBy } from "enums/AccountIncreaseRepresentedBy";

type Props = {
  entries: AccountLedgerTransactionEntryModel[],
  loadState?: boolean
}

export default function ListAccountLedgerTransactionEntries(props: Props) {
  return (
    <div className="lp-account-transaction-entry-list">
      <div className={'lp-account-transaction-entry-list-header primary'}>
        <div className="lp-account-transaction-entry-list-account">{"Account"}</div>
        <div className="lp-account-transaction-entry-list-amount">{"DR"}</div>
        <div className="lp-account-transaction-entry-list-amount">{"CR"}</div>
      </div>
      {props.loadState && <Loader inlineLoader={props.loadState} />}

      <ul>
        {props.entries.length == 0 && 
          <li className="lp-list-empty">No entries found!</li>
        }

        {props.entries.map((x: AccountLedgerTransactionEntryModel, index: number) => {
          return (
            <React.Fragment key={index}>
              <li>
                <div className="lp-account-transaction-entry-list-account">
                  {x.user?.name ? x.account?.displayName + " - " + x.user?.name : x.account?.displayName}
                </div>
                <div className="lp-account-transaction-entry-list-amount">
                  {x.entryType == AccountIncreaseRepresentedBy.DR ? formatCurrency(x.amount) : "—"}
                </div>
                <div className="lp-account-transaction-entry-list-amount">
                  {x.entryType == AccountIncreaseRepresentedBy.CR ? formatCurrency(x.amount) : "—"}
                </div>
              </li>
            </React.Fragment>
          )
        })}
        <React.Fragment key={props.entries.length}>
          <li>
            <div className="lp-account-transaction-entry-list-account total">
              TOTAL
            </div>
            <div className="lp-account-transaction-entry-list-amount">
              {formatCurrency(props.entries.map(x => x.entryType == AccountIncreaseRepresentedBy.DR ? x.amount : 0)
                .reduce((a, b) => a + b, 0))}
            </div>
            <div className="lp-account-transaction-entry-list-amount">
              {formatCurrency(props.entries.map(x => x.entryType == AccountIncreaseRepresentedBy.CR ? x.amount : 0)
                .reduce((a, b) => a + b, 0))}
            </div>
          </li>
        </React.Fragment>
      </ul>
    </div>
  );
}
