import { IFloatingFilterParams } from "ag-grid-community";
import CustomSelect from "components/Select/Select";
import { forwardRef, useRef, useState } from "react";

type Props = IFloatingFilterParams & {
  values: {id: string, name: string}[]
}

export const BooleanFloatingFilter = forwardRef((props: Props, ref) => {
  const [currentValue, setCurrentValue] = useState<any>(null);
  const inputRef = useRef<any>(null);

  const onOptionChange = (input: any) => {
    if (input == undefined || input == null || input.count == 0
      ) {
      // clear the filter
      props.parentFilterInstance((instance: any) => {
        instance.setSelectedValues(null);
        setCurrentValue(null);
      });
      return;
    }

    props.parentFilterInstance((instance: any) => {
      instance.setSelectedValues(input);
      setCurrentValue(input);
    });
  }

  return (
    <CustomSelect
      inputRef={inputRef}
      id="options"
      className="lp-select"
      options={props.values}
      value={currentValue?.map((x: any) => x.id) ?? []}
      onChange={onOptionChange}
      styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
      isClearable
      isMulti
    />
  );
});