import { Dropdown } from "react-bootstrap";
import { GridFilterButtonDataModel } from "models/view/GridFilterButtonDataModel";
import { GridFilterTypes } from "enums/GridFilterTypes";

const GridFilters = (
  buttonData: GridFilterButtonDataModel
) => {
  switch (buttonData.type) {
    case GridFilterTypes.GridFilters: {
      return <Dropdown.Item
        key={"grid-filters-option-0"}
        className={buttonData.className ? buttonData.className : ""}
        onClick={buttonData.callback != undefined ? () => buttonData.callback!() : () => {}}
      >{buttonData.text ?? "Grid Filters"}</Dropdown.Item>;
    }
    case GridFilterTypes.AdvancedFilters: {
      return <Dropdown.Item
        key={"grid-filters-option-1"}
        className={buttonData.className ? buttonData.className : ""}
        onClick={buttonData.callback != undefined ? () => buttonData.callback!() : () => {}}
      >{buttonData.text ?? "Advanced Filters"}</Dropdown.Item>;
    }
    default:
      return <></>;
  }
}

export default GridFilters;
