import IconButtonGrid from "components/Buttons/IconButtonGrid";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import React from "react";
import Loader from "components/Loader/index";
import './style.scss';
import { openUrlInNewtab } from "utils/misc";

type Props = {
  listItems: any[],
  listButtons: Array<{ type: ActionButtonTypes, callback?: Function }>,
  children: React.ReactNode,
  loadState?: boolean
}

export default function ListSlidingPanel(props: Props) {
  return (
    <div className="lp-simple-list">
      <div className={`lp-color-title${props.children == 'Departments included' ? ' primary' : ' secondary'}`}>{props.children}</div>
      {props.loadState && <Loader inlineLoader={props.loadState} />}
      <ul>
        {props.listItems.length === 0 &&
          <li className="lp-list-empty">No items found.</li>
        }
        {props.listItems.map((x: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <li>
                <span>{x.name}</span>
                {props.listButtons && 
                  <div className="lp-simple-list-actions">
                    {
                      props.listButtons.map((el: { type: ActionButtonTypes, callback?: Function }) => {
                        if(el.type === ActionButtonTypes.Delete && x.hideDeleteBtn) {
                          return <React.Fragment key={index}></React.Fragment>;
                        }
                        else if(el.type === ActionButtonTypes.OpenFile && x.webUrl) {
                          return IconButtonGrid({
                            value: x.id,
                            type: el.type,
                            callback: () => openUrlInNewtab(x.webUrl), 
                            id: x.id + index.toString()
                          });
                        }
                        else {
                          return IconButtonGrid({
                            ...el, callback: () => {
                              el.callback && el.callback(x.id)
                            }
                          })
                        }
                      })
                    }
                  </div>
                }
              </li>
            </React.Fragment>
          )
        })}
      </ul>
    </div>
  );
}
