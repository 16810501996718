import { Outlet } from "react-router-dom";
import NavBar from 'components/NavBar';
import NotificationMessages from "components/NotificationMessages/NotificationMessages";
import './style.scss';

function MainLayout() {
  return (
    <div className="lp-body-content">
      <div className="lp-body-content-left">
        <NavBar />
      </div>
      <div className="lp-body-content-right">
        <Outlet />
        <NotificationMessages />
      </div>
    </div>
  );
}

export default MainLayout;
