import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";
import { ColDef } from "ag-grid-community";
import { DateFormatter } from "components/Grid/ValueFormatters/DateFormatter";
import { MatterDisbursementModel } from "models/view/MatterDisbursementModel";

export const getColumnDefsDisbursementDetailForInvoice = () => {
  const columnDefs: ColDef<MatterDisbursementModel>[] = [
    { field: 'date', headerName: "Date", valueFormatter: DateFormatter },
    { field: 'displayName', headerName: "Display Name", flex: 1 },
    { field: 'grossValue', headerName: "Gross Value", valueFormatter: CurrencyFormatter },
    { field: 'netValue', headerName: "Net Value", valueFormatter: CurrencyFormatter },
    { field: 'vatValue', headerName: "VAT Value", valueFormatter: CurrencyFormatter },
    { field: 'writeOffAmount', headerName: "Write-Off Amount", valueFormatter: CurrencyFormatter },
    { field: 'grossValueOnInvoice', headerName: "Invoiced Gross Value", valueFormatter: CurrencyFormatter },
  ];

  return columnDefs;
}