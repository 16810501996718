export enum TemplateResourceType
{
    RecordableEvent = 0,
    RecordableItem = 1,
    IncidentalExpense = 2,
    User = 3,
    LawPageTradingEntity = 4,
    Matter = 5,
    Disbursement = 6,
    Invoice = 7,
    Payment = 8,
    DisbursementPayment = 9,
    InvoicePayment = 10,
    InvoiceParticipatingEntity = 11,
    InvoiceInvoicingParty = 12
}