import Loader from "components/Loader/index";
import IconButtonGrid from "components/Buttons/IconButtonGrid";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { Card } from "react-bootstrap";
import './style.scss';
import React from "react";

type Props = {
  listItems: any[],
  listButtons: Array<{type: ActionButtonTypes, callback: Function, id?: string}>,
  onClick?: Function,
  selected?: string,
  loadState?: boolean
  children: React.ReactNode
}

export default function ListChargingRates(props: Props) {
  return (
    <Card className="with-list">
      <Card.Header>{props.children}</Card.Header>
      {props.loadState && <Loader inlineLoader={props.loadState} />}
      <Card.Body>
        <ul className="lp-list-grid">
          {props.listItems.length == 0 && <li className="lp-empty list">No rows found!</li>}

          {props.listItems.map((x: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <li>
                  <div
                    className="lp-list-grid-item-name"
                  >
                    {x.chargingRate.activity.name}
                  </div>
                  <div
                    className="lp-list-grid-item-name"
                  >
                    {x.chargingRate.feeEarnerLevel?.name ?? '[All Levels]'}
                  </div>
                  <div className="lp-list-grid-item-actions">
                    {
                      props.listButtons.map((el: {type: ActionButtonTypes, callback: Function, id?: string}, index: number) => 
                        IconButtonGrid({...el, callback: () => {
                          el.callback(x.recordableEventTypeToChargingRateId)
                        }, id: x.recordableEventTypeToChargingRateId + index.toString()})
                      )
                    }
                  </div>
                </li>
              </React.Fragment>
            )
          })}
        </ul>
      </Card.Body>
    </Card>
  );
}
