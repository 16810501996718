import List from "components/List/List";
import { deleteLteBranch, deleteLteDepartment, deleteLteTeam, getLteBranchesWithDepartmentsAndTeams } from "actions/lte";
import Title from "components/Title/index";
import { useEffect, useState } from "react";
import { MdAdd, MdRestartAlt } from "react-icons/md";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { LteBranchModel } from "models/view/LteBranchModel";
import useSlidingPanelActions from "actions/slidingPanel";
import { LteDepartmentModel } from "models/view/LteDepartmentModel";
import ViewLteBranchForm from "./LteBranch/ViewLteBranch/ViewLteBranch";
import EditLteBranchForm from "./LteBranch/EditLteBranch/EditLteBranchForm";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";
import CreateLteBranchForm from "./LteBranch/CreateLteBranch/CreateLteBranchForm";
import EditLteDepartmentForm from "./LteDepartment/EditLteDepartment/EditLteDepartmentForm";
import CreateLteDepartmentForm from "./LteDepartment/CreateLteDepartment/CreateLteDepartmentForm";
import { LteTeamModel } from "models/view/LteTeamModel";
import CreateLteTeamForm from "./LteTeam/CreateLteTeam/CreateLteTeamForm";
import EditLteTeamForm from "./LteTeam/EditLteTeam/EditLteTeamForm";
import { Col, Button } from "react-bootstrap";

type Props = {
  lteId?: string,
  edit?: boolean
}

export default function ViewLawPageTradingEntityOrganisation(props: Props) {
  const [lteBranches, setLteBranches] = useState<LteBranchModel[]>();
  const [lteDepartments, setLteDepartments] = useState<LteDepartmentModel[]>();
  const [lteTeams, setLteTeams] = useState<LteTeamModel[]>();

  const [loaderBranch, setLoaderBranch] = useState<boolean>(false);
  const [loaderDepartment, setLoaderDepartment] = useState<boolean>(false);
  const [loaderTeam, setLoaderTeam] = useState<boolean>(false);

  const [selectedLteBranch, setSelectedLteBranch] = useState<string>();
  const [selectedLteDepartment, setSelectedLteDepartment] = useState<string>();
  const [selectedLteTeam, setSelectedLteTeam] = useState<string>();

  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();
  const [edit, setEdit] = useState<boolean>(props.edit ?? false);

  const loadHierarchy = () => {
    if(props.lteId) {
      setLoaderBranch(true);
      setLoaderDepartment(true);
      setLoaderTeam(true);
      getLteBranchesWithDepartmentsAndTeams(props.lteId).then((response) => 
      {
        setLteBranches(response.data);
        const departments: LteDepartmentModel[] = response.data?.flatMap((x: LteBranchModel) => x.children) ?? [];
        const teams: LteTeamModel[] = departments.flatMap((x: LteDepartmentModel) => x.children ?? []);
        setLteDepartments(departments);
        setLteTeams(teams);
        setLoaderBranch(false);
        setLoaderDepartment(false);
        setLoaderTeam(false);
      })
    }
  }

  useEffect(() => {
    loadHierarchy();
  }, [props.lteId]);
  
  const viewLteBranch = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: `View Branch`,
      children: <ViewLteBranchForm
        id={id}
        edit={props.edit}
        deleteCallback={deleteCallback}
        updateBranchesList={reloadAndReset}
      />
    });
  };

  /* add callbacks */
  const addToLteDepartments = (data: LteDepartmentModel) => {
    // const lteBranchIndex: number = lteBranches?.findIndex((x: LteBranchModel) => x.id == data.lteBranch.id) ?? -1;
    // let lteBranches2: LteBranchModel[] = [...lteBranches!];
    // if(lteBranchIndex != -1) {
    //   lteBranches2[lteBranchIndex].children = lteBranches2[lteBranchIndex].children?.concat(data);
    // }
    // setLteBranches(lteBranches2);
    // if(!selectedLteBranch) {
    //   setLteDepartments(lteDepartments?.concat(data))
    //   return;
    // }
    // if(data.lteBranch.id == selectedLteBranch) {
    //   setLteDepartments(lteDepartments?.concat(data))
    // }
    reloadAndReset();
  }

  const addToLteBranches = (data: LteBranchModel) => {
    setLteBranches(lteBranches?.concat(data))
  }

  const addToLteTeams = (data: LteTeamModel) => {
    // const lteBranch : LteBranchModel | undefined = lteBranches?.find((x: LteBranchModel) => x.children?.findIndex((x: LteDepartmentModel) => x.id == data.lteDepartment.id) != -1);
    // if(lteBranch) {
    //   const lteDepartments2: LteDepartmentModel[] | undefined = lteBranch?.children;
    //   let lteBranches2: LteBranchModel[] = [...lteBranches!];
    //   const lteDepartmentIndex: number = lteDepartments2?.findIndex((x: LteDepartmentModel) => x.id == data.lteDepartment.id) ?? -1;
    //   const lteBranchIndex: number = lteBranches?.findIndex((x: LteBranchModel) => x.id == lteBranch.id) ?? -1;
    //   if(lteDepartmentIndex != -1 && lteDepartments2) {
    //     lteDepartments2[lteDepartmentIndex].children = lteDepartments2[lteDepartmentIndex].children?.concat(data);
    //     if(lteBranchIndex != -1) {
    //       lteBranches2[lteBranchIndex].children = lteDepartments2;
    //     }
    //   }
    //   setLteBranches(lteBranches2);
    // }
    // if(!selectedLteDepartment) {
    //   setLteTeams(lteTeams?.concat(data))
    //   return;
    // } 
    // if(data.lteDepartment.id == selectedLteDepartment) {
    //   setLteTeams(lteTeams?.concat(data))
    // }
    reloadAndReset();
  }
  /* end add callbacks */

  /* create forms */
  const openCreateLteBranchSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: `Create Branch`,
      children: <CreateLteBranchForm submitCallback={addToLteBranches} />
    });
  };

  const openCreateLteDepartmentSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: `Create Department`,
      children: <CreateLteDepartmentForm submitCallback={addToLteDepartments} />
    });
  };

  const openCreateLteTeamSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: `Create Team`,
      children: <CreateLteTeamForm submitCallback={addToLteTeams} />
    });
  };
  /* end create forms */

  /* update callbacks */
  const reloadAndReset = () => {
    loadHierarchy();
    resetSelectedBranch();
  }
  /* end update callbacks */

  /* edit forms */
  const openEditBranchSlidingPanel = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: `Edit Branch`,
      children: <EditLteBranchForm
        branchId={id}
        submitCallback={reloadAndReset}
      />
    });
  };

  const openEditDepartmentSlidingPanel = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: `Edit Department`,
      children: <EditLteDepartmentForm
        departmentId={id}
        submitCallback={reloadAndReset}
      />
    });
  };

  const openEditTeamSlidingPanel = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: `Edit team`,
      children: <EditLteTeamForm
        teamId={id}
        submitCallback={reloadAndReset}
      />
    });
  };
  /* end edit forms */

  /* delete modals */
  const deleteBranchModal = (id: string) => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this branch?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(id),
      show: true
    }
    modalActions.setModal(modal);
  };

  const deleteDepartmentModal = (id: string) => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this department?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallbackLteDepartment(id),
      show: true
    }
    modalActions.setModal(modal);
  };

  const deleteTeamModal = (id: string) => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this team?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallbackLteTeam(id),
      show: true
    }
    modalActions.setModal(modal);
  };
  /* end delete modals */

  /* delete callbacks */
  const deleteCallback = (id: string) => {
    modalActions.toggleModalLoading();
    deleteLteBranch(id).then(() => {
      reloadAndReset();
      modalActions.toggleModalShownStatus();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  const deleteCallbackLteDepartment = (id: string) => {
    modalActions.toggleModalLoading();
    deleteLteDepartment(id).then(() => {
      reloadAndReset();
      modalActions.toggleModalShownStatus();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  const deleteCallbackLteTeam = (id: string) => {
    modalActions.toggleModalLoading();
    deleteLteTeam(id).then(() => {
      reloadAndReset();
      modalActions.toggleModalShownStatus();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };
  /* end delete callbacks */

  /* reset functions */
  const resetSelectedBranch = () => {
    setSelectedLteBranch(undefined);

    //get all departments for all branches
    const departments: LteDepartmentModel[] = lteBranches?.flatMap((x: LteBranchModel) => x.children ?? []) ?? [];
    //set all departments for all branches
    setLteDepartments(departments);
    //deselect current department
    setSelectedLteDepartment(undefined);

    //get all teams for all branches
    const teams = departments?.flatMap((x: LteDepartmentModel) => x.children ?? []);
    //set all teams for all branches
    setLteTeams(teams);
    //deselect current team
    setSelectedLteTeam(undefined);
  }

  const resetSelectedDepartment = () => {
    setSelectedLteDepartment(undefined);

    //get teams from departments
    const teams = lteDepartments?.flatMap((x: LteDepartmentModel) => x.children ?? []);
    //set teams from departments
    setLteTeams(teams);
    //deselect current team
    setSelectedLteTeam(undefined);
  }
  /* end reset functions */

  /* on click handlers */
  const onClickBranch = (id: string) => {
    if (selectedLteBranch === id) {
      //deselect branch if already selected
      resetSelectedBranch();
    }
    else {
      //select branch
      setSelectedLteBranch(id);
      //get departments for selected branch
      const departments: LteDepartmentModel[] = lteBranches?.find((x: LteBranchModel) => x.id === id)?.children ?? [];
      //set departments for selected branch
      setLteDepartments(departments);
      //deselect current department
      setSelectedLteDepartment(undefined);

      //get only teams for selected branch 
      const teams = departments?.flatMap((x: LteDepartmentModel) => x.children ?? []);
      //set only teams for selected branch 
      setLteTeams(teams);
      //deselect current team
      setSelectedLteTeam(undefined);
    }
  }

  const onClickDepartment = (id: string) => {
    if (selectedLteDepartment === id) {
      //deselect department if already selected
      resetSelectedDepartment();
    }
    else {
      //select department
      setSelectedLteDepartment(id);
      //get selected department
      const department: LteDepartmentModel | undefined = lteDepartments?.find((x: LteDepartmentModel) => x.id === id);
      //get branch for selected department
      const branch: LteBranchModel | undefined = lteBranches?.find((x: LteBranchModel) =>
        x.children?.findIndex((x: LteDepartmentModel) => x.id === department?.id) !== -1);
      //get teams for selected department
      const teams: LteTeamModel[] = department?.children ?? [];
      // set teams for selected department
      setLteTeams(teams);
      //if current selected team does not belong to the selected department deselect it
      if (selectedLteTeam && !teams.find((x: LteTeamModel) => x.id === selectedLteTeam)) {
        setSelectedLteTeam(undefined);
      }

      //if no branch is selected, select the branch of the selected department and set departments belonging to it
      if (branch && !selectedLteBranch) {
        //select the branch of the selected department 
        setSelectedLteBranch(branch.id);
        //get departments belonging to the branch of the selected department 
        const departments: LteDepartmentModel[] = lteBranches?.find((x: LteBranchModel) => x.id === branch.id)?.children ?? [];
        //set departments belonging to the branch of the selected department 
        setLteDepartments(departments);
      }
    }
  }

  const onClickTeam = (id: string) => {
    if (selectedLteTeam === id) {
      //deselect team if already selected
      setSelectedLteTeam(undefined);
    }
    else {
      //select team
      setSelectedLteTeam(id);
      //get selected team
      const team: LteTeamModel | undefined = lteTeams?.find((x: LteTeamModel) => x.id === id);
      //get department for selected team
      const department: LteDepartmentModel | undefined = lteDepartments?.find((x: LteDepartmentModel) =>
        x.children?.findIndex((x: LteTeamModel) => x.id === team?.id) !== -1);

      //if no department is selected, select the department of the selected team, set teams belonging to it
      // and if also not branch is selected, select the branch of the department of the selected team and set departments belonging to it
      if (department && !selectedLteDepartment) {
        //select the department of the selected team 
        setSelectedLteDepartment(department.id);

        //get branch of the department of the selected team
        const branch: LteBranchModel | undefined = lteBranches?.find((x: LteBranchModel) =>
          x.children?.findIndex((x: LteDepartmentModel) => x.id === department?.id) !== -1);
        //get teams belonging to the department of the selected team
        const teams: LteTeamModel[] = department?.children ?? [];
        //set teams belonging to the department of the selected team
        setLteTeams(teams);

        //if also not branch is selected, select the branch of the department of the selected team and set departments belonging to it
        if (branch && !selectedLteBranch) {
          //select the branch of the department of the selected team
          setSelectedLteBranch(branch.id);
          const departments: LteDepartmentModel[] = lteBranches?.find((x: LteBranchModel) => x.id === branch.id)?.children ?? [];
          //set departments belonging to the branch of the department of the selected team
          setLteDepartments(departments);
        }
      }
    }
  }
  /* end on click handlers */

  return (
    <>
      <Col xxl={4}>
        <Title type="section" title={"Branches"}>
          {selectedLteBranch &&
            <Button onClick={resetSelectedBranch} className="btn-icon" variant="secondary-400">
              <MdRestartAlt />
            </Button>
          }
          {props.edit &&
            <Button onClick={openCreateLteBranchSlidingPanel} className="btn-icon" variant="success">
              <MdAdd />
            </Button>
          }
        </Title>
        <List
          listItems={lteBranches ?? []}
          listButtons={
            [
              {
                type: ActionButtonTypes.View,
                callback: (id: string) => viewLteBranch(id)
              },
              ...(
                props.edit ?
                [{
                  type: ActionButtonTypes.Edit,
                  callback: (id: string) => openEditBranchSlidingPanel(id)
                },
                {
                  type: ActionButtonTypes.Delete,
                  callback: (id: string) => deleteBranchModal(id)
                }] : [])
            ]
          }
          selected={selectedLteBranch}
          loadState={loaderBranch}
          onClick={(id: string) => onClickBranch(id)}
        >
          <div>Branches</div>
        </List>
      </Col>
      <Col xxl={4} className="mt-4 mt-xxl-0">
        <Title type="section" title={"Departments"}>
          {selectedLteDepartment &&
            <Button onClick={resetSelectedDepartment} className="btn-icon" variant="secondary-400">
              <MdRestartAlt />
            </Button>
          }
          {props.edit &&
            <Button onClick={openCreateLteDepartmentSlidingPanel} className="btn-icon" variant="success">
              <MdAdd />
            </Button>
          }
        </Title>
        <List
          listItems={lteDepartments ?? []}
          listButtons={
            [
              ...(
                props.edit ?
                [{
                  type: ActionButtonTypes.Edit,
                  callback: (id: string) => openEditDepartmentSlidingPanel(id)
                },
                {
                  type: ActionButtonTypes.Delete,
                  callback: (id: string) => deleteDepartmentModal(id)
                }] : [])
            ]
          }
          selected={selectedLteDepartment}
          loadState={loaderDepartment}
          onClick={(id: string) => onClickDepartment(id)}
        >
          <div className={selectedLteBranch ? "lp-list-grid-parent-selected" : ""}>
            {
              selectedLteBranch ? (lteBranches?.find((branch: LteBranchModel) => {return branch.id === selectedLteBranch})?.name ?? "Departments") : "Departments"
            }
          </div>
        </List>
      </Col>
      <Col xxl={4} className="mt-4 mt-xxl-0">
        <Title type="section" title={"Teams"}>
          {props.edit &&
            <Button onClick={openCreateLteTeamSlidingPanel} className="btn-icon" variant="success">
              <MdAdd />
            </Button>
          }
        </Title>
        <List
          listItems={lteTeams ?? []}
          listButtons={
            [
              ...(
                props.edit ?
                [{
                  type: ActionButtonTypes.Edit,
                  callback: (id: string) => openEditTeamSlidingPanel(id)
                },
                {
                  type: ActionButtonTypes.Delete,
                  callback: (id: string) => deleteTeamModal(id)
                }] : [])
            ]
          }
          selected={selectedLteTeam}
          loadState={loaderTeam}
          onClick={(id: string) => onClickTeam(id)}
        >
          <div className={selectedLteDepartment ? "lp-list-grid-parent-selected" : ""}>
            {selectedLteDepartment? (lteDepartments?.find((department: LteDepartmentModel) => {return department.id === selectedLteDepartment})?.name ?? "Teams") : "Teams"}
          </div>
        </List>
      </Col>
    </>
  );
}
