import { deleteAllRecordableEventTypeToChargingRate, getAllRecordableEventTypeToChargingRate } from "actions/chargingSchemes";
import { RecordableEventTypeToChargingRateType } from "models/view/RecordableEventTypeToChargingRateModel";
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import Title from "components/Title";
import { MdAdd, MdDelete } from "react-icons/md";
import CreateRecordableEventTypeToChargingRate from "./CreateRecordableType/CreateRecordableEventTypeToChargingRate";
import Loader from "components/Loader/index";
import CustomAccordionItem from "components/CustomAccordionItem/CustomAccordionItem";
import ChargingRatesListFromRecordable from "./ChargingRatesList/ChargingRatesListFromRecordable";
import { ModalState } from "state/modalSlice";

type Props = {
  id: string
}

export default function ConfigureRecordableTypes(props: Props) {
  const [recordableEventTypesToChargingRates, setRecordableEventTypesToChargingRates] = useState<RecordableEventTypeToChargingRateType[]>();
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();

  const openCreateRecordableEventTypeToChargingRate = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Create Recordable Event Type',
      children: <CreateRecordableEventTypeToChargingRate
        chargingSchemeId={props.id}
        setRecordableEventTypesToChargingRates={setRecordableEventTypesToChargingRates}
      />
    });
  };

  const deleteModal = (chargingSchemeId: string, recordableEventTypeId: string) => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete all links to recordable event type?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteAndRemoveFromList(chargingSchemeId, recordableEventTypeId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const deleteAndRemoveFromList = (chargingSchemeId: string, recordableEventTypeId: string) => {
    modalActions.toggleModalLoading();
    deleteAllRecordableEventTypeToChargingRate(chargingSchemeId, recordableEventTypeId).then(() => {
      setRecordableEventTypesToChargingRates(
        recordableEventTypesToChargingRates?.filter(x => x.recordableEventType.id != recordableEventTypeId)
      );
      modalActions.toggleModalShownStatus();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  }

  useEffect(() => {
    getAllRecordableEventTypeToChargingRate(props.id).then((response) => {
      setRecordableEventTypesToChargingRates(response.data);
    }).catch((err) => {
      setGenericErrors(err);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <Title
        type="section"
        title="Recordable Event Types"
      >
        <Button onClick={openCreateRecordableEventTypeToChargingRate} className="btn-icon" variant="success">
          <MdAdd />
        </Button>
      </Title>
      <div className="lp-linked-entity-content">
        {genericErrors && (
          <div className="lp-errors">
            {genericErrors}
          </div>
        )}

        {isLoading &&
          <Loader inlineLoader />
        }

        {!isLoading && recordableEventTypesToChargingRates?.length == 0 &&
          <div className="lp-list-empty">No recordable event types found</div>
        }

        <Accordion>
          {recordableEventTypesToChargingRates?.map((el: RecordableEventTypeToChargingRateType, index: number) =>
            <React.Fragment key={index}>
              <CustomAccordionItem
                header={
                  <div className="title">
                    <span className="name">{el.recordableEventType.name}</span>
                    <Button onClick={() => deleteModal(el.chargingScheme.id, el.recordableEventType.id)} className="btn-icon" variant="danger">
                      <MdDelete />
                    </Button>
                  </div>
                }
                eventKey={el.recordableEventType.id}
              >
                <>
                <Row>
                  <Col>
                    <ChargingRatesListFromRecordable
                      chargingSchemeId={props.id}
                      recordableEventTypesToChargingRates={recordableEventTypesToChargingRates}
                      setRecordableEventTypesToChargingRates={setRecordableEventTypesToChargingRates}
                      recordableEventTypeToChargingRate={el}
                      isLoading={isLoading}
                    />
                  </Col>
                </Row>
                </>
              </CustomAccordionItem>
            </React.Fragment>
          )}
        </Accordion>
      </div>
    </>
  );
}