import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import Loader from "components/Loader";
import { updateLteStorageSettings } from "actions/lte";
import { removeEmptyFields } from "utils/form";
import { getValidationSuite } from "./validationSuite";
import { UpdateLteStorageSettingsModel } from "models/update/UpdateLteStorageSettingsModel";
import { LteStorageSettingsModel } from "models/view/LteStorageSettingsModel";

type Props = {
  lteId: string,
  lteStorageSettings: LteStorageSettingsModel | undefined,
  submitCallbackFn: Function,
  cancelCallbackFn: Function,
  isLoading: boolean
}

export default function EditLteStorageSettingsSummary(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(props.isLoading);

  const { register, reset, handleSubmit, formState: { errors } } = useForm<UpdateLteStorageSettingsModel>({
    resolver: vestResolver(getValidationSuite())
  });

  useEffect(() => {
    if (props.lteStorageSettings) {
      let initialFields: UpdateLteStorageSettingsModel = {
        storageTenantId: props.lteStorageSettings.storageTenantId ?? '',
        storageSiteId: props.lteStorageSettings.storageSiteId ?? '',
        storageBaseFolderPath: props.lteStorageSettings.storageBaseFolderPath
      };
      reset(initialFields);
    }
  }, [props.lteStorageSettings]);

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: UpdateLteStorageSettingsModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    updateLteStorageSettings(props.lteId, data).then((response) => {
      if(props.submitCallbackFn){
        props.submitCallbackFn(response.data);
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const onCancelClick = () => {
    if(props.cancelCallbackFn){
      props.cancelCallbackFn();
    }
  };

  return (
    <Card>
      {isLoading && <Loader inlineLoader />}

      <Card.Body>
        {genericErrors && (
          <div className="lp-errors">
            {genericErrors}
          </div>
        )}

        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-4" controlId="storageTenantId">
            <Form.Label className="required">Storage Tenant ID</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.storageTenantId?.message ? 'invalid' : ''}`}
              {...register("storageTenantId", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.storageTenantId?.message && (errors.storageTenantId.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-4" controlId="storageSiteId">
            <Form.Label className="required">Storage Site ID</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.storageSiteId?.message ? 'invalid' : ''}`}
              {...register("storageSiteId", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.storageSiteId?.message && (errors.storageSiteId.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-4" controlId="storageBaseFolderPath">
            <Form.Label>Storage Base Folder Path</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.storageBaseFolderPath?.message ? 'invalid' : ''}`}
              {...register("storageBaseFolderPath", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.storageBaseFolderPath?.message && (errors.storageBaseFolderPath.message)}
            </Form.Text>
          </Form.Group>

          <Row>
            <Form.Group className="d-flex justify-content-between">
              <Button variant="success" type="submit">Update</Button>
              <Button variant="secondary-400" onClick={onCancelClick}>Cancel</Button>
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
