import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ColDef, ColGroupDef } from "ag-grid-community"

export type GridState = {
  columnDefs: (ColDef<any>| {excludeFromExport: boolean})[],
  rowData: any[]
  id: string | undefined,
  advancedFilter: any | undefined,
  advancedFilterInfo: React.ReactNode | undefined,
  advancedFilterClearCallback: Function | undefined
}

const initialState: GridState = {
  columnDefs: undefined as any,
  rowData: undefined as any,
  id: undefined,
  advancedFilter: undefined as any,
  advancedFilterInfo: undefined,
  advancedFilterClearCallback: undefined
}

export const gridSlice = createSlice({
  name: 'grid',
  initialState,
  reducers: {
    setColumnDefs: (state, action: PayloadAction<(ColDef<any>| {excludeFromExport: boolean})[]>) => {
      state.columnDefs = action.payload;
    },
    setRowData: (state, action: PayloadAction<any[]>) => {
      state.rowData = action.payload;
    },
    setAdvancedFilter: (state, action: PayloadAction<any>) => {
      state.advancedFilter = action.payload;
    },
    setAdvancedFilterInfo: (state, action: PayloadAction<React.ReactNode>) => {
      state.advancedFilterInfo = action.payload;
    },
    setAdvancedFilterClearCallback: (state, action: PayloadAction<Function>) => {
      state.advancedFilterClearCallback = action.payload;
    },
    setId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    resetId: (state) => {
      state.id = undefined;
    },
    resetAdvancedFilter: (state) => {
      state.advancedFilter = undefined;
    },
    resetAdvancedFilterInfo: (state) => {
      state.advancedFilterInfo = undefined;
    },
    resetAdvancedFilterClearCallback: (state) => {
      state.advancedFilterClearCallback = undefined;
    },
    resetGrid: (state) => {
      state.id = undefined;
      state.advancedFilter = undefined;
      state.advancedFilterInfo = undefined;
      state.advancedFilterClearCallback = undefined;
    },
  },
})

export const {
  setColumnDefs,
  setRowData,
  setAdvancedFilter,
  setAdvancedFilterInfo,
  setAdvancedFilterClearCallback,
  setId,
  resetId,
  resetAdvancedFilter,
  resetAdvancedFilterInfo,
  resetAdvancedFilterClearCallback,
  resetGrid
} = gridSlice.actions;

export default gridSlice.reducer;
