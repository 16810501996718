import { ICellRendererParams } from "ag-grid-community";
import TooltipIcon from 'components/TooltipIcon';

export default function MatterIncidentalExpenseNameCellRenderer(props: ICellRendererParams) {

  return (
    <>
      {props.data.hasNoFiles && 
        <TooltipIcon type="error" text="No proofs of purchase" iconPosition="left" />
      }
      {!props.data.matterParticipatingEntityId && 
        <TooltipIcon type="error" text="No client" iconPosition="left" />
      }
      <span>{props.value}</span>
    </>
  );
}
