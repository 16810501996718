import { BsArrowRight, BsArrowUpRight, BsArrowDownRight } from 'react-icons/bs';
import { RiskRating } from "enums/RiskRating";

type Props = {
  value: string
}

export default function RiskRatingFieldValue(props: Props) {

  const checkRiskRating = () =>{
    switch(props.value)
    {
      case RiskRating.Low:
        return(
          <BsArrowDownRight fill="var(--lp-success)" />
        );
      case RiskRating.Medium:
        return(
          <BsArrowRight fill="var(--lp-warning)" />
        );
      case RiskRating.High:
        return(
          <BsArrowUpRight fill="var(--lp-danger)" />
        );
    }
  }

  return (
    <>
      {checkRiskRating()}
    </>
  );
}
