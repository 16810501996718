export enum MatterActivityHistoryTypes {
  RecordableEvent = 0,
  Note = 1,
  File = 2,
  Mail = 3,
  Sms = 4,
  WhatsApp = 5,
  Appointment = 6,
  Disbursement = 7,
  IncidentalExpense = 8,
  Invoice = 9
}