import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { useParams } from "react-router-dom";
import { CreateOrUpdateLteBranchModel } from "models/create/CreateOrUpdateLteBranchModel";
import { Form, Button } from "react-bootstrap";
import { getLteAddresses, getLteBranch, getLtePhoneNumbers, updateLteBranch } from "actions/lte";
import useSlidingPanelActions from "actions/slidingPanel";
import { validationSuite } from "./validationSuite";
import { useAppSelector } from "hooks/appSelector";
import CustomSelect from "components/Select/Select";
import { formatAddress } from "utils/address";
import { LteAddressModel } from "models/view/LteAddressModel";
import Loader from "components/Loader";
import { LtePhoneNumberModel } from "models/view/LtePhoneNumberModel";

type Props = {
  branchId: string,
  submitCallback?: Function
}

export default function EditLteBranchForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const [lteAddresses, setLteAddresses] = useState<LteAddressModel[]>();
  const [ltePhoneNumbers, setLtePhoneNumbers] = useState<LtePhoneNumberModel[]>();

  const { id: lteId } = useParams();
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    setIsLoading(true);
    const promises: Promise<any>[] = [];
    promises.push(getLteBranch(props.branchId));
    promises.push(getLteAddresses(lteId ?? user.lawPageTradingEntityId!));
    promises.push(getLtePhoneNumbers(lteId ?? user.lawPageTradingEntityId!))
    Promise.all(promises).then(
      ([lteBranchResponse, lteAddressResponse, ltePhonenumbersResponse]) => {
        reset(lteBranchResponse.data);
        setLteAddresses(lteAddressResponse.data.map(
          (x: LteAddressModel) => {return {...x, id: x.addressId}}
        ));
        setLtePhoneNumbers(ltePhonenumbersResponse.data.map(
          (x: LtePhoneNumberModel) => {return {...x, id: x.phoneNumberId}}
        ));
      }
    ).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const { register, reset, control, handleSubmit, formState: { errors } } = useForm<CreateOrUpdateLteBranchModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateLteBranchModel) {
    setIsLoading(true);
    data.lteId = lteId ?? user.lawPageTradingEntityId!;
    updateLteBranch(props.branchId, data).then(() => {
      slidingPanelActions.clearSlidingPanel();
      if (props.submitCallback) {
        props.submitCallback();
      }
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-4" controlId="name">
          <Form.Label className="required">Name</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.name?.message ? 'invalid' : ''}`}
            {...register("name", {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.name?.message && (errors.name.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="addressId">
          <Form.Label>Address</Form.Label>
          <Controller
            control={control}
            name="addressId"
            shouldUnregister={true}
            render={({ field: { onChange, value, ref } }) => (
              <CustomSelect
                id="addressId"
                options={lteAddresses}
                getOptionLabel={(x: LteAddressModel) => formatAddress(x.address)}
                value={value}
                onChange={(val: LteAddressModel) => { onChange(val?.addressId ?? null) }}
                isClearable
              />
            )}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="phoneNumberId">
          <Form.Label>Phone Number</Form.Label>
          <Controller
            control={control}
            name="phoneNumberId"
            shouldUnregister={true}
            render={({field: { onChange, value, ref }}) => (
              <CustomSelect
                id="phoneNumberId"
                getOptionLabel={(x: LtePhoneNumberModel) => x?.phoneNumber?.number + (x?.phoneNumber?.extension ? ' (Extension: ' + x?.phoneNumber?.extension + ')': '')}
                options={ltePhoneNumbers}
                value={value}
                onChange={val => {onChange(val?.phoneNumberId ?? null)}}
                isClearable
              />
            )}
          />
        </Form.Group>

        <Form.Group className="d-flex justify-content-between">
          <Button variant="success" type="submit">Update</Button>
          <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
        </Form.Group>
      </Form>
    </>
  );
}
