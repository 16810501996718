export enum SummaryBoxTypes {
  EstimatedCost,
  FeeIncomeDoughnut,
  FeeIncomeDoughnutForFinancialYear,
  BilledFeeIncome,
  UnBilledFeeIncome,
  TimeDoughnut,
  TimeDoughnutForFinancialYear,
  BilledTime,
  UnbilledTime,
  IncidentalExpensesDoughnut,
  BilledIncidentalExpenses,
  UnbilledIncidentalExpenses,
  DisbursementsDoughnut,
  BilledDisbursements,
  UnbilledDisbursements,
  UnpaidDisbursements,
  TotalFiles,
  Profit,
  UnpaidInvoices,
  UnpaidInvoicesCount,
  InvoicesDoughnut,
  InvoiceParticipatingEntityDoughnut,
  BalanceOnBusinessSide,
  BalanceOnClientSide,
  Balance,
  OpenMatters,
  FeeIncomeTargetForCurrentWeek
}
