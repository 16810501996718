import { enforce, test, create } from "vest";
import isEmail from "validator/lib/isEmail";

export const validationSuite = create((data = {}) => {
  enforce.extend({isEmail});

  test('role', 'Please select a Role', () => {
    enforce(data.role).isNotEmpty();
  });

  test('email', 'Email name is required', () => {
    enforce(data.email).isNotEmpty();
    enforce(data.email).isString();
  });

  test('email', 'Email doesn\'t have the correct format.', () => {
    enforce(data.email).isEmail();
  });
});