import { useEffect, useState } from "react";
import { getMatterRecordableItem } from "actions/matter";
import { Col, Row } from "react-bootstrap";
import Field from "components/Fields/Field";
import Loader from "components/Loader/index";
import { DateFormat } from "utils/constants";
import moment from "moment";
import { MatterRecordableItemModel } from "models/view/MatterRecordableItemModel";
import { formatCurrency } from "utils/misc";

type Props = {
  matterId: string,
  recordableEventId: string,
  recoradbleItemId: string
}

export default function ViewMatterRecordableItem(props: Props) {
  const [matterRecordableItem, setMatterRecordableItem] = useState<MatterRecordableItemModel | undefined>(undefined);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getMatterRecordableItem(props.matterId, props.recordableEventId, props.recoradbleItemId).then((response) => {
      setMatterRecordableItem(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Date"}
            value={matterRecordableItem?.date ? moment(matterRecordableItem?.date).format(DateFormat.Moment) : "—"}
          />
        </Col>
        <Col>
          <Field
            label={"Recordable Event"}
            value={matterRecordableItem?.recordableEventDisplayName}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Fee Earner"}
            value={matterRecordableItem?.user?.name}
          />
        </Col>
        <Col>
          <Field
            label={"Fee Earner Level"}
            value={matterRecordableItem?.feeEarnerLevel?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Activity"}
            value={matterRecordableItem?.activity?.name}
          />
        </Col>
        <Col>
          <Field
            label={"Activity Code"}
            value={matterRecordableItem?.activity?.code}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Description"}
            value={matterRecordableItem?.description}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Start Time"}
            value={matterRecordableItem?.startTime ? moment(matterRecordableItem?.startTime, "HH:mm:ss").format(DateFormat.MomentTime) : "—"}
          />
        </Col>
        <Col>
          <Field
            label={"End Time"}
            value={matterRecordableItem?.endTime ? moment(matterRecordableItem?.endTime, "HH:mm:ss").format(DateFormat.MomentTime) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Number of Minutes"}
            value={matterRecordableItem?.numberOfMinutes}
          />
        </Col>
        <Col>
          <Field
            label={"Number of Units"}
            value={matterRecordableItem?.numberOfUnits}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Charging Scheme"}
            value={matterRecordableItem?.chargingScheme?.name}
          />
        </Col>
        <Col>
          <Field
            label={"Charge Rate"}
            value={formatCurrency(matterRecordableItem?.chargeRate)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Amount Charged"}
            value={formatCurrency(matterRecordableItem?.amountCharged)}
          />
        </Col>
        <Col>
          <Field
            label={"Invoice Ref Number"}
            value={matterRecordableItem?.matterInvoice?.invoiceRefNumber}
          />
        </Col>
      </Row>
      {matterRecordableItem?.matterInvoiceId &&
        <Row>
          <Col>
            <Field
              label={"Amount Invoiced"}
              value={formatCurrency(matterRecordableItem?.adjustedAmountChargedOnInvoice)}
            />
          </Col>
          <Col>
            <Field
              label={"Calculated VAT Value"}
              value={formatCurrency(matterRecordableItem?.calculatedVATValueOnInvoice)}
            />
          </Col>
        </Row>
      }
      <Row>
        <Col>
          <Field
            label={"Client"}
            value={matterRecordableItem?.matterParticipatingEntity?.name}
          />
        </Col>
      </Row>
    </>
  );
}
