import { ColDef } from "ag-grid-community";
import { EntityModel } from "models/view/EntityModel";
import store from "state/store";
import { deleteEntity, getEntityTypes } from "actions/entity";
import { NavigateFunction } from "react-router-dom";
import { setRowData } from "state/gridSlice";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { getEntityRolesSummary } from "actions/settings";

const viewCallback = (id: string, navigate: NavigateFunction) => {
  navigate(`/addressBook/${id}`);
};

const editCallback = (id: string, navigate: NavigateFunction) => {
  navigate(`/addressBook/${id}`, {state: {"edit": true}});
};

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this entity?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteEntity(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const columnDefs: (ColDef<EntityModel>| {excludeFromExport: boolean})[] = [
  { 
    excludeFromExport: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    suppressMenu: true,
    suppressSizeToFit: true,
    floatingFilter: false,
    resizable: false,
    sortable: false,
    minWidth: 48,
    maxWidth: 48,
    width: 48,
    lockPosition: true,
    pinned: 'left'
  },
  {
    headerName: 'Display Name',
    field: 'displayName',
    filter: 'agTextColumnFilter',
    pinned: 'left',
    lockPosition: true
  },
  {
    headerName: 'Type',
    field: 'entityType.name',
    filter: DropdownFilter,
    filterParams: {
      property: 'entityTypeId',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: DropdownFloatingFilter,
    floatingFilterComponentParams: {
      endpointCall: getEntityTypes
    },
    minWidth: 220
  },
  {
    headerName: 'Default Role',
    field: 'defaultRole.name',
    filter: DropdownFilter,
    filterParams: {
      property: 'defaultRoleId',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: DropdownFloatingFilter,
    floatingFilterComponentParams: {
      endpointCall: getEntityRolesSummary
    },
    minWidth: 220,
    flex: 1
  },
  {
    headerName: 'Actions',
    cellClass: 'lp-actions-cell',
    cellRenderer: ActionsCellRenderer,
    cellRendererParams: {
      buttonsData: [
        {
          type: ActionButtonTypes.View,
          callback: (id: string, navigate: NavigateFunction) => viewCallback(id, navigate)
        },
        {
          type: ActionButtonTypes.Edit,
          callback: (id: string, navigate: NavigateFunction) => editCallback(id, navigate)
        },
        {
          type: ActionButtonTypes.Delete,
          callback: (id: string) => deleteModal(id)
        },
      ]
    },
    excludeFromExport: true,
    suppressSizeToFit: true,
    floatingFilter: false,
    sortable: false,
    minWidth: 145,
    maxWidth: 145,
    width: 145,
    lockPosition: true,
    pinned: 'right',
    resizable: false
  }
];
