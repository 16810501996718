export enum EntityTypeIds {
  HumanBeingId = '2072578b-10e4-4b03-a3ee-97ffdf24f0c0',
  LimitedCompanyId = 'fc5ac395-27e7-4735-900a-846b7790f864',
  PublicLimitedCompanyId = '744711d6-69cd-4e4a-989e-996382f7cf86',
  PartnershipAtWillId = '214790f8-8762-47b9-b15a-d815f2e905b0',
  LimitedLiabilityPartnershipId = '1c4ea6b9-4acf-4188-9c81-4fce7d5e745e',
  RegisteredCharityId = 'b2df91a2-85e1-40de-93b3-e321fd491b3c',
  CourtTribunalId = 'd6c5edaf-cda0-42f2-9e7a-2ce28fad0666',
  NonCPSProsecutingAuthorityId = '551769e8-ff66-4565-a5b3-0d07a3160cdb',
  HMPrisonServiceId = '7b230828-3585-4d02-bdcc-a40ee35d8cc2',
  PoliceStationId = '4839308d-fde3-4014-b90c-ebb155a386a3',
  CrownProsecutionServiceId = 'b37ec64f-0bf0-4a11-b974-28ad5348a5aa',
  NotSpecifiedId = 'b272f353-9044-44ce-bb62-6ce7bb843948'
}