import { ColDef } from "ag-grid-community";
import store from "state/store";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { CalendarEventModel } from "models/CalendarEventModel";
import { DateTimeFormatterForMatterActivityHistory } from "components/Grid/ValueFormatters/DateTimeFormatterForMatterActivityHistory";
import { dateComparator } from "components/Grid/Comparators/DateComparator";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { getMatterDashboardEvent } from "actions/matter";
import ViewCalendarEvent from "containers/Calendar/ViewCalendarEvent/ViewCalendarEvent";

const viewCallback = (matterId: string, graphId: string) => {
  getMatterDashboardEvent(matterId, graphId).then((response) => {
    store.dispatch(
      setSlidingPanelData(
        {
          isShown: true,
          width: "55rem",
          title: "View Appointment",
          children: <ViewCalendarEvent
            eventData={response.data}
          />
        }
      )
    );
  })
};

export const getColumnDefs = (matterId: string) => {
  const columnDefs: (ColDef<CalendarEventModel>| {excludeFromExport: boolean})[] = [
    {
      excludeFromExport: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      suppressMenu: true,
      suppressSizeToFit: true,
      floatingFilter: false,
      resizable: false,
      sortable: false,
      minWidth: 48,
      maxWidth: 48,
      width: 48,
      lockPosition: true,
      pinned: 'left'
    },
    { 
      headerName: 'Start Date',
      field: 'startDate',
      filter: 'agDateColumnFilter',
      suppressMenu: true,
      valueFormatter: DateTimeFormatterForMatterActivityHistory,
      filterParams: {
        comparator: dateComparator
      },
      lockPosition: true,
      pinned: 'left'
    },
    { 
      headerName: 'End Date',
      field: 'endDate',
      filter: 'agDateColumnFilter',
      suppressMenu: true,
      valueFormatter: DateTimeFormatterForMatterActivityHistory,
      filterParams: {
        comparator: dateComparator
      },
      lockPosition: true,
      pinned: 'left'
    },
    {
      headerName: 'Title',
      field: 'title',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Location',
      field: 'location',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Required Attendees',
      field: 'requiredAttendees',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Optional Attendees',
      field: 'optionalAttendees',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Rooms',
      field: 'rooms',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Actions',
      cellClass: 'lp-actions-cell',
      cellRendererSelector: (params: any) => {
        return {
          component: ActionsCellRenderer,
          params: {
            buttonsData: [{
              type: ActionButtonTypes.View,
              callback: () => viewCallback(matterId, params.data.graphId)
            },
            ]
          },
        }
      },
      excludeFromExport: true,
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 145,
      maxWidth: 145,
      width: 145,
      lockPosition: true,
      pinned: 'right',
      resizable: false
    },
  ];
  return columnDefs;
}
