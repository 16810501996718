import Title from "components/Title";
import { TabPanel } from "react-tabs";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import CustomTabs from "components/CustomTabs";
import TaxonomyList from "./Taxonomies/TaxonomyList";
import TaxonomyTermList from "./TaxonomyTerms/TaxonomyTermList";

export default function TaxonomySettings() {
  const loggedInUser = useAppSelector((state) => state.user);

  return (
    <div className="lp-page-content">
      <Title type="page" title="Taxonomy Settings"></Title>

      <CustomTabs 
        tabList={[
          ...(loggedInUser.userPermissions?.find(a => a.toLowerCase().trim() == UserPermissionsNames.ManageLTESettings.toLowerCase().trim()) ? [{
            name: 'Taxonomies',
            panel: <TabPanel>
              <TaxonomyList />
            </TabPanel>
          }] : []),
          ...(loggedInUser.userPermissions?.find(a => a.toLowerCase().trim() == UserPermissionsNames.ManageLTESettings.toLowerCase().trim()) ? [{
            name: 'Taxonomy Terms',
            panel: <TabPanel>
              <TaxonomyTermList />
            </TabPanel>
          }] : [])
        ]}
      />
    </div>
  );
}
