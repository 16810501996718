import { create, test, enforce, omitWhen } from "vest";
import isDate from "validator/lib/isDate";
import { FundsTransferTypeIds } from "enums/FundsTransferTypeIds";

export const validationSuite = create((data = {}) => {
  enforce.extend({ isDate });

  test('matterParticipatingEntityId', 'Please select a Client', () => {
    enforce(data.matterParticipatingEntityId).isNotEmpty();
    enforce(data.matterParticipatingEntityId).isString();
  });

  test('date', 'Please select a Date', () => {
    enforce(data.date).isDate({ strictMode: false });
  });

  test('transferTypeId', 'Please select a Transfer Type', () => {
    enforce(data.transferTypeId).isNotEmpty();
    enforce(data.transferTypeId).isString();
  });

  test('amount', 'Amount is required', () => {
    enforce(data.amount).isNotEmpty();
  });

  test('amount', 'Amount needs to be a number > 0', () => {
    enforce(data.amount).greaterThan(0);
  });

  test('description', 'Description is required', () => {
    enforce(data.description).isNotEmpty();
    enforce(data.description).isString();
  });

  omitWhen(data.transferTypeId != FundsTransferTypeIds.InterClientId, () => {
    test('transferToMatterId', 'Please select a Tranfer To Matter', () => {
      enforce(data.transferToMatterId).isNotEmpty();
      enforce(data.transferToMatterId).isString();
    });

    test('transferToMatterParticipatingEntityId', 'Please select a Tranfer To Client', () => {
      enforce(data.transferToMatterParticipatingEntityId).isNotEmpty();
      enforce(data.transferToMatterParticipatingEntityId).isString();
    });
  });
});