import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Row, Col, Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { getMatterActivityHistory, getMatterEmailById, updateMatterEmailDetails } from "actions/matter";
import moment from "moment";
import Field from "components/Fields/Field";
import BooleanField from "components/Fields/BooleanField";
import { DateFormat } from "utils/constants";
import { formatEmailSender } from "utils/misc";
import store from "state/store";
import { GridIds } from "enums/GridIds";
import useGridActions from "actions/grid";
import { UpdateMatterEmailDetailsModel } from "models/update/UpdateMatterEmailDetailsModel";
import { MatterEmailModel } from "models/view/MatterEmailModel";
import { getEmailTypeName } from "utils/enums";

type Props = {
  matterId: string,
  emailId: string
}

export default function EditMatterEmailDetailsForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const [matterEmail, setMatterEmail] = useState<MatterEmailModel>();
  const gridActions = useGridActions();

  const {reset, register, handleSubmit, formState: {errors}} = useForm<UpdateMatterEmailDetailsModel>({
    resolver: vestResolver(validationSuite)
  });

  useEffect(() => {
    setIsLoading(true);
    getMatterEmailById(props.matterId, props.emailId).then((response) => {
      setMatterEmail(response.data);
      let initialState: UpdateMatterEmailDetailsModel = {
        description: response.data.description
      };

      reset(initialState);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
      
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: UpdateMatterEmailDetailsModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    updateMatterEmailDetails(props.matterId, props.emailId, data)
      .then((response) => {
        const gridState = store.getState().grid;
        if (gridState.id == `${GridIds.ActivityHistory}/${props.matterId}`) {
          getMatterActivityHistory(props.matterId).then((response) => {
            gridActions.setGridRowData(response.data);
          });
        }

        slidingPanelActions.clearSlidingPanel();
        reset();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-4" controlId="description">
          <Form.Label className="required">Description</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.description?.message ? 'invalid' : ''}`}
            {...register(`description`, {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.description?.message && (errors.description?.message)}
          </Form.Text>
        </Form.Group>

        <Row>
          <Col>
            <Field label={'Subject'} value={matterEmail?.subject} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={'Sender'}
              value={matterEmail?.senderEmailAddress}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field label={'Type'} value={getEmailTypeName(matterEmail?.type!)} />
          </Col>
        </Row>
        {matterEmail?.type == 0 && (
          <Row>
            <Col>
              <Field
                label={'Sent Date Time'}
                value={
                  matterEmail?.sentDateTime
                    ? moment(matterEmail?.sentDateTime).format(
                        DateFormat.MomentWithTime
                      )
                    : '—'
                }
              />
            </Col>
          </Row>
        )}
        {matterEmail?.type != 0 && (
          <Row>
            <Col>
              <Field
                label={'Received Date Time'}
                value={
                  matterEmail?.receivedDateTime
                    ? moment(matterEmail?.receivedDateTime).format(
                        DateFormat.MomentWithTime
                      )
                    : '—'
                }
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Field
              label={'To'}
              value={
                matterEmail?.toRecipients && matterEmail?.toRecipients.length > 0
                  ? matterEmail?.toRecipients.join(', ')
                  : '—'
              }
            />
            {matterEmail?.ccRecipients &&
              matterEmail?.ccRecipients.length > 0 && (
                <Field
                  label={'Cc'}
                  value={matterEmail?.ccRecipients.join(', ')}
                />
              )}
            {matterEmail?.bccRecipients &&
              matterEmail?.bccRecipients.length > 0 && (
                <Field
                  label={'Bcc'}
                  value={matterEmail?.bccRecipients.join(', ')}
                />
              )}
          </Col>
        </Row>
        <Row>
          <Col>
            <BooleanField
              label={'Has Attachments'}
              value={matterEmail?.hasAttachments ?? false}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <BooleanField
              label={'Internal'}
              value={matterEmail?.isInternal ?? false}
            />
          </Col>
        </Row>

        <Form.Group className="d-flex justify-content-between">
          <Button variant="success" type="submit">Update</Button>
          <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
        </Form.Group>
      </Form>
    </>
  );
}
