import { ColDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { ChartOfAccountsBalanceSummaryModel } from "models/view/ChartOfAccountsBalanceSummaryModel";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { NavigateFunction } from "react-router-dom";
import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";

const viewCallback = (rowData: ChartOfAccountsBalanceSummaryModel, navigate: NavigateFunction) => {
  navigate(`/chartOfAccounts/${rowData.accountId}`);
};

export const columnDefs: (ColDef<ChartOfAccountsBalanceSummaryModel> | {excludeFromExport: boolean})[] = [
  { 
    excludeFromExport: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    suppressMenu: true,
    suppressSizeToFit: true,
    floatingFilter: false,
    resizable: false,
    sortable: false,
    minWidth: 48,
    maxWidth: 48,
    width: 48,
    lockPosition: true,
    pinned: 'left'
  },
  {
    headerName: 'Account Name',
    field: 'accountName',
    filter: 'agTextColumnFilter',
    pinned: 'left',
    lockPosition: true,
    flex: 1,
  },
  {
    headerName: 'DR',
    field: 'drAmount',
    filter: 'agNumberColumnFilter',
    valueFormatter: CurrencyFormatter,
    suppressMenu: true
  },
  {
    headerName: 'CR',
    field: 'crAmount',
    filter: 'agNumberColumnFilter',
    valueFormatter: CurrencyFormatter,
    suppressMenu: true
  },
  {
    headerName: 'Balance',
    field: 'balance',
    filter: 'agNumberColumnFilter',
    valueFormatter: CurrencyFormatter,
    suppressMenu: true
  },
  {
    headerName: 'Actions',
    cellClass: 'lp-actions-cell',
    cellRenderer: ActionsCellRenderer,
    cellRendererParams: (params: any) => {
      return { buttonsData: [
        {
          type: ActionButtonTypes.View,
          callback: (id: string, navigate: NavigateFunction) => viewCallback(params.data, navigate)
        }
      ]};
    },
    excludeFromExport: true,
    suppressSizeToFit: true,
    floatingFilter: false,
    sortable: false,
    minWidth: 145,
    maxWidth: 145,
    width: 145,
    lockPosition: true,
    pinned: 'right',
    resizable: false
  }
];
