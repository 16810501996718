import FieldWithImage from "./FieldWithImage";

interface Props {
  label: string,
  value: boolean
}

export default function BooleanField(props: Props) {
  return (
    <FieldWithImage
      label={props.label}
    >
      <span className={`lp-chip boolean ${props.value ? 'success' : 'secondary'}`}>
        {props.value ? 'Yes' : 'No'}
      </span>
    </FieldWithImage>
  );
}
