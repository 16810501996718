import { useCallback } from "react";
import useGridActions from 'actions/grid';
import Grid from "components/Grid";
import { columnDefs } from "./columnDefs";
import { MdAddCircle } from 'react-icons/md';
import Title from 'components/Title';
import SlidingPanel from 'components/SlidingPanel';
import { Card, Button } from 'react-bootstrap';
import CustomModal from "components/Modal/Modal";
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from "hooks/appSelector";
import { getEntities } from "actions/entity";
import EntityCreateForm from "./CreateEntity/EntityCreateForm";
import './style.scss';

function EntityList() {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const slidingPanelState = useAppSelector((state) => state.slidingPanel);

  const onGridReady = useCallback(() =>
  {
    gridActions.setGridColumnDefs([...columnDefs]);
    getEntities().then((response) => 
    {
      gridActions.setGridRowData(response.data);
    })
  }, []);

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: !slidingPanelState.isShown,
      title: "Add Entity",
      children: <EntityCreateForm />
    });
  };

  return (
    <div className="lp-page-content">
      <Title
        type="page"
        title="Address Book"
      >
        <Button variant="success" onClick={toggleSlidingPanel}>
          <MdAddCircle />
          Add Entity
        </Button>
      </Title>

      <SlidingPanel />

      <Card className="with-grid">
        <Card.Body>
          <Grid
            onGridReady={onGridReady}
          />
        </Card.Body>
      </Card>

      <CustomModal />
    </div>
  );
}

export default EntityList;
