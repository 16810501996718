import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { LinkedPhoneNumberModel } from "models/view/LinkedPhoneNumberModel";
import { PhoneNumberModel } from "models/view/PhoneNumberModel";
import { get } from "utils/request";

const getPhoneNumberSuggestions = async (phoneNumber: string, lteId?: string, entityId?: string): Promise<any> => {
  let endpoint = `/management/phoneNumber/search?phoneNumber=${encodeURIComponent(phoneNumber)}`;
  if(lteId){
    endpoint += `&lteId=${lteId}`;
  }
  if(entityId){
    endpoint += `&entityId=${entityId}`;
  }
  const response = await get<PhoneNumberModel[]>(endpoint);
  return response;
}

const checkIfPhoneNumberIsLinked =
  async (phoneNumberId: string, lteId?: string, entityId?: string): Promise<any> => {
  let endpoint = `/management/phoneNumber/check?phoneNumberId=${phoneNumberId}`;
  if(lteId){
    endpoint += `&lteId=${lteId}`;
  }
  if(entityId){
    endpoint += `&entityId=${entityId}`;
  }
  const response = await get<LinkedPhoneNumberModel[]>(endpoint);
  return response;
}

const getPhoneTypes = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/phoneType/summary');
  return response;
}

export { 
  getPhoneNumberSuggestions,
  checkIfPhoneNumberIsLinked,
  getPhoneTypes
};
