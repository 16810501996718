import { forwardRef, useEffect, useState } from "react";
import { IFloatingFilterParams } from "ag-grid-community";
import TreeSelectForFilter from "components/TreeSelect";
import { CheckedStrategy } from "rc-tree-select/lib/utils/strategyUtil";
import TreeSelect from "rc-tree-select";

type Props = IFloatingFilterParams & {
  endpointCall: Function,
  showCheckedStrategy?: CheckedStrategy
}

export const DropdownFloatingFilter = forwardRef((props: Props, ref) => {
  const [currentValue, setCurrentValue] = useState<any>();
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    props.endpointCall().then((response: any) => {
      setData(response.data);
    });
  }, []);

  const onOptionChange = (input: any) => {
    if (input == undefined || input == null || input.count == 0) {
      // clear the filter
      props.parentFilterInstance((instance: any) => {
        instance.setSelectedValues(null);
        setCurrentValue(null);
      });
      return;
    }

    props.parentFilterInstance((instance: any) => {
      instance.setSelectedValues(input);
      setCurrentValue(input);
    });
  }

  return (
    <TreeSelectForFilter
      data={data}
      value={currentValue}
      onChange={onOptionChange}
      showCheckedStrategy={props.showCheckedStrategy ?? TreeSelect.SHOW_CHILD}
      treeCheckable={true}
    />
  );
});