import { ColDef } from "ag-grid-community";
import { MatterModel } from "models/view/MatterModel";
import RiskRatingCellRenderer from "components/Grid/RiskRatingCellRenderer";
import ConflictCellRenderer from "components/Grid/ConflictCellRenderer";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { NavigateFunction } from "react-router-dom";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import store from "state/store";
import { deleteMatter, getConflicts, getFundingAssuranceChecks, getMatterTypes, getMatterTypesWithoutMatterStages, getRiskRatings } from 'actions/matter';
import { setRowData } from "state/gridSlice";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { getChargingSchemes } from "actions/chargingSchemes";

const viewCallback = (id: string, navigate: NavigateFunction) => {
  navigate(`/matter/${id}`, {state: { "edit": false}});
};

const editCallback = (id: string, navigate: NavigateFunction) => {
  navigate(`/matter/${id}`, {state: {"edit": true}});
};

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Matter?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteMatter(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const columnDefs: (ColDef<MatterModel>| {excludeFromExport: boolean})[] = [
  { 
    excludeFromExport: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    suppressMenu: true,
    suppressSizeToFit: true,
    floatingFilter: false,
    resizable: false,
    sortable: false,
    minWidth: 48,
    maxWidth: 48,
    width: 48,
    lockPosition: true,
    pinned: 'left'
  },
  { 
    headerName: 'Matter Ref',
    field: 'fileNumberWithDisplayName',
    filter: 'agTextColumnFilter',
    lockPosition: true,
    pinned: 'left',
    suppressMenu: true,
    suppressSizeToFit: true,
    minWidth: 300,
    width: 300
  },
  { 
    headerName: 'Description',
    field: 'description',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    suppressSizeToFit: true,
    minWidth: 450,
    width: 450,
    flex: 1
  },
  { 
    headerName: 'Type',
    field: 'matterType.name',
    filter: DropdownFilter,
    filterParams: {
      property: 'matterTypeId',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: DropdownFloatingFilter,
    floatingFilterComponentParams: {
      endpointCall: getMatterTypesWithoutMatterStages
    },
    suppressMenu: true,
    minWidth: 220
  },
  { 
    headerName: 'Stage',
    field: 'matterStage.name',
    filter: DropdownFilter,
    filterParams: {
      property: 'matterStageId',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: DropdownFloatingFilter,
    floatingFilterComponentParams: {
      endpointCall: getMatterTypes
    },
    suppressMenu: true,
    minWidth: 220
  },
  { 
    headerName: 'Conflict',
    field: 'conflictStatus.name',
    cellRenderer: ConflictCellRenderer,
    filter: DropdownFilter,
    filterParams: {
      property: 'conflictStatusId',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: DropdownFloatingFilter,
    floatingFilterComponentParams: {
      endpointCall: getConflicts
    },
    suppressMenu: true,
    minWidth: 220
  },
  { 
    headerName: 'Funding Assurance Check',
    field: 'fundingAssuranceStatus.name',
    cellRenderer: ConflictCellRenderer,
    filter: DropdownFilter,
    filterParams: {
      property: 'fundingAssuranceStatusId',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: DropdownFloatingFilter,
    floatingFilterComponentParams: {
      endpointCall: getFundingAssuranceChecks
    }, 
    suppressMenu: true,
    minWidth: 220
  },
  { 
    headerName: 'Risk Rating',
    field: 'riskRating.name',
    cellRenderer: RiskRatingCellRenderer,
    filter: DropdownFilter,
    filterParams: {
      property: 'riskRatingId',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: DropdownFloatingFilter,
    floatingFilterComponentParams: {
      endpointCall: getRiskRatings
    },
    suppressMenu: true,
    minWidth: 220
  },
  { 
    headerName: 'Charging Scheme',
    field: 'chargingScheme.name', 
    filter: DropdownFilter,
    filterParams: {
      property: 'chargingSchemeId',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: DropdownFloatingFilter,
    floatingFilterComponentParams: {
      endpointCall: getChargingSchemes
    },
    suppressMenu: true,
    minWidth: 220
  },
  { 
    excludeFromExport: true,
    headerName: 'Actions',
    cellClass: 'lp-actions-cell',
    suppressMenu: true,
    cellRenderer: ActionsCellRenderer,
    cellRendererParams: (params: any) => {
      return { buttonsData: [{
        type: ActionButtonTypes.View,
        callback: (id: string, navigate: NavigateFunction) => viewCallback(id, navigate)
       },
       !params.data.isClosed &&
       {
        type: ActionButtonTypes.Edit,
        callback: (id: string, navigate: NavigateFunction) => editCallback(id, navigate)
       },
       {
        type: ActionButtonTypes.Delete,
        callback: (id: string) => deleteModal(id)
       },
      ]};
    },
    suppressSizeToFit: true,
    floatingFilter: false,
    sortable: false,
    minWidth: 145,
    maxWidth: 145,
    width: 145,
    lockPosition: true,
    pinned: 'right',
    resizable: false
  }
];
