import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { LteBankAccountModel } from "models/view/LteBankAccountModel";
import { getLteBankAccount } from "actions/lte";
import { Row, Col } from "react-bootstrap";
import BooleanField from "components/Fields/BooleanField";

type Props = {
  id: string
}

function ViewLteBankAccount(props: Props){
  const [loading, setLoading] = useState(true);
  const [genericErrors, setGenericErrors] = useState(null);
  const [lteBankAccount, setLteBankAccount] = useState<LteBankAccountModel>();

  useEffect(()=>{
    setLoading(true);
    getLteBankAccount(props.id).then((response) => {
      setLteBankAccount(response.data)
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setLoading(false);
    });
  },[])

  return(
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Bank Name"}
            value={lteBankAccount?.bankName ?? ""}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Bank Account Name"}
            value={lteBankAccount?.bankAccountName ?? ""}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Bank Sort Code"}
            value={lteBankAccount?.bankSortCode ?? ""}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Bank Account No."}
            value={lteBankAccount?.bankAccountNumber ?? ""}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Bank IBAN"}
            value={lteBankAccount?.bankIban ?? ""}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <BooleanField
            label={"Is Receivable Account"}
            value={lteBankAccount?.isReceivableAccount ?? false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <BooleanField
            label={"Is Client Account"}
            value={lteBankAccount?.isClientAccount ?? false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <BooleanField
            label={"Is Payable Account"}
            value={lteBankAccount?.isPayableAccount ?? false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Description"}
            value={lteBankAccount?.description ?? ""}
          />
        </Col>
      </Row>
    </>
  );
}

export default ViewLteBankAccount;
