import { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { DetailGridInfo, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { DEFAULT_DETAIL_COLUMN_DEFINITIONS } from 'components/Grid/DefaultDetailColumnDefinitions';
import { getMatterFileHistory } from 'actions/matter';
import { columnDefsFileHistoryDetail } from './columnDefsFileHistoryDetail';

type Props = {
  matterId: string,
  rendererParams: ICellRendererParams
}

export default function FileDetailCellRenderer(props: Props) {
  const rowId = props.rendererParams.node.id!;
  const [rowData, setRowData] = useState<any[]>();

  useEffect(() => {
    return () => {
      // the detail grid is automatically destroyed as it is a React component
      props.rendererParams.api.removeDetailGridInfo(rowId);
    };
  }, []);

  const onGridReady = (params: GridReadyEvent) => {
    const gridInfo: DetailGridInfo = {
      id: rowId,
      api: params.api,
      columnApi: params.columnApi,
    };

    props.rendererParams.api.addDetailGridInfo(rowId, gridInfo);

    getMatterFileHistory(props.matterId, props.rendererParams.data.matterFileId!).then((response) => {
      setRowData(response.data);
    });
  };

  return (
    <div className="ag-details-row">
      <AgGridReact
        data-id="detailGrid"
        className="ag-details-grid"
        columnDefs={columnDefsFileHistoryDetail()}
        defaultColDef={DEFAULT_DETAIL_COLUMN_DEFINITIONS}
        rowData={rowData}
        onGridReady={onGridReady}
        suppressRowClickSelection={true}
        suppressContextMenu={true}
        enableCellTextSelection={true}
      />
    </div>
  );
};
