import Loader from 'components/Loader';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, InputGroup, Row, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import useSlidingPanelActions from 'actions/slidingPanel';
import DatePicker from "react-datepicker";
import { DateFormat } from 'utils/constants';
import moment from 'moment';
import { addCalendarEvent, checkEventAttendees, getCalendarRecurrenceTypes, getLawpageRooms, getLteCalendarSettingsById, getMeetingTimes } from 'actions/lte';
import { CreateCalendarEventModel } from 'models/create/CreateCalendarEventModel';
import CustomSelect from 'components/Select/Select';
import { getCaseManager, getDiaryManager, getEventAttendeeSuggestions, getMatter, getMattersSummary } from 'actions/matter';
import CustomCreatableSelect from 'components/Select/CreatableSelect';
import { CalendarFreeBusyStatus } from 'enums/CalendarFreeBusyStatus';
import { getCalendarFreeBusyStatusDropdownValues, getCalendarReminderBeforeStatusDropdownValues, getDaysOfWeek, getOptionsForRepeatOn } from 'utils/misc';
import { getValidationSuite } from './validationSuite';
import { vestResolver } from '@hookform/resolvers/vest';
import { CreatableDropDownOptionModel } from 'models/view/CreatableDropDownOptionModel';
import ColoredOption from 'components/Select/ColoredOption';
import { CalendarRecurrencePatternTypes } from 'enums/CalendarRecurrencePatternTypes';
import { WeekIndexes } from 'enums/WeekIndexes';
import { useAppSelector } from 'hooks/appSelector';
import { FreeBusyAttendeeCheckModel } from 'models/create/FreeBusyAttendeeCheckModel';
import { FreeBusyAvailabilityViewModel } from 'models/view/FreeBusyAttendeeAvailabilityViewModel';
import { MeetingTimeSuggestedTimeModel } from 'models/view/MeetingTimeSuggestedTimeModel';
import { LteCalendarSettingsModel } from 'models/view/LteCalendarSettingsModel';
import Field from "components/Fields/Field";
import { DropDownOptionModel } from 'models/view/DropDownOptionModel';

type Props = {
  startDate: Date;
  endDate: Date;
  isAllDay: boolean
  reloadEvents?: Function;
  removeDummyEvent?: Function;
  changeDummyEvent?: Function;
  matterId?: string
};

export default function CreateCalendarEvent(props: Props) {
  const [loading, setIsLoading] = useState(false);
  const slidingPanelActions = useSlidingPanelActions();
  const [genericErrors, setGenericErrors] = useState<string | null>(null);
  const [matterFileNumber, setMatterFileNumber] = useState<string | undefined>(undefined);
  const [diaryManager, setDiaryManager] = useState<string>();
  const [eventAttendeeSuggestions, setEventAttendeeSuggestions] = useState<CreatableDropDownOptionModel[]>();
  const [optionalEventAttendeeSuggestions, setOptionalEventAttendeeSuggestions] = useState<CreatableDropDownOptionModel[]>();
  const [isNoEndEvent, setIsNoEndEvent] = useState<boolean>(true);
  const [lteCalendarSettings, setLteCalendarSettings] = useState<LteCalendarSettingsModel | undefined>(undefined);
  const [availabilityStatus, setAvailabilityStatus] = useState<boolean | undefined>(undefined);
  const [suggestedTimes, setSuggestedTimes] = useState<MeetingTimeSuggestedTimeModel[]>();
  const [calendarRecurrenceTypes, setCalendarRecurrenceTypes] = useState<DropDownOptionModel[]>();
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    const promises: Promise<any>[] = [];
    promises.push(getDiaryManager(user.lawPageTradingEntityId!));
    promises.push(getLteCalendarSettingsById(user.lawPageTradingEntityId!));
    promises.push(getCalendarRecurrenceTypes());
    if(props.matterId != undefined && props.matterId.trim() != "") {
      promises.push(getMatter(props.matterId, true))
    }
    Promise.all(promises).then(
      ([diaryManagerResponse, lteCalendarSettings, calendarRecurrenceTypeResponse, matterResponse]) => {
        setDiaryManager(diaryManagerResponse.data);
        setLteCalendarSettings(lteCalendarSettings.data);
        calendarRecurrenceTypeResponse.data.unshift({ id: CalendarRecurrencePatternTypes.NoRepeat, name: "Don\'t Repeat" });
        setCalendarRecurrenceTypes(calendarRecurrenceTypeResponse.data);
        if(props.matterId != undefined && props.matterId.trim() != "") {
          setMatterFileNumber(matterResponse.data?.fileNumber);
          onChangeMatter(props.matterId, diaryManagerResponse.data);
        }
      }
    ).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const {register, reset, control, watch, setValue, handleSubmit, formState: {errors}} = useForm<CreateCalendarEventModel>({
    resolver: vestResolver(getValidationSuite(isNoEndEvent)),
    defaultValues: {
      startDate: props.startDate,
      endDate: props.endDate,
      reminderMinutesBeforeStart: "15",
      showAs: CalendarFreeBusyStatus.Busy.toString(),
      requiredAttendees: [],
      optionalAttendees: [],
      rooms: [],
      calendarRecurrence: {
        recurrencePatternType: CalendarRecurrencePatternTypes.NoRepeat,
        daysOfWeek: [],
        interval: 1,
        index: WeekIndexes.DateIndex
      },
      isAllDay: props.isAllDay,
      matterId: props.matterId
    }
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateCalendarEventModel) {
    setIsLoading(true);
    const requestBody = { ...data };

    if(requestBody.calendarRecurrence?.recurrencePatternType == CalendarRecurrencePatternTypes.NoRepeat) {
      requestBody.calendarRecurrence = undefined;
    }

    if(requestBody.calendarRecurrence?.index == WeekIndexes.DateIndex) {
      requestBody.calendarRecurrence.index = undefined;
    }

    if(requestBody.reminderMinutesBeforeStart == "-1") {
      requestBody.reminderMinutesBeforeStart = undefined;
    }

    addCalendarEvent(requestBody).then((response) => {
      //open event in outlook in new tab
      //window.open(response.data, '_blank', 'noreferrer');

      slidingPanelActions.clearSlidingPanel();
      reset();
      if(props.removeDummyEvent != undefined) {
        props.removeDummyEvent();
      }
      if(props.reloadEvents != undefined) {
        props.reloadEvents();
      }
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const updateCalendar = (data: CreateCalendarEventModel) => {
    if(data.endDate > data.startDate) {
      if(props.changeDummyEvent != undefined) {
        props.changeDummyEvent(data);
      }
    }
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if(props.removeDummyEvent != undefined) {
      props.removeDummyEvent();
    }
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  const onChangeMatter = useCallback((matterId?: string, diaryManagerParam?: string) => {
    const diaryManagerToAdd: string[] = diaryManager ? [diaryManager] : (diaryManagerParam ? [diaryManagerParam] : []);
    let requiredAttendees: string[] = []

    if(matterId) {
      getEventAttendeeSuggestions(matterId!).then((response) => {
        setEventAttendeeSuggestions(response.data);
        setOptionalEventAttendeeSuggestions(response.data);
      }).catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      });
      getCaseManager(matterId).then((response) => {
        if(response.data.user.email == user.email || !user.email) {
          requiredAttendees = [response.data.user.email];
        } else {
          requiredAttendees = [response.data.user.email, user.email!];
        }
        if(diaryManagerToAdd.length > 0 && requiredAttendees.every(x => x != diaryManagerToAdd[0])) {
          requiredAttendees.unshift(...diaryManagerToAdd);
        }
        setValue('requiredAttendees', requiredAttendees);
      });
    } else {
      setEventAttendeeSuggestions([]);
      setOptionalEventAttendeeSuggestions([]);
      setValue('requiredAttendees', requiredAttendees);
    }
    setValue('optionalAttendees', []);
  }, [diaryManager])

  const onChangeIsAllDay = (value: boolean) => {
    if(value) {
      setValue('startDate', moment(watch('startDate')).startOf('day').toDate());
      setValue('endDate', moment(watch('startDate')).startOf('day').toDate());
    } else {
      setValue('startDate', props.startDate);
      setValue('endDate', props.endDate);
    }
    updateCalendar(watch());
  }

  const onChangeRequiredAttendees = (selectedOptions: CreatableDropDownOptionModel[]) => {
    setOptionalEventAttendeeSuggestions(eventAttendeeSuggestions?.filter(
      (x) => !selectedOptions.some((y) => y.label == x.label)
    ));
  }

  const checkAttendees = (
    requiredAttendees: string[], 
    optionalAttendees: string[],
    rooms: string[],
    isAllDay: boolean,
    startDate?: Date,
    endDate?: Date
  ) => {
    setAvailabilityStatus(undefined);
    setSuggestedTimes([]);
    let attendees = requiredAttendees.concat(optionalAttendees);
    if(attendees.length == 0 || !startDate || !endDate) {
      return;
    }

    if(startDate > endDate) {
      setGenericErrors("Start Date cannot be after End Date");
      return;
    }
    const momentDate1 = moment(startDate);
    const momentDate2 = moment(endDate);
    const daysDifference = momentDate2.diff(momentDate1, 'days');

    if(daysDifference > 62) {
      setGenericErrors("The requested time duration specified is too long. The limit is 62 days.");
      return;
    }

    if(rooms.length > 0) {
      attendees = attendees.concat(rooms);
    }

    const body: FreeBusyAttendeeCheckModel = {
      attendees: attendees,
      startDate: startDate,
      endDate: isAllDay ? moment(endDate).add(1, 'days').toDate() : endDate,
    }
    checkEventAttendees(body).then((response) => {
      if (response.data.some((x: FreeBusyAvailabilityViewModel) => x.availabilityView.split('').some(y => y !== '0'))) {
        setAvailabilityStatus(false);
        getSuggestions(requiredAttendees, optionalAttendees, rooms, watch('matterId'), body.startDate, body.endDate);
      } 
      else
      {
        setAvailabilityStatus(true);
      }
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    }).finally(() => {
      setGenericErrors(null);
    });
  }

  const getSuggestions = (
    requiredAttendees: string[], 
    optionalAttendees: string[],
    rooms: string[],
    matterId?: string,
    startDate?: Date,
    endDate?: Date
  ) => {
    let attendees = requiredAttendees.concat(optionalAttendees);
    if(attendees.length == 0 || !startDate || !endDate) {
      return;
    }

    const momentDate1 = moment(startDate);
    const momentDate2 = moment(endDate);
    const minutes = momentDate2.diff(momentDate1, 'minutes');

    if(minutes > 720) {
      return;
    }

    if(startDate > endDate) {
      setGenericErrors("Start Date cannot be after End Date");
      return;
    }

    if(rooms.length > 0) {
      attendees = attendees.concat(rooms);
    }

    const body: FreeBusyAttendeeCheckModel = {
      matterId: matterId,
      attendees: attendees,
      startDate: startDate,
      endDate: endDate
    }
    getMeetingTimes(body).then((response) => {
      setSuggestedTimes(response.data);
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    }).finally(() => {
      setGenericErrors(null);
    });
  }
  
  useEffect(() => {
    checkAttendees(watch('requiredAttendees'), watch('optionalAttendees'), watch('rooms'), watch('isAllDay'), watch('startDate'), watch('endDate'));
  }, [watch('requiredAttendees'), watch('rooms'), watch('optionalAttendees'), watch('startDate'), watch('endDate')]);

  const onChangeStartDate = (val?: Date) => {
    if(watch('isAllDay')) {
      setValue('endDate', moment(val).startOf('day').toDate());
    }
    else {
      setValue('endDate', moment(val).add(30, "minutes").toDate());
    }    
  }

  const onClickSuggestedTime = (startDate: Date, endDate: Date) => {
    setValue("startDate", startDate);
    setValue("endDate", endDate);

    updateCalendar(watch());
  }

  const onChangeIsNoEnd = () => {
    setIsNoEndEvent(!isNoEndEvent);
    setValue("calendarRecurrence.endDate", undefined);
  }

  const setDaysOfWeek = (val: CalendarRecurrencePatternTypes) => {
    if(val == CalendarRecurrencePatternTypes.Weekly.valueOf()) {
      const day = moment(watch('startDate')).day();
      setValue('calendarRecurrence.daysOfWeek', [day.toString()]);
    } else {
      setValue('calendarRecurrence.daysOfWeek', []);
    }
  }

  return (
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      {availabilityStatus != undefined && (
        <div className={`lp-calendar-availability${availabilityStatus ? " free" : " busy"}`}>
          {availabilityStatus ? "All the Attendees are free in the specified time window." : "Some of the Attendees are not free in the specified time window."}
        </div>
      )}

      {suggestedTimes?.length != 0 && (
        <>
          <div className="lp-calendar-suggestions-title">Available time slot suggestions:</div>
          <div className="lp-calendar-suggestions">
            {suggestedTimes?.map((x: MeetingTimeSuggestedTimeModel, index: number) => (
              <div key={index} onClick={() => onClickSuggestedTime(x.startDate, x.endDate)}>
                <Field
                  label={"Start Date"}
                  value={moment(x.startDate).format(DateFormat.MomentWithTime)}
                />
                <Field
                  label={"End Date"}
                  value={moment(x.endDate).format(DateFormat.MomentWithTime)}
                />
              </div>
            ))}
          </div>
        </>
      )}

      <Form onSubmit={onSubmit}>
        {
          props.matterId == undefined &&
          <Form.Group className="mb-4" controlId="matterId">
            <Form.Label>Matter</Form.Label>
            <Controller
              control={control}
              name="matterId"
              shouldUnregister={true}
              render={({field: { onChange, value, ref }}) => (
                <CustomSelect
                  id="matterId"
                  inputRef={ref}
                  className={`lp-select${errors?.matterId?.message ? ' invalid' : ''}`}
                  endpointCall={getMattersSummary}
                  value={value}
                  onChange={val => { 
                    onChange(val?.id ?? null); 
                    onChangeMatter(val?.id);
                    setMatterFileNumber(val?.name.split("-")[0].trim());
                  }}
                  isClearable
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.matterId?.message && (errors.matterId.message)}
            </Form.Text>
          </Form.Group>
        }

        <Form.Group className="mb-4" controlId="title">
          <Form.Label className="required">Title</Form.Label>
          <InputGroup className="mb-3">
            {matterFileNumber &&
              <InputGroup.Text id="basic-addon1">{`[LPID#${matterFileNumber}]`}</InputGroup.Text>
            }
            <Form.Control
              type="text"
              className={`${errors?.title?.message ? 'invalid' : ''}`}
              {...register('title', {shouldUnregister: true})}
              onBlur={() => updateCalendar(watch())}
            />
          </InputGroup>
          <Form.Text className="lp-error">
            {errors?.title?.message && errors.title.message}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="showAs">
          <Form.Label className="required">Show As</Form.Label>
          <Controller
            control={control}
            name="showAs"
            shouldUnregister={true}
            render={({field: { onChange, value, ref }}) => (
              <CustomSelect
                id="showAs"
                inputRef={ref}
                options={getCalendarFreeBusyStatusDropdownValues()}
                formatOptionLabel={ColoredOption}
                className={`lp-select${errors?.showAs?.message ? ' invalid' : ''}`}
                value={value}
                onChange={val => (onChange(val?.id ?? null), updateCalendar(watch()))}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.showAs?.message && (errors.showAs.message)}
          </Form.Text>
        </Form.Group>

        <Row>
          {!watch('matterId') &&
            <Form.Group as={Col} xs={12} sm={6} className="mb-4 mb-sm-0" controlId="private">
              <Form.Label>Private</Form.Label>
              <Controller
                control={control}
                name="private"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Form.Check type="switch" id="private">
                    <Form.Check.Input
                      className="form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => {
                        onChange(ev.target.checked);
                      }}
                    />
                  </Form.Check>
                )}
              />
            </Form.Group>
          }

          <Form.Group as={Col} xs={12} sm={6} controlId="isAllDay">
            <Form.Label>Is All Day</Form.Label>
            <Controller
              control={control}
              name="isAllDay"
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <Form.Check type="switch" id="isAllDay">
                  <Form.Check.Input
                    className="form-check-input"
                    ref={ref}
                    checked={value ?? false}
                    onChange={(ev: any) => {
                      onChange(ev.target.checked);
                      onChangeIsAllDay(ev.target.checked);
                    }}
                  />
                </Form.Check>
              )}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} xs={12} sm={6} className="mb-4" controlId="startDate">
            <Form.Label className="required">Start Date</Form.Label>
            <Controller
              control={control}
              name="startDate"
              shouldUnregister={true}
              render={({field: { onChange, value }}) => (
                <DatePicker
                  className={`${errors?.startDate?.message ? 'invalid' : ''}`}
                  dateFormat={watch('isAllDay') ? DateFormat.Datepicker : DateFormat.DatepickerWithTime}
                  selected={value ?? null}
                  onChange={(val) => (onChange(val ?? null), onChangeStartDate(val != null ? val : undefined), updateCalendar(watch()))}
                  onBlur={() => updateCalendar(watch())}
                  showTimeSelect={!watch('isAllDay')}
                  autoComplete="off"
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.startDate?.message && (errors.startDate.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} xs={12} sm={6} className="mb-4" controlId="endDate">
            <Form.Label className="required">End Date</Form.Label>
            <Controller
              control={control}
              name="endDate"
              shouldUnregister={true}
              render={({field: { onChange, value }}) => (
                <DatePicker
                  className={`${errors?.endDate?.message ? 'invalid' : ''}`}
                  dateFormat={watch('isAllDay') ? DateFormat.Datepicker : DateFormat.DatepickerWithTime}
                  selected={value ?? null}
                  onChange={(val) => (onChange(val ?? null), updateCalendar(watch()))}
                  showTimeSelect={!watch('isAllDay')}
                  autoComplete="off"
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.endDate?.message && (errors.endDate.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Form.Group className="mb-4" controlId="requiredAttendees">
          <Form.Label>Required Attendees</Form.Label>
          <Controller
            control={control}
            name="requiredAttendees"
            shouldUnregister={true}
            render={({field: { value, onChange }}) => (
              <CustomCreatableSelect
                id="requiredAttendees"
                className={`lp-select${errors?.requiredAttendees?.message ? ' invalid' : ''}`}
                options={eventAttendeeSuggestions}
                onChange={(selectedOptions) => {
                  onChange(selectedOptions ? selectedOptions.map((option: any) => option.value) : []);
                  onChangeRequiredAttendees(selectedOptions)
                }}
                value={value}
                isClearable
                isMulti
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.requiredAttendees?.message && errors.requiredAttendees.message}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="optionalAttendees">
          <Form.Label>Optional Attendees</Form.Label>
          <Controller
            control={control}
            name="optionalAttendees"
            shouldUnregister={true}
            render={({field: { value, onChange }}) => (
              <CustomCreatableSelect
                id="optionalAttendees"
                className={`lp-select${errors?.optionalAttendees?.message ? ' invalid' : ''}`}
                options={optionalEventAttendeeSuggestions}
                onChange={(selectedOptions) =>
                  {
                    onChange(selectedOptions ? selectedOptions.map((option: any) => option.value) : []);
                  }
                }
                value={value}
                isClearable
                isMulti
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.optionalAttendees?.message && errors.optionalAttendees.message}
          </Form.Text>
        </Form.Group>

        {lteCalendarSettings?.hasAccessToLawPageRooms && 
          <Form.Group className="mb-4" controlId="rooms">
            <Form.Label>LawPage Rooms</Form.Label>
            <Controller
              control={control}
              name="rooms"
              shouldUnregister={true}
              render={({field: { value, onChange }}) => (
                <CustomSelect
                  id="rooms"
                  className={`lp-select${errors?.rooms?.message ? ' invalid' : ''}`}
                  endpointCall={getLawpageRooms}
                  onChange={(selectedOptions) => {
                    onChange(selectedOptions ? selectedOptions.map((option: any) => option.id) : []);
                  }}
                  value={value}
                  isClearable
                  isMulti
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.rooms?.message && errors.rooms.message}
            </Form.Text>
          </Form.Group>
        }

        <Form.Group className="mb-4" controlId="reminderMinutesBeforeStart">
          <Form.Label className="required">Reminder Before Start</Form.Label>
          <Controller
            control={control}
            name="reminderMinutesBeforeStart"
            shouldUnregister={true}
            render={({field: { onChange, value, ref }}) => (
              <CustomSelect
                id="reminderMinutesBeforeStart"
                inputRef={ref}
                className={`lp-select${errors?.reminderMinutesBeforeStart?.message ? ' invalid' : ''}`}
                options={getCalendarReminderBeforeStatusDropdownValues()}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.reminderMinutesBeforeStart?.message && (errors.reminderMinutesBeforeStart.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="location">
          <Form.Label>Location</Form.Label>
          <Controller
            control={control}
            name="location"
            shouldUnregister={true}
            render={({field: { value, onChange, onBlur }}) => (
              <CustomCreatableSelect
                id="location"
                className={`lp-select${errors?.location?.message ? ' invalid' : ''}`}
                value={value}
                onChange={val => onChange(val?.value ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.location?.message && errors.location.message}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="isOnlineMeeting">
          <Form.Label>Is Online Meeting</Form.Label>
          <Controller
            control={control}
            name="isOnlineMeeting"
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <Form.Check type="switch" id="isOnlineMeeting">
                <Form.Check.Input
                  className="form-check-input"
                  ref={ref}
                  checked={value ?? false}
                  onChange={(ev: any) => {
                    onChange(ev.target.checked);
                  }}
                />
              </Form.Check>
            )}
          />  
        </Form.Group>

        <Form.Group className="mb-4" controlId="description">
          <Form.Label>
            Description
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            {...register("description", {shouldUnregister: true})}
          />
        </Form.Group>

        {/*<CalendarRecurrence
          register={register}
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          isNoEndEvent={isNoEndEvent}
          setIsNoEndEvent={setIsNoEndEvent}
                />*/}

        <div className="lp-color-title primary full-width">Recurrence Settings</div>
        <Form.Group className="mb-4" controlId="calendarRecurrence.recurrencePatternType">
          <Form.Label className="required">Recurrence</Form.Label>
          <Controller
            control={control}
            name="calendarRecurrence.recurrencePatternType"
            shouldUnregister={true}
            render={({field: { onChange, value, ref }}) => (
              <CustomSelect
                id="calendarRecurrence.recurrencePatternType"
                inputRef={ref}
                options={calendarRecurrenceTypes}
                className={`lp-select${errors?.calendarRecurrence?.recurrencePatternType?.message ? ' invalid' : ''}`}
                value={value}
                onChange={(val) => {
                  onChange(val?.id ?? null);
                  setIsNoEndEvent(true);
                  setDaysOfWeek(val?.id ?? null);
                  setValue("calendarRecurrence.interval", 1);
                  setValue("calendarRecurrence.index", WeekIndexes.DateIndex);
                }}
                menuPlacement='top'
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.calendarRecurrence?.recurrencePatternType?.message && (errors.calendarRecurrence.recurrencePatternType.message)}
          </Form.Text>
        </Form.Group>

        {watch('calendarRecurrence.recurrencePatternType') != CalendarRecurrencePatternTypes.NoRepeat &&
          <>
            <Form.Group className="mb-4">
              <Form.Label>Is No End</Form.Label>
              <Form.Check type="switch" id="isNoEndEvent">
                <Form.Check.Input
                  className="form-check-input"
                  checked={isNoEndEvent}
                  onChange={(ev: any) => {
                    onChangeIsNoEnd()
                  }}
                />
              </Form.Check>
            </Form.Group>

            <Form.Group className="mb-4" controlId="calendarRecurrence.interval">
              <Form.Label className="required">Recurring Event Interval</Form.Label>
              <Form.Control
                type="number"
                className={`${errors?.calendarRecurrence?.interval?.message ? 'invalid' : ''}`}
                {...register("calendarRecurrence.interval", {shouldUnregister: true})}
                min="1"
                onWheel={e => e.currentTarget.blur()}
              />
              <Form.Text className="lp-error">
                {errors?.calendarRecurrence?.interval?.message && (errors.calendarRecurrence.interval.message)}
              </Form.Text>
            </Form.Group>

            {(watch('calendarRecurrence.recurrencePatternType') == CalendarRecurrencePatternTypes.Weekly) && 
              <Form.Group className="mb-4" controlId="calendarRecurrence.daysOfWeek">
                <Form.Label className="required">Days of Week</Form.Label>
                <Controller
                  control={control}
                  name="calendarRecurrence.daysOfWeek"
                  shouldUnregister={true}
                  render={({field: { value, onChange }}) => (
                    <CustomSelect
                      id="calendarRecurrence.daysOfWeek"
                      className={`lp-select${errors?.calendarRecurrence?.daysOfWeek?.message ? ' invalid' : ''}`}
                      options={getDaysOfWeek()}
                      defaultValue={[]}
                      onChange={(selectedOptions) => {
                        onChange(selectedOptions ? selectedOptions.map((option: any) => option.id) : []);
                      }}
                      value={value}
                      menuPlacement="top"
                      isMulti
                    />
                  )}
                />
              </Form.Group>
            }

            {(watch('calendarRecurrence.recurrencePatternType') == CalendarRecurrencePatternTypes.Monthly ||
              watch('calendarRecurrence.recurrencePatternType') == CalendarRecurrencePatternTypes.Yearly) && 
              <Form.Group className="mb-4" controlId="calendarRecurrence.index">
                <Form.Label className="required">Repeat On</Form.Label>
                <Controller
                  control={control}
                  name="calendarRecurrence.index"
                  shouldUnregister={true}
                  render={({field: { value, onChange }}) => (
                    <CustomSelect
                      id="calendarRecurrence.index"
                      className={`lp-select${errors?.calendarRecurrence?.index?.message ? ' invalid' : ''}`}
                      options={getOptionsForRepeatOn(watch('startDate'))}
                      onChange={val => {onChange(val?.id ?? null)}}
                      value={value}
                      menuPlacement="top"
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors?.calendarRecurrence?.index?.message && errors.calendarRecurrence.index.message}
                </Form.Text>
              </Form.Group>
            }

            {!isNoEndEvent && (
              <Form.Group className="mb-4" controlId="calendarRecurrence.endDate">
                <Form.Label className="required">Recurring Event End Date</Form.Label>
                <Controller
                  control={control}
                  name="calendarRecurrence.endDate"
                  shouldUnregister={true}
                  render={({field: { onChange, value }}) => (
                    <DatePicker
                      className={`${errors?.calendarRecurrence?.endDate?.message ? 'invalid' : ''}`}
                      dateFormat={DateFormat.DatepickerWithTime}
                      selected={value ?? null}
                      onChange={(val) => (onChange(val ?? null))}
                      autoComplete="off"
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors?.calendarRecurrence?.endDate?.message && (errors.calendarRecurrence.endDate.message)}
                </Form.Text>
              </Form.Group>
            )}
          </>
        }

        <Form.Group className="d-flex justify-content-between">
          <Button variant="success" type="submit">
            Create
          </Button>
          <Button variant="secondary-400" onClick={cancelForm}>
            Cancel
          </Button>
        </Form.Group>
      </Form>
    </>
  );
}
