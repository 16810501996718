import { ColDef } from "ag-grid-community";
import { MatterRecordableItemModel } from "models/view/MatterRecordableItemModel";
import { DateFormatter } from "components/Grid/ValueFormatters/DateFormatter";
import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";

export const getColumnDefsRecordableEventItemDetailForInvoice = () => {
  const columnDefs: ColDef<MatterRecordableItemModel>[] = [
    { field: 'date', headerName: "Date", valueFormatter: DateFormatter },
    { field: 'recordableEventDisplayName', headerName: "Recordable Event Display Name", flex: 1 },
    { field: 'activity.name', headerName: "Activity", flex: 1 },
    { field: 'amountCharged', headerName: "Amount Charged", valueFormatter: CurrencyFormatter },
    { field: 'adjustedAmountChargedOnInvoice', headerName: "Amount Invoiced", valueFormatter: CurrencyFormatter },
    { field: 'calculatedVATValueOnInvoice', headerName: "Calculated VAT Value", valueFormatter: CurrencyFormatter }
  ];

  return columnDefs;
}