import { checkIfValidPhoneNumber } from "utils/form";
import isMobilePhone from "validator/lib/isMobilePhone";
import { create, test, enforce } from "vest";

export const validationSuite = create((data = {}) => {
  test('phoneNumber', 'Phone Number not in the correct format.', () => {
    enforce(data.phoneNumber).condition(value => checkIfValidPhoneNumber(value) || isMobilePhone(value))
  });
  
  test('phoneTypeId', 'Please select a Phone Type', () => {
    enforce(data.phoneTypeId).isNotEmpty();
    enforce(data.phoneTypeId).isString();
  });
});