import { enforce, test, create } from "vest";

export const validationSuite = create((data = {}) => {
  test('note', 'Note is required', () => {
    enforce(data.note).isNotEmpty();
    enforce(data.note).isString();
  });

  test('noteTypeId', 'Please select Type', () => {
    enforce(data.noteTypeId).isNotEmpty();
    enforce(data.noteTypeId).isString();
  });
});