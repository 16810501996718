import { vestResolver } from "@hookform/resolvers/vest";
import {
  getMatterActivityHistory,
  getMatterClientsSummary,
  getMatterDisbursementsForInvoice,
  getMatterIncidentalExpensesForInvoice,
  getMatterInvoiceById,
  getMatterInvoiceStatuses,
  getMatterInvoicingPartiesSummary,
  getMatterRecordableItemsForInvoice,
  getUninvoicedMatterDisbursements,
  getUninvoicedMatterIncidentalExpenses,
  getUninvoicedMatterRecordableItems,
  updateMatterInvoice
} from "actions/matter";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader/index";
import CustomSelect from "components/Select/Select";
import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { DateFormat } from "utils/constants";
import { removeEmptyFields } from "utils/form";
import { validationSuite } from "./validationSuite";
import useGridActions from "actions/grid";
import store from "state/store";
import { GridIds } from "enums/GridIds";
import { CreateOrUpdateMatterInvoiceModel } from "models/create/CreateOrUpdateMatterInvoiceModel";
import { getVATRatesForSales } from "actions/settings";
import { VatRateModel } from "models/view/VatRateModel";
import Field from "components/Fields/Field";
import { formatCurrency } from "utils/misc";
import { getDateOnly } from "utils/date";
import { MatterDisbursementModel } from "models/view/MatterDisbursementModel";
import { MatterIncidentalExpenseModel } from "models/view/MatterIncidentalExpenseModel";
import { MatterRecordableItemModel } from "models/view/MatterRecordableItemModel";
import { BsCheckCircleFill, BsCheckCircle } from "react-icons/bs";
import moment from "moment";
import { MatterRecordableItemForInvoiceModel } from "models/view/MatterRecordableItemForInvoiceModel";
import { MatterIncidentalExpenseForInvoiceModel } from "models/view/MatterIncidentalExpenseForInvoiceModel";
import { getUserSummaryForLte } from "actions/user";
import { useAppSelector } from "hooks/appSelector";
import { MatterInvoiceItemFilterModel } from "models/view/MatterInvoiceItemFilterModel";
import { MdWarning } from "react-icons/md";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import { MatterInvoiceModel } from "models/view/MatterInvoiceModel";
import currency from 'currency.js';

type Values = {
  grossValue: number,
  vatValue: number,
  netValue: number
}

type Props = {
  matterId: string,
  matterInvoiceId: string,
  onSubmitCallback?: Function
}

export default function EditMatterInvoiceForm(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const genericErrorsRef = useRef<HTMLDivElement>(null);
  const slidingPanelActions = useSlidingPanelActions();
  const gridActions = useGridActions();
  const user = useAppSelector((state) => state.user);
  const [vatRates, setVatRates] = useState<VatRateModel[]>([]);
  const [isLoadingVatRates, setIsLoadingVatRates] = useState(false);
  const [recordableItems, setRecordableItems] = useState<MatterRecordableItemForInvoiceModel[]>([]);
  const [isLoadingRecordableItems, setIsLoadingRecordableItems] = useState(false);
  const [incidentalExpenses, setIncidentalExpenses] = useState<MatterIncidentalExpenseForInvoiceModel[]>([]);
  const [isLoadingIncidentalExpenses, setIsLoadingIncidentalExpenses] = useState(false);
  const [disbursements, setDisbursements] = useState<MatterDisbursementModel[]>([]);
  const [isLoadingDisbursements, setIsLoadingDisbursements] = useState(false);
  const modalActions = useModalActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);

  const currentDate: Date = getDateOnly(new Date());
  const [totalValues, setTotalValues] = useState<Values>({grossValue: 0, vatValue: 0, netValue: 0});
  const [adjustmentValues, setAdjustmentValues] = useState<Values>({grossValue: 0, vatValue: 0, netValue: 0});
  const [totalGrossValueAfterAdjustment, setTotalGrossValueAfterAdjustment] = useState<number>(0);
  const [totalVATValueAfterAdjustment, setTotalVATValueAfterAdjustment] = useState<number>(0);
  const [vatRateValue, setVATRateValue] = useState<number>(0);
  const [invoice, setInvoice] = useState<MatterInvoiceModel>();

  const [isFilterActive, setIsFilterActive] = useState(false);

  const { register, handleSubmit, control, setValue, trigger, watch, reset, resetField, formState: { errors } } = useForm<CreateOrUpdateMatterInvoiceModel>({
    resolver: vestResolver(validationSuite),
    defaultValues: {
      date: currentDate,
      invoiceNetValue: 0,
      totalNetValueWithoutRecordableItems: 0
    }
  });

  useEffect(() => {
    setIsLoading(true);
    getMatterInvoiceById(props.matterId, props.matterInvoiceId).then((response) => {
      let initialState: CreateOrUpdateMatterInvoiceModel = {
        matterParticipatingEntityIds: response.data.matterParticipatingEntities?.map((a: any) => a.id) ?? [],
        invoicingPartyId: response.data.invoicingPartyId,
        description: response.data.description,
        date: getDateOnly(response.data.date),
        matterInvoiceStatusId: response.data.matterInvoiceStatusId,
        vatRateId: response.data.vatRateId,
        invoiceNetValue: response.data.invoicedTotalNetValue,
        totalNetValueWithoutRecordableItems: 0,
        filterEndDate: getDateOnly(response.data.date)
      };

      populateVatRates(initialState.date);
      const currentVATRate = response.data.vatRate?.vatRateValue;
      setVATRateValue(currentVATRate);
      setInvoice(response.data);

      setIsLoadingRecordableItems(true);
      setIsLoadingIncidentalExpenses(true);
      setIsLoadingDisbursements(true);

      const filter: MatterInvoiceItemFilterModel = {
        clientIds: initialState.matterParticipatingEntityIds,
        endDate: initialState.filterEndDate,
        includeItemFromInvoiceId: props.matterInvoiceId
      };

      const promises = [
        getUninvoicedMatterRecordableItems(props.matterId, filter),
        getUninvoicedMatterIncidentalExpenses(props.matterId, filter),
        getUninvoicedMatterDisbursements(props.matterId, filter),
        getMatterRecordableItemsForInvoice(props.matterId, props.matterInvoiceId),
        getMatterIncidentalExpensesForInvoice(props.matterId, props.matterInvoiceId),
        getMatterDisbursementsForInvoice(props.matterId, props.matterInvoiceId)
      ];

      Promise.all(promises)
        .then(([uninvoicedRecordableItemsResponse, uninvoicedIncidentalExpensesResponse, uninvoicedDisbursementsResponse,
          recordableItemsForInvoiceResponse, incidentalExpensesForInvoiceResponse, disbursementsForInvoiceResponse
        ]) => {
          //set recordable items
          const recordableItemsForInvoice = uninvoicedRecordableItemsResponse.data.map((x: MatterRecordableItemModel) => ({
            ...x,
            calculatedVatValue: x.calculatedVATValueOnInvoice ? x.calculatedVATValueOnInvoice : currency(x.amountCharged * currentVATRate / 100).value,
            adjustmentAmountCharged: x.adjustedAmountChargedOnInvoice ? currency(x.adjustedAmountChargedOnInvoice).subtract(x.amountCharged).value : 0
          }));
          setRecordableItems(recordableItemsForInvoice);
          const selectedMatterRecordableItemIds = recordableItemsForInvoiceResponse.data.map((x: MatterRecordableItemModel)=> x.id!);
          
          //set incidental expenses
          const incidentalExpensesForInvoice = uninvoicedIncidentalExpensesResponse.data.map((x: MatterIncidentalExpenseModel) => ({
            ...x,
            netValue: currency(x.netValue - (x.netValue / x.grossValue * (x.writeOffAmount ?? 0))).value,
            vatValue: x.vatValue ? currency(x.vatValue - (x.vatValue / x.grossValue * (x.writeOffAmount ?? 0))).value : undefined,
            originalVATValue: x.vatValue,
            calculatedVatValue: x.vatValueOnInvoice ? currency(x.vatValueOnInvoice - (x.vatValueOnInvoice / x.grossValue * (x.writeOffAmount ?? 0))).value 
              : (x.vatValue ? 0 : currency((x.netValue - (x.writeOffAmount ?? 0)) * currentVATRate / 100).value)
          }));
          setIncidentalExpenses(incidentalExpensesForInvoice);
          const selectedMatterIncidentalExpenseIds = incidentalExpensesForInvoiceResponse.data.map((x: MatterIncidentalExpenseModel) => x.id);
          
          //set disbursements
          const disbursementsForInvoice = uninvoicedDisbursementsResponse.data.map((x: MatterDisbursementModel) => ({
            ...x,
            netValue: currency(x.netValue - (x.netValue / x.grossValue * (x.writeOffAmount ?? 0))).value,
            vatValue: x.vatValue ? currency(x.vatValue - (x.vatValue / x.grossValue * (x.writeOffAmount ?? 0))).value : undefined
          }));
          setDisbursements(disbursementsForInvoice);
          const selectedMatterDisbursementIds = disbursementsForInvoiceResponse.data.map((x: MatterDisbursementModel) => x.id);

          initialState.matterRecordableItemIds = selectedMatterRecordableItemIds;
          initialState.matterIncidentalExpenseIds = selectedMatterIncidentalExpenseIds;
          initialState.matterDisbursementIds = selectedMatterDisbursementIds;
          reset(initialState);

          //calculate totals
          const selectedRecordableItems = recordableItemsForInvoice.filter((x: MatterRecordableItemForInvoiceModel) => 
            selectedMatterRecordableItemIds.includes(x.id));
          const selectedIncidentalExpenses = incidentalExpensesForInvoice.filter((x: MatterIncidentalExpenseForInvoiceModel) => 
            selectedMatterIncidentalExpenseIds.includes(x.id));
          const selectedDisbursements = disbursementsForInvoice.filter((x: MatterDisbursementModel) => selectedMatterDisbursementIds.includes(x.id));

          calculateTotals(selectedRecordableItems, selectedIncidentalExpenses, selectedDisbursements, currentVATRate, false);
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
        })
        .finally(() => {
          setIsLoadingRecordableItems(false);
          setIsLoadingIncidentalExpenses(false);
          setIsLoadingDisbursements(false);
        });
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateMatterInvoiceModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    
    if(!data.matterParticipatingEntityIds.includes(data.invoicingPartyId)) {
      var bodyMessage: React.ReactNode = (
        <div className="lp-modal-warning">
          <MdWarning />
          Invoicing Party is not one of the clients on the Matter Invoice. Are you sure you want to continue?
        </div>
      );
      let modal: ModalState = {
        title: 'Update confirmation',
        body: bodyMessage,
        actionText: 'Update',
        onAction: () => submitDataCallback(data),
        show: false,
        onClose: () => setIsLoading(false)
      };
      modalActions.setModal(modal);
      modalActions.toggleModalShownStatus();
    }
    else {
      submitDataCallback(data, false);
    }
  }

  const submitDataCallback = (data: CreateOrUpdateMatterInvoiceModel, fromModal: boolean = true) => {
    if(fromModal)
    {
      modalActions.toggleModalLoading();
    }
    
    updateMatterInvoice(props.matterId, props.matterInvoiceId, data).then((response) => {
      const gridState = store.getState().grid;
      if (gridState.id == `${GridIds.ActivityHistory}/${props.matterId}`) {
        getMatterActivityHistory(props.matterId).then((response2) => {
          gridActions.setGridRowData(response2.data);
        });
      }
      slidingPanelActions.clearSlidingPanel();
      reset();

      props.onSubmitCallback && props.onSubmitCallback();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message ?? error.response.data);
    })
    .finally(() => {
      setIsLoading(false);
      if(fromModal)
      {
        modalActions.toggleModalLoading();
        modalActions.toggleModalShownStatus();
      }
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel !== undefined && currentSlidingPanelState?.onCancel();
  }

  useEffect(() => {
    if(genericErrorsRef.current)
    {
      genericErrorsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [genericErrors]);

  const populateVatRates = (invoiceDate?: Date) => {
    setIsLoadingVatRates(true);
    const date: Date = invoiceDate ?? watch('date');
    getVATRatesForSales(date).then((response) => {
      setVatRates(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingVatRates(false);
    });
  }

  const populateInvoiceItems = (clientIds: string[], filterStartDate?: Date, filterEndDate?: Date, filterUserIds?: string[]) => {
    setIsLoadingRecordableItems(true);
    setIsLoadingIncidentalExpenses(true);
    setIsLoadingDisbursements(true);

    const filter: MatterInvoiceItemFilterModel = {
      clientIds: clientIds,
      startDate: filterStartDate ? getDateOnly(filterStartDate) : undefined,
      endDate: filterEndDate ? getDateOnly(filterEndDate) : undefined,
      userIds: filterUserIds,
      includeItemFromInvoiceId: props.matterInvoiceId
    };

    const promises = [
      getUninvoicedMatterRecordableItems(props.matterId, filter),
      getUninvoicedMatterIncidentalExpenses(props.matterId, filter),
      getUninvoicedMatterDisbursements(props.matterId, filter)
    ];

    Promise.all(promises)
      .then(([recordableItemsResponse, incidentalExpensesResponse, disbursementsResponse]) => {
        //set recordable items
        const recordableItemsForInvoice = recordableItemsResponse.data.map((x: MatterRecordableItemModel) => ({
          ...x,
          calculatedVatValue: currency(x.amountCharged * vatRateValue / 100).value,
          adjustmentAmountCharged: 0
        }));
        setRecordableItems(recordableItemsForInvoice);
        setValue('matterRecordableItemIds', recordableItemsForInvoice.map((x: MatterRecordableItemForInvoiceModel)=> x.id!));

        //set incidental expenses
        const incidentalExpensesForInvoice = incidentalExpensesResponse.data.map((x: MatterIncidentalExpenseModel) => ({
          ...x,
          netValue: currency(x.netValue - (x.netValue / x.grossValue * (x.writeOffAmount ?? 0))).value,
          vatValue: x.vatValue ? currency(x.vatValue - (x.vatValue / x.grossValue * (x.writeOffAmount ?? 0))).value : undefined,
          originalVATValue: x.vatValue,
          calculatedVatValue: x.vatValue ? 0 : currency((x.netValue - (x.writeOffAmount ?? 0)) * vatRateValue / 100).value
        }));
        setIncidentalExpenses(incidentalExpensesForInvoice);
        setValue('matterIncidentalExpenseIds', incidentalExpensesForInvoice.map((x: MatterIncidentalExpenseForInvoiceModel)=> x.id));

        //set disbursements
        const disbursementsForInvoice = disbursementsResponse.data.map((x: MatterDisbursementModel) => ({
          ...x,
          netValue: currency(x.netValue - (x.netValue / x.grossValue * (x.writeOffAmount ?? 0))).value,
          vatValue: x.vatValue ? currency(x.vatValue - (x.vatValue / x.grossValue * (x.writeOffAmount ?? 0))).value : undefined
        }));
        setDisbursements(disbursementsForInvoice);
        setValue('matterDisbursementIds', disbursementsForInvoice.map((x: MatterDisbursementModel) => x.id));

        //calculate totals
        calculateTotals(recordableItemsForInvoice, incidentalExpensesForInvoice, disbursementsForInvoice, vatRateValue);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoadingRecordableItems(false);
        setIsLoadingIncidentalExpenses(false);
        setIsLoadingDisbursements(false);
      });
  }

  const onChangeDate = (val?: Date) => {
    populateVatRates();
    resetField("vatRateId");

    setValue('filterEndDate', val);
    filterUninvoicedItems(watch("filterStartDate"), val, watch("filterUserIds"));
  }

  const onChangeVatRate = (value: VatRateModel) => {
    setVATRateValue(value.vatRateValue); 
    const selectedRecordableItemIds = watch('matterRecordableItemIds') ?? [];
    const selectedIncidentalExpenseIds = watch('matterIncidentalExpenseIds') ?? [];
    const selectedDisbursementIds = watch('matterDisbursementIds') ?? [];
    const invoiceNetValue: number = watch("invoiceNetValue") ?? 0;
    const recordableItemsForInvoice = calculateAdjustmentsAndVATValueForRecordableItems(totalValues.netValue, invoiceNetValue, value.vatRateValue);
    const incidentalExpensesForInvoice = calculateVATValueForIncidentalExpenses(value.vatRateValue);
    
    const selectedRecordableItems = recordableItemsForInvoice.filter(x => selectedRecordableItemIds?.includes(x.id!));
    const selectedIncidentalExpenses = incidentalExpensesForInvoice.filter(x => selectedIncidentalExpenseIds?.includes(x.id));
    const selectedDisbursements = disbursements.filter(x => selectedDisbursementIds?.includes(x.id));
    
    calculateTotals(selectedRecordableItems, selectedIncidentalExpenses, selectedDisbursements, value.vatRateValue);
  }

  //when client changes populate recordable items, incidental expenses and disbursements 
  const onChangeClients = (value?: string[]) => {
    if(value) {
      const startDate = watch("filterStartDate");
      const endDate = watch("filterEndDate");
      const filterUserIds = watch("filterUserIds");

      populateInvoiceItems(value, startDate, endDate, filterUserIds);
    }
  }

  const handleRecordableItemSelection = (id: string, onChange: Function) => {
    const matterRecordableItemIds = watch('matterRecordableItemIds');
    let newMatterRecordableItemIds = [];
    if (matterRecordableItemIds?.some((x: string) => x == id)) {
      newMatterRecordableItemIds = matterRecordableItemIds?.filter((x: string) => x != id);
    } 
    else {
      if(matterRecordableItemIds) {
        newMatterRecordableItemIds = [...matterRecordableItemIds, id];
      }
      else {
        newMatterRecordableItemIds = [id];
      }
    }

    setValue('matterRecordableItemIds', newMatterRecordableItemIds);
    onChange(newMatterRecordableItemIds);
    calculateTotalsWithIds(newMatterRecordableItemIds, watch('matterIncidentalExpenseIds') ?? [], watch('matterDisbursementIds') ?? [], vatRateValue);
  };

  const handleIncidentalExpenseSelection = (id: string, onChange: Function) => {
    const matterIncidentalExpenseIds = watch('matterIncidentalExpenseIds');
    let newMatterIncidentalExpenseIds = [];
    if (matterIncidentalExpenseIds?.some((x: string) => x == id)) {
      newMatterIncidentalExpenseIds = matterIncidentalExpenseIds?.filter((x: string) => x != id);
    } 
    else {
      if(matterIncidentalExpenseIds) {
        newMatterIncidentalExpenseIds = [...matterIncidentalExpenseIds, id];
      }
      else {
        newMatterIncidentalExpenseIds = [id];
      }
    }

    setValue('matterIncidentalExpenseIds', newMatterIncidentalExpenseIds);
    onChange(newMatterIncidentalExpenseIds);
    calculateTotalsWithIds(watch('matterRecordableItemIds') ?? [], newMatterIncidentalExpenseIds, watch('matterDisbursementIds') ?? [], vatRateValue);
  };

  const handleDisbursementSelection = (id: string, onChange: Function) => {
    const matterDisbursementIds = watch('matterDisbursementIds');
    let newMatterDisbursementIds = [];
    if (matterDisbursementIds?.some((x: string) => x == id)) {
      newMatterDisbursementIds = matterDisbursementIds?.filter((x: string) => x != id);
    } else {
      if(matterDisbursementIds) {
        newMatterDisbursementIds = [...matterDisbursementIds, id];
      }
      else {
        newMatterDisbursementIds = [id];
      }
    }

    setValue('matterDisbursementIds', newMatterDisbursementIds);
    onChange(newMatterDisbursementIds);
    calculateTotalsWithIds(watch('matterRecordableItemIds') ?? [], watch('matterIncidentalExpenseIds') ?? [], newMatterDisbursementIds, vatRateValue);
  };

  const calculateTotalsWithIds = (
    selectedRecordableItemIds: string[],
    selectedIncidentalExpenseIds: string[],
    selectedDisbursementIds: string[],
    vatRateVal: number
  ) => {
    const selectedRecordableItems = selectedRecordableItemIds ? recordableItems.filter(x => selectedRecordableItemIds?.includes(x.id!)) : [];
    const selectedIncidentalExpenses = selectedIncidentalExpenseIds ? incidentalExpenses.filter(x => selectedIncidentalExpenseIds?.includes(x.id)) : [];
    const selectedDisbursements = selectedDisbursementIds ? disbursements.filter(x => selectedDisbursementIds?.includes(x.id)) : [];
    
    calculateTotals(selectedRecordableItems, selectedIncidentalExpenses, selectedDisbursements, vatRateVal);
  }

  const calculateTotals = (
    selectedRecordableItems: MatterRecordableItemForInvoiceModel[],
    selectedIncidentalExpenses: MatterIncidentalExpenseForInvoiceModel[],
    selectedDisbursements: MatterDisbursementModel[],
    vatRateVal: number,
    resetInvoiceNetValue: boolean = true
  ) => {
    let totalNetValueRecordableItems = 0;
    let totalVatValueRecordableItems = 0;
    selectedRecordableItems.forEach(x => {
      totalNetValueRecordableItems = currency(totalNetValueRecordableItems).add(x.amountCharged).value;
      totalVatValueRecordableItems = currency(totalVatValueRecordableItems).add(currency(x.amountCharged * vatRateVal / 100)).value;
    });

    let totalNetValueIncidentalExpenses = 0;
    let totalVatValueIncidentalExpenses = 0;
    selectedIncidentalExpenses.forEach(x => {
      totalNetValueIncidentalExpenses = currency(totalNetValueIncidentalExpenses).add(x.netValue).value;
      totalVatValueIncidentalExpenses = currency(totalVatValueIncidentalExpenses).add(x.originalVATValue ? (x.vatValue ?? 0) : x.calculatedVatValue).value;
    });

    let totalNetValueDisbursements = 0;
    let totalVatValueDisbursements = 0;
    selectedDisbursements.forEach(x => {
      totalNetValueDisbursements = currency(totalNetValueDisbursements).add(x.netValue).value;
      totalVatValueDisbursements = currency(totalVatValueDisbursements).add(x.vatValue ?? 0).value;
    });

    const totalNetValue = currency(totalNetValueRecordableItems).add(totalNetValueIncidentalExpenses).add(totalNetValueDisbursements).value;
    const totalVATValue = currency(totalVatValueRecordableItems).add(totalVatValueIncidentalExpenses).add(totalVatValueDisbursements).value;
    setTotalValues({
      netValue: totalNetValue,
      vatValue: totalVATValue,
      grossValue: totalNetValue + totalVATValue
    });
    setValue("totalNetValueWithoutRecordableItems", currency(totalNetValueIncidentalExpenses).add(totalNetValueDisbursements).value);

    if(resetInvoiceNetValue) {
      //update invoice net value when total values changes
      setValue('invoiceNetValue', totalNetValue);
      trigger('invoiceNetValue');
    }
  }

  const calculateAdjustmentsAndVATValueForRecordableItems = (
    netValue: number,
    invoiceNetValue: number,
    vatRateVal: number
  ) => {
    const matterRecordableItemIds = watch("matterRecordableItemIds");
    const selectedRecordableItems = matterRecordableItemIds ? recordableItems.filter(x => matterRecordableItemIds?.includes(x.id!)) : [];
    let totalNetValueRecordableItems = 0;
    selectedRecordableItems.forEach(x => {
      totalNetValueRecordableItems = currency(totalNetValueRecordableItems).add(x.amountCharged).value;
    });
    const adjustmentForNetValue = currency(invoiceNetValue).subtract(totalValues.netValue).value;
    const totalVATValueRecordableItems = currency((totalNetValueRecordableItems + adjustmentForNetValue) * vatRateValue / 100).value;

    let updatedRecordableItems: MatterRecordableItemForInvoiceModel[] = [];
    let tempTotalAdjusmentRecordableItems = 0;
    let tempTotalVATAdjusmentRecordableItems = 0;
    recordableItems.forEach((x, index) => {
      if(matterRecordableItemIds?.includes(x.id!)){
        const adjustmentAmountCharged = index != recordableItems.length - 1 ?
          currency(x.amountCharged * 100 / totalNetValueRecordableItems * (invoiceNetValue - netValue) / 100).value :
          currency(invoiceNetValue).subtract(netValue).subtract(tempTotalAdjusmentRecordableItems).value;
        const calculatedVatValue = index != recordableItems.length - 1 ?
          currency((x.amountCharged + adjustmentAmountCharged) * vatRateVal / 100).value :
          currency(totalVATValueRecordableItems).subtract(tempTotalVATAdjusmentRecordableItems).value;

        updatedRecordableItems.push(
        {
          ...x, 
          adjustmentAmountCharged: adjustmentAmountCharged,
          calculatedVatValue: calculatedVatValue
        });
        tempTotalAdjusmentRecordableItems += adjustmentAmountCharged;
        tempTotalVATAdjusmentRecordableItems += calculatedVatValue;
      }
      else {
        updatedRecordableItems.push(
        {
          ...x, 
          adjustmentAmountCharged: 0,
          calculatedVatValue: currency(x.amountCharged * vatRateVal / 100).value
        });
      }
    });

    setRecordableItems(updatedRecordableItems);
    return updatedRecordableItems;
  }

  const calculateVATValueForIncidentalExpenses = (
    vatRateVal: number
  ) => {
    let updatedIncidentalExpenses: MatterIncidentalExpenseForInvoiceModel[] = [];
    
    incidentalExpenses.forEach(x => {
      updatedIncidentalExpenses.push(
      {
        ...x, 
        calculatedVatValue: x.vatValue ? 0 : currency(x.netValue * vatRateVal / 100).value
      });
    });
    setIncidentalExpenses(updatedIncidentalExpenses);
    return updatedIncidentalExpenses;
  }

  //update total adjustments when total values, invoice value or vat rate changes
  useEffect(() => {
    const invoiceNetValue: number = watch("invoiceNetValue") ?? 0;
    const adjustmentForNetValue = currency(invoiceNetValue).subtract(totalValues.netValue).value;
    const adjustmentForVATValue = currency(adjustmentForNetValue * vatRateValue / 100).value;

    setAdjustmentValues({
      netValue: adjustmentForNetValue,
      vatValue: adjustmentForVATValue == 0 ? 0 : adjustmentForVATValue, //in order to remove - sign when is -0
      grossValue: currency(adjustmentForNetValue).add(adjustmentForVATValue).value
    });
    setTotalGrossValueAfterAdjustment(currency(totalValues.grossValue).add(adjustmentForNetValue).add(adjustmentForVATValue).value);
    setTotalVATValueAfterAdjustment(currency(totalValues.vatValue).add(adjustmentForVATValue).value);

    calculateAdjustmentsAndVATValueForRecordableItems(totalValues.netValue, invoiceNetValue, vatRateValue);
  }, [totalValues, watch("invoiceNetValue"), vatRateValue]);

  const filterUninvoicedItems = (startDate?: Date, endDate?: Date, userIds?: string[]) => {
    const clientIds = watch("matterParticipatingEntityIds");

    if(clientIds) {
      populateInvoiceItems(clientIds, startDate, endDate, userIds);
    }
  }

  const toggleDateFilter = () => {
    if (isFilterActive) {
      setIsFilterActive(false);
      setValue('filterStartDate', undefined);
      setValue('filterEndDate', watch("date"));
      setValue('filterUserIds', undefined);

      filterUninvoicedItems(undefined, watch("date"), undefined);
      return;
    }
    setIsFilterActive(true);
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div ref={genericErrorsRef} className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit}>
        <Row>
          <Form.Group as={Col} xs={12} sm={6} className="mb-4" controlId="matterParticipatingEntityIds">
            <Form.Label className="required">Clients</Form.Label>
            <Controller
              control={control}
              name={`matterParticipatingEntityIds`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="matterParticipatingEntityIds"
                  inputRef={ref}
                  className={`lp-select${errors?.matterParticipatingEntityIds?.message ? ' invalid' : ''}`}
                  endpointCall={() => getMatterClientsSummary(props.matterId)}
                  value={value}
                  onChange={val => {onChange(val?.map((x: { id: any; }) => x.id) ?? null); onChangeClients(val?.map((x: { id: any; }) => x.id));}}
                  isMulti
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.matterParticipatingEntityIds?.message && (errors.matterParticipatingEntityIds?.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} xs={12} sm={6} className="mb-4" controlId="invoicingPartyId">
            <Form.Label className="required">Invoicing Party</Form.Label>
            <Controller
              control={control}
              name={`invoicingPartyId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="invoicingPartyId"
                  inputRef={ref}
                  className={`lp-select${errors?.invoicingPartyId?.message ? ' invalid' : ''}`}
                  endpointCall={() => getMatterInvoicingPartiesSummary(props.matterId)}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.invoicingPartyId?.message && (errors.invoicingPartyId?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Form.Group className="mb-4" controlId="date">
          <Form.Label className="required">Date</Form.Label>
          <Controller
            control={control}
            name="date"
            shouldUnregister={true}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                className={`${errors?.date?.message ? 'invalid' : ''}`}
                id="date"
                dateFormat={DateFormat.Datepicker}
                selected={value ? getDateOnly(value) : null}
                onChange={(val) => {onChange(val != null ? getDateOnly(val) : val); onChangeDate(val != null ? getDateOnly(val) : undefined);}}
                showMonthDropdown
                showYearDropdown
                autoComplete="off"
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.date?.message && (errors.date.message)}
          </Form.Text>
        </Form.Group>

        <Row>
          <Col>
            <Field
              label={"Invoice Ref Number"}
              value={invoice?.invoiceRefNumber}
            />
          </Col>

          <Col>
            <Field
              label={"Display Name"}
              value={invoice?.displayName}
            />
          </Col>
        </Row>

        <Form.Group className="mb-4 mt-4" controlId="description">
          <Form.Label className="required">Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            className={`${errors?.description?.message ? 'invalid' : ''}`}
            {...register(`description`, {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.description?.message && (errors.description?.message)}
          </Form.Text>
        </Form.Group>

        <Row>
          <Form.Group as={Col} controlId="matterInvoiceStatusId">
            <Form.Label className="required">Status</Form.Label>
            <Controller
              control={control}
              name={`matterInvoiceStatusId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="matterInvoiceStatusId"
                  inputRef={ref}
                  className={`lp-select${errors?.matterInvoiceStatusId?.message ? ' invalid' : ''}`}
                  endpointCall={getMatterInvoiceStatuses}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.matterInvoiceStatusId?.message && (errors.matterInvoiceStatusId?.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} controlId="vatRateId">
            <Form.Label className="required">VAT Rate</Form.Label>
            <Controller
              control={control}
              name={`vatRateId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="vatRateId"
                  inputRef={ref}
                  className={`lp-select${errors?.vatRateId?.message ? ' invalid' : ''}`}
                  options={vatRates}
                  isLoading={isLoadingVatRates}
                  value={value}
                  onChange={val => {onChange(val?.id ?? null); onChangeVatRate(val);}}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.vatRateId?.message && (errors.vatRateId?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        {isFilterActive ? (
          <>
            <Row>
              <Form.Group as={Col} xs={12}>
                <div className="lp-text-title">Filter</div>
              </Form.Group>
              <Form.Group as={Col} controlId="filterStartDate">
                <Form.Label>Start Date</Form.Label>
                <Controller
                  control={control}
                  name="filterStartDate"
                  //shouldUnregister={true}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      className={`${errors?.date?.message ? 'invalid' : ''}`}
                      id="filterStartDate"
                      dateFormat={DateFormat.Datepicker}
                      selected={value ? getDateOnly(value) : null}
                      onChange={(val) => {
                        onChange(val != null ? getDateOnly(val) : val);
                        filterUninvoicedItems(val != null ? getDateOnly(val) : undefined, watch("filterEndDate"), watch("filterUserIds"));
                      }}
                      showMonthDropdown
                      showYearDropdown
                      maxDate={watch("filterEndDate")}
                      autoComplete="off"
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors?.filterStartDate?.message && (errors.filterStartDate.message)}
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} controlId="filterEndDate">
                <Form.Label>End Date</Form.Label>
                <Controller
                  control={control}
                  name="filterEndDate"
                  //shouldUnregister={true}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      className={`${errors?.date?.message ? 'invalid' : ''}`}
                      id="filterEndDate"
                      dateFormat={DateFormat.Datepicker}
                      selected={value ? getDateOnly(value) : null}
                      onChange={(val) => {
                        onChange(val != null ? getDateOnly(val) : val);
                        filterUninvoicedItems(watch("filterStartDate"), val != null ? getDateOnly(val) : undefined, watch("filterUserIds"));
                      }}
                      showMonthDropdown
                      showYearDropdown
                      minDate={watch("filterStartDate")}
                      maxDate={watch("date")}
                      autoComplete="off"
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors?.filterEndDate?.message && (errors.filterEndDate.message)}
                </Form.Text>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className="mb-4" controlId="filterUserIds">
                <Form.Label className="required">Fee Earners</Form.Label>
                <Controller
                  control={control}
                  name={`filterUserIds`}
                  //shouldUnregister={true}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <CustomSelect
                      id="filterUserIds"
                      inputRef={ref}
                      className={`lp-select${errors?.filterUserIds?.message ? ' invalid' : ''}`}
                      endpointCall={() => getUserSummaryForLte(user.lawPageTradingEntityId!)}
                      value={value}
                      onChange={val => {
                        onChange(val?.map((x: { id: any }) => x.id) ?? null);
                        filterUninvoicedItems(watch("filterStartDate"), watch("filterEndDate"), val?.map((x: { id: any }) => x.id));
                      }}
                      isMulti
                      isClearable
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors?.filterUserIds?.message && (errors.filterUserIds?.message)}
                </Form.Text>
              </Form.Group>
              <Form.Group as={Col} className="lp-cancel-btn mb-4">
                <Button variant="secondary-400" onClick={toggleDateFilter}>Cancel</Button>
              </Form.Group>
            </Row>
          </>
        ) : (
          <Button variant="secondary-400" onClick={toggleDateFilter} className="my-4">Filter</Button>
        )}

        <div className="lp-color-title primary full-width">Recordable Items</div>
        <Form.Group controlId="matterRecordableItemIds">
          <Controller
            control={control}
            name="matterRecordableItemIds"
            shouldUnregister={true}
            render={({ field: { onChange, value } }) => (
              <div className="lp-box-list">
                {isLoadingRecordableItems && <Loader inlineLoader />}

                {recordableItems.length > 0 &&
                  recordableItems.map((x: MatterRecordableItemForInvoiceModel, index: number) => (
                    <div
                      key={x.id}
                      onClick={() => handleRecordableItemSelection(x.id!, onChange)}
                      className={`lp-box-list-item${
                        watch('matterRecordableItemIds')?.some((y: string) => y == x.id)
                          ? ' selected'
                          : ''
                      }`}
                    >
                      {watch('matterRecordableItemIds')?.some((y: string) => y == x.id) ? (
                        <span className="selected-icon">
                          <BsCheckCircleFill />
                        </span>
                      ) : (
                        <span className="unselected-icon">
                          <BsCheckCircle />
                        </span>
                      )}
                      <span className="type-icon date">
                        {moment(x?.date).format(DateFormat.Moment) + (x.user ? ' - ' + x.user?.name : '')}
                      </span>
                      <div className="lp-box-list-full-row">
                        <Field label={'Description'} value={x.description} />
                      </div>
                      <Field
                        label={'Amount Charged'}
                        value={
                          <>
                            <span>{formatCurrency(x.amountCharged)}</span>
                            {x.adjustmentAmountCharged > 0 &&
                              <span className="positive-value">{'+' + formatCurrency(x.adjustmentAmountCharged)}</span>
                            }
                            {x.adjustmentAmountCharged < 0 &&
                              <span className="negative-value">{formatCurrency(x.adjustmentAmountCharged)}</span>
                            }
                          </>
                        }
                      />
                      
                      <Field
                        label={'VAT Value'}
                        value={formatCurrency(x.calculatedVatValue)}
                        className="highlighted"
                      />
                    </div>
                  ))
                }
                {recordableItems.length == 0 &&
                  <div className="lp-list-empty">No unbilled recordable items found</div>
                }
              </div>
            )}
          />
          <Form.Text className="lp-error">
            {errors?.matterRecordableItemIds?.message && (errors.matterRecordableItemIds.message)}
          </Form.Text>
        </Form.Group>
        
        
        <div className="lp-color-title primary full-width">Incidental Expenses</div>
        <Form.Group controlId="matterIncidentalExpenseIds">
          <Controller
            control={control}
            name="matterIncidentalExpenseIds"
            shouldUnregister={true}
            render={({ field: { onChange, value } }) => (
              <div className="lp-box-list">
                {isLoadingIncidentalExpenses && <Loader inlineLoader />}

                {incidentalExpenses.length > 0 &&
                  incidentalExpenses.map((x: MatterIncidentalExpenseForInvoiceModel, index: number) => (
                    <div
                      key={x.id}
                      onClick={() => handleIncidentalExpenseSelection(x.id!, onChange)}
                      className={`lp-box-list-item${
                        watch('matterIncidentalExpenseIds')?.some((y: string) => y == x.id)
                          ? ' selected'
                          : ''
                      }`}
                    >
                      {watch('matterIncidentalExpenseIds')?.some((y: string) => y == x.id) ? (
                        <span className="selected-icon">
                          <BsCheckCircleFill />
                        </span>
                      ) : (
                        <span className="unselected-icon">
                          <BsCheckCircle />
                        </span>
                      )}
                      <span className="type-icon date">
                        {moment(x?.date).format(DateFormat.Moment) + (x.user ? ' - ' + x.user?.name : '')}
                      </span>
                      <div className="lp-box-list-full-row">
                        <Field label={'Description'} value={x.displayName} />
                      </div>
                      <Field
                        label={'Net Value'}
                        value={formatCurrency(x.netValue)}
                      />
                      {x.originalVATValue &&
                        <Field
                          label={'VAT Value'}
                          value={formatCurrency(x.vatValue)}
                        />
                      }
                      {!x.originalVATValue &&
                        <Field
                          label={'VAT Value'}
                          value={formatCurrency(x.calculatedVatValue)}
                          className="highlighted"
                        />
                      }
                    </div>
                  ))
                }
                {incidentalExpenses.length == 0 &&
                  <div className="lp-list-empty">No unbilled incidental expenses found</div>
                }
              </div>
            )}
          />
          <Form.Text className="lp-error">
            {errors?.matterIncidentalExpenseIds?.message && (errors.matterIncidentalExpenseIds.message)}
          </Form.Text>
        </Form.Group>

        <div className="lp-color-title primary full-width">Disbursements</div>
        <Form.Group controlId="matterDisbursementIds">
          <Controller
            control={control}
            name="matterDisbursementIds"
            shouldUnregister={true}
            render={({ field: { onChange, value } }) => (
              <div className="lp-box-list">
                {isLoadingDisbursements && <Loader inlineLoader />}

                {disbursements.length > 0 &&
                  disbursements.map((x: MatterDisbursementModel, index: number) => (
                    <div
                      key={x.id}
                      onClick={() => handleDisbursementSelection(x.id, onChange)}
                      className={`lp-box-list-item${
                        watch('matterDisbursementIds')?.some((y: string) => y == x.id)
                          ? ' selected'
                          : ''
                      }`}
                    >
                      {watch('matterDisbursementIds')?.some((y: string) => y == x.id) ? (
                        <span className="selected-icon">
                          <BsCheckCircleFill />
                        </span>
                      ) : (
                        <span className="unselected-icon">
                          <BsCheckCircle />
                        </span>
                      )}
                      <span className="type-icon date">
                        {moment(x?.date).format(DateFormat.Moment) + (x.user ? ' - ' + x.user?.name : '')}
                      </span>
                      <div className="lp-box-list-full-row">
                        <Field label={'Description'} value={x.displayName} />
                      </div>
                      <Field
                        label={'Net Value'}
                        value={formatCurrency(x.netValue)}
                      />
                      <Field
                        label={'VAT Value'}
                        value={formatCurrency(x.vatValue)}
                      />
                    </div>
                  ))
                }
                {disbursements.length == 0 &&
                  <div className="lp-list-empty">No unbilled disbursements found</div>
                }
              </div>
            )}
          />
          <Form.Text className="lp-error">
            {errors?.matterIncidentalExpenseIds?.message && (errors.matterIncidentalExpenseIds.message)}
          </Form.Text>
        </Form.Group>

        <div className="lp-color-title primary full-width">Total Values</div>
        <Row>
          <Form.Group as={Col} className="mb-4">
            <Field
              label={"Gross Value"}
              value={formatCurrency(totalValues.grossValue)}
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-4">
            <Field
              label={"VAT Value"}
              value={formatCurrency(totalValues.vatValue)}
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-4">
            <Field
              label={"Net Value"}
              value={formatCurrency(totalValues.netValue)}
            />
          </Form.Group>
        </Row>

        <div className="lp-color-title primary full-width">Invoice Values</div>
        <Row>
          <Form.Group as={Col} className="mb-4">
            <Field
              label={"Gross Value"}
              value={formatCurrency(totalGrossValueAfterAdjustment)}
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-4">
            <Field
              label={"VAT Value"}
              value={formatCurrency(totalVATValueAfterAdjustment)}
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-4" controlId="invoiceNetValue">
            <Form.Label>Net Value</Form.Label>
            <Form.Control
              type="number"
              className={`${errors?.invoiceNetValue?.message ? 'invalid' : ''}`}
              disabled={(watch('totalNetValueWithoutRecordableItems') == totalValues.netValue)}
              {...register(`invoiceNetValue`, {shouldUnregister: true})}
              min="0.00"
              step="0.01"
              onBlur={() => trigger('invoiceNetValue')}
              onWheel={e => e.currentTarget.blur()}
            />
            <Form.Text className="lp-error">
              {errors?.invoiceNetValue?.message && (errors.invoiceNetValue?.message)}
            </Form.Text>
          </Form.Group>
        </Row>
        
        <div className="lp-color-title primary full-width">Adjustments</div>
        <Row>
          <Form.Group as={Col} className="mb-4">
            <Field
              label={"Gross Value"}
              value={formatCurrency(adjustmentValues.grossValue)}
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-4">
            <Field
              label={"VAT Value"}
              value={formatCurrency(adjustmentValues.vatValue)}
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-4">
            <Field
              label={"Net Value"}
              value={formatCurrency(adjustmentValues.netValue)}
            />
          </Form.Group>
        </Row>

        <Form.Group className="d-flex justify-content-between">
          <Button variant="success" type="submit">Update</Button>
          <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
        </Form.Group>
      </Form>
    </>
  );
}
