import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col } from "react-bootstrap";
import { getMatterNoteById } from "actions/matter";
import { MatterNoteModel } from "models/view/MatterNoteModel";

type Props = {
  matterId: string,
  noteId: string
}

function ViewMatterNote(props: Props){
  const [isLoading, setIsLoading] = useState(true);
  const [matterNote, setMatterNote] = useState<MatterNoteModel>();
  const [genericErrors, setGenericErrors] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getMatterNoteById(props.matterId, props.noteId).then((response) => {
      setMatterNote(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, [])

  return(
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Note"}
            value={matterNote?.note}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Type"}
            value={matterNote?.noteType?.name}
          />
        </Col>
      </Row>
    </>
  );
}

export default ViewMatterNote;
