import { NotificationMessageModel } from "models/NotificationMessageModel";
import { useDispatch } from "react-redux";
import { addNotification, clearNotifications, removeNotification, resetNewNotificationAdded} from "state/notificationSlice";

const useNotificationActions = () => {
  const dispatch = useDispatch();

  const addNotificationMessage = (error: NotificationMessageModel) => {
    dispatch(addNotification(error));
  };

  const removeNotificationMessage = (index: number) => {
    dispatch(removeNotification(index));
  };

  const clearNotificationMessages = () => {
    dispatch(clearNotifications());
  };

  const resetNewNotificationAddedFlag = () => {
    dispatch(resetNewNotificationAdded());
  };

  return {
    addNotificationMessage,
    removeNotificationMessage,
    clearNotificationMessages,
    resetNewNotificationAddedFlag
  };
}

export default useNotificationActions;
