import isEmail from "validator/lib/isEmail";
import { create, test, enforce } from "vest";

export const validationSuite = create((data = {}) => {
  enforce.extend({isEmail});
  test('email', 'Email Address is required', () => {
    enforce(data.email).isNotEmpty();
    enforce(data.email).isString();
  });

  test('email', 'Email Address doesn\'t have the correct format.', () => {
    enforce(data.email).isEmail();
  });

  test('emailTypeId', 'Please select a Type', () => {
    enforce(data.emailTypeId).isNotEmpty();
    enforce(data.emailTypeId).isString();
  });
});