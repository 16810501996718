import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect } from "react";
import { MdAdd } from "react-icons/md";
import { columnDefs } from "./columnDefs";
import CreateLteBankAccountForm from "./CreateLteBankAccount/CreateLteBankAccountForm";
import { Card, Button } from "react-bootstrap";
import { getLteBankAccounts } from "actions/lte";
import useSlidingPanelActions from "actions/slidingPanel";
import Title from "components/Title/index";

type Props = {
  lteId: string
}

export default function LteBankAccountList(props: Props) {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();

  const onGridReady = useCallback(() =>
  {
    gridActions.setGridColumnDefs([...columnDefs]);
  }, [props.lteId]);  

  useEffect(() => {
    loadLteBankAccounts();
  }, [props.lteId]);

  const loadLteBankAccounts = () => {
    getLteBankAccounts(props.lteId).then((response) => 
    {
      gridActions.setGridRowData(response.data as any)
    });
  }

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Add Bank Account",
        children: <CreateLteBankAccountForm submitCallback={loadLteBankAccounts} />
      }
    );
  }

  return (
    <>
      <Title type="section" title={"Bank accounts"}>
        <Button onClick={toggleSlidingPanel} className="btn-icon" variant="success">
          <MdAdd />
        </Button>
      </Title>
      <Card className="with-grid">
        <Card.Body>
          <Grid 
            onGridReady={onGridReady}
          />
        </Card.Body>
      </Card>
    </>
  );
}
