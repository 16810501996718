import { LteBranchModel } from "models/view/LteBranchModel";
import { create, test, enforce, omitWhen } from "vest";

export const getValidationSuite = (lteBranches: LteBranchModel[]) => {
  return create((data = {}) => {
    test('userRoleId', 'Please select an User Role', () => {
      enforce(data.userRoleId).isNotEmpty();
      enforce(data.userRoleId).isString();
    });

    test('lteBranchId', 'Please select a Lte Branch', () => {
      enforce(data.lteBranchId).isNotEmpty();
      enforce(data.lteBranchId).isString();
    });

    const branchId = data.lteBranchId;
    const branch = branchId ? lteBranches.find(x => x.id === branchId) : undefined;
    omitWhen(
      !branch?.children || branch?.children.length === 0,
      () => {
        test('lteDepartmentId', 'Please select a Lte Department', () => {
          enforce(data.lteDepartmentId).isNotEmpty();
          enforce(data.lteDepartmentId).isString();
        });
      }
    );

    const dept = branch?.children && data.lteDepartmentId ? branch.children.find(x => x.id === data.lteDepartmentId) : undefined;
    omitWhen(
      !dept?.children || dept?.children.length === 0,
      () => {
        test('lteTeamId', 'Please select a Lte Team', () => {
          enforce(data.lteTeamId).isNotEmpty();
          enforce(data.lteTeamId).isString();
        });
      }
    );
  });
}