import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type ModalState = {
  title: string,
  body: React.ReactNode,
  onAction: Function,
  onSecondAction?: Function,
  show: boolean,
  actionText?: string,
  actionVariant?: string,
  secondActionText?: string,
  secondActionVariant?: string,
  loading?: boolean,
  errors?: string,
  onClose?: Function
}

const initialState: ModalState = {
  title: "Modal",
  body: "",
  onAction: () => {},
  onSecondAction: () => {},
  show: false,
  loading: false,
  actionText: undefined,
  actionVariant: undefined,
  secondActionText: undefined,
  secondActionVariant: undefined,
  errors: undefined,
  onClose: undefined
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalData: (state, action: PayloadAction<ModalState>) => {
      state.title = action.payload.title;
      state.body = action.payload.body;
      state.onAction = action.payload.onAction;
      state.actionText = action.payload.actionText;
      state.actionVariant = action.payload.actionVariant;
      state.show = action.payload.show;
      state.secondActionText = action.payload.secondActionText;
      state.secondActionVariant = action.payload.secondActionVariant;
      state.onSecondAction = action.payload.onSecondAction;
      state.errors = action.payload.errors;
      state.loading = action.payload.loading;
      state.onClose = action.payload.onClose;
    },
    toggleModal: (state) => {
      state.show = !state.show;
    },
    toggleModalLoadingState: (state) => {
      state.loading = !state.loading;
    },
    setModalErrors: (state, action: PayloadAction<string>) => {
      state.errors = action.payload;
    }
  },
})

export const { setModalData, toggleModal, toggleModalLoadingState, setModalErrors } = modalSlice.actions;

export default modalSlice.reducer;
