import { ColDef } from "ag-grid-community";
import { LteBankAccountModel } from "models/view/LteBankAccountModel";
import store from "state/store";
import { deleteLteBankAccount, getLteBankAccounts } from "actions/lte";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { setRowData } from "state/gridSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import ViewLteBankAccount from "./ViewLteBankAccount/ViewLteBankAccount";
import EditLteBankAccountForm from "./EditLteBankAccount/EditLteBankAccountForm";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";

const viewCallback = (id: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "View Bank Account",
        children: <ViewLteBankAccount id={id}/>
      }
    )
  );
}

const editCallback = (id: string, lteId: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Edit Bank Account",
        children: <EditLteBankAccountForm 
          bankAccountId={id} 
          submitCallback={() => {
            //reload the whole grid, because we might have bank accounts that changed
            getLteBankAccounts(lteId).then((response) => 
            {
              store.dispatch(setRowData(response.data));
            });
          }}
        />
      }
    )
  );
}

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this bank account?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteLteBankAccount(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const columnDefs: (ColDef<LteBankAccountModel>| {excludeFromExport: boolean})[] = [
  { 
    excludeFromExport: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    suppressMenu: true,
    suppressSizeToFit: true,
    floatingFilter: false,
    resizable: false,
    sortable: false,
    minWidth: 48,
    maxWidth: 48,
    width: 48,
    lockPosition: true,
    pinned: 'left'
  },
  {
    headerName: 'Bank Account Name',
    field: 'bankAccountName',
    filter: 'agTextColumnFilter',
    lockPosition: true,
    pinned: 'left'
  },
  {
    headerName: 'Bank Name',
    field: 'bankName',
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Bank Sort Code',
    field: 'bankSortCode',
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Bank Account Number',
    field: 'bankAccountNumber',
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Bank IBAN',
    field: 'bankIban',
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Is Receivable Account',
    field: 'isReceivableAccount',
    filter: BooleanFilter,
    filterParams: {
      property: 'isReceivableAccount',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: BooleanFloatingFilter,
    floatingFilterComponentParams: {
      values: [{
        id: 'true',
        name: 'Yes'
      },
      {
        id: 'false',
        name: 'No'
      },
    ]},
    minWidth: 100,
    cellRenderer: CheckboxCellRenderer,
    valueFormatter: CheckboxFormatter
  },
  {
    headerName: 'Is Client Account',
    field: 'isClientAccount',
    filter: BooleanFilter,
    filterParams: {
      property: 'isClientAccount',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: BooleanFloatingFilter,
    floatingFilterComponentParams: {
      values: [{
        id: 'true',
        name: 'Yes'
      },
      {
        id: 'false',
        name: 'No'
      },
    ]},
    minWidth: 100,
    cellRenderer: CheckboxCellRenderer,
    valueFormatter: CheckboxFormatter
  },
  {
    headerName: 'Is Payable Account',
    field: 'isPayableAccount',
    filter: BooleanFilter,
    filterParams: {
      property: 'isPayableAccount',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: BooleanFloatingFilter,
    floatingFilterComponentParams: {
      values: [{
        id: 'true',
        name: 'Yes'
      },
      {
        id: 'false',
        name: 'No'
      },
    ]},
    minWidth: 100,
    cellRenderer: CheckboxCellRenderer,
    valueFormatter: CheckboxFormatter
  },
  {
    headerName: 'Description',
    field: 'description',
    filter: 'agTextColumnFilter'
  },
  { 
    excludeFromExport: true,
    headerName: 'Actions',
    cellClass: 'lp-actions-cell',
    cellRenderer: ActionsCellRenderer, 
    cellRendererParams: (params: any) => {
      return {buttonsData: [
        { type: ActionButtonTypes.View, callback: () => viewCallback(params.data.id) },
        { type: ActionButtonTypes.Edit, callback: () => editCallback(params.data.id, params.data.lteId) },
        { type: ActionButtonTypes.Delete, callback: () => deleteModal(params.data.id) }
      ]};
    },
    suppressSizeToFit: true,
    floatingFilter: false,
    sortable: false,
    minWidth: 145,
    maxWidth: 145,
    width: 145,
    lockPosition: true,
    pinned: 'right',
    resizable: false
  }
];
