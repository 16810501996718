import { deleteEntityAddress, getEntityAddresses } from "actions/entity";
import Title from "components/Title/index";
import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";
import { Button } from "react-bootstrap";
import ListContact from "components/List/ListContact";
import { ContactModel } from "models/view/ContactModel";
import { EntityAddressModel } from "models/view/EntityAddressModel";
import CreateEntityAddressForm from "./CreateEntityAddress/CreateEntityAddressForm";
import { LinkedAddressModel } from "models/view/LinkedAddressModel";
import EditEntityAddressForm from "./EditEntityAddress/EditEntityAddressForm";
import { checkIfAddressIsLinked } from "actions/address";
import { formatAddress } from 'utils/address';
import EditLteAddressOnlyIsPreferredForm from "./EditEntityAddressOnlyIsPreferred/EditEntityAddressOnlyIsPreferredForm";

type Props = {
  entityId: string,
  edit: boolean
}

export default function LteAddressList(props: Props) {
  const [entityAddresses, setEntityAddresses] = useState<ContactModel[]>();
  const [loaderAddress, setLoaderAddress] = useState<boolean>(false);

  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();
  const [genericErrors, setGenericErrors] = useState(null);

  const loadContacts = () => {
    if(props.entityId) {
      setLoaderAddress(true);
      getEntityAddresses(props.entityId)
        .then((response) => 
        {
          const entityContactAddresses = [] as ContactModel[];
          response.data.forEach((x: EntityAddressModel) => {
            entityContactAddresses.push({
              id: x.addressId,
              contact: formatAddress(x?.address, x?.sourceEntityAddress?.entity?.displayName),
              type: x.address?.addressType?.name,
              isPreferred: x.isPreferred,
              systemOwned: x.address?.systemOwned,
              isLinked: x.sourceAddressId != undefined && x.sourceEntityId != undefined
            })
          });
          
          setEntityAddresses(entityContactAddresses);
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
        })
        .finally(() => {
          setLoaderAddress(false);
        });
    }
  }

  useEffect(() =>
  {
    loadContacts();
  }, [props.entityId]);

  const openCreateEntityAddressSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Create Address',
      children: <CreateEntityAddressForm
                  entityId={props.entityId}
                  submitCallback={reloadAndResetContacts} />
    });
  };

  const openEditEntityAddressSlidingPanel = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Edit Address',
      children: <EditEntityAddressForm 
                  entityId={props.entityId}
                  addressId={id}
                  submitCallback={reloadAndResetContacts}
                />
    });
  };

  const openEditEntityAddressOnlyIsPreferredSlidingPanel = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Edit Address',
      children: <EditLteAddressOnlyIsPreferredForm 
                  entityId={props.entityId}
                  addressId={id}
                  submitCallback={reloadAndResetContacts}
                />
    });
  };

  const reloadAndResetContacts = () => {
    loadContacts();
  }

  const deleteAddressModal = (id: string) => {
    checkIfAddressIsLinked(id, undefined, props.entityId)
      .then((response) => 
      {
        const filteredResponse = response.data.filter((p: LinkedAddressModel) => p.addressId !== id || p.entityId !== props.entityId);
        if(filteredResponse.length > 0){
          const linkedEntites = filteredResponse.map((x: LinkedAddressModel) =>
            x.lawPageTradingEntity ? x.lawPageTradingEntity.legalName + ' (trading entity)': (x.entity ? x.entity.displayName + ' (entity)' : undefined) );
          let modal: ModalState = {
            title: "Delete confirmation",
            actionText: "For all entities",
            secondActionText: "Just for this entity",
            body: 
              <>
                <p>This address is also linked to following entities:</p>
                <ul>
                  {linkedEntites.filter((x: LinkedAddressModel) => x !== undefined).map((entityName: any) => {
                    return <li>{entityName}</li>;
                  })}
                </ul>
                <div>Do you want to delete the address assigned only to this entity or for all entities?</div>
              </>,
            onAction: () => {
              modalActions.toggleModalLoading();
              deleteEntityAddress(props.entityId, id, true).then(() => {
                reloadAndResetContacts();
              })
              .catch((error) => {
                setGenericErrors(error.response?.data?.Message ?? error.message);
              })
              .finally(() => {
                modalActions.toggleModalLoading();
                modalActions.toggleModalShownStatus();
              });
            },
            onSecondAction: () => {
              modalActions.toggleModalLoading();
              deleteEntityAddress(props.entityId, id, false).then(() => {
                reloadAndResetContacts();
              })
              .catch((error) => {
                setGenericErrors(error.response?.data?.Message ?? error.message);
              })
              .finally(() => {
                modalActions.toggleModalLoading();
                modalActions.toggleModalShownStatus();
              });
            },
            show: true
          }
          modalActions.setModal(modal);
        }
        else{
          let modal: ModalState = {
            title: "Delete confirmation",
            body: "Are you sure you want to delete this address?",
            actionText: "Delete",
            actionVariant: "danger",
            onAction: () => {
              modalActions.toggleModalLoading();
              deleteEntityAddress(props.entityId, id, true).then(() => {
                reloadAndResetContacts();
              })
              .catch((error) => {
                setGenericErrors(error.response?.data?.Message ?? error.message);
              })
              .finally(() => {
                modalActions.toggleModalLoading();
                modalActions.toggleModalShownStatus();
              });
            },
            show: true
          }
          modalActions.setModal(modal);
        }
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      });
  };

  const unlinkCallbackEntityAddress = (id: string) => {
    modalActions.toggleModalLoading();
    deleteEntityAddress(props.entityId, id, false).then(() => {
      reloadAndResetContacts();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    }).finally(() => {
      modalActions.toggleModalLoading();
      modalActions.toggleModalShownStatus();
    });
  };

  const unlinkEntityAddressModal = (id: string) => {
    let modal: ModalState = {
      title: "Unlink confirmation",
      body: "Are you sure you want to unlink this address?",
      actionText: "Unlink",
      actionVariant: "danger",
      onAction: () => unlinkCallbackEntityAddress(id),
      show: true
    }
    modalActions.setModal(modal);
   };

  return (
    <>
      <Title type="section" title={"Addresses"}>
        <Button onClick={openCreateEntityAddressSlidingPanel} className="btn-icon" variant="success">
          <MdAdd />
        </Button>
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <ListContact
        listItems={entityAddresses ?? []}
        listButtons={
          [
            {
              type: ActionButtonTypes.Edit,
              callback: (id: string) => openEditEntityAddressSlidingPanel(id)
            },
            {
              type: ActionButtonTypes.EditOnlyIsPreferred,
              callback: (id: string) => openEditEntityAddressOnlyIsPreferredSlidingPanel(id)
            },
            {
              type: ActionButtonTypes.Delete,
              callback: (id: string) => deleteAddressModal(id)
            },
            {
              type: ActionButtonTypes.Unlink,
              callback: (id: string) => unlinkEntityAddressModal(id)
            }
          ]
        }
        loadState={loaderAddress}
      >
        <>
          <div>{"Address"}</div>
          <div>{"Type"}</div>
          <div></div>
        </>
      </ListContact>
    </>
  );
}
