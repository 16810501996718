import isUUID from "validator/lib/isUUID";
import { create, test, enforce } from "vest";

export const getValidationSuite = () => {
  return create((data = {}) => {
    enforce.extend({isUUID});

    test('storageTenantId', 'Storage Tenant ID is required', () => {
      enforce(data.storageTenantId).isNotEmpty();
      enforce(data.storageTenantId).isString();
    });

    test('storageTenantId', 'Storage Tenant ID must be a GUID', () => {
      enforce(data.storageTenantId).isUUID();
    });

    test('storageSiteId', 'Storage Site ID is required', () => {
      enforce(data.storageSiteId).isNotEmpty();
      enforce(data.storageSiteId).isString();
    });

    test('storageSiteId', 'Storage Site ID must be a GUID', () => {
      enforce(data.storageSiteId).isUUID();
    });
  });
}