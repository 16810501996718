import { CountryModel } from "models/view/CountryModel";
import { get } from "utils/request";

const getCountries = async (): Promise<any> => {
  const response = await get<CountryModel[]>('/management/country/summary');
  return response;
}

export { 
  getCountries
};
