import moment from 'moment';
import 'moment-timezone';
import { Calendar, momentLocalizer, SlotInfo, ToolbarProps, View, Views } from 'react-big-calendar';
import { CalendarEventModel } from 'models/CalendarEventModel';
import { Dispatch, SetStateAction, useMemo } from 'react';
import Loader from 'components/Loader';
import { getFreeBusyStatusFromValue } from 'utils/misc';
import Toolbar from './CustomizedComponents/Toolbar';
import WeekHeader from './CustomizedComponents/WeekHeader';
import CustomEvent from './CustomizedComponents/CustomEvent';
import './style.scss';

const eventStyleGetter = (event: CalendarEventModel) => {
  const pastEventClassname = event.isPastEvent ? ' past-event' : '';
  const className = getFreeBusyStatusFromValue(event.showAs) + pastEventClassname;
  
  return {
    className
  };
}

const slotPropGetter = (slotDate: Date) => {
  const currentHour = new Date().getHours();
  const style = {
    fontWeight: slotDate.getHours() === currentHour ? "bold" : "normal"
  };

  return {
    style
  };
};

const viewClassNames = (view: View) => {
  switch (view) {
    case Views.MONTH:
      return 'rbc-month-view';
    case Views.WEEK:
      return 'rbc-week-view';
    case Views.DAY:
      return 'rbc-day-view';
    default:
      return '';
  }
};

type Props = {
  handleEventClick?: (event: any) => void,
  handleSelectSlot?: (slotInfo: SlotInfo) => void,
  onRangeChange: Function,
  events: CalendarEventModel[],
  view: View
  setView: Dispatch<SetStateAction<View>>
  isLoading?: boolean,
  selectable?: boolean
}

export default function DashboardCalendar(props: Props) {
  const { getNow, defaultDate, localizer, scrollToTime, formats, views } =
    useMemo(() => {
      return {
        defaultDate: moment().toDate(),
        getNow: () => moment().toDate(),
        localizer: momentLocalizer(moment),
        scrollToTime: moment().toDate(),
        views: [Views.MONTH, Views.WEEK, Views.DAY],
        formats: {
          timeGutterFormat: (date: any, culture: any, localizer: any) => localizer.format(date, 'h A', culture),
          eventTimeRangeFormat: () => { return "" },
        },
      }
    }, []);
  
  return (
    <>
      {props.isLoading && <Loader inlineLoader />}
      
      <Calendar
        localizer={localizer}
        components={{
          toolbar: (toolbarProps: ToolbarProps) => ( <Toolbar {...toolbarProps} setView={props.setView} />),
          event: CustomEvent,
          week: {
            header: WeekHeader
          }
        }}
        formats={formats}
        views={views}
        className={viewClassNames(props.view)}
        defaultView={Views.WEEK}
        events={props.events}
        startAccessor="startDate"
        endAccessor="endDate"
        allDayAccessor="isAllDay"
        onSelectEvent={props.handleEventClick}
        onSelectSlot={props.handleSelectSlot}
        onRangeChange={(range: Date[] | { start: Date; end: Date }) => props.onRangeChange(range)}
        getNow={getNow}
        defaultDate={defaultDate}
        scrollToTime={scrollToTime}
        //disable tooltip
        tooltipAccessor={() => ""}
        eventPropGetter={eventStyleGetter}
        slotPropGetter={slotPropGetter}
        popup
        selectable={props.selectable}
      />
    </>
  );
}
