import SlidingPanel from "components/SlidingPanel/index";
import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect } from "react";
import { MdAdd } from "react-icons/md";
import { columnDefs } from "./columnDefs";
import { Card, Button } from "react-bootstrap";
import CustomModal from "components/Modal/Modal";
import { getAllUsers } from "actions/user";
import CreateLteUserForm from "./CreateLteUser/CreateLteUserForm";
import useSlidingPanelActions from "actions/slidingPanel";
import { LawPageTradingEntityModel } from "models/view/LawPageTradingEntityModel";
import Title from 'components/Title';

type Props = {
  lte: LawPageTradingEntityModel | undefined
}

export default function LteUserList(props: Props) {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();

  const onGridReady = useCallback(() =>
  {
    gridActions.setGridColumnDefs([...columnDefs]);
  }, []); 

  useEffect(() => {
    if(props.lte?.id) {
      getAllUsers(props.lte?.id!).then((response) => 
      {
        gridActions.setGridRowData(response.data as any)
      });
    }
    else {
      gridActions.setGridRowData([]);
    }
  }, [props.lte]);

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Add User",
        children: <CreateLteUserForm />
      });
  }

  return (
    <>
      <Title type="section" title={"Users"}>
        <Button onClick={toggleSlidingPanel} className="btn-icon" variant="success" disabled={!props.lte?.isActive}>
          <MdAdd />
        </Button>
      </Title>
      <Card className="with-grid">
        <Card.Body>
          <Grid 
            onGridReady={onGridReady}
          />
        </Card.Body>
      </Card>

      <SlidingPanel/>

      <CustomModal />
    </>
  );
}
