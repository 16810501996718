import { ColDef, ColGroupDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { dateComparator } from "components/Grid/Comparators/DateComparator";
import { DateFormatter } from "components/Grid/ValueFormatters/DateFormatter";
import store from "state/store";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ChartOfAccountsLedgerEntryModel } from "models/view/ChartOfAccountsLedgerEntryModel";
import EditChartOfAccountsLedgerEntryForm from "./EditChartOfAccountsLedgerEntry/EditChartOfAccountsLedgerEntryForm";
import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";
import { AccountLedgerTransactionTypes } from "enums/AccountLedgerTransactionTypes";
import ViewMatterDisbursement from "containers/Matter/ViewMatter/ActivityHistory/MatterDisbursement/ViewMatterDisbursement/ViewMatterDisbursement";
import ViewMatterIncidentalExpense from "containers/Matter/ViewMatter/ActivityHistory/MatterIncidentalExpense/ViewMatterIncidentalExpense/ViewMatterIncidentalExpense";
import ViewMatterInvoice from "containers/Matter/ViewMatter/ActivityHistory/MatterInvoice/ViewMatterInvoice/ViewMatterInvoice";
import ViewMatterInvoicePayments from "containers/Matter/ViewMatter/ActivityHistory/MatterInvoice/ViewMatterInvoicePayments/ViewMatterInvoicePayments";
import ViewMatterReceiveMoney from "containers/Matter/ViewMatter/ActivityHistory/MatterReceiveMoney/ViewMatterReceiveMoney/ViewMatterReceiveMoney";
import ViewMatterFundsTransfer from "containers/Matter/ViewMatter/ActivityHistory/MatterFundsTransfer/ViewMatterFundsTransfer/ViewMatterFundsTransfer";
import ViewMatterDisbursementPayments from "containers/Matter/ViewMatter/ActivityHistory/MatterDisbursement/ViewMatterDisbursementPayments/ViewMatterDisbursementPayments";
import MatterDisbursementWriteOffForm from "containers/Matter/ViewMatter/ActivityHistory/MatterDisbursement/MatterDisbursementWriteOff/MatterDisbursementWriteOffForm";
import MatterIncidentalExpenseWriteOffForm from "containers/Matter/ViewMatter/ActivityHistory/MatterIncidentalExpense/MatterIncidentalExpenseWriteOff/MatterIncidentalExpenseWriteOffForm";
import MatterInvoiceWriteOffForm from "containers/Matter/ViewMatter/ActivityHistory/MatterInvoice/MatterInvoiceWriteOff/MatterInvoiceWriteOffForm";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { getLteBranchesSummary, getLteDepartmentsSummary, getLteTeamsSummary } from "actions/lte";
import ViewAccountLedgerTransaction from "components/SlidingPanel/ViewAccountLedgerTransaction/ViewAccountLedgerTransaction";
import ViewMatterReturnMoney from "containers/Matter/ViewMatter/ActivityHistory/MatterReturnMoney/ViewMatterReturnMoney/ViewMatterReturnMoney";
import MatterInvoiceAbatementForm from "containers/Matter/ViewMatter/ActivityHistory/MatterInvoice/MatterInvoiceAbatement/MatterInvoiceAbatementForm";

const viewTransactionCallback = (rowData: ChartOfAccountsLedgerEntryModel) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        width: "70rem",
        title: "View Ledger Transaction",
        children: <ViewAccountLedgerTransaction
          accountLedgerTransactionId={rowData.accountLedgerTransactionId}
          showMatterInfo
        />
      }
    )
  );
}

const editCallback = (rowData: ChartOfAccountsLedgerEntryModel) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Edit Ledger Entry",
        children: <EditChartOfAccountsLedgerEntryForm 
            ledgerTransactionEntryId={rowData.accountLedgerTransactionEntryId}
          />
      }
    )
  );
}

const viewParentResourceCallback = (rowData: ChartOfAccountsLedgerEntryModel, reloadGridData?: Function) => {
  const gridState = store.getState().grid;
  switch(rowData.accountLedgerTransactionType) { 
    case AccountLedgerTransactionTypes.Disbursement: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "View Disbursement",
            children: <ViewMatterDisbursement
              matterId={rowData.matterId}
              matterDisbursementId={rowData.matterDisbursementId!}
              showActions
              onDeleteCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
              onEditCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
              onRecordPaymentCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
              onWriteOffCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
            />
          }
        )
      );
      return;
    }
    case AccountLedgerTransactionTypes.DisbursementPayment: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Disbursement Payments",
            children: <ViewMatterDisbursementPayments
              matterId={rowData.matterId}
              matterDisbursementId={rowData.matterDisbursementId!}
              onPaymentsChangedCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
            />
          }
        )
      );
      return;
    }
    case AccountLedgerTransactionTypes.DisbursementWriteOff: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Disbursement Write-off",
            children: <MatterDisbursementWriteOffForm
              matterId={rowData.matterId}
              matterDisbursementId={rowData.matterDisbursementId!}
              onSubmitCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
              onDeleteCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
            />
          }
        )
      );
      return;
    }
    case AccountLedgerTransactionTypes.IncidentalExpense: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "View Incidental Expense",
            children: <ViewMatterIncidentalExpense
              matterId={rowData.matterId}
              matterIncidentalExpenseId={rowData.matterIncidentalExpenseId!}
              showActions
              onDeleteCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
              onEditCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
              onWriteOffCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
            />
          }
        )
      );
      return;
    }
    case AccountLedgerTransactionTypes.IncidentalExpenseWriteOff: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Incidental Expense Write-off",
            children: <MatterIncidentalExpenseWriteOffForm
              matterId={rowData.matterId}
              matterIncidentalExpenseId={rowData.matterIncidentalExpenseId!}
              onSubmitCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
              onDeleteCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
            />
          }
        )
      );
      return;
    }
    case AccountLedgerTransactionTypes.Invoice: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            width: "55rem",
            title: "View Invoice",
            children: <ViewMatterInvoice 
              matterId={rowData.matterId}
              matterInvoiceId={rowData.matterInvoiceId!}
              showActions
              onMarkAsDraftCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
              onDeleteCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
              onEditCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
              onRecordPaymentCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
              onWriteOffCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
              onAbatementCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
            />
          }
        )
      );
      return;
    }
    case AccountLedgerTransactionTypes.InvoicePayment: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            width: "55rem",
            title: "Invoice Payments",
            children: <ViewMatterInvoicePayments 
              matterId={rowData.matterId}
              matterInvoiceId={rowData.matterInvoiceId!}
            />,
            onCancel: () => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}
          }
        )
      );
      return;
    }
    case AccountLedgerTransactionTypes.InvoiceWriteOff: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Invoice Write-Off",
            children: <MatterInvoiceWriteOffForm
              matterId={rowData.matterId}
              matterInvoiceId={rowData.matterInvoiceId!}
              onSubmitCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
              onDeleteCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
            />
          }
        )
      );
      return;
    }
    case AccountLedgerTransactionTypes.InvoiceAbatement: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Invoice Abatement",
            children: <MatterInvoiceAbatementForm
              matterId={rowData.matterId}
              matterInvoiceId={rowData.matterInvoiceId!}
              onSubmitCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
              onDeleteCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
            />
          }
        )
      );
      return;
    }
    case AccountLedgerTransactionTypes.ReceiveMoney: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "View Receive Money",
            children: <ViewMatterReceiveMoney
              matterId={rowData.matterId}
              matterReceiveMoneyId={rowData.matterReceiveMoneyId!}
              showActions
              onDeleteCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
              onEditCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
            />
          }
        )
      );
      return;
    }
    case AccountLedgerTransactionTypes.ReturnMoney: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "View Return Money",
            children: <ViewMatterReturnMoney
              matterId={rowData.matterId}
              matterReturnMoneyId={rowData.matterReturnMoneyId!}
              showActions
              onDeleteCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
              onEditCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
            />
          }
        )
      );
      return;
    }
    case AccountLedgerTransactionTypes.FundsTransfer: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "View Funds Transfer",
            children: <ViewMatterFundsTransfer
              matterId={rowData.matterId}
              matterFundsTransferId={rowData.matterFundsTransferId!}
              showActions
              onDeleteCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
              onEditCallback={() => reloadGridData ? reloadGridData(gridState.advancedFilter) : {}}
            />
          }
        )
      );
      return;
    }
    default: { 
      return;
    }
  }
}

export const getColumnDefs = (reloadGridData?: Function) => {
  const columnDefs: (ColDef<ChartOfAccountsLedgerEntryModel> | ColGroupDef<ChartOfAccountsLedgerEntryModel> | {excludeFromExport: boolean})[] = [
    { 
      excludeFromExport: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      suppressMenu: true,
      suppressSizeToFit: true,
      floatingFilter: false,
      resizable: false,
      sortable: false,
      minWidth: 48,
      maxWidth: 48,
      width: 48,
      lockPosition: true,
      pinned: 'left'
    },
    { 
      headerName: 'Date',
      field: 'accountLedgerTransactionDate',
      filter: 'agDateColumnFilter',
      sortable: false,
      suppressMenu: true,
      valueFormatter: DateFormatter,
      filterParams: {
        comparator: dateComparator
      },
      minWidth: 120
    },
    { 
      headerName: 'Matter',
      field: 'matterFileNumberWithDisplayName',
      filter: 'agTextColumnFilter',
      sortable: false,
      suppressMenu: true,
      minWidth: 180
    },
    { 
      headerName: 'Details',
      field: 'accountLedgerTransactionDescription',
      filter: 'agTextColumnFilter',
      sortable: false,
      suppressMenu: true,
      minWidth: 400
    },
    {
      headerName: 'DR',
      field: 'drAmount',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      sortable: false,
      suppressMenu: true
    },
    {
      headerName: 'CR',
      field: 'crAmount',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      sortable: false,
      suppressMenu: true
    },
    {
      headerName: 'Balance',
      field: 'balance',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      sortable: false,
      suppressMenu: true
    },
    { 
      headerName: 'Notes',
      field: 'accountLedgerTransactionEntryNotes',
      filter: 'agTextColumnFilter',
      sortable: false,
      suppressMenu: true,
      flex: 1
    },
    {
      headerName: 'Fee Earner',
      field: 'user.name',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      minWidth: 150,
      width: 150
    },
    { 
      headerName: 'Branch',
      field: 'branch.name', 
      filter: DropdownFilter,
      filterParams: {
        property: 'branchId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getLteBranchesSummary
      },
      suppressMenu: true,
      minWidth: 220
    },
    { 
      headerName: 'Department',
      field: 'department.name', 
      filter: DropdownFilter,
      filterParams: {
        property: 'departmentId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getLteDepartmentsSummary
      },
      suppressMenu: true,
      minWidth: 220
    },
    { 
      headerName: 'Team',
      field: 'team.name', 
      filter: DropdownFilter,
      filterParams: {
        property: 'teamId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getLteTeamsSummary
      },
      suppressMenu: true,
      minWidth: 220
    },
    { 
      excludeFromExport: true,
      headerName: 'Actions',
      cellClass: 'lp-actions-cell',
      suppressMenu: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return { buttonsData: [
          {
            type: ActionButtonTypes.View,
            callback: () => viewTransactionCallback(params.data)
          },
          {
            type: ActionButtonTypes.Edit,
            callback: () => editCallback(params.data)
          },
          {
            type: ActionButtonTypes.ViewParentResource,
            callback: () => viewParentResourceCallback(params.data, reloadGridData)
          },
        ]};
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 145,
      maxWidth: 145,
      width: 145,
      lockPosition: true,
      pinned: 'right',
      resizable: false
    }
  ];

  return columnDefs;
}