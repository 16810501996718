import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { getMatterNoteById, getNoteTypes, updateMatterNote } from "actions/matter";
import { CreateOrUpdateMatterNoteModel } from "models/create/CreateOrUpdateMatterNoteModel";
import { MatterNoteModel } from "models/view/MatterNoteModel";
import { MatterActivityHistoryModel } from "models/view/MatterActivityHistoryModel";
import useGridActions from "actions/grid";
import { useAppSelector } from "hooks/appSelector";
import CustomSelect from "components/Select/Select";

type Props = {
  matterId: string,
  noteId: string
}

export default function EditMatterNoteForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const gridActions = useGridActions();
  const grid = useAppSelector((state) => state.grid);

  const {reset, control, register, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateMatterNoteModel>({
    resolver: vestResolver(validationSuite)
  });

  useEffect(() => {
    setIsLoading(true);
    getMatterNoteById(props.matterId, props.noteId).then((response) => {
      let initialState: CreateOrUpdateMatterNoteModel = {
        note: response.data.note,
        noteTypeId: response.data.noteTypeId
      };

      reset(initialState);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
      
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: CreateOrUpdateMatterNoteModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    updateMatterNote(props.matterId, props.noteId, data)
      .then((response) => {
        let index: number = grid.rowData.findIndex((x: any) => x.matterNoteId === response.data.id);
        let matterNote: MatterNoteModel = {...response.data};
        let newArray: Array<MatterActivityHistoryModel> = [...grid.rowData];
        let currentRow: MatterActivityHistoryModel = {...newArray[index]};
        currentRow.description = matterNote?.note; 
        newArray[index] = currentRow;
        gridActions.setGridRowData(newArray);
        slidingPanelActions.clearSlidingPanel();
        reset();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-4" controlId="note">
          <Form.Label className="required">Note</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            className={`${errors?.note?.message ? 'invalid' : ''}`}
            {...register("note", {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.note?.message && (errors.note.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="noteTypeId">
          <Form.Label className="required">Type</Form.Label>
          <Controller
            control={control}
            name="noteTypeId"
            shouldUnregister={true}
            render={({field: { onChange, value, name, ref }}) => (
              <CustomSelect
                id="noteTypeId"
                inputRef={ref}
                className={`lp-select${errors?.noteTypeId?.message ? ' invalid' : ''}`}
                endpointCall={getNoteTypes}
                value={value}
                onChange={val => onChange(val?.id ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.noteTypeId?.message && (errors.noteTypeId.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="d-flex justify-content-between">
          <Button variant="success" type="submit">Update</Button>
          <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
        </Form.Group>
      </Form>
    </>
  );
}
