import { enforce, test, create, omitWhen } from "vest";

export const getValidationSuite = (
  showFeeEarnersFilter?: boolean,
  showDateFilter?: boolean
) => {
  return create((data = {}) => {
    omitWhen(!showDateFilter || !data.startDate || !data.endDate, () => {
      test('endDate', 'End Date needs to be greater than or equals Start Date.', () => {
        const calculatedMinutesDif = data.endDate.getTime() - data.startDate.getTime();
        enforce(calculatedMinutesDif).greaterThanOrEquals(0);
      });
    });
  });
}