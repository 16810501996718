import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col, Button } from "react-bootstrap";
import { getMatterActivityHistoryByFileId, getMatterFileById, getMatterFilePreview } from "actions/matter";
import { MatterFileModel } from "models/view/MatterFileModel";
import { BiLinkExternal } from 'react-icons/bi';
import { openUrlInNewtab } from "utils/misc";
import useSlidingPanelActions from "actions/slidingPanel";
import EditActivityHistoryTaxonomyTermsForm from "../../ActivityHistoryGrid/ActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTermsForm";
import { useAppSelector } from "hooks/appSelector";
import { MatterActivityHistoryModel } from "models/view/MatterActivityHistoryModel";

type Props = {
  matterId: string,
  fileId: string
}

function PreviewMatterFile(props: Props){
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [matterFile, setMatterFile] = useState<MatterFileModel>();
  const [matterFilePreviewUrl, setMatterFilePreviewUrl] = useState<string>();
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const [activityHistoryRecord, setActivityHistoryRecord] = useState<MatterActivityHistoryModel | undefined>(undefined);

  useEffect(() => {
    setIsLoading(true);
    const promises: Promise<any>[] = [];
    promises.push(getMatterFileById(props.matterId, props.fileId));
    promises.push(getMatterFilePreview(props.matterId, props.fileId));
    promises.push(getMatterActivityHistoryByFileId(props.matterId, props.fileId));

    Promise.all(promises).then(([getMatterResponse, getMatterPreviewResponse, activityHistoryResponse]) => {
      setMatterFile(getMatterResponse.data);
      setMatterFilePreviewUrl(getMatterPreviewResponse.data.getUrl);
      setActivityHistoryRecord(activityHistoryResponse.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  function onClickEditTaxonomyTermsAndNotes() {
    if(activityHistoryRecord?.id) {
      //close View Matter File Preview sliding panel
      slidingPanelActions.clearSlidingPanel();
      //open Edit Taxonomy Terms sliding panel having onCancel event to reopen 
      //Edit Matter File Preview sliding panel
      slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Edit Taxonomy Terms",
        children: <EditActivityHistoryTaxonomyTermsForm
          matterId={props.matterId}
          activityHistoryId={activityHistoryRecord.id}
          onSubmitCallback={reopenPreview}
        />,
        onCancel: reopenPreview
      });
    }
  }

  function reopenPreview() {
    //open back View Matter File Preview sliding panel
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState
    });
  }

  return(
    <div className="lp-iframe-panel">
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"File Name"}
            value={matterFile?.name}
          />
        </Col>
      </Row>

      {matterFilePreviewUrl &&
        <>
          <Row className="full-height">
            <Col>
              <iframe src={matterFilePreviewUrl} title="Matter File Preview" height="100%" width="100%"></iframe> 
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="d-flex justify-content-between">
                <Button variant="primary" onClick={() => openUrlInNewtab(matterFilePreviewUrl)}>
                  <BiLinkExternal />
                  Open in new tab
                </Button>
                {activityHistoryRecord?.id &&
                  <Button onClick={onClickEditTaxonomyTermsAndNotes} variant="secondary-400">
                  Edit Taxonomy/Notes
                </Button>
                }
              </div>
            </Col>
          </Row>
        </>
      }
    </div>
  );
}

export default PreviewMatterFile;
