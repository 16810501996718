import { Navigate } from "react-router-dom";

type Props = {
  redirectPath: string,
  isAllowed: boolean,
  children: JSX.Element
}

export default function ProtectedRoute(props: Props) {
  if(!props.isAllowed) {
    return <Navigate to={props.redirectPath} />;
  }
  return props.children;
}
