import { Button } from "react-bootstrap";

type Props = {
  className: string,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  icon: JSX.Element
}

export default function ViewButtonGrid(props: Props) {
  return (
    <>
      <Button variant={`${props.className}`} onClick={props.onClick} className="btn-icon">
        {props.icon}
      </Button>
    </>
  );
}
