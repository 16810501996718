import './style.scss';

type Props = {
  inlineLoader?: boolean;
  text?: string;
}

export default function Loader(props: Props) {
  return (
    <div className="lp-loader">
      {props.inlineLoader ?? false ? (
        <div className="inline">
          <div className="inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="big">
          <div className="inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          {props.text &&
            <div className="lp-loader-text">{props.text}</div>
          }
        </div>
      )}
    </div>
  );
}
