import { deleteLtePhoneNumber, getLtePhoneNumbers } from "actions/lte";
import Title from "components/Title/index";
import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";
import { Button } from "react-bootstrap";
import { LtePhoneNumberModel } from "models/view/LtePhoneNumberModel";
import ListContact from "components/List/ListContact";
import { ContactModel } from "models/view/ContactModel";
import CreateLtePhoneNumberForm from "./CreateLtePhoneNumber/CreateLtePhoneNumberForm";
import EditLtePhoneNumberForm from "./EditLtePhoneNumber/EditLtePhoneNumberForm";
import { checkIfPhoneNumberIsLinked } from "actions/phoneNumber";
import { LinkedPhoneNumberModel } from "models/view/LinkedPhoneNumberModel";

type Props = {
  lteId: string,
  edit?: boolean
}

export default function LtePhoneNumberList(props: Props) {
  const [ltePhoneNumbers, setLtePhoneNumbers] = useState<ContactModel[]>();
  const [loaderPhoneNumber, setLoaderPhoneNumber] = useState<boolean>(false);

  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();

  const loadContacts = () => {
    if(props.lteId) {
      setLoaderPhoneNumber(true);
      getLtePhoneNumbers(props.lteId)
        .then((response) => {
          const lteContactPhoneNumbers = [] as ContactModel[];
          response.data.forEach((x: LtePhoneNumberModel) => {
            lteContactPhoneNumbers.push({
              id: x.phoneNumberId,
              contact: x.phoneNumber?.number,
              type: x.phoneNumber?.phoneType?.name,
              isPreferred: x.isPreferred,
              systemOwned: x.phoneNumber?.systemOwned,
              additionalInfo: x.phoneNumber?.extension,
            })
          });
          
          setLtePhoneNumbers(lteContactPhoneNumbers);
        })
        .finally(() => {
          setLoaderPhoneNumber(false);
        });
    }
  }

  useEffect(() =>
  {
    loadContacts();
  }, [props.lteId]);

  const openCreateLtePhoneNumberSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Create Phone Number',
      children: <CreateLtePhoneNumberForm
                  lteId={props.lteId}
                  submitCallback={reloadAndResetContacts} />
    });
  };

  const openEditLtePhoneNumberSlidingPanel = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Edit Phone Number',
      children: <EditLtePhoneNumberForm 
                  lteId={props.lteId}
                  phoneNumberId={id}
                  submitCallback={reloadAndResetContacts}
                />
    });
  };

  const reloadAndResetContacts = () => {
    loadContacts();
  }

  const deletePhoneNumberModal = (id: string) => {
    checkIfPhoneNumberIsLinked(id, props.lteId)
      .then((response) => 
      {
        const filteredResponse = response.data.filter((p: any) => p.phoneNumberId !== id || p.entityId !== props.lteId);
        if(filteredResponse.length > 0){
          const linkedEntites = filteredResponse.map((x: LinkedPhoneNumberModel) =>
            x.lawPageTradingEntity ? x.lawPageTradingEntity.legalName + ' (trading entity)': (x.entity ? x.entity.displayName + ' (entity)' : undefined) );
          let modal: ModalState = {
            title: "Delete confirmation",
            actionText: "For all entities",
            secondActionText: "Just for this entity",
            body: 
              <>
                <p>This phone number is also linked to following entities:</p>
                <ul>
                  {linkedEntites.filter((x: any) => x !== undefined).map((entityName: any) => {
                    return <li>{entityName}</li>;
                  })}
                </ul>
                <div>Do you want to delete the number assigned only to this entity or for all entities?</div>
              </>,
            onAction: () => {
              modalActions.toggleModalLoading();
              deleteLtePhoneNumber(props.lteId, id, true).then(() => {
                reloadAndResetContacts();
                modalActions.toggleModalShownStatus();
              })
              .catch((error) => {
                modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
              })
              .finally(() => {
                modalActions.toggleModalLoading();
              });
            },
            onSecondAction: () => {
              modalActions.toggleModalLoading();
              deleteLtePhoneNumber(props.lteId, id, false).then(() => {
                reloadAndResetContacts();
                modalActions.toggleModalShownStatus();
              })
              .catch((error) => {
                modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
              })
              .finally(() => {
                modalActions.toggleModalLoading();
              });
            },
            show: true
          }
          modalActions.setModal(modal);
        }
        else{
          let modal: ModalState = {
            title: "Delete confirmation",
            body: "Are you sure you want to delete this phone number?",
            actionText: "Delete",
            actionVariant: "danger",
            onAction: () => {
              modalActions.toggleModalLoading();
              deleteLtePhoneNumber(props.lteId, id, true).then(() => {
                reloadAndResetContacts();
                modalActions.toggleModalShownStatus();
              })
              .catch((error) => {
                modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
              })
              .finally(() => {
                modalActions.toggleModalLoading();
              });
            },
            show: true
          }
          modalActions.setModal(modal);
        }
      })
  };

  return (
    <>
      <Title type="section" title={"Phone Numbers"}>
        {props.edit &&
          <Button onClick={openCreateLtePhoneNumberSlidingPanel} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>
      <ListContact
        listItems={ltePhoneNumbers ?? []}
        listButtons={
          [
            {
              type: ActionButtonTypes.Call
            },
            ...(
              props.edit ?
              [{
                type: ActionButtonTypes.Edit,
                callback: (id: string) => openEditLtePhoneNumberSlidingPanel(id)
              },
              {
                type: ActionButtonTypes.Delete,
                callback: (id: string) => deletePhoneNumberModal(id)
              }] : [])
          ]
        }
        loadState={loaderPhoneNumber}
      >
        <>
          <div>{"Phone Number"}</div>
          <div>{"Type"}</div>
          <div></div>
        </>
      </ListContact>
    </>
  );
}
