import InvoiceStatusFieldValue from "./InvoiceStatusFieldValue";
import FieldWithImage from "./FieldWithImage";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";

interface Props {
  label: string,
  value: DropDownOptionModel
}

export default function InvoiceStatusField(props: Props) {
  return (
    <FieldWithImage
      label={props.label}
    >
      <InvoiceStatusFieldValue value={props.value} />
    </FieldWithImage>
  );
}
