import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col, Button } from "react-bootstrap";
import { deleteMatterReceiveMoney, getMatterReceiveMoneyById } from "actions/matter";
import moment from "moment";
import { DateFormat } from "utils/constants";
import _ from 'lodash';
import { formatCurrency } from "utils/misc";
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";
import CustomModal from "components/Modal/Modal";
import { useAppSelector } from "hooks/appSelector";
import { MatterReceiveMoneyModel } from "models/view/MatterReceiveMoneyModel";
import EditMatterReceiveMoneyForm from "../EditMatterReceiveMoney/EditMatterReceiveMoneyForm";

type Props = {
  matterId: string,
  matterReceiveMoneyId: string,
  showActions?: boolean,
  onDeleteCallback?: Function,
  onEditCallback?: Function
}

function ViewMatterReceiveMoney(props: Props){
  const [isLoading, setIsLoading] = useState(true);
  const [matterReceiveMoney, setMatterReceiveMoney] = useState<MatterReceiveMoneyModel>();
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);

  useEffect(() => {
    setIsLoading(true);
    getMatterReceiveMoneyById(props.matterId, props.matterReceiveMoneyId).then((response) => {
      setMatterReceiveMoney(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  },[]);

  function onClickDelete() {
    setIsLoading(true);
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Matter Receive Money?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.matterReceiveMoneyId),
      onClose: () => {setIsLoading(false);},
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();  
  }

  const deleteCallback = (matterId: string, matterReceiveMoneyId: string) => {
    modalActions.toggleModalLoading();
    deleteMatterReceiveMoney(matterId, matterReceiveMoneyId).then(() => {
      slidingPanelActions.clearSlidingPanel();
      modalActions.toggleModalShownStatus(); 

      props.onDeleteCallback && props.onDeleteCallback();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      setIsLoading(false);
    });
  };

  function onClickEdit() {
    //close View Matter Receive Money sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open Edit Matter Receive Money sliding panel having onCancel event to reopen 
    //Edit Matter Receive Money sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Edit Receive Money",
      children: <EditMatterReceiveMoneyForm
        matterId={props.matterId}
        matterReceiveMoneyId={props.matterReceiveMoneyId}
        onSubmitCallback={onSubmitEditMatterReceiveMoney}
      />,
      onCancel: onCancelMatterReceiveMoney
    });
  }

  function onSubmitEditMatterReceiveMoney() {
    //open back View Matter Receive Money sliding panel with onEditCallback on onCancel event
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState,
      onCancel: props.onEditCallback
    });
  }

  function onCancelMatterReceiveMoney() {
    //open back View Matter Receive Money sliding panel
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState
    });
  }

  return(
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Client"}
            value={matterReceiveMoney?.matterParticipatingEntity?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Date"}
            value={matterReceiveMoney?.date ? moment(matterReceiveMoney?.date).format(DateFormat.Moment) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Received Into Account"}
            value={matterReceiveMoney?.account?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Received By"}
            value={matterReceiveMoney?.receivedBy?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Received From"}
            value={matterReceiveMoney?.receivedFrom}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Amount"}
            value={matterReceiveMoney?.amount ? formatCurrency(matterReceiveMoney?.amount) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Reason"}
            value={matterReceiveMoney?.reason?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Description"}
            value={matterReceiveMoney?.description}
          />
        </Col>
      </Row>

      {props.showActions && !matterReceiveMoney?.matter?.isClosed &&
        <Row>
          <Col className="d-flex flex-row-reverse justify-content-between">
            <Button onClick={onClickDelete} variant="danger">
              Delete
            </Button>
            <Button onClick={onClickEdit} variant="secondary-400">
              Edit
            </Button>
          </Col>
        </Row>
      }

      <CustomModal />
    </>
  );
}

export default ViewMatterReceiveMoney;
