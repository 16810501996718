import { EntityModel } from "models/view/EntityModel";
import Field from "components/Fields/Field";
import BooleanField from "components/Fields/BooleanField";
import { EntityTypeIds } from "enums/EntityTypeIds";
import moment from 'moment';
import { DateFormat } from "utils/constants";
import { Card, Row, Col } from "react-bootstrap";

type Props = {
  entity?: EntityModel
}

export default function ViewEntitySummary(props: Props) {

  return (
    <Card>
      <Card.Body>
        <Row className="multiple">
          <Col sm={6} lg={4} xxl={3}>
            <Field
              label={"Display Name"}
              value={props.entity?.displayName}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <Field
              label={"Type"}
              value={props.entity?.entityType?.name}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <Field
              label={"Default Role"}
              value={props.entity?.defaultRole?.name}
            />
          </Col>
          {(props.entity?.entityType && props.entity?.entityType.id === EntityTypeIds.CourtTribunalId) &&
            <>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Court Code"}
                  value={props.entity?.courtDetail?.courtCode}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"LAA Code"}
                  value={props.entity?.courtDetail?.laaCode}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <BooleanField
                  label={"Is Common Platform"}
                  value={props.entity?.courtDetail?.isCommonPlatform ?? false}
                />
              </Col>
            </>
          }
          {(props.entity?.entityType && props.entity?.entityType.id === EntityTypeIds.CrownProsecutionServiceId) &&
            <Col sm={6} lg={4} xxl={3}>
              <Field
                label={"CPS Area"}
                value={props.entity?.crownProsecutionServiceDetail?.cpsArea}
              />
            </Col>
          }
          {(props.entity?.entityType && props.entity?.entityType.id === EntityTypeIds.HumanBeingId) &&
            <>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"First Name"}
                  value={props.entity?.humanBeingDetail?.firstName}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Middle Name"}
                  value={props.entity?.humanBeingDetail?.middleName}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Last Name"}
                  value={props.entity?.humanBeingDetail?.lastName}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Last Name At Birth"}
                  value={props.entity?.humanBeingDetail?.lastNameAtBirth}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Date Of Birth"}
                  value={props.entity?.humanBeingDetail?.dateOfBirth ?
                    moment(props.entity?.humanBeingDetail?.dateOfBirth).format(DateFormat.Moment) : "—"}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Place Of Birth"}
                  value={props.entity?.humanBeingDetail?.placeOfBirth}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Sex"}
                  value={props.entity?.humanBeingDetail?.sex?.name}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Gender Pronoun"}
                  value={props.entity?.humanBeingDetail?.genderPronoun?.name}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Prefix"}
                  value={props.entity?.humanBeingDetail?.prefix}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Suffix"}
                  value={props.entity?.humanBeingDetail?.suffix}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Salutation"}
                  value={props.entity?.humanBeingDetail?.salutation}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Ethnicity"}
                  value={props.entity?.humanBeingDetail?.ethnicity?.name}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Marital Status"}
                  value={props.entity?.humanBeingDetail?.maritalStatus?.name}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Disability Status"}
                  value={props.entity?.humanBeingDetail?.disabilityStatus?.name}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"National Insurance No."}
                  value={props.entity?.humanBeingDetail?.nationalInsuranceNo}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Prison Number"}
                  value={props.entity?.humanBeingDetail?.prisonNumber}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Languages Spoken"}
                  value={props.entity?.humanBeingDetail?.languagesSpoken && props.entity?.humanBeingDetail?.languagesSpoken.length > 0 ?
                    props.entity?.humanBeingDetail?.languagesSpoken.map(x => x.name).join(', ') : "—"}
                />
              </Col>
            </>
          }
          {(props.entity?.entityType && 
          (props.entity?.entityType.id === EntityTypeIds.LimitedCompanyId || props.entity?.entityType.id === EntityTypeIds.PublicLimitedCompanyId)) &&
            <>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Company Name"}
                  value={props.entity?.limitedCompanyDetail?.companyName}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Trading Name"}
                  value={props.entity?.limitedCompanyDetail?.tradingName}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Company Number"}
                  value={props.entity?.limitedCompanyDetail?.companyNumber}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Incorporation Date"}
                  value={props.entity?.limitedCompanyDetail?.incorporationDate ?
                    moment(props.entity?.limitedCompanyDetail?.incorporationDate).format(DateFormat.Moment) : "—"}
                />
              </Col>
            </>
          }
          {(props.entity?.entityType && props.entity?.entityType.id === EntityTypeIds.LimitedLiabilityPartnershipId ) &&
            <>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Partnership Name"}
                  value={props.entity?.limitedLiabilityPartnershipDetail?.partnershipName}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Trading Name"}
                  value={props.entity?.limitedLiabilityPartnershipDetail?.tradingName}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Company Number"}
                  value={props.entity?.limitedLiabilityPartnershipDetail?.companyNumber}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Incorporation Date"}
                  value={props.entity?.limitedLiabilityPartnershipDetail?.incorporationDate ?
                    moment(props.entity?.limitedLiabilityPartnershipDetail?.incorporationDate).format(DateFormat.Moment) : "—"}
                />
              </Col>
            </>
          }
          {(props.entity?.entityType && props.entity?.entityType.id === EntityTypeIds.PartnershipAtWillId ) &&
            <Col sm={6} lg={4} xxl={3}>
              <Field
                label={"Trading Name"}
                value={props.entity?.partnershipAtWillDetail?.tradingName}
              />
            </Col>
          }
          {(props.entity?.entityType && props.entity?.entityType.id === EntityTypeIds.PoliceStationId ) &&
            <>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"LAA Code"}
                  value={props.entity?.policeStationDetail?.laaCode}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Constabulary"}
                  value={props.entity?.policeStationDetail?.constabulary}
                />
              </Col>
            </>
          }
          {(props.entity?.entityType && props.entity?.entityType.id === EntityTypeIds.HMPrisonServiceId ) &&
            <Col sm={6} lg={4} xxl={3}>
              <Field
                label={"LAA Code"}
                value={props.entity?.prisonDetail?.laaCode}
              />
            </Col>
          }
          {(props.entity?.entityType && props.entity?.entityType.id === EntityTypeIds.RegisteredCharityId ) &&
            <>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Charity Name"}
                  value={props.entity?.registeredCharityDetail?.charityName}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Trading Name"}
                  value={props.entity?.registeredCharityDetail?.tradingName}
                />
              </Col>
              <Col sm={6} lg={4} xxl={3}>
                <Field
                  label={"Charity Number"}
                  value={props.entity?.registeredCharityDetail?.charityNumber}
                />
              </Col>
            </>
          }
        </Row>
      </Card.Body>
    </Card>
  );
}
