import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button } from 'react-bootstrap';
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { getEntityAddresses, linkEntityAddress } from "actions/entity";
import CustomSelect from "components/Select/Select";
import { removeEmptyFields } from "utils/form";
import { LinkEntityAddressModel } from "models/create/LinkEntityAddressModel";
import { EntityAddressModel } from "models/view/EntityAddressModel";
import { formatAddress } from "utils/address";

type Props = {
  toEntityId: string,
  fromEntityId: string,
  submitCallback?: Function
}

export default function LinkEntityAddressForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingAddresses, setIsLoadingAddresses] = useState<boolean>(false);
  const [addresses, setAddresses] = useState<EntityAddressModel[]>([]);
  const slidingPanelActions = useSlidingPanelActions();

  useEffect(() => {
    setIsLoadingAddresses(true);
    setAddresses([]);
    getEntityAddresses(props.fromEntityId).then((response) => {
      setAddresses(response.data.map(
        (x: EntityAddressModel) => {return {...x, id: x.addressId}}
      ));
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingAddresses(false);
    });
  }, []);

  const {register, reset, control, handleSubmit, formState: {errors}} = useForm<LinkEntityAddressModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: LinkEntityAddressModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    data.entityIdToCopy = props.fromEntityId;
    linkEntityAddress(props.toEntityId, data).then((response) => {
      slidingPanelActions.clearSlidingPanel();
      if(props.submitCallback) {
        props.submitCallback(response.data);
      }
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}
      
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-4" controlId="addressIdToCopy">
          <Form.Label className="required">Address</Form.Label>
          <Controller
            control={control}
            name="addressIdToCopy"
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <CustomSelect
                id="addressIdToCopy"
                inputRef={ref}
                className={`lp-select${errors?.addressIdToCopy?.message ? ' invalid' : ''}`}
                getOptionLabel={(x: EntityAddressModel) => formatAddress(x.address)}
                options={addresses}
                isLoading={isLoadingAddresses}
                value={value}
                onChange={val => onChange(val?.addressId ?? null)}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.addressIdToCopy?.message && (errors.addressIdToCopy.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="d-flex justify-content-between">
          <Button variant="success" type="submit">Create</Button>
          <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
        </Form.Group>
      </Form>
    </>
  );
}
