import { enforce, test, create } from "vest";

export const validationSuite = create((data = {}) => {
  test('files', 'Please select a file.', () => {
    enforce(data.files.length).greaterThan(0);
  });

  test('files', 'Files must be less than 200MB.', () => {
    let size = 0;
    for (let i = 0; i < data.files.length; i++) {
      size += data.files[i].size;
    }
    enforce(size).lessThan(209715200);
  });
});