import { ColDef } from "ag-grid-community";
import { MatterRecordableItemModel } from "models/view/MatterRecordableItemModel";
import { TimeFormatter } from "components/Grid/ValueFormatters/TimeFormatter";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";

export const getColumnDefsRecordableEventItemDetail = () => {
  const columnDefs: ColDef<MatterRecordableItemModel>[] = [
    { field: 'feeEarnerLevel.name', headerName: "Fee Earner Level" },
    { field: 'activity.name', headerName: "Activity", flex: 1 },
    {
      headerName: 'Is Billed',
      field: 'isBilled',
      minWidth: 100,
      width: 100,
      cellRenderer: CheckboxCellRenderer
    },
    {
      field: 'startTime',
      headerName: "Start Time",
      valueFormatter: TimeFormatter,
      minWidth: 100,
      width: 100
    },
    {
      field: 'endTime',
      headerName: "End Time",
      valueFormatter: TimeFormatter,
      minWidth: 100,
      width: 100
    },
    { field: 'numberOfMinutes', headerName: "Number of Minutes" },
    { field: 'numberOfUnits', headerName: "Number Of Units" },
    { field: 'amountCharged', headerName: "Amount Charged", valueFormatter: CurrencyFormatter },
    { field: 'adjustedAmountChargedOnInvoice', headerName: "Amount Invoiced", valueFormatter: CurrencyFormatter },
    { field: 'calculatedVATValueOnInvoice', headerName: "Calculated VAT Value", valueFormatter: CurrencyFormatter }
  ];

  return columnDefs;
}