import { vestResolver } from "@hookform/resolvers/vest";
import { updateUserProfileInfo, getRegulators } from "actions/user";
import { UpdateUserPersonalInfoModel } from "models/update/UpdateUserPersonalInfoModel";
import { LteUserModel } from "models/view/LteUserModel";
import { Dispatch, SetStateAction, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import CustomSelect from "components/Select/Select";
import { useParams } from "react-router-dom";
import { useAppSelector } from "hooks/appSelector";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { DateFormat } from "utils/constants";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { getEthnicities, getGenderPronouns, getLanguages, getSexes } from "actions/entity";
import { RegulatorTypeIds } from "enums/RegulatorTypeIds";
import Loader from "components/Loader";
import PhoneInput from "react-phone-input-2";
import { getDateOnly } from "utils/date";

type Props = {
  user: LteUserModel | undefined,
  setEdit: Dispatch<SetStateAction<boolean>>,
  setUserData: Dispatch<SetStateAction<LteUserModel | undefined>>
}

export default function EditUserPersonalInfo(props: Props) {
  const { id } = useParams();
  const user = useAppSelector((state) => state.user);

  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { register, trigger, reset, control, setValue, watch, clearErrors, handleSubmit, formState: { errors } } = useForm<UpdateUserPersonalInfoModel>({
    resolver: vestResolver(validationSuite),
    defaultValues: props.user
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: UpdateUserPersonalInfoModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    data.isFeeEarner = props.user?.isFeeEarner ?? false;
    updateUserProfileInfo(id ?? user.userId!, data).then((response) => {
      if (response?.data.languagesSpoken) {
        response.data.languagesSpokenIds = response.data.languagesSpoken.map((a: any) => a.id);
      }

      if (response?.data.userPermissions) {
        response.data.userPermissionsIds = response.data.userPermissions.map((a: any) => a.id);
      }
      
      props.setUserData(response.data);
      props.setEdit(false);
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const onBlurFirstName = () => {
    if (watch("firstName") == '' || watch("firstName") == undefined) {
      return;
    }
    if (watch("salutation") == '' || watch("salutation") == undefined) {
      setValue('salutation', watch("firstName"));
      trigger('salutation');
    }
  }

  const onBlurLastName = () => {
    if (watch("lastName") == '' || watch("lastName") == undefined) {
      return;
    }
    if (watch("lastNameAtBirth") == '' || watch("lastNameAtBirth") == undefined) {
      setValue('lastNameAtBirth', watch("lastName"));
      trigger('lastNameAtBirth');
    }
  }

  const onChangeRegulator = () => {
    clearErrors('admissionOrCallDate');
    clearErrors('regulatorReference');
  }

  return (
    <Card>
      {isLoading && <Loader inlineLoader />}

      <Card.Body>
        {genericErrors && (
          <div className="lp-errors">
            {genericErrors}
          </div>
        )}

        <Form onSubmit={onSubmit}>
          <Row className="multiple">
            <Form.Group as={Col} sm={6} xxl={4} controlId="firstName">
              <Form.Label className="required">First Name</Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.firstName?.message ? 'invalid' : ''}`}
                {...register("firstName", {shouldUnregister: true})}
                onBlur={onBlurFirstName}
              />
              <Form.Text className="lp-error">
                {errors?.firstName?.message && (errors.firstName.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="middleName">
              <Form.Label>Middle Name</Form.Label>
              <Form.Control
                type="text"
                {...register("middleName", {shouldUnregister: true})}
              />
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="lastName">
              <Form.Label className="required">Last Name</Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.lastName?.message ? 'invalid' : ''}`}
                {...register("lastName", {shouldUnregister: true})}
                onBlur={() => onBlurLastName()}
              />
              <Form.Text className="lp-error">
                {errors?.lastName?.message && (errors.lastName.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="lastNameAtBirth">
              <Form.Label>Last Name at Birth</Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.lastNameAtBirth?.message ? 'invalid' : ''}`}
                {...register("lastNameAtBirth", {shouldUnregister: true})}
              />
              <Form.Text className="lp-error">
                {errors?.lastNameAtBirth?.message && (errors.lastNameAtBirth.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="dateOfBirth">
              <Form.Label>Date Of Birth</Form.Label>
              <Controller
                control={control}
                name="dateOfBirth"
                shouldUnregister={true}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    className={`${errors?.dateOfBirth?.message ? 'invalid' : ''}`}
                    dateFormat={DateFormat.Datepicker}
                    selected={value ? getDateOnly(value) : null}
                    onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                    showMonthDropdown
                    showYearDropdown
                    autoComplete="off"
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.dateOfBirth?.message && (errors.dateOfBirth.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="sexId">
              <Form.Label className="required">Sex</Form.Label>
              <Controller
                control={control}
                name="sexId"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="sexId"
                    inputRef={ref}
                    className={`lp-select${errors?.sexId?.message ? ' invalid' : ''}`}
                    endpointCall={getSexes}
                    value={value}
                    onChange={val => onChange(val?.id ?? null)}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.sexId?.message && (errors.sexId.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="genderPronounId">
              <Form.Label className="required">Gender Pronoun</Form.Label>
              <Controller
                control={control}
                name="genderPronounId"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="genderPronoun"
                    inputRef={ref}
                    className={`lp-select${errors?.genderPronounId?.message ? ' invalid' : ''}`}
                    endpointCall={getGenderPronouns}
                    value={value}
                    onChange={val => onChange(val?.id ?? null)}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.genderPronounId?.message && (errors.genderPronounId.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="prefix">
              <Form.Label>Prefix</Form.Label>
              <Form.Control
                type="text"
                {...register("prefix", {shouldUnregister: true})}
              />
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="suffix">
              <Form.Label>Suffix</Form.Label>
              <Form.Control
                type="text"
                {...register("suffix", {shouldUnregister: true})}
              />
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="salutation">
              <Form.Label className="required">Salutation</Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.salutation?.message ? 'invalid' : ''}`}
                {...register("salutation", {shouldUnregister: true})}
              />
              <Form.Text className="lp-error">
                {errors?.salutation?.message && (errors.salutation.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="ethnicityId">
              <Form.Label>Ethnicity</Form.Label>
              <Controller
                control={control}
                name="ethnicityId"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="ethnicityId"
                    inputRef={ref}
                    endpointCall={getEthnicities}
                    value={value}
                    isClearable
                    onChange={val => onChange(val?.id ?? null)}
                  />
                )}
              />
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="nationalInsuranceNo">
              <Form.Label>National Insurance No.</Form.Label>
              <Form.Control
                type="text"
                placeholder="PP-NNNNNN-S"
                className={`${errors?.nationalInsuranceNo?.message ? 'invalid' : ''}`}
                {...register("nationalInsuranceNo", {shouldUnregister: true})}
              />
              <Form.Text className="lp-error">
                {errors?.nationalInsuranceNo?.message && (errors.nationalInsuranceNo.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="regulatorId">
              <Form.Label className="required">Regulator</Form.Label>
              <Controller
                control={control}
                name="regulatorId"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="regulatorId"
                    inputRef={ref}
                    className={`lp-select${errors?.regulatorId?.message ? ' invalid' : ''}`}
                    endpointCall={getRegulators}
                    value={value}
                    onChange={val => { onChange(val?.id ?? null); onChangeRegulator(); }}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.regulatorId?.message && (errors.regulatorId.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="admissionOrCallDate">
              <Form.Label
                className={`${(watch("regulatorId") && watch("regulatorId") != RegulatorTypeIds.UnregulatedId) ? "required" : ""}`}
              >
                Admission or Call Date
              </Form.Label>
              <Controller
                control={control}
                name="admissionOrCallDate"
                shouldUnregister={true}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    className={`${errors?.admissionOrCallDate?.message ? 'invalid' : ''}`}
                    dateFormat={DateFormat.Datepicker}
                    selected={value ? getDateOnly(value) : null}
                    onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                    showMonthDropdown
                    showYearDropdown
                    autoComplete="off"
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.admissionOrCallDate?.message && (errors.admissionOrCallDate.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="regulatorReference">
              <Form.Label
                className={`${(watch("regulatorId") && watch("regulatorId") != RegulatorTypeIds.UnregulatedId) ? " required" : ""}`}
              >
                Regulator Registration Reference
              </Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.regulatorReference?.message ? 'invalid' : ''}`}
                {...register("regulatorReference", {shouldUnregister: true})}
              />
              <Form.Text className="lp-error">
                {errors?.regulatorReference?.message && (errors.regulatorReference.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="practicingCertificateNotes">
              <Form.Label>Practicing Certificate Notes</Form.Label>
              <Form.Control
                type="text"
                {...register("practicingCertificateNotes", {shouldUnregister: true})}
              />
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="emergencyContactName">
              <Form.Label className="required">Emergency Contact Name</Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.emergencyContactName?.message ? 'invalid' : ''}`}
                {...register("emergencyContactName", {shouldUnregister: true})}
              />
              <Form.Text className="lp-error">
                {errors?.emergencyContactName?.message && (errors.emergencyContactName.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="emergencyContactPhone">
              <Form.Label className="required">Emergency Contact Phone</Form.Label>
              <Controller
                control={control}
                name="emergencyContactPhone"
                shouldUnregister={true}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    containerClass={`lp-phone-input${errors?.emergencyContactPhone?.message ? ' invalid' : ''}`}
                    autoFormat={false}
                    inputProps={{ name: 'emergencyContactPhone' }}
                    country={'gb'}
                    placeholder="Emergency Contact Phone"
                    value={value}
                    onChange={val => onChange('+' + val)}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.emergencyContactPhone?.message && (errors.emergencyContactPhone.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="emergencyContactEmail">
              <Form.Label>Emergency Contact Email</Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.emergencyContactEmail?.message ? 'invalid' : ''}`}
                {...register("emergencyContactEmail", {shouldUnregister: true})}
              />
              <Form.Text className="lp-error">
                {errors?.emergencyContactEmail?.message && (errors.emergencyContactEmail.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} xxl={4} controlId="relationshipToEmergencyContact">
              <Form.Label className="required">Relationship To Emergency Contact</Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.relationshipToEmergencyContact?.message ? 'invalid' : ''}`}
                {...register("relationshipToEmergencyContact", {shouldUnregister: true})}
              />
              <Form.Text className="lp-error">
                {errors?.relationshipToEmergencyContact?.message && (errors.relationshipToEmergencyContact.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} xxl={4} controlId="languagesSpokenIds">
              <Form.Label>Languages Spoken</Form.Label>
              <Controller
                control={control}
                name="languagesSpokenIds"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="languagesSpokenIds"
                    inputRef={ref}
                    endpointCall={getLanguages}
                    isMulti
                    isClearable
                    value={value}
                    onChange={val => onChange(val?.map((x: { id: any; }) => x.id) ?? null)}
                  />
                )}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className="d-flex justify-content-between">
              <Button variant="success" type="submit">Update</Button>
              <Button variant="secondary-400" onClick={() => props.setEdit(false)}>Cancel</Button>
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
