import { ColDef, ColGroupDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import store from "state/store";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import ViewMatterFile from "../MatterFile/ViewMatterFile/ViewMatterFile";
import { dateComparator } from "components/Grid/Comparators/DateComparator";
import { DropdownArraySearchFilter } from "components/Grid/GridFilters/Filters/DropdownArraySearchFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import {
  changeMatterInvoiceShowOnMatterSummary,
  deleteMatterDisbursement,
  deleteMatterEmail,
  deleteMatterFile,
  deleteMatterIncidentalExpense,
  deleteMatterInvoice,
  deleteMatterNote,
  deleteMatterRecordableEvent,
  getMatterActivityHistory,
  getMatterActivityHistorySubtypes,
  markMatterInvoiceAsDraft
} from "actions/matter";
import { MatterActivityHistoryModel } from "models/view/MatterActivityHistoryModel";
import { MatterActivityHistorySubtypeFormatter } from "components/Grid/ValueFormatters/MatterActivityHistorySubtypeFormatter";
import { MatterActivityHistoryTypes } from "enums/MatterActivityHistoryTypes";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import EditMatterFileDetailsForm from "../MatterFile/EditMatterFileDetails/EditMatterFileDetailsForm";
import ViewMatterNote from "../MatterNote/ViewMatterNote/ViewMatterNote";
import EditMatterNoteForm from "../MatterNote/EditMatterNote/EditMatterNoteForm";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { setRowData } from "state/gridSlice";
import MatterActivityHistoryColumnWithSubtypeIconCellRenderer from "components/Grid/MatterActivityHistoryColumnWithSubtypeIconCellRenderer";
import DropDownOptionListCellRenderer from "components/Grid/DropDownOptionListCellRenderer";
import { MatterActivityHistorySubtypes } from "enums/MatterActivityHistorySubtypes";
import PreviewMatterFile from "../MatterFile/PreviewMatterFile/PreviewMatterFile";
import ViewMatterRecordableEvent from "../MatterRecordableEvent/ViewMatterRecordableEvent/ViewMatterRecordableEvent";
import EditMatterRecordableEvent from "../MatterRecordableEvent/EditMatterRecordableEvent/EditMatterRecordableEvent";
import EditActivityHistoryTaxonomyTermsForm from "./ActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTermsForm";
import { DateTimeFormatterForMatterActivityHistory } from "components/Grid/ValueFormatters/DateTimeFormatterForMatterActivityHistory";
import EditMatterDisbursementForm from "../MatterDisbursement/EditMatterDisbursement/EditMatterDisbursementForm";
import ViewMatterDisbursement from "../MatterDisbursement/ViewMatterDisbursement/ViewMatterDisbursement";
import PreviewMatterEmail from "../MatterEmail/PreviewMatterEmail/PreviewMatterEmail";
import ViewMatterEmail from "../MatterEmail/ViewMatterEmail/ViewMatterEmail";
import { getMatterActivityHistorySubtypeName } from "utils/enums";
import ViewMatterIncidentalExpense from "../MatterIncidentalExpense/ViewMatterIncidentalExpense/ViewMatterIncidentalExpense";
import EditMatterIncidentalExpenseForm from "../MatterIncidentalExpense/EditMatterIncidentalExpense/EditMatterIncidentalExpenseForm";
import SaveAttachments from "../MatterEmail/SaveAttachments/SaveAttachments";
import LinkMatterIncidentalExpenseForm from "../MatterIncidentalExpense/LinkMatterIncidentalExpense/LinkMatterIncidentalExpenseForm";
import UnlinkMatterIncidentalExpenseForm from "../MatterRecordableEvent/UnlinkMatterIncidentalExpense/UnlinkMatterIncidentalExpenseForm";
import ViewMatterInvoice from "../MatterInvoice/ViewMatterInvoice/ViewMatterInvoice";
import EditMatterInvoiceForm from "../MatterInvoice/EditMatterInvoice/EditMatterInvoiceForm";
import ViewMatterInvoicePayments from "../MatterInvoice/ViewMatterInvoicePayments/ViewMatterInvoicePayments";
import ViewMatterDisbursementPayments from "../MatterDisbursement/ViewMatterDisbursementPayments/ViewMatterDisbursementPayments";
import MatterIncidentalExpenseWriteOffForm from "../MatterIncidentalExpense/MatterIncidentalExpenseWriteOff/MatterIncidentalExpenseWriteOffForm";
import MatterDisbursementWriteOffForm from "../MatterDisbursement/MatterDisbursementWriteOff/MatterDisbursementWriteOffForm";
import MatterInvoiceWriteOffForm from "../MatterInvoice/MatterInvoiceWriteOff/MatterInvoiceWriteOffForm";
import { MatterInvoiceStatusesIds } from "enums/MatterInvoiceStatusesIds";
import { openUrlInNewtab } from "utils/misc";
import EditMatterEmailDetailsForm from "../MatterEmail/EditMatterEmailDetails/EditMatterEmailDetailsForm";
import { TaxonomyModel } from "models/view/TaxonomyModel";
import { getMatterTaxonomyTermsSummary } from "actions/taxonomy";
import { TaxonomyTermModel } from "models/view/TaxonomyTermModel";
import TreeSelect from "rc-tree-select";
import { DropDownOptionListFormatter } from "components/Grid/ValueFormatters/DropDownOptionListFormatter";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";
import MatterInvoiceAbatementForm from "../MatterInvoice/MatterInvoiceAbatement/MatterInvoiceAbatementForm";

const viewCallback = (rowData: MatterActivityHistoryModel) => {
  switch(rowData.activityType) { 
    case MatterActivityHistoryTypes.RecordableEvent: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "View Recordable Event",
            children: <ViewMatterRecordableEvent recordableEventId={rowData.matterRecordableEventId!} />
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.Note: {
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "View Matter Note",
            children: <ViewMatterNote matterId={rowData.matterId} noteId={rowData.matterNoteId!} />
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.File: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "View Matter File Details",
            children: <ViewMatterFile matterId={rowData.matterId} fileId={rowData.matterFileId!} />
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.Mail: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "View Matter Email Details",
            children: <ViewMatterEmail matterId={rowData.matterId} emailId={rowData.matterEmailId!} />
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.Disbursement: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "View Disbursement",
            children: <ViewMatterDisbursement matterId={rowData.matterId} matterDisbursementId={rowData.matterDisbursementId!} />
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.IncidentalExpense: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "View Incidental Expense",
            children: <ViewMatterIncidentalExpense matterId={rowData.matterId} matterIncidentalExpenseId={rowData.matterIncidentalExpenseId!} />
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.Invoice: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            width: "55rem",
            title: "View Invoice",
            children: <ViewMatterInvoice
              matterId={rowData.matterId}
              matterInvoiceId={rowData.matterInvoiceId!}
              showDocumentActions
            />
          }
        )
      );
      return;
    }
    default: { 
      return;
    }
  }
}

const editCallback = (rowData: MatterActivityHistoryModel, syncMatterFiles?: Function) => {
  switch(rowData.activityType) { 
    case MatterActivityHistoryTypes.RecordableEvent: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Edit Recordable Event",
            width: "55rem",
            children: <EditMatterRecordableEvent 
              matterId={rowData.matterId}
              recordableEventId={rowData.matterRecordableEventId!}
              />
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.Note: {
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Edit Matter Note",
            children: <EditMatterNoteForm matterId={rowData.matterId} noteId={rowData.matterNoteId!} />
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.File: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Edit Matter File Details",
            children: <EditMatterFileDetailsForm matterId={rowData.matterId} fileId={rowData.matterFileId!} />
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.Mail: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Edit Matter Email Details",
            children: <EditMatterEmailDetailsForm matterId={rowData.matterId} emailId={rowData.matterEmailId!} />
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.Disbursement: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            width: "55rem",
            title: "Edit Disbursement",
            children: <EditMatterDisbursementForm
                matterId={rowData.matterId}
                matterDisbursementId={rowData.matterDisbursementId!}
              />
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.IncidentalExpense: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            width: "55rem",
            title: "Edit Incidental Expense",
            children: <EditMatterIncidentalExpenseForm
                matterId={rowData.matterId}
                matterIncidentalExpenseId={rowData.matterIncidentalExpenseId!}
              />
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.Invoice: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            width: "70rem",
            title: "Edit Matter Invoice",
            children: <EditMatterInvoiceForm
              matterId={rowData.matterId}
              matterInvoiceId={rowData.matterInvoiceId!}
              onSubmitCallback={syncMatterFiles}/>
          }
        )
      );
      return;
    }
    default: { 
      return;
    }
  }
}

const deleteModal = (rowData: MatterActivityHistoryModel, syncMatterFiles?: Function) => {
  switch(rowData.activityType) { 
    case MatterActivityHistoryTypes.RecordableEvent: { 
      let modal: ModalState = {
        title: "Delete confirmation",
        body: "Are you sure you want to delete this Recordable Event?",
        actionText: "Delete",
        actionVariant: "danger",
        onAction: () => deleteCallback(rowData.matterId, rowData.matterRecordableEventId!, rowData.activityType, syncMatterFiles),
        show: false
      }
      store.dispatch(setModalData(modal));
      store.dispatch(toggleModal());
      return;
    }
    case MatterActivityHistoryTypes.Note: {
      let modal: ModalState = {
        title: "Delete confirmation",
        body: "Are you sure you want to delete this Matter Note?",
        actionText: "Delete",
        actionVariant: "danger",
        onAction: () => deleteCallback(rowData.matterId, rowData.matterNoteId!, rowData.activityType, syncMatterFiles),
        show: false
      }
      store.dispatch(setModalData(modal));
      store.dispatch(toggleModal());
      return;
    }
    case MatterActivityHistoryTypes.File: { 
      let modal: ModalState = {
        title: "Delete confirmation",
        body: "Are you sure you want to delete this Matter File?",
        actionText: "Delete",
        actionVariant: "danger",
        onAction: () => deleteCallback(rowData.matterId, rowData.matterFileId!, rowData.activityType, syncMatterFiles),
        show: false
      }
      store.dispatch(setModalData(modal));
      store.dispatch(toggleModal());
      return;
    }
    case MatterActivityHistoryTypes.Disbursement: {
      let modal: ModalState = {
        title: "Delete confirmation",
        body: "Are you sure you want to delete this Matter Disbursement?",
        actionText: "Delete",
        actionVariant: "danger",
        onAction: () => deleteCallback(rowData.matterId, rowData.matterDisbursementId!, rowData.activityType, syncMatterFiles),
        show: false
      }
      store.dispatch(setModalData(modal));
      store.dispatch(toggleModal());
      return;
    }
    case MatterActivityHistoryTypes.IncidentalExpense: {
      let modal: ModalState = {
        title: "Delete confirmation",
        body: "Are you sure you want to delete this Matter Incidental Expense?",
        actionText: "Delete",
        actionVariant: "danger",
        onAction: () => deleteCallback(rowData.matterId, rowData.matterIncidentalExpenseId!, rowData.activityType, syncMatterFiles),
        show: false
      }
      store.dispatch(setModalData(modal));
      store.dispatch(toggleModal());
      return;
    }
    case MatterActivityHistoryTypes.Invoice: {
      let modal: ModalState = {
        title: "Delete confirmation",
        body: "Are you sure you want to delete this Matter Invoice?",
        actionText: "Delete",
        actionVariant: "danger",
        onAction: () => deleteCallback(rowData.matterId, rowData.matterInvoiceId!, rowData.activityType, syncMatterFiles),
        show: false
      }
      store.dispatch(setModalData(modal));
      store.dispatch(toggleModal());
      return;
    }
    case MatterActivityHistoryTypes.Mail: { 
      let modal: ModalState = {
        title: "Delete confirmation",
        body: "Are you sure you want to delete this Matter Email? This will also delete the associated Recordable Events. (if there are any)",
        actionText: "Delete",
        actionVariant: "danger",
        onAction: () => deleteCallback(rowData.matterId, rowData.matterEmailId!, rowData.activityType, syncMatterFiles),
        show: false
      }
      store.dispatch(setModalData(modal));
      store.dispatch(toggleModal());
      return;
    }
    default: {
      return;
    }
  }
};

const deleteCallback = (matterId: string, activityId: string, activityType: number, syncMatterFiles?: Function) => {
  switch(activityType) { 
    case MatterActivityHistoryTypes.RecordableEvent: { 
      const gridState = store.getState().grid;
      store.dispatch(toggleModalLoadingState());
      deleteMatterRecordableEvent(matterId, activityId).then(() => {
        store.dispatch(setRowData(gridState.rowData.filter((x: MatterActivityHistoryModel) => x.matterRecordableEventId !== activityId)));
        store.dispatch(toggleModal());
      })
      .catch((error) => {
        store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
      })
      .finally(() => {
        store.dispatch(toggleModalLoadingState());
      });
      return;
    }
    case MatterActivityHistoryTypes.Note: {
      const gridState = store.getState().grid;
      store.dispatch(toggleModalLoadingState());
      deleteMatterNote(matterId, activityId).then(() => {
        store.dispatch(setRowData(gridState.rowData.filter((x: MatterActivityHistoryModel) => x.matterNoteId !== activityId)));
        store.dispatch(toggleModal());
      })
      .catch((error) => {
        store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
      })
      .finally(() => {
        store.dispatch(toggleModalLoadingState());
      });
      return;
    }
    case MatterActivityHistoryTypes.File: {
      store.dispatch(toggleModalLoadingState());
      deleteMatterFile(matterId, activityId).then(() => {
        if(syncMatterFiles){
          syncMatterFiles();
        }
        store.dispatch(toggleModal());
      })
      .catch((error) => {
        store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
      })
      .finally(() => {
        store.dispatch(toggleModalLoadingState());
      });
      return;
    }
    case MatterActivityHistoryTypes.Disbursement: {
      const gridState = store.getState().grid;
      store.dispatch(toggleModalLoadingState());
      deleteMatterDisbursement(matterId, activityId).then(() => {
        store.dispatch(setRowData(gridState.rowData.filter((x: MatterActivityHistoryModel) => x.matterDisbursementId !== activityId)));
        store.dispatch(toggleModal());
      })
      .catch((error) => {
        store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
      })
      .finally(() => {
        store.dispatch(toggleModalLoadingState());
      });
      return;
    }
    case MatterActivityHistoryTypes.IncidentalExpense: {
      const gridState = store.getState().grid;
      store.dispatch(toggleModalLoadingState());
      deleteMatterIncidentalExpense(matterId, activityId).then(() => {
        store.dispatch(setRowData(gridState.rowData.filter((x: MatterActivityHistoryModel) => x.matterIncidentalExpenseId !== activityId)));
        store.dispatch(toggleModal());
      })
      .catch((error) => {
        store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
      })
      .finally(() => {
        store.dispatch(toggleModalLoadingState());
      });
      return;
    }
    case MatterActivityHistoryTypes.Invoice: {
      store.dispatch(toggleModalLoadingState());
      deleteMatterInvoice(matterId, activityId).then(() => {
        if(syncMatterFiles){
          syncMatterFiles();

          store.dispatch(toggleModal());
        }
        else {
          //reload the whole grid, because we might have RecordableEvents, IncidentalExpenses or Disbursements that changed by deleting the invoice
          getMatterActivityHistory(matterId).then((response) => 
          {
            store.dispatch(setRowData(response.data));
            store.dispatch(toggleModal());
          });
        }
      })
      .catch((error) => {
        store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
      })
      .finally(() => {
        store.dispatch(toggleModalLoadingState());
      });
      return;
    }
    case MatterActivityHistoryTypes.Mail: {
      store.dispatch(toggleModalLoadingState());
      deleteMatterEmail(matterId, activityId).then(() => {
        //reload the whole grid, because we might have RecordableEvents that changed by deleting the email
        getMatterActivityHistory(matterId).then((response) => 
        {
          store.dispatch(setRowData(response.data));
          store.dispatch(toggleModal());
        });
      })
      .catch((error) => {
        store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
      })
      .finally(() => {
        store.dispatch(toggleModalLoadingState());
      });
      return;
    }
    default: {
      return;
    }
  }
};

const editTaxonomyTermsCallback = (matterId: string, activityHistoryId: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Edit Taxonomy Terms",
        children: <EditActivityHistoryTaxonomyTermsForm matterId={matterId} activityHistoryId={activityHistoryId} />
      }
    )
  );
}

const previewFileCallback = (matterId: string, fileId: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Preview Matter File",
        children: <PreviewMatterFile matterId={matterId} fileId={fileId} />,
        width: "80rem"
      }
    )
  );
}

const previewEmailCallback = (matterId: string, emailId: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Preview Matter Email",
        children: <PreviewMatterEmail matterId={matterId} emailId={emailId} />,
        width: "80rem"
      }
    )
  );
}

const saveAttachmentsCallback = (matterId: string, emailId: string, syncMatterFiles?: Function) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Save Attachments",
        children: <SaveAttachments matterId={matterId} emailId={emailId} syncMatterFiles={syncMatterFiles}/>,
      }
    )
  );
}

const linkMatterIncidentalExpenseCallback = (matterId: string, matterIncidentalExpenseId: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Link Incidental Expense",
        children: <LinkMatterIncidentalExpenseForm matterId={matterId} matterIncidentalExpenseId={matterIncidentalExpenseId} />,
      }
    )
  );
}

const unlinkMatterIncidentalExpenseCallback = (matterId: string, matterRecordableEventId: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Unlink Incidental Expense",
        children: <UnlinkMatterIncidentalExpenseForm matterId={matterId} recordableEventId={matterRecordableEventId} />,
      }
    )
  );
}

const paymentCallback = (matterId: string, rowData: MatterActivityHistoryModel) => {
  switch(rowData.activityType) { 
    case MatterActivityHistoryTypes.Disbursement: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Disbursement Payments",
            children: <ViewMatterDisbursementPayments
                matterId={rowData.matterId}
                matterDisbursementId={rowData.matterDisbursementId!}
                onPaymentsChangedCallback={() => {
                  //reload the whole grid, because we have Disbursement that has changed
                  getMatterActivityHistory(matterId).then((response) => 
                  {
                    store.dispatch(setRowData(response.data));
                  });
                }}
              />
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.Invoice: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            width: "55rem",
            title: "Invoice Payments",
            children: <ViewMatterInvoicePayments
                matterId={rowData.matterId}
                matterInvoiceId={rowData.matterInvoiceId!}
              />
          }
        )
      );
      return;
    }
    default: { 
      return;
    }
  }
}

const writeOffCallback = (matterId: string, rowData: MatterActivityHistoryModel) => {
  switch(rowData.activityType) { 
    case MatterActivityHistoryTypes.Disbursement: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Disbursement Write-Off",
            children: <MatterDisbursementWriteOffForm
                matterId={matterId}
                matterDisbursementId={rowData.matterDisbursementId!}
              />
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.IncidentalExpense: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Incidental Expense Write-Off",
            children: <MatterIncidentalExpenseWriteOffForm
                matterId={matterId}
                matterIncidentalExpenseId={rowData.matterIncidentalExpenseId!}
              />
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.Invoice: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Invoice Write-Off",
            children: <MatterInvoiceWriteOffForm
                matterId={matterId}
                matterInvoiceId={rowData.matterInvoiceId!}
              />
          }
        )
      );
      return;
    }
    default: { 
      return;
    }
  }
}

const abatementCallback = (matterId: string, rowData: MatterActivityHistoryModel) => {
  switch(rowData.activityType) { 
    case MatterActivityHistoryTypes.Invoice: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Invoice Abatement",
            children: <MatterInvoiceAbatementForm
                matterId={matterId}
                matterInvoiceId={rowData.matterInvoiceId!}
              />
          }
        )
      );
      return;
    }
    default: { 
      return;
    }
  }
}

const markAsDraftModal = (rowData: MatterActivityHistoryModel, syncMatterFiles?: Function) => {
  let modal: ModalState = {
    title: "Mark as Draft confirmation",
    body: "Are you sure you want to mark as Draft this Matter Invoice?",
    actionText: "Mark as Draft",
    onAction: () => markAsDraftCallback(rowData.matterId, rowData.matterInvoiceId!, syncMatterFiles),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const markAsDraftCallback = (matterId: string, matterInvoiceId: string, syncMatterFiles?: Function) => {
  store.dispatch(toggleModalLoadingState());
  markMatterInvoiceAsDraft(matterId, matterInvoiceId).then(() => {
    if(syncMatterFiles){
      syncMatterFiles();

      store.dispatch(toggleModal());
    }
    else {
      //reload the whole grid, because we might have RecordableEvents, IncidentalExpenses or Disbursements that changed by deleting the invoice
      getMatterActivityHistory(matterId).then((response) => 
      {
        store.dispatch(setRowData(response.data));
        store.dispatch(toggleModal());
      });
    }
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

const showInvoiceOnMatterSummaryModal = (rowData: MatterActivityHistoryModel, syncMatterFiles?: Function) => {
  let modal: ModalState = {
    title: "Show On Summary confirmation",
    body: "Are you sure you want to show Invoice on Summary?",
    actionText: "Show on Matter Summary",
    onAction: () => changeMatterInvoiceShowOnMatterSummaryCallback(rowData.matterId, rowData.matterInvoiceId!),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const hideInvoiceFromMatterSummaryModal = (rowData: MatterActivityHistoryModel, syncMatterFiles?: Function) => {
  let modal: ModalState = {
    title: "Hide From Summary confirmation",
    body: "Are you sure you want to hide Invoice from Summary?",
    actionText: "Hide from Matter Summary",
    onAction: () => changeMatterInvoiceShowOnMatterSummaryCallback(rowData.matterId, rowData.matterInvoiceId!),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const changeMatterInvoiceShowOnMatterSummaryCallback = (matterId: string, matterInvoiceId: string) => {
  store.dispatch(toggleModalLoadingState());
  changeMatterInvoiceShowOnMatterSummary(matterId, matterInvoiceId).then(() => {
    //reload the whole grid, because we might have RecordableEvents, IncidentalExpenses or Disbursements that changed by deleting the invoice
    getMatterActivityHistory(matterId).then((response) => 
    {
      store.dispatch(setRowData(response.data));
      store.dispatch(toggleModal());
    });
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

const setActivityTypeCellClass = (params: any) => {
  var activityTypeCellClass = getMatterActivityHistorySubtypeName(params.value);
  activityTypeCellClass = "lp-activity-type-cell " + activityTypeCellClass.replace(/\s+/g, '-').toLowerCase();  // replace space with dash
  activityTypeCellClass = "lp-activity-type-cell " + activityTypeCellClass.replace(/\//g, '-').toLowerCase();  // replace audio/video forward slash with dash
  return activityTypeCellClass;
}

export const getColumnDefs = (matterId: string, taxonomies: TaxonomyModel[], syncMatterFiles?: Function, ) => {
  let taxonomyChildrenColumnDefs: any[] = [];

  //defining taxonomy columns
  taxonomies.forEach((taxonomy: TaxonomyModel) => {
    taxonomyChildrenColumnDefs.push({
      headerName: taxonomy.displayName,
      field: 'taxonomyTerms',
      filter: DropdownArraySearchFilter,
      filterParams: {
        property: 'taxonomyTerms',
        suppressFilterButtons: false
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: () => getMatterTaxonomyTermsSummary(matterId,taxonomy.id, true),
        showCheckedStrategy: TreeSelect.SHOW_ALL
      },
      cellRenderer: DropDownOptionListCellRenderer,
      valueFormatter: DropDownOptionListFormatter,
      valueGetter: (params: any) => {
        if (params.data['taxonomyTerms']) {
            return params.data['taxonomyTerms'].filter((x: TaxonomyTermModel) => x.taxonomyId == taxonomy.id)
              .map((x: TaxonomyTermModel) => ({name: x.displayNameWithFullSequence, id: x.id}));
        }
        return [];
      },
      sortable: true,
      suppressMenu: true,
      minWidth: 220
    });
  });

  const columnDefs: (ColDef<MatterActivityHistoryModel> | ColGroupDef<MatterActivityHistoryModel> | {excludeFromExport: boolean})[] = [
    { 
      excludeFromExport: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      suppressMenu: true,
      suppressSizeToFit: true,
      floatingFilter: false,
      resizable: false,
      sortable: false,
      minWidth: 48,
      maxWidth: 48,
      width: 48,
      lockPosition: true,
      pinned: 'left'
    },
    {
      excludeFromExport: true,
      //ag-grid doesn't render cell if we don't have a valid field in that column definition, so we will use any field and we will not show its value
      field: 'id',
      headerName: '',
      minWidth: 58,
      maxWidth: 58,
      width: 58,
      cellRenderer: 'agGroupCellRenderer',
      lockPosition: true,
      suppressSizeToFit: true,
      floatingFilter: false,
      pinned: 'left'
    },
    { 
      headerName: 'Description',
      cellClass: 'lp-type-icon-cell',
      field: 'description',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      cellRenderer: MatterActivityHistoryColumnWithSubtypeIconCellRenderer,
      lockPosition: true,
      pinned: 'left',
      minWidth: 400,
      suppressSizeToFit: true
    },
    { 
      headerName: 'DateTime',
      field: 'dateTime',
      filter: 'agDateColumnFilter',
      suppressMenu: true,
      valueFormatter: DateTimeFormatterForMatterActivityHistory,
      filterParams: {
        comparator: dateComparator
      },
      minWidth: 190,
      width: 190,
      suppressSizeToFit: true
    },
    { 
      headerName: 'Author / Sender / Fee Earner',
      field: 'authorSenderFeeEarner',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      minWidth: 150,
      width: 150,
    },
    { 
      headerName: 'Recipient',
      field: 'recipient',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      minWidth: 150,
      width: 150
    },
    { 
      headerName: 'Activity Type',
      field: 'activitySubtype',
      cellClass: setActivityTypeCellClass,
      minWidth: 220,
      suppressMenu: true,
      valueFormatter: MatterActivityHistorySubtypeFormatter,
      filter: DropdownFilter,
      filterParams: {
        property: 'activitySubtype',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getMatterActivityHistorySubtypes
      }
    },
    {
      headerName: 'Is Billed',
      field: 'isBilled',
      minWidth: 100,
      width: 100,
      filter: BooleanFilter,
      filterParams: {
        property: 'isBilled',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    ...taxonomyChildrenColumnDefs,
    { 
      headerName: 'File Path',
      field: 'filePath',
      filter: 'agTextColumnFilter',
      suppressMenu: true
    },
    { 
      headerName: 'File Notes',
      field: 'fileAdditionalNote',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      flex: 1
    },
    { 
      excludeFromExport: true,
      headerName: 'Actions',
      cellClass: 'lp-actions-cell',
      suppressMenu: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        if(params.data.matter.isClosed) {
          return { buttonsData: [
            {
              type: ActionButtonTypes.View,
              callback: () => viewCallback(params.data)
            }]
          };
        }
        else {
          return { buttonsData: [
            {
              type: ActionButtonTypes.View,
              callback: () => viewCallback(params.data)
            },
            ...(params.data.activitySubtype == MatterActivityHistorySubtypes.SystemNote || 
              (params.data.activityType == MatterActivityHistoryTypes.Invoice && params.data.invoiceStatusId != MatterInvoiceStatusesIds.DraftId) ||
              (params.data.activityType == MatterActivityHistoryTypes.Disbursement && (params.data.disbursementHasPayments || params.data.disbursementHasWriteOff)) ||
              (params.data.activityType == MatterActivityHistoryTypes.IncidentalExpense && params.data.incidentalExpenseHasWriteOff) ||
              (params.data.activityType == MatterActivityHistoryTypes.File && params.data.isFileDeleted) ||
              params.data.isBilled ? [] : [{
              type: ActionButtonTypes.Edit,
              callback: () => editCallback(params.data, syncMatterFiles)
            }]),
            ...(taxonomies.length > 0 ? [{
              type: ActionButtonTypes.EditTaxonomyTerms,
              callback: () => editTaxonomyTermsCallback(params.data.matterId, params.data.id)
            }] : []),
            ...(params.data.activityType == MatterActivityHistoryTypes.File && !params.data.isFileDeleted && params.data.fileWebUrl ? [{
              type: ActionButtonTypes.OpenFile,
              callback: () => openUrlInNewtab(params.data.fileWebUrl)
            }] : []),
            ...(params.data.activityType == MatterActivityHistoryTypes.File && params.data.activitySubtype != MatterActivityHistorySubtypes.OneNoteFile && 
                !params.data.fileIsCSV && !params.data.isFileDeleted ? [{
              type: ActionButtonTypes.PreviewFile,
              callback: () => previewFileCallback(params.data.matterId, params.data.matterFileId!)
            }] : []),
            ...(params.data.activityType == MatterActivityHistoryTypes.Mail ? [{
            type: ActionButtonTypes.PreviewEmail,
            callback: () => previewEmailCallback(params.data.matterId, params.data.matterEmailId!)
            }] : []),
            ...(params.data.activityType == MatterActivityHistoryTypes.Mail ? [{
              type: ActionButtonTypes.SaveAttachments,
              callback: () => saveAttachmentsCallback(params.data.matterId, params.data.matterEmailId!, syncMatterFiles)
            }] : []),
            ...(params.data.activityType == MatterActivityHistoryTypes.IncidentalExpense ? [{
              type: ActionButtonTypes.LinkToRecordableEvent,
              callback: () => linkMatterIncidentalExpenseCallback(params.data.matterId, params.data.matterIncidentalExpenseId!)
            }] : []),
            ...(params.data.activityType == MatterActivityHistoryTypes.RecordableEvent ? [{
              type: ActionButtonTypes.UnlinkIncidentalExpenses,
              callback: () => unlinkMatterIncidentalExpenseCallback(params.data.matterId, params.data.matterRecordableEventId!)
            }] : []),
            ...(params.data.activityType == MatterActivityHistoryTypes.Disbursement || 
              (params.data.activityType == MatterActivityHistoryTypes.Invoice && params.data.invoiceStatusId != MatterInvoiceStatusesIds.DraftId)
            ? [{
              type: ActionButtonTypes.Payment,
              callback: () => paymentCallback(params.data.matterId, params.data)
            }] : []),
            ...((params.data.activityType == MatterActivityHistoryTypes.Invoice && params.data.invoiceStatusId == MatterInvoiceStatusesIds.AwaitingPaymentId)
            ? [{
              type: ActionButtonTypes.Abate,
              callback: () => abatementCallback(params.data.matterId, params.data)
            }] : []),
            ...((params.data.activityType == MatterActivityHistoryTypes.Disbursement && !params.data.isBilled) ||
              (params.data.activityType == MatterActivityHistoryTypes.IncidentalExpense && !params.data.isBilled) ||
              (params.data.activityType == MatterActivityHistoryTypes.Invoice && params.data.invoiceStatusId != MatterInvoiceStatusesIds.DraftId)
            ? [{
              type: ActionButtonTypes.WriteOff,
              callback: () => writeOffCallback(params.data.matterId, params.data)
            }] : []),
            ...((params.data.activityType == MatterActivityHistoryTypes.Invoice && params.data.invoiceStatusId == MatterInvoiceStatusesIds.AwaitingPaymentId)
            ? [{
              type: ActionButtonTypes.MarkAsDraft,
              callback: () => markAsDraftModal(params.data, syncMatterFiles)
            }] : []),
            ...((params.data.activityType == MatterActivityHistoryTypes.Invoice && !params.data.invoiceShowOnMatterSummary)
            ? [{
              type: ActionButtonTypes.ShowOnMatterSummary,
              callback: () => showInvoiceOnMatterSummaryModal(params.data)
            }] : []),
            ...((params.data.activityType == MatterActivityHistoryTypes.Invoice && params.data.invoiceShowOnMatterSummary)
            ? [{
              type: ActionButtonTypes.HideFromFromMatterSummary,
              callback: () => hideInvoiceFromMatterSummaryModal(params.data)
            }] : []),
            ...((params.data.activitySubtype == MatterActivityHistorySubtypes.SystemNote) || 
              (params.data.activityType == MatterActivityHistoryTypes.File && params.data.isFileDeleted) ||
              params.data.isBilled ? [] : [{
              type: ActionButtonTypes.Delete,
              callback: () => deleteModal(params.data, syncMatterFiles)
            }]),
          ]};
        }
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 145,
      maxWidth: 145,
      width: 145,
      lockPosition: true,
      pinned: 'right',
      resizable: false
    }
  ];

  return columnDefs;
}