import { validateLteUserEmail } from "actions/user";
import { LteBranchModel } from "models/view/LteBranchModel";
import isEmail from "validator/lib/isEmail";
import { create, test, enforce, omitWhen } from "vest";

export const getValidationSuite = (lteBranches: LteBranchModel[]) => {
  return create((data = {}) => {
    enforce.extend({isEmail});
    test('email', 'Email Address is required', () => {
      enforce(data.email).isNotEmpty();
      enforce(data.email).isString();
    });

    test('email', 'Email Address doesn\'t have the correct format.', () => {
      enforce(data.email).isEmail();
    });

    test.memo('email', 'User Email must be unique', async () => {
      enforce((await validateLteUserEmail(data.email)).data).isTruthy();
    }, [data.email]);

    test('userRoleId', 'Please select an User Role', () => {
      enforce(data.userRoleId).isNotEmpty();
      enforce(data.userRoleId).isString();
    });

    test('lteBranchId', 'Please select a Lte Branch', () => {
      enforce(data.lteBranchId).isNotEmpty();
      enforce(data.lteBranchId).isString();
    });

    const branchId = data.lteBranchId;
    const branch = branchId ? lteBranches.find(x => x.id === branchId) : undefined;
    omitWhen(
      !branch?.children || branch?.children.length === 0,
      () => {
        test('lteDepartmentId', 'Please select a Lte Department', () => {
          enforce(data.lteDepartmentId).isNotEmpty();
          enforce(data.lteDepartmentId).isString();
        });
      }
    );

    const dept = branch?.children && data.lteDepartmentId ? branch.children.find(x => x.id === data.lteDepartmentId) : undefined;
    omitWhen(
      !dept?.children || dept?.children.length === 0,
      () => {
        test('lteTeamId', 'Please select a Lte Team', () => {
          enforce(data.lteTeamId).isNotEmpty();
          enforce(data.lteTeamId).isString();
        });
      }
    );
  });
}