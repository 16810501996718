import { LteTypeIds } from "enums/LteTypeIds";

const DateFormat = {
  Moment: "DD/MM/yyyy",
  MomentWithoutYear: "DD/MM",
  MomentWithTime: "DD/MM/yyyy h:mm A",
  MomentWithTimeNoSpaces: "DD/MM/yyyyh:mmA",
  MomentTime: "h:mm A",
  Datepicker: "dd/MM/yyyy",
  DayMonthpicker: "dd/MM",
  DatepickerWithTime: "dd/MM/yyyy h:mm aa",
}

const CompanyLteTypes: string[] = [
  LteTypeIds.LimitedCompanyId,
  LteTypeIds.PublicLimitedCompanyId,
  LteTypeIds.LimitedLiabilityPartnershipId,
  LteTypeIds.RegisteredCharityId
];

const DefaultCurrency = "GBP";
const DefaultCurrencySymbol = "￡";
const DefaultLocalization = 'en-GB';

const RecordableEventMinutesWarningLimit = 600;

export {
  DateFormat,
  CompanyLteTypes,
  DefaultCurrency,
  DefaultCurrencySymbol,
  DefaultLocalization,
  RecordableEventMinutesWarningLimit
};
