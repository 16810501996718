export const DEFAULT_COLUMN_DEFINITIONS = {
  sortable: true,
  resizable: true,
  suppressMenu: true,
  filter: true,
  floatingFilter: true,
  floatingFilterComponentParams: {
    suppressFilterButton: true
  },
  minWidth: 100
}
