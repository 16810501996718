import { LteBranchModel } from "models/view/LteBranchModel";
import { create, test, enforce, omitWhen } from "vest";
import { EmploymentRelationshipTypeIds } from "enums/EmploymentRelationshipTypeIds";
import isDate from "validator/lib/isDate";
import isInt from "validator/lib/isInt";
import isFloat from "validator/lib/isFloat";

export const getValidationSuite = (lteBranches: LteBranchModel[]) => {
  return create((data = {}) => {
    enforce.extend({isDate});
    enforce.extend({isInt});
    enforce.extend({isFloat});
    
    test('employmentRelationshipTypeId', 'Please select a Relationship with Business', () => {
      enforce(data.employmentRelationshipTypeId).isNotEmpty();
      enforce(data.employmentRelationshipTypeId).isString();
    });

    omitWhen(data.employmentRelationshipTypeId != EmploymentRelationshipTypeIds.EmployeeId, () => {
      test('employmentStartDate', 'Employment Start Date needs to be a Date', () => {
        enforce(data.employmentStartDate).isDate({strictMode: false});
      });
      
      test('annualHolidayEntitlement', 'Annual Holiday Entitlement is required', () => {
        enforce(data.annualHolidayEntitlement?.toString()).isNotEmpty();
      });
    })

    omitWhen((!data.annualHolidayEntitlement || data.annualHolidayEntitlement == ""), () => {
      test('annualHolidayEntitlement', 'Annual Holiday Entitlement must be a positive number', () => {
        enforce(data.annualHolidayEntitlement.toString()).isInt({min: 0});
      });
    });

    omitWhen(!data.isFeeEarner, () => {    
      test('defaultChargingSchemeId', 'Please select a Charging Scheme', () => {
        enforce(data.defaultChargingSchemeId).isNotEmpty();
        enforce(data.defaultChargingSchemeId).isString();
      });
    
      test('defaultFeeEarnerLevelId', 'Please select a Fee Earner Level', () => {
        enforce(data.defaultFeeEarnerLevelId).isNotEmpty();
        enforce(data.defaultFeeEarnerLevelId).isString();
      });
    })

    test('jobTitle', 'Job Title is required', () => {
      enforce(data.jobTitle).isNotEmpty();
      enforce(data.jobTitle).isString();
    });

    test('userHourlyCost', 'User Hourly Cost is required', () => {
      enforce(data.userHourlyCost?.toString()).isNotEmpty();
    });

    test('userHourlyCost', 'User Hourly Cost must be a positive number', () => {
      enforce(data.userHourlyCost.toString()).isFloat({min: 0});
    });

    test('annualFeeIncomeTarget', 'Annual Fee Income Target is required', () => {
      enforce(data.annualFeeIncomeTarget?.toString()).isNotEmpty();
    });

    test('annualFeeIncomeTarget', 'Annual Fee Income Target must be a positive number', () => {
      enforce(data.annualFeeIncomeTarget.toString()).isFloat({min: 0});
    });

    test('lteBranchId', 'Please select a Lte Branch', () => {
      enforce(data.lteBranchId).isNotEmpty();
      enforce(data.lteBranchId).isString();
    });

    const branchId = data.lteBranchId;
    const branch = branchId ? lteBranches.find(x => x.id === branchId) : undefined;
    omitWhen(
      !branch?.children || branch?.children.length === 0,
      () => {
        test('lteDepartmentId', 'Please select a Lte Department', () => {
          enforce(data.lteDepartmentId).isNotEmpty();
          enforce(data.lteDepartmentId).isString();
        });
      }
    );

    const dept = branch?.children && data.lteDepartmentId ? branch.children.find(x => x.id === data.lteDepartmentId) : undefined;
    omitWhen(
      !dept?.children || dept?.children.length === 0,
      () => {
        test('lteTeamId', 'Please select a Lte Team', () => {
          enforce(data.lteTeamId).isNotEmpty();
          enforce(data.lteTeamId).isString();
        });
      }
    );
  });
}