export enum MatterActivityHistorySubtypes {
  RecordableEvent = 0,

  //note subtypes
  UserNote = 1,
  SystemNote = 2,

  //file subtypes
  WordFile = 3,
  PDFFile = 4,
  ExcelFile = 5,
  CSVFile = 6,
  PowerPointFile = 7,
  TextFile = 8,
  Image = 9,
  AudioVideo = 10,
  Archive = 11,
  EmailFile = 12,
  HtmlFile = 13,
  OneNoteFile = 14,
  OtherFile = 15,

  //mail subtypes
  ReceivedMail = 16,
  SentMail = 17,
  
  Sms = 18,
  WhatsApp = 19,
  Appointment = 20,
  Disbursement = 21,
  IncidentalExpense = 22,
  Invoice = 23
}