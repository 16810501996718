import { SlidingPanelModel } from "models/SlidingPanelModel";
import { useDispatch } from "react-redux";
import { setSlidingPanelData, toggleSlidingPanel, clearSlidingPanelData } from "state/slidingPanelSlice";

const useSlidingPanelActions = () => {
  const dispatch = useDispatch();

  const setSlidingPanel = (data: SlidingPanelModel) => {
    dispatch(setSlidingPanelData(data));
  };

  const toggleSlidingPanelShownStatus = () => {
    dispatch(toggleSlidingPanel());
  };

  const clearSlidingPanel = () => {
    dispatch(clearSlidingPanelData());
  };

  return {
    setSlidingPanel,
    toggleSlidingPanelShownStatus,
    clearSlidingPanel
  };
}

export default useSlidingPanelActions;
