import useSlidingPanelActions from "actions/slidingPanel";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { MdClose } from 'react-icons/md';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "state/store";
import './style.scss';

export default function SlidingPanel() {
  const slidingPanelState = useSelector((state: RootState) => state.slidingPanel);
  const slidingPanelActions = useSlidingPanelActions();
  const location = useLocation();

  const cancel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    slidingPanelState.onCancel !== undefined && slidingPanelState?.onCancel();
  }

  useEffect(() => {
    slidingPanelActions.clearSlidingPanel();
  }, [location]);

  const panel = (
    <div className={`lp-slide-panel-container${slidingPanelState.isShown ? ' show' : ' hide'}`}>
      <div onClick={cancel} className="lp-slide-panel-overlay" />
      <div className="lp-slide-panel" style={slidingPanelState.width ? {["--lp-panel-width" as any]: slidingPanelState.width } : {}}>
        <div className="lp-slide-panel-title">
          <h4>{slidingPanelState.title}</h4>
          <div onClick={cancel} className="lp-slide-panel-close">
            <MdClose />
          </div>
        </div>
        <div className="lp-slide-panel-content">
          {slidingPanelState.children}
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(panel, document.body);
}
