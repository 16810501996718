import { validateDefaultFeeEarnerLevelCode } from "actions/settings";
import { create, test, enforce } from "vest";

export const getValidationSuite = (id: string) => {
  return create((data = {}) => {
    test('code', 'Code is required', () => {
      enforce(data.code).isNotEmpty();
      enforce(data.code).isString();
    });

    test.memo('code', 'Code must be unique', async () => {
      enforce((await validateDefaultFeeEarnerLevelCode(data.code, id)).data).isTruthy();
    }, [data.code]);

    test('name', 'Name is required', () => {
      enforce(data.name).isNotEmpty();
      enforce(data.name).isString();
    });
  });
}