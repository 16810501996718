import "./style.scss";

interface Props {
  label: string,
  children: React.ReactNode;
}

export default function FieldWithImage(props: Props) {
  return (
    <div className="lp-label-value">
      <div className="lp-label">
        {props.label}
      </div>
      <div className="lp-value">
        {props.children}
      </div>
    </div> 
  );
}
