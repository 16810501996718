import { Button } from "react-bootstrap";
import { MdOutlineContentCopy } from "react-icons/md";
import "./style.scss";

interface Props {
  label: string,
  url: string,
  urlDisplayText?: string,
  canBeCopied?: boolean,
  openUrlInNewTab?: boolean
}

export default function FieldWithUrl(props: Props) {

  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.url);
  };

  return (
    <div className="lp-label-value">
      <div className="lp-label">
        {props.label}
      </div>
      <div className="lp-value">
        <a {...((props.openUrlInNewTab ?? false) ? { target: "_blank"} : {})} href={props.url} rel="noreferrer">
          {props.urlDisplayText ?? props.url}
        </a>
        {(props.canBeCopied ?? false) &&
          <Button variant={'secondary-400'} onClick={copyToClipboard} className="btn-icon">
            <MdOutlineContentCopy />
          </Button>
        }
      </div>
    </div> 
  );
}
