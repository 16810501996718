import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import CustomSelect from "components/Select/Select";
import { MatterDisbursementModel } from "models/view/MatterDisbursementModel";
import { CreatePaymentModel } from "models/create/CreatePaymentModel";
import { getDateOnly } from "utils/date";
import { addPayment } from "actions/lte";
import DatePicker from "react-datepicker";
import { DateFormat } from "utils/constants";
import { MdClose } from "react-icons/md";
import Field from "components/Fields/Field";
import { formatCurrency, openUrlInNewtab } from "utils/misc";
import { getMatterDisbursementAccounts, getPaymentMethods } from "actions/matter";
import moment from "moment";
import { getPreferredEntityBankAccount } from "actions/entity";
import { EntityBankAccountModel } from "models/view/EntityBankAccountModel";
import { useAppSelector } from "hooks/appSelector";
import currency from 'currency.js';

type Props = {
  disbursements: MatterDisbursementModel[],
  onSubmitCallback?: Function
}

export default function CreatePaymentForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const currentDate: Date = getDateOnly(new Date());
  const genericErrorsRef = useRef<HTMLDivElement>(null);
  const [totalAmountPaid, setTotalAmountPaid] = useState<number>(0);
  const [supplierName, setSupplierName] = useState<string>();
  const [supplierBankAccount, setSupplierBankAccount] = useState<EntityBankAccountModel | undefined>(undefined);
  const user = useAppSelector((state) => state.user);

  const {reset, register, control, watch, trigger, handleSubmit, formState: {errors}} = useForm<CreatePaymentModel>({
    resolver: vestResolver(validationSuite),
    defaultValues: {
      date: currentDate,
      automaticallyEmailRemittanceAdvice: false,
    }
  });

  const { fields, append, remove } = useFieldArray({
    name: "matterDisbursementPayments",
    control,
    rules: {
      required: "Please append at least 1 item"
    }
  });

  useEffect(() => {
    if(genericErrorsRef.current)
    {
      genericErrorsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [genericErrors]);

  useEffect(() => {
    setIsLoading(true);
    props.disbursements.map((x: MatterDisbursementModel, index: number) => {
      append({
        matterDisbursementId: x.id,
        date: x.date,
        amountPaid: x.amountDue,
        amountDue: x.amountDue,
        displayName: x.displayName,
        clientName: x.matterParticipatingEntity?.name,
        supplierReference: x.supplierReference
      }, { shouldFocus: false });
    });
    setTotalAmountPaid(props.disbursements.map(x => x.amountDue).reduce((a, b) => currency(a).add(b).value, 0));
    
    var supplierId = props.disbursements[0].supplierId;
    setSupplierName(props.disbursements[0].supplier?.name);

    getPreferredEntityBankAccount(supplierId).then((response) => {
      setSupplierBankAccount(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreatePaymentModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    addPayment(data, user.msalAccessToken!)
      .then((response) => {
        if(response.data) {
          openUrlInNewtab(response.data);
        }
        slidingPanelActions.clearSlidingPanel();
        reset();
        props.onSubmitCallback && props.onSubmitCallback();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  const onBlurAmountPaid = (index: number) => {
    const disbursements = watch('matterDisbursementPayments');
    setTotalAmountPaid(disbursements.map(x => +x.amountPaid).reduce((a, b) => currency(a).add(b).value, 0));
    trigger(`matterDisbursementPayments.${index}.amountPaid`);
  }

  const onRemoveDisbursement = (disbursementId: string, index: number) => {
    remove(index)
    setTotalAmountPaid(fields.filter(x => x.id != disbursementId).map(x => x.amountPaid)
      .reduce((a, b) => currency(a).add(b).value, 0));
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div ref={genericErrorsRef} className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="lp-payments-form">
        <Row>
          <Form.Group as={Col} xs={12} sm={6} className="mb-4 mb-sm-0" controlId="date">
            <Form.Label className="required">Date</Form.Label>
            <Controller
              control={control}
              name="date"
              shouldUnregister={true}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  className={`${errors?.date?.message ? 'invalid' : ''}`}
                  id="date"
                  dateFormat={DateFormat.Datepicker}
                  selected={value ? getDateOnly(value) : null}
                  onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                  showMonthDropdown
                  showYearDropdown
                  autoComplete="off"
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.date?.message && (errors.date.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} xs={12} sm={6} controlId="paymentReference">
            <Form.Label className="required">Payment Reference</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.paymentReference?.message ? 'invalid' : ''}`}
              {...register(`paymentReference`, {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.paymentReference?.message && (errors.paymentReference?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} xs={12} sm={6} className="mb-4" controlId="paidFromAccountId">
            <Form.Label className="required">Pay From</Form.Label>
            <Controller
              control={control}
              name={`paidFromAccountId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="paidFromAccountId"
                  inputRef={ref}
                  className={`lp-select${errors?.paidFromAccountId?.message ? ' invalid' : ''}`}
                  endpointCall={() => getMatterDisbursementAccounts()}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.paidFromAccountId?.message && (errors.paidFromAccountId?.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} xs={12} sm={6} className="mb-4" controlId="paymentMethodId">
            <Form.Label className="required">Payment Method</Form.Label>
            <Controller
              control={control}
              name={`paymentMethodId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="paymentMethodId"
                  inputRef={ref}
                  className={`lp-select${errors?.paymentMethodId?.message ? ' invalid' : ''}`}
                  endpointCall={() => getPaymentMethods()}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.paymentMethodId?.message && (errors.paymentMethodId?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Form.Group className="mb-4" controlId="automaticallyEmailRemittanceAdvice">
          <Form.Label>Automatically Email Remittance Advice To Supplier</Form.Label>
          <Controller
            control={control}
            name={`automaticallyEmailRemittanceAdvice`}
            shouldUnregister={true}
            render={({field: { onChange, value, name, ref }}) => (
              <Form.Check 
                type="switch"
                id={`automaticallyEmailRemittanceAdvice`}>
                  <Form.Check.Input
                    className= "form-check-input"
                    ref={ref}
                    checked={value ?? false}
                    onChange={(ev: any) => onChange(ev.target.checked)}
                  />
              </Form.Check>
            )}
          />
        </Form.Group>

        <div className="lp-color-title secondary full-width">Payment Details</div>
        <Row>
          <Form.Group as={Col} xs={12} sm={6} className="mb-4 mb-sm-0">
            <Field
              label={"Supplier"}
              value={supplierName}
            />
          </Form.Group>

          <Form.Group as={Col} xs={12} sm={6}>
            <Field
              label={"Bank Account"}
              value={supplierBankAccount?.bankAccountName}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} xs={12} sm={6} className="mb-4 mb-sm-0">
            <Field
              label={"Bank Sort Code"}
              value={supplierBankAccount?.bankSortCode}
            />
          </Form.Group>

          <Form.Group as={Col} xs={12} sm={6} className="mb-4">
            <Field
              label={"Bank Account Number"}
              value={supplierBankAccount?.bankAccountNumber}
            />
          </Form.Group>
        </Row>

        <div className="lp-color-title secondary full-width">Disbursements</div>
        <div className="lp-box-list auto">
          {fields.map((field, index) => {
            return (
              <section key={field.id} className="lp-box-list-item">
                <Button
                  variant="danger"
                  onClick={() => onRemoveDisbursement(field.id, index)}
                  className={`delete-item btn-icon${fields.length === 1 ? ' disabled' : ''}`}
                >
                  <MdClose />
                </Button>

                <span className="type-icon date">
                  {moment(field.date).format(DateFormat.Moment) + " - " + field.displayName}
                </span>

                <Field
                  label={"Client"}
                  value={field.clientName}
                />

                <Field
                  label={"Supplier Reference"}
                  value={field.supplierReference}
                />

                <Field
                  label={"Due Date"}
                  value={field?.dueDate ? moment(field?.dueDate).format(DateFormat.Moment) : "—"}
                />

                <Field
                  label={"Amount Due"}
                  value={formatCurrency(field.amountDue)}
                />

                <Form.Group controlId={`matterDisbursementPayments.${index}.amountPaid`}>
                  <Form.Label className="required">
                    Payment Amount
                  </Form.Label>
                  <Form.Control
                    type="number"
                    className={`${errors?.matterDisbursementPayments?.[index]?.amountPaid?.message ? 'invalid' : ''}`}
                    {...register(`matterDisbursementPayments.${index}.amountPaid`, {shouldUnregister: true})}
                    onBlur={() => onBlurAmountPaid(index)}
                    min="0"
                    step="0.01"
                    max={field.amountDue}
                    onWheel={e => e.currentTarget.blur()}
                  />
                  <Form.Text className="lp-error">
                    {errors?.matterDisbursementPayments?.[index]?.amountPaid?.message && (errors.matterDisbursementPayments?.[index]?.amountPaid?.message)}
                  </Form.Text>
                </Form.Group>

              </section>
            );
          })}
        </div>

        <div className="lp-sticky-bottom">
          <div className="lp-color-title primary full-width">Total Amount Being Paid: <span>{formatCurrency(totalAmountPaid)}</span></div>

          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">Create</Button>
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
