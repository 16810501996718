import { create, test, enforce, omitWhen } from "vest";
import isInt from "validator/lib/isInt";
import { validateChargingSchemeDisplayName } from "actions/chargingSchemes";

export const getValidationSuite = (id: string) => {
  return create((data = {}) => {
    enforce.extend({isInt});

    test('displayName', 'Display name is required.', () => {
      enforce(data.displayName).isNotEmpty();
      enforce(data.displayName).isString();
    });

    test('displayName', 'Display Name must be unique', async () => {
      enforce((await validateChargingSchemeDisplayName(data.displayName, id)).data).isTruthy();
    });

    test('minutesPerUnit', 'Minutes Per Unit is required.', () => {
      enforce(data.minutesPerUnit.toString()).isNotEmpty();
    });

    omitWhen((!data.minutesPerUnit || data.minutesPerUnit == ""), () => {
      test('minutesPerUnit', 'Minutes Per Unit needs to be a number > 0.', () => {
        enforce(data.minutesPerUnit.toString()).isInt().greaterThan(0);
      });
    });

  });
}