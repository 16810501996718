import { Dispatch, useEffect, useState } from "react";
import Title from 'components/Title';
import ViewMatterSummary from "./MatterSummary/ViewMatterSummary/ViewMatterSummary";
import EditMatterSummary from "./MatterSummary/EditMatterSummary/EditMatterSummary";
import { MatterModel } from "models/view/MatterModel";
import { MdEdit } from "react-icons/md";
import { Row, Col, Button } from 'react-bootstrap';
import MatterFeeEarnerList from "./MatterFeeEarners/MatterFeeEarnerList";
import ParticipatingEntityList from "./ParticipatingEntities/ParticipatingEntityList";
import SlidingPanel from "components/SlidingPanel/index";
import CustomModal from "components/Modal/Modal";
import SummaryBox from 'components/SummaryBox';
import { SummaryBoxTypes } from "enums/SummaryBoxTypes";
import SummaryBoxesSection from "components/SummaryBoxesSection";
import { MatterSummaryInfoModel } from "models/view/MatterSummaryInfoModel";
import { getMatterSummaryInfo } from "actions/matter";
import Loader from "components/Loader";
import { MatterInvoiceParticipatingEntitySummaryInfoModel } from "models/view/MatterInvoiceParticipatingEntitySummaryInfoModel";
import React from "react";
import { isMobile } from 'react-device-detect';

type Props = {
  matter: MatterModel | undefined,
  setMatter: Dispatch<React.SetStateAction<MatterModel | undefined>>,
  edit: boolean
}

function ViewMatterDetails(props: Props){
  const [edit, setEdit] = useState(props?.edit ?? false);
  const [matterSummaryInfo, setMatterSummaryInfo] = useState<MatterSummaryInfoModel | undefined>(undefined);
  const [isSummaryInfoLoading, setIsSummaryInfoLoading] = useState<boolean>(false);
  const [genericErrorsForSummaryInfo, setGenericErrorsForSummaryInfo] = useState(undefined);

  useEffect(() => {
    loadMatterSummaryInfo();
  }, [props.matter]);

  const loadMatterSummaryInfo = () => {
    if(props.matter) {
      setIsSummaryInfoLoading(true);
      getMatterSummaryInfo(props.matter.id).then((response) => {
        setMatterSummaryInfo(response.data);
      })
      .catch((error) => {
        setGenericErrorsForSummaryInfo(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsSummaryInfoLoading(false);
      });
    }
    else{
      setMatterSummaryInfo(undefined);
    }
  }

  return(
    <>
      {isSummaryInfoLoading &&
        <div className="lp-summary-boxes-placeholder">
          <Loader inlineLoader />
        </div>
      }

      {genericErrorsForSummaryInfo && (
        <div className="lp-errors">
          {genericErrorsForSummaryInfo}
        </div>
      )}

      {matterSummaryInfo && 
        <SummaryBoxesSection>
          <div className="lp-summary-boxes-area">
            <SummaryBox
              type={SummaryBoxTypes.TimeDoughnut}
              values={[
                {
                  value: matterSummaryInfo.totalBilledMinutesRecordableItems ?? 0,
                  label: "Billed",
                  className: "lp-primary"
                },
                {
                  value: matterSummaryInfo.totalUnbilledMinutesRecordableItems ?? 0,
                  label: "Unbilled",
                  className: "lp-warning"
                }
              ]}
            />

            <SummaryBox 
              type={SummaryBoxTypes.FeeIncomeDoughnut}
              values={[
                {
                  value: matterSummaryInfo.totalBilledRecordableItems ?? 0,
                  label: "Billed",
                  className: "lp-primary"
                },
                {
                  value: matterSummaryInfo.totalUnbilledRecordableItems ?? 0,
                  label: "Unbilled",
                  className: "lp-warning"
                }
              ]}
            />

            <SummaryBox
              type={SummaryBoxTypes.DisbursementsDoughnut}
              values={[
                {
                  value: matterSummaryInfo.totalBilledDisbursements ?? 0,
                  label: "Billed",
                  className: "lp-primary",
                  
                },
                {
                  value: matterSummaryInfo.totalUnbilledDisbursements ?? 0,
                  label: "Unbilled",
                  className: "lp-warning"
                },
                ...matterSummaryInfo.totalWriteOffDisbursements ? [{
                  value: matterSummaryInfo.totalWriteOffDisbursements ?? 0,
                  label: "Write-Off",
                  className: "lp-danger"
                }] : []
              ]}
            />

            <SummaryBox
              type={SummaryBoxTypes.IncidentalExpensesDoughnut}
              values={[
                {
                  value: matterSummaryInfo.totalBilledIncidentalExpenses ?? 0,
                  label: "Billed",
                  className: "lp-primary"
                },
                {
                  value: matterSummaryInfo.totalUnbilledIncidentalExpenses ?? 0,
                  label: "Unbilled",
                  className: "lp-warning"
                },
                ...matterSummaryInfo.totalWriteOffIncidentalExpenses ? [{
                  value: matterSummaryInfo.totalWriteOffIncidentalExpenses ?? 0,
                  label: "Write-Off",
                  className: "lp-danger"
                }] : []
              ]}
            />

            <SummaryBox
              type={SummaryBoxTypes.InvoicesDoughnut}
              values={[
                {
                  value: matterSummaryInfo.totalPaidInvoices ?? 0,
                  label: "Paid",
                  className: "lp-primary"
                },
                {
                  value: matterSummaryInfo.totalUnpaidInvoices ?? 0,
                  label: "Unpaid",
                  className: "lp-warning"
                },
                ...matterSummaryInfo.totalWriteOffInvoices ? [{
                  value: matterSummaryInfo.totalWriteOffInvoices ?? 0,
                  label: "Write-Off",
                  className: "lp-danger"
                }] : []
              ]}
            />

            {matterSummaryInfo?.invoiceParticipatingEntitySummaryInfos &&
              matterSummaryInfo.invoiceParticipatingEntitySummaryInfos.map((x: MatterInvoiceParticipatingEntitySummaryInfoModel, index: number) => (
                <React.Fragment key={index}>
                  <SummaryBox
                    type={SummaryBoxTypes.InvoiceParticipatingEntityDoughnut}
                    title={x.matterInvoiceDisplayName + ' - ' + x.matterParticipatingEntity?.name + ' - ' + x.matterInvoiceStatus?.name}
                    values={[
                      {
                        value: x.totalRecordableItems ?? 0,
                        label: isMobile ? "Fee" : "Professional Fees",
                        className: "lp-primary"
                      },
                      {
                        value: x.totalDisbursements ?? 0,
                        label: isMobile ? "Dis" : "Disbursements",
                        className: "lp-primary-400"
                      },
                      {
                        value: x.totalIncidentalExpenses ?? 0,
                        label: isMobile ? "Exp" : "Incidental Expenses",
                        className: "lp-primary-700"
                      }
                    ]}
                  />
                </React.Fragment>
              ))
            }
          </div>
        </SummaryBoxesSection>
      }

      <Row>
        <Col lg={8} xxl={9}>
          <Title type="section" title={"Summary"}>
            {(edit !== true && !props.matter?.isClosed) &&
              <Button variant="primary" className="btn-icon" onClick={() => setEdit(true)}>
                <MdEdit />
              </Button>
            }
          </Title>
          {props.matter &&
            <>
              {edit === true ?
                (<EditMatterSummary setEdit={setEdit} matter={props.matter} setMatter={props.setMatter} />)
                :
                (<ViewMatterSummary matter={props.matter} />)
              }
            </>
          }
        </Col>
        <Col lg={4} xxl={3} className="mt-4 mt-lg-0">
          {props.matter?.id && 
            <MatterFeeEarnerList 
              matterId={props.matter?.id}
              matterIsClosed={props.matter?.isClosed}
            />
          }
        </Col>
      </Row>
      <Row>
        <Col>
          {props.matter && 
            <ParticipatingEntityList
              matterId={props.matter?.id}
              matterTypeId={props.matter.matterTypeId}
              matterIsClosed={props.matter?.isClosed}
            />
          }
        </Col>
      </Row>

      <SlidingPanel />
      <CustomModal />
    </>
  );
}

export default ViewMatterDetails;
