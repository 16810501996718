import React, { useState, useEffect } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';
import { NavbarMenuItem, getNavBarMenuItems } from './navbarData';
import Logo from 'assets/images/lp-logo-dark-horiz.webp';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import './style.scss';
import UserMenu from 'containers/User/UserMenu/UserMenu';
import { useAppSelector } from 'hooks/appSelector';
import { IoMdMenu, IoMdClose } from 'react-icons/io';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';

function NavBar() {
  const [isExpanded, setIsExpanded] = useState<boolean | null>(null);
  const [expandedSubmenu, setExpandedSubmenu] = useState<string | undefined>(undefined);
  const [isMobileOpened, setIsMobileOpened] = useState<boolean | null>(null);
  const [navBarData, setNavBarData] = useState<NavbarMenuItem[]>([]);
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    setNavBarData(getNavBarMenuItems(user.isDiaryManager ?? false));
  }, [user.isDiaryManager]);

  useEffect(() => {
    const navbarExpanded = localStorage.getItem(`navbarExpanded`);
    setIsExpanded(navbarExpanded === 'true');
  }, []);

  useEffect(() => {
    if (isExpanded !== null) {
      localStorage.setItem(`navbarExpanded`, isExpanded.toString());
    }
  }, [isExpanded]);

  const toggleNavBar = () => {
    if (isExpanded) {
      setIsExpanded(false);
      setExpandedSubmenu(undefined);
      return;
    }
    setIsExpanded(true);
  }

  const toggleSubmenu = (itemId: string) => {
    if (expandedSubmenu == itemId) {
      setExpandedSubmenu(undefined);
      return;
    }
    setExpandedSubmenu(itemId);
  }

  const toggleNavBarMobile = () => {
    if (isMobileOpened) {
      setIsExpanded(false);
      setIsMobileOpened(false);
      return;
    }
    setIsExpanded(true);
    setIsMobileOpened(true);
  }

  const closeNavBarMobile = () => {
    if (isMobileOpened) {
      setIsExpanded(false);
      setIsMobileOpened(false);
    }
  }

  const checkPermissions = (item: NavbarMenuItem) => {
    if(item.permission) {
      return user.userPermissions?.includes(item.permission);
    }
    else if(item.atLeastOnePermision){
      return user.userPermissions?.some((x: string) => item.atLeastOnePermision?.some(p => p.toLowerCase().trim() === x.toLowerCase().trim()) ?? false);
    }
    return true;
  }

  return (
    <>
      <div className={`lp-navbar${isExpanded ? ' expanded' : ' collapsed'} ${isMobileOpened ? 'mobile-opened' : ''}`}>
        <div className="lp-logo">
          <img src={Logo} alt="LawPage Logo" />
        </div>

        <div className="lp-main-menu">
          {
            navBarData.map((item) =>
              checkPermissions(item) &&
                <div className="lp-main-menu-item" key={item.id} onClick={!item.children ? () => closeNavBarMobile() : () => { }}>
                  {!isExpanded ? (
                    !item.children ? (
                      <OverlayTrigger
                        key={item.id}
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-right">{item.title}</Tooltip>
                        }
                      >
                        <NavLink to={item.path}>
                          <span className="lp-main-menu-item-icon">{item.icon}</span>
                          <span className="lp-main-menu-item-text">{item.title}</span>
                        </NavLink>
                      </OverlayTrigger>
                    ) : (
                      <>
                        <div className="lp-main-menu-submenu">
                          {!isExpanded && <div className='lp-main-menu-submenu-title'>{item.title}</div>}
                          {
                            item.children?.map((subitem) =>
                              checkPermissions(subitem) &&
                                <div className="lp-main-menu-submenu-item" key={subitem.title}>
                                  <NavLink to={subitem.path}>
                                    <span className="lp-main-menu-submenu-item-text">{subitem.title}</span>
                                  </NavLink>
                                </div>
                            )
                          }
                        </div>
                        <div className="lp-main-menu-item-parent">
                          <span className="lp-main-menu-item-icon">{item.icon}</span>
                          <span className="lp-main-menu-item-text">{item.title}</span>
                        </div>
                      </>
                    )
                  ) : (
                    !item.children ? (
                      <NavLink to={item.path}>
                        <span className="lp-main-menu-item-icon">{item.icon}</span>
                        <span className="lp-main-menu-item-text">{item.title}</span>
                      </NavLink>
                    ) : (
                      <>
                        <div className={`lp-main-menu-item-parent${expandedSubmenu == item.id ? ' opened' : ''}`} onClick={() => toggleSubmenu(item.id)}>
                          <span className="lp-main-menu-item-icon">{item.icon}</span>
                          <span className="lp-main-menu-item-text">{item.title}</span>
                          <span className="lp-main-menu-item-arrow">
                            {expandedSubmenu == item.id ? <MdExpandLess /> : <MdExpandMore />}
                          </span>
                        </div>
                        <div className={`lp-main-menu-submenu${expandedSubmenu == item.id ? ' show' : ''}`}>
                          {
                            item.children?.map((subitem) =>
                              checkPermissions(subitem) &&
                                <div className="lp-main-menu-submenu-item" key={subitem.title}>
                                  <NavLink to={subitem.path}>
                                    <span className="lp-main-menu-submenu-item-text">{subitem.title}</span>
                                  </NavLink>
                                </div>
                            )
                          }
                        </div>
                      </>
                    )
                  )}
                </div>
            )
          }
        </div>

        <UserMenu />

        <div className="lp-navbar-toggle" onClick={toggleNavBar}>
          {isExpanded ? <HiChevronLeft /> : <HiChevronRight />}
        </div>

        <div className="lp-mobile-trigger" onClick={toggleNavBarMobile}>
          {isMobileOpened ? <IoMdClose /> : <IoMdMenu />}
        </div>
      </div>
      <div className={`lp-mobile-overlay${isMobileOpened ? ' show' : ''}`} onClick={toggleNavBarMobile} />
    </>
  );
}

export default NavBar;
