export enum GridOptionTypes {
  ExportSelectedCSV,
  ExportAllCSV,
  ExportSelectedExcel,
  ExportAllExcel,
  ExportActivityHistory,
  Download,
  Merge,
  Print,
  Copy,
  Move,
  Share,
  AddChargingRateToRecordableEventType,
  SendFiles,
  MakePayment,
  ConvertToMail,
  BulkAddTaxonomyTerms,
  BulkDeleteTaxonomyTerms
}
