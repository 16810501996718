import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: '53bfc3b4-bda7-4f7d-96c7-1efe536f802b',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: `${window.location.origin}/login`,
    //postLogoutRedirectUri: `${window.location.origin}/login`
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

const loginParams = {
  scopes: ["api://22f2f89e-3dc2-46ed-8edf-3219d6252fee/.default"]
};

const msalInstance = new PublicClientApplication(msalConfig);

export {
  msalInstance,
  loginParams
};
