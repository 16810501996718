import { create, test, enforce, omitWhen } from "vest";
import isDate from "validator/lib/isDate";
import { MatterLedgerBalanceSummaryModel } from "models/view/MatterLedgerBalanceSummaryModel";
import { MatterInvoiceParticipatingEntityModel } from "models/view/MatterInvoiceParticipatingEntityModel";

export const getValidationSuite = (lteHasClientBankAccounts: boolean) => {
  return create((data = {}) => {
    enforce.extend({ isDate });

    test('date', 'Please select a Date', () => {
      enforce(data.date).isDate({ strictMode: false });
    });

    test('displayName', 'Display Name is required', () => {
      enforce(data.displayName).isNotEmpty();
      enforce(data.displayName).isString();
    });

    test('amountPaid', 'Amount Paid is required', () => {
      enforce(data.amountPaid).isNotEmpty();
    });

    test('amountPaid', 'Amount Paid needs to be a number > 0', () => {
      enforce(data.amountPaid).greaterThan(0);
    });

    test('amountPaid', 'Amount Paid exceeds Amount Due on Invoice', () => {
      enforce(data.amountPaid).lessThanOrEquals(data.invoiceAmountDue);
    });

    omitWhen(!lteHasClientBankAccounts || !data.hasFundsFromClientAccount, () => {
      test('amountPaidFromClientAccount', 'Amount Paid From Client Account is required', () => {
        enforce(data.amountPaidFromClientAccount).isNotEmpty();
      });

      test('amountPaidFromClientAccount', 'Amount From Client Account needs to be a number > 0', () => {
        enforce(data.amountPaidFromClientAccount).greaterThan(0);
      });

      //test('amountPaidFromClientAccount', `Amount From Client Account exceeds Client Cash Account Balance`, () => {
      //  enforce(data.amountPaidFromClientAccount).lessThanOrEquals(data.clientAccountBalance);
      //});

      test('amountPaidFromClientAccount', `Amount From Client Account exceeds Amount Paid`, () => {
        enforce(data.amountPaidFromClientAccount).lessThanOrEquals(data.amountPaid);
      });
    });

    omitWhen(!data.forSingleClient, () => {
      test('matterParticipatingEntityId', 'Please select a Client', () => {
        enforce(data.matterParticipatingEntityId).isNotEmpty();
        enforce(data.matterParticipatingEntityId).isString();
      });

      test('amountPaid', `Amount Paid exceeds client amount due on the invoice`, () => {
        const matterInvoiceParticipatingEntity = data.matterInvoiceParticipatingEntities?.find((x: MatterInvoiceParticipatingEntityModel) => x.matterParticipatingEntityId == data.matterParticipatingEntityId);
        enforce(data.amountPaid).lessThanOrEquals(matterInvoiceParticipatingEntity.amountDue ?? 0);
      });

      omitWhen(!lteHasClientBankAccounts || !data.hasFundsFromClientAccount, () => {
        test('amountPaidFromClientAccount', `Amount From Client Account exceeds Client Cash Account Balance`, () => {
          const clientBalance = data.clientAccountBalances?.find((x: MatterLedgerBalanceSummaryModel) => x.matterParticipatingEntityId == data.matterParticipatingEntityId)?.balance ?? 0;
          enforce(data.amountPaidFromClientAccount).lessThanOrEquals(clientBalance);
        });
      });
    });
  });
}