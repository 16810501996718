export const dateComparator = (filterLocalDateAtMidnight: any, cellValue: any) => {
  if(cellValue) {
    var filterDate = new Date(filterLocalDateAtMidnight.toDateString());
    var cellValueDate = new Date(cellValue.toDateString());

    if(filterDate.getTime() === cellValueDate.getTime()) {
      return 0;
    }
    if(cellValueDate.getTime() < filterDate.getTime()) {
      return -1;
    }
    if(cellValueDate.getTime() > filterDate.getTime()) {
      return 1;
    }
    return 0;
  }
  else {
    return 1;
  }
};
