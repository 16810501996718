import { enforce, test, create, each } from "vest";
import isDate from "validator/lib/isDate";
import { CreateMatterDisbursementPaymentModel } from "models/create/CreateMatterDisbursementPaymentModel";

export const validationSuite = create((data = {}) => {
  enforce.extend({ isDate });

  test('date', 'Please select a Date.', () => {
    enforce(data.date).isDate({ strictMode: false });
  });

  test('paymentReference', 'Payment Reference is required', () => {
    enforce(data.paymentReference).isNotEmpty();
    enforce(data.paymentReference).isString();
  });

  test('paidFromAccountId', 'Please select a Pay From', () => {
    enforce(data.paidFromAccountId).isNotEmpty();
    enforce(data.paidFromAccountId).isString();
  });

  test('paymentMethodId', 'Please select a Payment Method', () => {
    enforce(data.paymentMethodId).isNotEmpty();
    enforce(data.paymentMethodId).isString();
  });

  each(data.matterDisbursementPayments, (field: CreateMatterDisbursementPaymentModel, index: number) => {
    test(`matterDisbursementPayments.${index}.amountPaid`, 'Payment Amount is required', () => {
      enforce(field.amountPaid).isNotEmpty();
    });

    test(`matterDisbursementPayments.${index}.amountPaid`, 'Payment Amount needs to be a number <= Amount Due', () => {
      enforce(field.amountPaid).lessThanOrEquals(field.amountDue);
    });

    test(`matterDisbursementPayments.${index}.amountPaid`, 'Payment Amount needs to be a number > 0', () => {
      enforce(field.amountPaid).greaterThan(0);
    });
  });
});