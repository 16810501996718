import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { DefaultCurrency, DefaultLocalization } from "./constants";
import { CalendarFreeBusyStatus } from "enums/CalendarFreeBusyStatus";
import { DropDownOptionWithColorModel } from "models/view/DropDownOptionWithColorModel";
import { DayOfWeek } from "enums/DayOfWeek";
import { WeekIndexes } from "enums/WeekIndexes";
import moment from "moment";
import { AvailabilityViewInterval } from "enums/AvailabilityViewInterval";
import { GridAdvancedFilterDateOptions } from "enums/GridAdvancedFilterDateOptions";

function formatFileSize(bytes: number, precision: number = 1) {
  const thresh = 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  const r = 10**precision;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(precision) + ' ' + units[u];
}

function formatCurrency(value?: number) {
  return (value ?? 0).toLocaleString(DefaultLocalization, {
    style: 'currency',
    currency: DefaultCurrency,
    maximumFractionDigits: 3
  });
}

function downloadFile(response: any) {
  const urlCreator = window.URL || window.webkitURL;
  const exportedFile = urlCreator.createObjectURL(new Blob([response.data]));
  const tempLink = document.createElement('a');
  tempLink.href = exportedFile;
  const header = response.headers['content-disposition'];
  const fileName = header.match(/filename="(.+)"/)[1];
  tempLink.setAttribute('download', fileName);
  tempLink.click();
}

const printPdf = (pdfBlob: Blob) => {
  const urlCreator = window.URL || window.webkitURL;
  const pdfUrl = urlCreator.createObjectURL(pdfBlob);
  let iframe = document.querySelector(".lp-print-helper-hidden");
  (iframe as any).src = pdfUrl;
  (iframe as any).onload = () => {
    setTimeout(function() {
      (iframe as any).focus();
      (iframe as any).contentWindow!.print();
    }, 1);
  }
}

function getCalendarFreeBusyStatusDropdownValues(): DropDownOptionWithColorModel[] {
  const dropdownValues: DropDownOptionWithColorModel[] = [
    { id: CalendarFreeBusyStatus.Unknown.toString(), name: CalendarFreeBusyStatus[CalendarFreeBusyStatus.Unknown], color: "unknown" },
    { id: CalendarFreeBusyStatus.Free.toString(), name: CalendarFreeBusyStatus[CalendarFreeBusyStatus.Free], color: "free" },
    { id: CalendarFreeBusyStatus.Tentative.toString(), name: CalendarFreeBusyStatus[CalendarFreeBusyStatus.Tentative], color: "tentative" },
    { id: CalendarFreeBusyStatus.Busy.toString(), name: CalendarFreeBusyStatus[CalendarFreeBusyStatus.Busy], color: "busy" },
    { id: CalendarFreeBusyStatus.OutOfOffice.toString(), name: CalendarFreeBusyStatus[CalendarFreeBusyStatus.OutOfOffice].split(/(?=[A-Z])/).join(" "), color: "out-of-office" },
    { id: CalendarFreeBusyStatus.WorkingElsewhere.toString(), name: CalendarFreeBusyStatus[CalendarFreeBusyStatus.WorkingElsewhere].split(/(?=[A-Z])/).join(" "), color: "working-elsewhere" }
  ];

  return dropdownValues;
}

function getCalendarReminderBeforeStatusDropdownValues(): DropDownOptionModel[] {
  const dropdownValues: DropDownOptionModel[] = [
    { id: "-1", name: "Don't remind me" },
    { id: "0", name: "At the time of the Event" },
    { id: "5", name: "5 minutes" },
    { id: "15", name: "15 minutes" },
    { id: "30", name: "30 minutes" },
    { id: "60", name: "1 hour" },
    { id: "120", name: "2 hours" },
    { id: "720", name: "12 hours" },
    { id: "1440", name: "1 day" },
    { id: "10080", name: "1 week before Event" },
  ];

  return dropdownValues;
}

function getAvailabilityViewIntervalValues(): DropDownOptionModel[] {
  const dropdownValues: DropDownOptionModel[] = [
    { id: AvailabilityViewInterval.Hours1.toString(), name: "1 hour" },
    { id: AvailabilityViewInterval.Hours2.toString(), name: "2 hours" },
    { id: AvailabilityViewInterval.Hours4.toString(), name: "4 hours" },
    { id: AvailabilityViewInterval.Hours8.toString(), name: "8 hours" },
    { id: AvailabilityViewInterval.Hours12.toString(), name: "12 hours" },
    { id: AvailabilityViewInterval.Hours24.toString(), name: "A day" },
  ];

  return dropdownValues;
}

function getDaysOfWeek(): DropDownOptionModel[] {
  const daysOfWeek: DropDownOptionModel[] = getEnumKeys(DayOfWeek).map((day) => {
    return { id: DayOfWeek[day].toString(), name: day.toString() };
  });

  return daysOfWeek;
}

const getFreeBusyStatusFromValue = (value: CalendarFreeBusyStatus) => {
  switch (value) {
    case CalendarFreeBusyStatus.Unknown:
      return 'unknown';
    case CalendarFreeBusyStatus.Free:
      return 'free';
    case CalendarFreeBusyStatus.Tentative:
      return 'tentative';
    case CalendarFreeBusyStatus.Busy:
      return 'busy';
    case CalendarFreeBusyStatus.OutOfOffice:
      return 'out-of-office';
    case CalendarFreeBusyStatus.WorkingElsewhere:
      return 'working-elsewhere';
    default:
      return 'busy';
  }
};

const getGridAdvancedFilterDateOptionName = (value: GridAdvancedFilterDateOptions) => {
  switch (value) {
    case GridAdvancedFilterDateOptions.Today:
      return 'Today';
    case GridAdvancedFilterDateOptions.CurrentWeek:
      return 'Current Week';
    case GridAdvancedFilterDateOptions.PreviousWeek:
      return 'Previous Week';
    case GridAdvancedFilterDateOptions.CurrentMonth:
      return 'Current Month';
    case GridAdvancedFilterDateOptions.PreviousMonth:
      return 'Previous Month';
    case GridAdvancedFilterDateOptions.CurrentPrevious3Months:
      return 'Current and Previous 3 Months';
    case GridAdvancedFilterDateOptions.CurrentPrevious6Months:
      return 'Current and Previous 6 Months';
    case GridAdvancedFilterDateOptions.CurrentFinancialYear:
      return 'Current Financial Year';
    case GridAdvancedFilterDateOptions.PreviousFinancialYear:
      return 'Previous Financial Year';
    case GridAdvancedFilterDateOptions.Custom:
      return 'Custom';
    default:
      return 'Unknown';
  }
};

const getOrdinal = (num: number) => {
  const j = num % 10;
  const k = num % 100;
  if (j === 1 && k !== 11) {
    return num + 'st';
  }
  if (j === 2 && k !== 12) {
    return num + 'nd';
  }
  if (j === 3 && k !== 13) {
    return num + 'rd';
  }
  return num + 'th';
};

function getEnumKeys<T extends Record<keyof T, number | string>>(enumObject: T): (keyof T)[] {
  return Object.keys(enumObject).filter((x) => Number.isNaN(Number(x))) as (keyof T)[];
}

const getOptionsForRepeatOn = (startDate: Date) => {
  const options = [];
  const dayName = moment(startDate).format('dddd');

  options.push({ id: WeekIndexes.DateIndex, name: `On Day ${moment(startDate).format('D')}` });

  const dayInMonth = moment(startDate).date();
  const firstDayOfMonth = moment(startDate).startOf('month').day();
  const occurrenceInMonth = Math.ceil((dayInMonth - 1 - firstDayOfMonth) / 7) + 1;

  //a day can also be the 5th in the month in special cases. for example Sunday in July 2023
  //in Microsoft Outlook, on the 4th occurrence the user gets the options: on date, 4th Sunday and last Sunday
  //while on the 5th occurrence it gets on on date, last Sunday.
  if (occurrenceInMonth != 5) {
      options.push({ id: occurrenceInMonth - 1, name: `On ${getOrdinal(occurrenceInMonth)} ${dayName}` });
  }

  if (occurrenceInMonth == 4 || occurrenceInMonth == 5) {
    options.push({ id: WeekIndexes.Last, name: `On Last ${dayName}` });
  }

  return options;
};

const formatEmailSender = (emailAddress: string, name?: string) => {
  if (name && name != emailAddress) {
    return (
      <>
        <span className="name">{name}</span>
        <span className="email">{emailAddress}</span>
      </>
    );
  } else {
    return (
      <>
        <span className="email">{emailAddress}</span>
      </>
    );
  }
};

const openUrlInNewtab = (url?: string) => {
  if(!url) {
    return;
  }
  var link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.click();
  link.remove();
}

const compareNumberedSectionStringArrays = (x: string[], y: string[]): number => {
    if(x.length > y.length) {
      return -compareNumberedSectionStringArrays(y, x);//saves needing separate logic.
    }
    for(let i = 0; i != x.length; ++i) {
      if(+x[i] > +y[i]) {
        return 1;
      }
      else if(+x[i] < +y[i]) {
        return -1;
      }
    }
    return x.length == y.length ? 0 : -1;
}

const compareNumberedSectionStrings = (x?: string, y?: string): number => {
    if(x == y) { //short-cut
      return 0;
    }
    if(x == null) {
      return -1;
    }
    if(y == null) {
      return 1;
    }
    
    return compareNumberedSectionStringArrays(x.split('.'), y.split('.'));
}


export {
  formatFileSize,
  formatCurrency,
  getCalendarFreeBusyStatusDropdownValues,
  getCalendarReminderBeforeStatusDropdownValues,
  getFreeBusyStatusFromValue,
  getAvailabilityViewIntervalValues,
  getDaysOfWeek,
  getOrdinal,
  getEnumKeys,
  getOptionsForRepeatOn,
  downloadFile,
  printPdf,
  openUrlInNewtab,
  formatEmailSender,
  compareNumberedSectionStrings,
  getGridAdvancedFilterDateOptionName
};
