import { getMattersForUser } from 'actions/user';
import { useEffect, useState } from 'react';
import Title from 'components/Title';
import { MatterModel } from 'models/view/MatterModel';
import ListMatters from 'components/List/ListMatters';
import { ActionButtonTypes } from 'enums/ActionButtonTypes';
import { useNavigate, Link } from 'react-router-dom';
import { MdArrowOutward } from 'react-icons/md';

export default function DashboardMatters() {
  const [matters, setMatters] = useState<MatterModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    loadMatters();
  }, []);

  const loadMatters = () => {
    setIsLoading(true);
    getMattersForUser(10).then((response) => {
      setMatters(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const viewMatter = (id: string) => {
    navigate(`/matter/${id}`, {state: { "edit": false}});
  };

  return (
    <>
      <Title type="section" title="Recent Matters">
        <Link to="/matter" rel="noopener noreferrer" className="link">
          View all
          <MdArrowOutward />
        </Link>
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <ListMatters
        listItems={matters}
        listButtons={
          [
            {
              type: ActionButtonTypes.View,
              callback: (id: string) => viewMatter(id)
            }
          ]
        }
        loadState={isLoading}
      />
    </>
  );
}