import Title from "components/Title";
import { TabPanel } from "react-tabs";
import CustomTabs from "components/CustomTabs";
import MatterTaxonomyList from "./MatterTaxonomies/MatterTaxonomyList";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getMatterById } from "actions/matter";
import { MatterModel } from "models/view/MatterModel";
import MatterTaxonomyTermList from "./MatterTaxonomyTerms/MatterTaxonomyTermList";

export default function ViewMatterTaxonomies() {
  const [matter, setMatter] = useState<MatterModel>();
  const navigate = useNavigate();
  
  const { id: matterId } = useParams();

  useEffect(() => {
    getMatterById(matterId ?? '').then((response) => {
      setMatter(response.data);
    }).catch((error) => {
      if(error.response.status == 404 || error.response.status == 400) {
        navigate('/error404');
      }
    });
  }, []);

  return (
    <div className="lp-page-content">
      <Title type="page" 
        title={
        <>
          <strong key={0}>Taxonomy for Matter </strong>
          {matter?.id && 
            <a href={`/matter/${matter.id}`} rel="noreferrer">
              <span key={1}>{matter?.fileNumberWithDisplayName}</span>
            </a>
          }
        </>}></Title>

      <CustomTabs 
        tabList={[
          {
            name: 'Taxonomies',
            panel: <TabPanel>
              <MatterTaxonomyList matterId={matterId!}/>
            </TabPanel>
          },
          {
            name: 'Taxonomy Terms',
            panel: <TabPanel>
              <MatterTaxonomyTermList matterId={matterId!}/>
            </TabPanel>
          }
        ]}
      />
    </div>
  );
}
