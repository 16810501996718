import Field from "components/Fields/Field";
import { Card, Row, Col } from "react-bootstrap";
import { LteStorageSettingsModel } from "models/view/LteStorageSettingsModel";
import Loader from "components/Loader/index";
import FieldWithUrl from "components/Fields/FieldWithUrl";

type Props = {
  lteStorageSettings: LteStorageSettingsModel | undefined,
  isLoading: boolean
}

export default function ViewLteStorageSettingsSummary(props: Props) {
    
  return (
    <>
      <Card>
        {props.isLoading && <Loader inlineLoader />}
        <Card.Body>
          <Row>
            <Col>
              <Field
                label={"Storage Tenant ID"}
                value={props.lteStorageSettings?.storageTenantId}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                label={"Storage Site ID"}
                value={props.lteStorageSettings?.storageSiteId}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                label={"Storage Base Folder Path"}
                value={props.lteStorageSettings?.storageBaseFolderPath}
              />
            </Col>
          </Row>
          {props.lteStorageSettings?.adminConsentUrl &&
            <Row>
              <Col>
                <FieldWithUrl
                  label={"Admin Consent URL"}
                  url={props.lteStorageSettings?.adminConsentUrl}
                  urlDisplayText={"Admin Consent URL"}
                  canBeCopied
                  openUrlInNewTab
                />
              </Col>
            </Row>
          }
        </Card.Body>
      </Card>
    </>
  );
}
