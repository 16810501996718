import { ICellRendererParams } from "ag-grid-community";

export default function MatterRefCellRenderer(props: ICellRendererParams) {

  return (
    <>
      {props.data.matterId && 
        <a href={`/matter/${props.data.matterId}`} rel="noreferrer">
          {props.value}
        </a>
      }

      {!props.data.matterId &&
        <span>{props.value}</span>
      }
    </>
  );
}
