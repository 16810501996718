import { Controller, useForm } from "react-hook-form";
import { Dispatch, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { vestResolver } from "@hookform/resolvers/vest";
import Loader from "components/Loader";
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { updateChargingScheme } from "actions/chargingSchemes";
import { removeEmptyFields } from "utils/form";
import { CreateOrUpdateChargingSchemeModel } from "models/create/CreateOrUpdateChargingSchemeModel";
import { ChargingSchemeModel } from "models/view/ChargingSchemeModel";
import { getActivitiesSummaryForLte } from "actions/settings";
import { useAppSelector } from "hooks/appSelector";
import { getValidationSuite } from "./validationSuite";
import CustomSelect from "components/Select/Select";

type Props = {
  chargingScheme?: ChargingSchemeModel,
  setChargingScheme: Dispatch<React.SetStateAction<ChargingSchemeModel | undefined>>,
  setEdit: Dispatch<React.SetStateAction<boolean>>
}

function EditChargingSchemeSummary(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const user = useAppSelector((state) => state.user);

  const { register, handleSubmit, control, reset, formState: { errors } } = useForm<CreateOrUpdateChargingSchemeModel>({
    resolver: vestResolver(getValidationSuite(id!))
  });

  useEffect(() => {
    if (props.chargingScheme) {
      let initialState: CreateOrUpdateChargingSchemeModel = {
        ...props.chargingScheme
      };

      reset(initialState);
    }
  }, [props.chargingScheme]);

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateChargingSchemeModel) {
    setLoading(true);
    removeEmptyFields(data);
    updateChargingScheme(id!, data).then((response) => {
      props.setChargingScheme(response.data);
      props.setEdit(false);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  return (
    <Card>
      {loading && <Loader inlineLoader />}

      <Card.Body>
        {genericErrors && <div className="lp-errors">{genericErrors}</div>}

        <Form onSubmit={onSubmit}>
          <Row className="multiple">
            <Form.Group as={Col} sm={6} lg={3} controlId="displayName">
              <Form.Label className="required">Display Name</Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.displayName?.message ? 'invalid' : ''}`}
                {...register('displayName', {shouldUnregister: true})}
              />
              <Form.Text className="lp-error">
                {errors?.displayName?.message && errors.displayName.message}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={3} controlId="minutesPerUnit">
              <Form.Label className="required">Minutes per Unit</Form.Label>
              <Form.Control
                type="number"
                className={`${
                  errors?.minutesPerUnit?.message ? 'invalid' : ''
                }`}
                {...register('minutesPerUnit', {shouldUnregister: true})}
                min="0"
                onWheel={e => e.currentTarget.blur()}
              />
              <Form.Text className="lp-error">
                {errors?.minutesPerUnit?.message &&
                  errors.minutesPerUnit.message}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={3} controlId="receivedEmailActivityId">
              <Form.Label>Received Email Activity</Form.Label>
              <Controller
                control={control}
                name="receivedEmailActivityId"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="receivedEmailActivityId"
                    inputRef={ref}
                    className={`lp-select${
                      errors?.receivedEmailActivityId?.message ? ' invalid' : ''
                    }`}
                    endpointCall={() =>
                      getActivitiesSummaryForLte(user.lawPageTradingEntityId!)
                    }
                    value={value}
                    onChange={(val) => onChange(val?.id ?? null)}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.receivedEmailActivityId?.message &&
                  errors.receivedEmailActivityId.message}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={3} controlId="sentEmailActivityId">
              <Form.Label>Sent Email Activity</Form.Label>
              <Controller
                control={control}
                name="sentEmailActivityId"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="sentEmailActivityId"
                    inputRef={ref}
                    className={`lp-select${
                      errors?.sentEmailActivityId?.message ? ' invalid' : ''
                    }`}
                    endpointCall={() =>
                      getActivitiesSummaryForLte(user.lawPageTradingEntityId!)
                    }
                    value={value}
                    onChange={(val) => onChange(val?.id ?? null)}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.sentEmailActivityId?.message &&
                  errors.sentEmailActivityId.message}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={3} controlId="active">
              <Form.Label>Active</Form.Label>
              <Controller
                control={control}
                name="active"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <Form.Check 
                    type="switch"
                    id="active">
                      <Form.Check.Input
                        className= "form-check-input"
                        ref={ref}
                        checked={value ?? false}
                        onChange={(ev: any) => onChange(ev.target.checked)}
                      />
                  </Form.Check>
                )}
              />
            </Form.Group>

            <Form.Group as={Col} xs={12} controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" {...register('description', {shouldUnregister: true})} />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className="d-flex justify-content-between">
              <Button variant="success" type="submit">
                Update
              </Button>
              <Button
                variant="secondary-400"
                onClick={() => props.setEdit(false)}
              >
                Cancel
              </Button>
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default EditChargingSchemeSummary;
