import { create, test, enforce } from "vest";

export const validationSuite = create((data = {}) => {
  test('name', 'Name is required', () => {
    enforce(data.name).isNotEmpty();
    enforce(data.name).isString();
  });
  test('lteBranchId', 'Please select a Branch', () => {
    enforce(data.lteBranchId).isNotEmpty();
    enforce(data.lteBranchId).isString();
  });
});