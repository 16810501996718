import ConflictFieldValue from "./ConflictFieldValue";
import FieldWithImage from "./FieldWithImage";

interface Props {
  label: string,
  value: string
}

export default function ConflictField(props: Props) {
  return (
    <FieldWithImage
      label={props.label}
    >
      <ConflictFieldValue value={props.value} />
    </FieldWithImage>
  );
}
