import FieldWithImage from "components/Fields/FieldWithImage";
import BooleanField from "components/Fields/BooleanField";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { CalendarEventModel } from "models/CalendarEventModel";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { BiLinkExternal } from "react-icons/bi";
import { MdRefresh } from "react-icons/md";
import { DateFormat } from "utils/constants";
import { getReminderTextByNumberOfMinutes, getShowAsTypeName } from "utils/enums";
import { getFreeBusyStatusFromValue, openUrlInNewtab } from 'utils/misc';
import { getDiaryManagerEvent } from "actions/lte";

type Props = {
  eventData?: CalendarEventModel,
  graphId?: string
}

export default function ViewCalendarEvent(props: Props) {
  const [loading, setLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const [event, setEvent] = useState<CalendarEventModel>();

  useEffect(() => {
    if(props.graphId) {
      setLoading(true);
      getDiaryManagerEvent(props.graphId).then((response) => {
        setEvent(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setLoading(false);
      });
    } 
    if(props.eventData && !props.graphId) {
      setEvent(props.eventData);
    }
  }, []);

  return (
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      {event?.isEditable && event?.resourceUrl &&
        <Row>
          <Col>
            <Button variant="primary" onClick={() => openUrlInNewtab(event?.resourceUrl)}>
              <BiLinkExternal />
              Open with Outlook
            </Button>
          </Col>
        </Row>
      }

      <Row>
        <Col>
          <Field
            label={"Title"}
            value={event?.title}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Field
            label={"Show As"}
            value={getShowAsTypeName(event?.showAs ?? 0)}
            className={getFreeBusyStatusFromValue(event?.showAs ?? 0)}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={6} className="mb-4 mb-sm-0">
          <BooleanField
            label={"Private"}
            value={event?.private ?? false}
          />
        </Col>
        <Col xs={12} sm={6}>
          <BooleanField
            label={"Is All Day"}
            value={event?.isAllDay ?? false}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={6} className="mb-4 mb-sm-0">
          <FieldWithImage label={"Start Date"}>
            {moment(event?.startDate).format(DateFormat.MomentWithTime)}
            {(!event?.isSingleInstance ?? true) && <MdRefresh />}
          </FieldWithImage>
        </Col>
        <Col xs={12} sm={6}>
          <Field
            label={"End Date"}
            value={moment(event?.endDate).format(DateFormat.MomentWithTime)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Field
            className="lp-email-chips"
            label={'Required Attendees'}
            value={
              event?.requiredAttendees && event?.requiredAttendees.length > 0
                ? event?.requiredAttendees.map((x) => <span className="email">{x}</span>)
                : '—'
            }
          />
          {event?.optionalAttendees &&
            event?.optionalAttendees.length > 0 && (
              <Field
              label={'Optional Attendees'}
              value={
                event?.optionalAttendees && event?.optionalAttendees.length > 0
                  ? event?.optionalAttendees.join(', ')
                  : '—'
              }
            />
          )}
          {event?.rooms &&
            event?.rooms.length > 0 && (
              <Field
              label={'Rooms'}
              value={
                event?.rooms && event?.rooms.length > 0
                  ? event?.rooms.join(', ')
                  : '—'
              }
            />
          )}
        </Col>
      </Row>
      
      <Row>
        <Col>
          <Field
            label={"Reminder Before Start"}
            value={getReminderTextByNumberOfMinutes(event?.reminderMinutesBeforeStart ?? undefined)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Field
            label={"Location"}
            value={event?.location || "—"}
          />
        </Col>
        {
          event?.isOnlineMeeting && (
            <Col>
              <Field
                label={"Is Online Meeting"}
                value={
                  <Button variant="primary" onClick={() => openUrlInNewtab(event?.onlineMeetingUrl)}>
                    Join Teams Meeting
                  </Button>
                }
              />
            </Col>
          )
        }
      </Row>        

      <Row>
        <Col>
          <Field
            label={"Description"}
            value={event?.description || "—"}
            className="lp-event-description"
          />
        </Col>
      </Row>
    </>
  );
}
