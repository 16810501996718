import { enforce, test, create, omitWhen } from "vest";

export const getValidationSuite = (displayClient: boolean) => {
  return create((data = {}) => {
    test('destinationMatterId', 'Please select a Destination Matter', () => {
      enforce(data.destinationMatterId).isNotEmpty();
      enforce(data.destinationMatterId).isString();
    });

    omitWhen(!displayClient, () => {
      test('destinationMatterParticipatingEntityId', 'Please select a Destination Client', () => {
        enforce(data.destinationMatterParticipatingEntityId).isNotEmpty();
        enforce(data.destinationMatterParticipatingEntityId).isString();
      });
    });
  });
}