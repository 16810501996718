import { ColDef } from "ag-grid-community";
import store from "state/store";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { setRowData } from "state/gridSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import { TaxonomyModel } from "models/view/TaxonomyModel";
import { changeMatterTaxonomyStatus, deleteMatterTaxonomy } from "actions/taxonomy";
import EditMatterTaxonomyForm from "./EditMatterTaxonomy/EditTaxonomyForm";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";

const editCallback = (matterId: string, taxonomyId: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Edit Taxonomy",
        children: <EditMatterTaxonomyForm matterId={matterId} taxonomyId={taxonomyId}/>
      }
    )
  );
}

const changeStatusModal = (matterId: string, taxonomyId: string) => {
  let bodyMessage: React.ReactNode;
  const gridState = store.getState().grid;
  if (!gridState.rowData.find((x: TaxonomyModel) => x.id == taxonomyId).active) {
    bodyMessage = "Are you sure you want to activate this Taxonomy?";
  } else {
    bodyMessage = "Are you sure you want to deactivate this Taxonomy?";
  }
  let modal: ModalState = {
    title: "Confirmation",
    body: bodyMessage,
    onAction: () => changeStatus(matterId, taxonomyId),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
}

const changeStatus = (matterId: string, taxonomyId: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  changeMatterTaxonomyStatus(matterId, taxonomyId).then(() => {
    let index: number = gridState.rowData.findIndex((x: any) => x.id == taxonomyId);
    let newEntry: TaxonomyModel = { ...gridState.rowData[index], active: !gridState.rowData[index].active };
    let newArray: Array<any> = [...gridState.rowData];
    newArray[index] = newEntry;
    store.dispatch(setRowData(newArray));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

const deleteModal = (matterId: string, taxonomyId: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Taxonomy?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(matterId, taxonomyId),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (matterId: string, taxonomyId: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteMatterTaxonomy(matterId, taxonomyId).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== taxonomyId)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const getColumnDefs = (matterId: string) => {
  const columnDefs: (ColDef<TaxonomyModel>| {excludeFromExport: boolean})[] = [
    { 
      excludeFromExport: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      suppressMenu: true,
      suppressSizeToFit: true,
      floatingFilter: false,
      resizable: false,
      sortable: false,
      minWidth: 48,
      maxWidth: 48,
      width: 48,
      lockPosition: true,
      pinned: 'left'
    },
    {
      headerName: 'Display Name',
      field: 'displayName',
      filter: 'agTextColumnFilter',
      lockPosition: true,
      pinned: 'left'
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: 'agTextColumnFilter',
      flex: 1
    },
    {
      headerName: 'Is Hierarchical',
      field: 'isHierarchical',
      filter: BooleanFilter,
      filterParams: {
        property: 'isHierarchical',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      minWidth: 100,
      width: 100,
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    {
      headerName: 'Is MultiSelect',
      field: 'isMultiSelect',
      filter: BooleanFilter,
      filterParams: {
        property: 'isMultiSelect',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      minWidth: 100,
      width: 100,
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    {
      headerName: 'Is Active',
      field: 'active',
      filter: BooleanFilter,
      filterParams: {
        property: 'active',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      minWidth: 100,
      width: 100,
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    { 
      excludeFromExport: true,
      headerName: 'Actions',
      cellClass: 'lp-actions-cell',
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        if(params.data.matterId != matterId){
          return {
            buttonsData: []
          }
        }
        else {
          return { buttonsData: [
            {
              type: ActionButtonTypes.Edit,
              callback: (id: string) => editCallback(matterId, id)
            },
            {
              type: params.data.active ? ActionButtonTypes.Deactivate : ActionButtonTypes.Activate,
              callback: (id: string) => changeStatusModal(matterId, id)
            },
            {
              type: ActionButtonTypes.Delete,
              callback: (id: string) => deleteModal(matterId, id)
            }
          ]};
        }
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 145,
      maxWidth: 145,
      width: 145,
      lockPosition: true,
      pinned: 'right',
      resizable: false
    }
  ];

  return columnDefs;
}
