import React from "react";
import './style.scss';

type TitleType = 'page' | 'section';

type Props = {
  type: TitleType; // page, section
  title: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
}

export default function Title(props: Props) {
  let titleClass = `${props.className ?? 'lp-title'}`;
  if (props.type === "page")
    titleClass += ' page';
  else if (props.type === "section")
    titleClass += ' section';

  return (
    <div className={titleClass}>
      <div className="lp-title-text">
        {props.title}
      </div>
      <div className="lp-title-actions">
        {props.children}
      </div>
    </div>
  );
}
