import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { DEFAULT_COLUMN_DEFINITIONS } from './DefaultColumnDefinitions';
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';
import { useCallback, useMemo, useRef, useState } from 'react';
import { FirstDataRenderedEvent, GridReadyEvent, GridSizeChangedEvent, RowDataUpdatedEvent, RowSelectedEvent, ValueFormatterParams } from 'ag-grid-community';
import useGridActions from 'actions/grid';
import { Button, Dropdown } from 'react-bootstrap';
import { MdExpandMore, MdFilterAlt } from 'react-icons/md';
import 'containers/App/_lp-ag-theme.scss';
import { useEffect } from 'react';
import Loader from 'components/Loader/index';
import { GridOptionTypes } from '../../enums/GridOptionTypes';
import GridOptions from './GridOptions/GridOptions';
import { GridOptionButtonDataModel } from 'models/view/GridOptionButtonDataModel';
import { GridFilterButtonDataModel } from 'models/view/GridFilterButtonDataModel';
import { GridFilterTypes } from 'enums/GridFilterTypes';
import GridFilters from './GridFilters/GridFilters';
import { FaTimesCircle } from "react-icons/fa";

type GridProps = AgGridReactProps & {
  gridOptions?: GridOptionButtonDataModel[],
  gridFilters?: GridFilterButtonDataModel[]
}

function Grid(props: GridProps) {
  const col = useSelector((state: RootState) => state.grid.columnDefs);
  const row = useSelector((state: RootState) => state.grid.rowData);
  const gridId = useSelector((state: RootState) => state.grid.id);
  const gridAdvancedFilterInfo = useSelector((state: RootState) => state.grid.advancedFilterInfo);
  const gridAdvancedFilterClearCallback = useSelector((state: RootState) => state.grid.advancedFilterClearCallback);
 
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [gridButtonFilters, setGridButtonFilters] = useState<GridFilterButtonDataModel[]>([]);
  const gridActions = useGridActions();

  const gridRef = useRef<AgGridReact>(null);

  const { gridOptions, gridFilters, ...agGridReactProps } = props;

  const defaultGridOptions: GridOptionButtonDataModel[] = [
    {
      type: GridOptionTypes.ExportSelectedCSV,
      callback: () => exportSelected(true)
    },
    {
      type: GridOptionTypes.ExportSelectedExcel,
      callback: () => exportSelectedExcel(true)
    },
    {
      type: GridOptionTypes.ExportAllCSV,
      callback: () => exportSelected(false)
    },
    {
      type: GridOptionTypes.ExportAllExcel,
      callback: () => exportSelectedExcel(false)
    },
  ];

  const gridButtonOptions: GridOptionButtonDataModel[] = props.gridOptions != undefined ? 
    defaultGridOptions.concat(props.gridOptions) : defaultGridOptions;
  
  useEffect(() => {
    const gridFilters = props.gridFilters?.map((x: GridFilterButtonDataModel) => {
      x.className = `${gridAdvancedFilterInfo && x.type == GridFilterTypes.AdvancedFilters ? 'primary' : ''}`;
      return x;
    });

    setGridButtonFilters(gridFilters != undefined ? gridFilters : []);
  }, [gridAdvancedFilterInfo, props.gridFilters]);

  //overload icons for expand and contract
  const icons = useMemo(() => {
    return {
      groupExpanded: '<button type="button" class="btn-icon btn btn-secondary-400"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path></svg></button>',
      groupContracted: '<button type="button" class="btn-icon btn btn-secondary-400"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg></button>',
    }
  }, []);

  const emptyGrid = '<div class="lp-grid-empty"><div class="image"></div><div class="text">No rows found!</div></div>';

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.api.sizeColumnsToFit();
  }, []);

  const onGridSizeChanged = useCallback((params: GridSizeChangedEvent) => {
    let newColumns = gridRef.current?.api.getColumnDefs();
    if (newColumns == undefined || newColumns == null || newColumns.length == 0) {
      return;
    }
    //we want to remove the checkbox from the logic, because it is the only column which remains the same on both mobile and desktop
    const checkbox = newColumns.shift()!;
    if (window.innerWidth < 1200 && isMobile == false) {
      setIsMobile(true);
      newColumns = newColumns.map(({ pinned, lockPosition, ...newColumn }: any) => ({
        ...newColumn,
        ...(pinned && { oldPinned: pinned }),
        ...(lockPosition && { oldLockPosition: lockPosition }),
      }));
      gridRef.current?.api.setColumnDefs([checkbox, ...newColumns]);
      params.api.sizeColumnsToFit();
      return;
    } 
    if (window.innerWidth > 1200 && isMobile) {
      newColumns = newColumns.map(({ oldPinned, oldLockPosition, ...newColumn }: any) => ({
        ...newColumn,
        ...(oldPinned && { pinned: oldPinned }),
        ...(oldLockPosition && { lockPosition: oldLockPosition }),
      }));
      setIsMobile(false);
      gridRef.current?.api.setColumnDefs([checkbox, ...newColumns]);
      params.api.sizeColumnsToFit();
      return;
    }
  }, [isMobile]);

  //we need this so when the data is changed the columns are auto sized
  const onRowDataUpdated = useCallback((params: RowDataUpdatedEvent) => {
    params.api.sizeColumnsToFit();

    //deselect rows when data changes
    setSelectedRows([]);

    if(props.onRowDataUpdated) {
      props.onRowDataUpdated(params);
    }

    if(col != undefined && row != undefined)
    {
      setIsLoading(false);
    }
  }, [col, row]);

  const exportSelected = useCallback((selectedOnly: boolean) => {
    gridRef.current?.api?.exportDataAsCsv({
      columnKeys: gridRef.current?.columnApi.getAllDisplayedColumns()?.filter(x => !(x.getUserProvidedColDef() as any).excludeFromExport),
      onlySelected: selectedOnly,
      processCellCallback: (params) => {
        const valueFormatter = params.column.getColDef().valueFormatter;
        if (typeof valueFormatter === 'function' && params.node) {
          const valueFormatterParams: ValueFormatterParams = {
            ...params,
            data: params.node.data,
            node: params.node!,
            colDef: params.column.getColDef()
          };
          return valueFormatter(valueFormatterParams);
        }
        return params.value;
      }
    });
  }, [col]);
  
  const exportSelectedExcel = useCallback((selectedOnly: boolean) => {
    gridRef.current?.api?.exportDataAsExcel({
      columnKeys: gridRef.current?.columnApi.getAllDisplayedColumns()?.filter(x => !(x.getUserProvidedColDef() as any).excludeFromExport),
      onlySelected: selectedOnly,
      processCellCallback: (params) => {
        const valueFormatter = params.column.getColDef().valueFormatter;
        if (typeof valueFormatter === 'function' && params.node) {
          const valueFormatterParams: ValueFormatterParams = {
            ...params,
            data: params.node.data,
            node: params.node!,
            colDef: params.column.getColDef()
          };
          return valueFormatter(valueFormatterParams);
        }
        return params.value;
      }
    });
  }, [col]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    if (props.onGridReady) {
      //if we don't clear the rowData, when changing between Grids, we will see the rows & columns from the previous Grid for a flash second
      //this clearance is only needed when we already have some data and a data change will follow in props.onGridReady
      if (col != undefined && row != undefined) {
        gridActions.setGridColumnDefs(undefined as any);
        gridActions.setGridRowData(undefined as any);
      }
      props.onGridReady(params);
    }
    params.api.sizeColumnsToFit();
  }, []);

  const onRowSelected = useCallback((event: RowSelectedEvent) => {
    setSelectedRows(gridRef.current?.api?.getSelectedRows() ?? []);
    if (props.onRowSelected) {
      props.onRowSelected(event);
    }
  }, []);

  useEffect(() => {
    //deselect rows when columns or rows change
    setSelectedRows([]);
    //set loading when columns or rows change
    setIsLoading(true);
  }, [col, row]);

  //without this, if we directly inject col into columnDefs, there is a small flash
  useEffect(() => {
    if(gridRef.current?.api)
    {
      if(props.columnDefs) //in case we use the grid locally, not with redux
      {
        gridRef.current.api.setColumnDefs(props.columnDefs);
        return;
      }
      gridRef.current.api.setColumnDefs(col as any);
    }
  }, [col]);

  /*
  we need this because onGridReady is called only when the component first mounts, so 
  if the component doesn't re-mount but we change our onGridReady callback, the new
  function will not get executed. with this useEffect, we check if there
  is any change of onGridReady and if we are not at first render (by checking if we already
  have the api set). if those two conditions are met, execute onGridReady.
  we also synthetically create a GridReadyEvent so we can pass it to onGridReady.
  */
  useEffect(() => {
    if(gridRef.current?.api) {
      if(props.onGridReady) {

        //same explanation as in onGridReady callback
        if (col != undefined && row != undefined) {
          gridActions.setGridRowData(undefined as any);
          gridActions.setGridColumnDefs(undefined as any);
        }

        props.onGridReady(
          {
            api: gridRef.current?.api,
            columnApi: gridRef.current.columnApi,
            type: "gridReady",
            context: gridRef.current.context
          } as GridReadyEvent);
      }

      gridRef.current.api.sizeColumnsToFit();
    }
  }, [props.onGridReady]);

  const onGridColumnsChanged = useCallback(() => {
    if(col != undefined && row != undefined)
    {
      setIsLoading(false);
    }
    //columns get changed in onGridReady by default, then we need to check if there is an existing state in the localStorage
    //if(col != undefined)
    //{
    //  loadState();
    //}
  }, [col, row]);

  /*const saveState = useCallback(() => {
    if(gridId != undefined && gridRef.current?.columnApi) {
      localStorage.setItem(gridId.split("/")[0], JSON.stringify(gridRef.current?.columnApi.getColumnState()))
    }
  }, [gridId]);

  const loadState = useCallback(() => {
    if(gridId != undefined && gridRef.current?.columnApi) {
      const gridState = localStorage.getItem(gridId.split("/")[0]);
      if(gridState) {
        let state = JSON.parse(gridState);
        gridRef.current?.columnApi.applyColumnState({state: state, applyOrder: true});
      }
    }
  }, [gridId]);*/

  useEffect(() => {
    //when grid is unmounted reset grid state
    return () => {
      gridActions.resetGridModel();
    };
  }, []);

  return (
    <>
      <div className="lp-grid-btns">
        {gridAdvancedFilterInfo &&
          <div className="lp-advanced-filters-info">
            <div>{gridAdvancedFilterInfo}</div>
            {gridAdvancedFilterClearCallback && 
              <Button variant='secondary-400' onClick={() => gridAdvancedFilterClearCallback()}>
                <FaTimesCircle />
                Clear
              </Button>
            }
          </div>
        }
        <Dropdown>
          <Dropdown.Toggle variant="secondary-400">
            <MdExpandMore />
            Grid options
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {
              gridButtonOptions.map((el: GridOptionButtonDataModel) => GridOptions({
                ...el,
                gridRef: gridRef,
                selectedRows: selectedRows
              }))
            }
          </Dropdown.Menu>
        </Dropdown>
        {gridButtonFilters.length > 1 &&
          <Dropdown>
            <Dropdown.Toggle variant={`${gridAdvancedFilterInfo ? 'primary' : 'secondary-400'}`}>
              <MdExpandMore />
              Advanced Filters
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {
                gridButtonFilters.map((el: GridFilterButtonDataModel) => GridFilters({
                  ...el,
                  gridRef: gridRef
                }))
              }
            </Dropdown.Menu>
          </Dropdown>
        }
        {gridButtonFilters.length == 1 &&
          <Button variant={`${gridAdvancedFilterInfo ? 'primary' : 'secondary-400'}`} onClick={gridButtonFilters[0].callback != undefined ? () => gridButtonFilters[0].callback!() : () => {}}>
            <MdFilterAlt />
            Advanced Filters
          </Button>
        }
      </div>

      {isLoading && <Loader inlineLoader />}
      <div className="ag-theme-lawpage grid">
        <AgGridReact
          {...agGridReactProps}
          ref={gridRef}
          icons={icons}
          suppressLoadingOverlay={true}
          suppressRowClickSelection={true}
          suppressContextMenu={true}
          defaultColDef={DEFAULT_COLUMN_DEFINITIONS}
          rowSelection="multiple"
          rowData={row}
          onRowSelected={onRowSelected}
          overlayNoRowsTemplate={emptyGrid}
          domLayout="autoHeight"
          onFirstDataRendered={onFirstDataRendered}
          onGridColumnsChanged={onGridColumnsChanged}
          onRowDataUpdated={onRowDataUpdated} 
          onGridSizeChanged={onGridSizeChanged}
          onGridReady={onGridReady}
          //onColumnMoved={saveState}
          //onFilterChanged={saveState}
          //onColumnVisible={saveState}
          //onColumnPinned={saveState}
          //onColumnResized={saveState}
          enableCellTextSelection={true}
          detailRowAutoHeight={true}
        />
      </div>
    </>
  );
}

export default Grid;
