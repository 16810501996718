import { ICellRendererParams } from "ag-grid-community";
import { useNavigate } from "react-router-dom";
import "components/Grid/style.scss";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import IconButtonGrid from "components/Buttons/IconButtonGrid";
import MoreButton from "components/Buttons/MoreButton";

export interface ActionsCellRendererParams extends ICellRendererParams {
  buttonsData: Array<{ type: ActionButtonTypes, callback: Function }>
}

export default function ActionsCellRenderer(props: ActionsCellRendererParams) {
  const navigate = useNavigate();

  return (
    <div className="lp-actions">
      {props.buttonsData.length <= 3 &&
        <div className="lp-actions-btns">
          {
            props.buttonsData.map((el: { type: ActionButtonTypes, callback: Function }) =>
              IconButtonGrid({
                ...el, callback: () => {
                  el.callback(props.data.id, navigate)
                }
              })
            )
          }
        </div>
      }

      {props.buttonsData.length > 3 &&
        <div className="lp-actions-btns">
          {
            props.buttonsData.slice(0, 2).map((el: { type: ActionButtonTypes, callback: Function }) =>
              IconButtonGrid({
                ...el, callback: () => {
                  el.callback(props.data.id, navigate)
                }
              })
            )
          }
          <MoreButton id={props.data.id} listButtons={props.buttonsData.slice(2)} />
        </div>
      }
    </div>
  );
}
