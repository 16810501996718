import { validateLteLegalName } from "actions/lte";
import { RegulatorTypeIds } from "enums/RegulatorTypeIds";
import { LawPageTradingEntityModel } from "models/view/LawPageTradingEntityModel";
import { CompanyLteTypes } from "utils/constants";
import { create, test, enforce, omitWhen } from "vest";
import isURL from "validator/lib/isURL";

export const getValidationSuite = (lte: LawPageTradingEntityModel) => {
  return create((data = {}) => {
    enforce.extend({ isURL });

    test('lawPageTradingEntityTypeId', 'Please select a Type', () => {
      enforce(data.lawPageTradingEntityTypeId).isNotEmpty();
      enforce(data.lawPageTradingEntityTypeId).isString();
    });

    test('tradingName', 'Trading Name is required', () => {
      enforce(data.tradingName).isNotEmpty();
      enforce(data.tradingName).isString();
    });

    test('legalName', 'Legal Name is required', () => {
      enforce(data.legalName).isNotEmpty();
      enforce(data.legalName).isString();
    });

    //memo caches the responses and doesn't run the callback function if
    //the value is cached already
    test.memo('legalName', 'Legal Name must be unique', async () => {
      enforce((await validateLteLegalName(data.legalName, lte.id)).data).isTruthy();
    }, [data.legalName]); 

    omitWhen(
      !CompanyLteTypes.includes(data.lawPageTradingEntityTypeId),
      () => {
        test('companyRegistrationNo', 'Company Registration No. is required', () => {
          enforce(data.companyRegistrationNo).isNotEmpty();
          enforce(data.companyRegistrationNo).isString();
        });
      }
    );

    test('regulatorId', 'Please select a Regulator', () => {
      enforce(data.regulatorId).isNotEmpty();
      enforce(data.regulatorId).isString();
    });

    omitWhen(data.regulatorId == undefined || data.regulatorId == RegulatorTypeIds.UnregulatedId, () => {
      test('regulatorReference', 'Regulator Registration Reference is required', () => {
        enforce(data.regulatorReference).isNotEmpty();
        enforce(data.regulatorReference).isString();
      });
    });

    omitWhen(data.entityUrl == undefined || data.entityUrl == "", () => {
      test('entityUrl', 'Entity URL is not an actual URL', () => {
        enforce(data.entityUrl).isURL();
      });
    });
  });
}