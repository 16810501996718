import { MdEmail, MdPhone } from "react-icons/md";
import { MatterFeeEarnerModel } from "models/view/MatterFeeEarnerModel";
import { EmailModel } from "models/view/EmailModel";
import { PhoneNumberModel } from "models/view/PhoneNumberModel";
import React from "react";

type Props = {
  matterFeeEarner: MatterFeeEarnerModel
}

export default function ViewMatterFeeEarnerPreferredContacts(props: Props) {

  return (
    <>
      {((props.matterFeeEarner?.emails?.length ?? 0) == 0 && (props.matterFeeEarner?.phoneNumbers?.length ?? 0) == 0) &&
        <div className="lp-list-empty managers-contact">No preferred contact info found.</div>
      }
      {((props.matterFeeEarner?.emails?.length ?? 0) > 0 || (props.matterFeeEarner?.phoneNumbers?.length ?? 0) > 0) &&
        <div className="lp-entity-contact">
          {props.matterFeeEarner?.emails?.map((email: EmailModel, index: number) => 
            <React.Fragment key={index}>
              <div className="lp-entity-contact-item">
                <MdEmail />
                <span className="email">{email?.email}</span>
              </div>
            </React.Fragment>
          )}

          {props.matterFeeEarner?.phoneNumbers?.map((phoneNumber: PhoneNumberModel, index: number) => 
            <React.Fragment key={index}>
              <div className="lp-entity-contact-item">
                <MdPhone />
                {phoneNumber?.number}
              </div>
            </React.Fragment>
          )}
        </div>
      }
    </>
  );
}
