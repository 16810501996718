import useWindowSize from "hooks/windowSize";
import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";

type Props = {
  children: React.ReactNode
}

export default function SummaryBoxesSection(props: Props) {
  const [width, height] = useWindowSize();
  return (
    <>
      {width < 1200 ?
        <Accordion>
          <Accordion.Item eventKey="0" className="lp-summary-boxes-accordion">
            <Accordion.Header>View statistics cards</Accordion.Header>
            <Accordion.Body>
              {props.children}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      : 
        props.children
      }
    </>
  );
}
