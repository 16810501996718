import { create, enforce, test } from "vest";

export const validationSuite = create((data = {}) => {
  test('recordableEventTypeId', 'Please select a Recordable Event Type.', () => {
    enforce(data.recordableEventTypeId).isNotEmpty();
    enforce(data.recordableEventTypeId).isString();
  });

  test('chargingRates', 'Please select a Charging Rate.', () => {
    enforce(data.chargingRates).isNotEmpty();
  });
});