import { validateLteTaxonomyDisplayName } from "actions/taxonomy";
import { create, test, enforce } from "vest";

export const validationSuite = create((data = {}) => {
  test('displayName', 'Display Name is required', () => {
    enforce(data.displayName).isNotEmpty();
    enforce(data.displayName).isString();
  });

  test.memo('displayName', 'Display Name must be unique', async () => {
    enforce((await validateLteTaxonomyDisplayName(data.displayName)).data).isTruthy();
  }, [data.displayName]);

  test('matterTypeIds', 'Please select at least a Matter Type', () => {
    enforce(data.matterTypeIds).isArray();
    enforce(data.matterTypeIds).isNotEmpty();
  });
});