import { useState } from "react";
import Title from "components/Title";
import { deleteEntityDocumentExchange } from "actions/entity";
import { Button } from 'react-bootstrap';
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { MdAdd } from "react-icons/md";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState } from "state/modalSlice";
import LinkEntityDocumentExchangeForm from "./LinkEntityDocumentExchange/LinkEntityDocumentExchangeForm";
import ListContact from "components/List/ListContact";
import { EntityDocumentExchangeModel } from "models/view/EntityDocumentExchangeModel";

type EntityLinkType = 'ownedBy' | 'owns';

type Props = {
  entityId: string,
  linkedEntityId: string,
  linkedEntityDocumentExchanges: EntityDocumentExchangeModel[],
  linkType: EntityLinkType,
  edit: boolean
}

export default function LinkedEntityDocumentExchangeList(props: Props) {
  const [linkedEntityDocumentExchanges, setLinkedEntityDocumentExchanges] = useState<EntityDocumentExchangeModel[]>(props.linkedEntityDocumentExchanges);
  const [isLoadingEntityDocumentExchanges, setIsLoadingEntityDocumentExchanges] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();

  const openLinkEntityDocumentExchangeSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Link Document Exchange',
      children: <LinkEntityDocumentExchangeForm 
                  toEntityId={props.linkType == 'ownedBy' ? props.entityId : props.linkedEntityId}
                  fromEntityId={props.linkType == 'ownedBy' ? props.linkedEntityId : props.entityId}
                  submitCallback={linkEntityDocumentExchangeCallback} />
    });
  };

  const linkEntityDocumentExchangeCallback = (newEntityDocumentExchange: EntityDocumentExchangeModel) => {
    setIsLoadingEntityDocumentExchanges(true);
    setLinkedEntityDocumentExchanges([...linkedEntityDocumentExchanges, newEntityDocumentExchange]);
    setIsLoadingEntityDocumentExchanges(false);
  };

  const unlinkCallbackEntityDocumentExchange = (id: string) => {
    setIsLoadingEntityDocumentExchanges(true);
    modalActions.toggleModalLoading();
    deleteEntityDocumentExchange(id).then(() => {
      setLinkedEntityDocumentExchanges(linkedEntityDocumentExchanges.filter(x => x.id != id));     
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingEntityDocumentExchanges(false);
      modalActions.toggleModalLoading();
      modalActions.toggleModalShownStatus();
    });
  };

  const unlinkEntityDocumentExchangeModal = (id: string) => {
    let modal: ModalState = {
      title: "Unlink confirmation",
      body: "Are you sure you want to unlink this document exchange?",
      actionText: "Unlink",
      actionVariant: "danger",
      onAction: () => unlinkCallbackEntityDocumentExchange(id),
      show: true
    }
    modalActions.setModal(modal);
   };

  return (
    <>
      <Title type="section" title={"Document Exchanges"}>
        <Button onClick={openLinkEntityDocumentExchangeSlidingPanel} className="btn-icon" variant="success">
          <MdAdd />
        </Button>
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <ListContact
        listItems={linkedEntityDocumentExchanges.map((dx: EntityDocumentExchangeModel) => 
          ({id: dx.id, contact: dx.dxExchange + ' (Number: ' + dx.dxNumber + ')', isPreferred: dx.isPreferred})
        ) ?? []}
        listButtons={
          [
            {
              type: ActionButtonTypes.Unlink,
              callback: (id: string) => unlinkEntityDocumentExchangeModal(id)
            }
          ]
        }
        loadState={isLoadingEntityDocumentExchanges}
        hideTypeColumn
        displayUnlinkButton
      >
        <div>{"Document Exchange"}</div>
      </ListContact>
    </>
  );
}
