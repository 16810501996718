import { deleteSingleRecordableEventTypeToChargingRate } from "actions/chargingSchemes";
import useModalActions from "actions/modal";
import useSlidingPanelActions from "actions/slidingPanel";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ListChargingRates from "components/List/ListChargingRates";
import Title from "components/Title/index";
import { RecordableEventTypeToChargingRateType } from "models/view/RecordableEventTypeToChargingRateModel";
import { Button } from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import { ModalState } from "state/modalSlice";
import CreateRecordableEventTypeToChargingRate from "../CreateRecordableType/CreateRecordableEventTypeToChargingRate";

type Props = {
  recordableEventTypesToChargingRates: RecordableEventTypeToChargingRateType[],
  recordableEventTypeToChargingRate: RecordableEventTypeToChargingRateType,
  setRecordableEventTypesToChargingRates: any,
  isLoading: boolean,
  chargingSchemeId: string
}

export default function ChargingRatesListFromRecordable(props: Props) {
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();

  const deleteModal = (id: string) => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this link?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteAndRemoveFromList(id),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const deleteAndRemoveFromList = (id: string) => {
    modalActions.toggleModalLoading();
    deleteSingleRecordableEventTypeToChargingRate(id).then(() => {
      let updatingRecordableEventType = props.recordableEventTypesToChargingRates.find(x => x.chargingRates.some(y => y.recordableEventTypeToChargingRateId == id));
      if(updatingRecordableEventType)
      {
        const index = props.recordableEventTypesToChargingRates.indexOf(updatingRecordableEventType);
        let recordableEventTypesToChargingRates: RecordableEventTypeToChargingRateType[] = props.recordableEventTypesToChargingRates;
        updatingRecordableEventType.chargingRates = updatingRecordableEventType.chargingRates.filter(x => x.recordableEventTypeToChargingRateId != id);
        if(updatingRecordableEventType.chargingRates == null || updatingRecordableEventType.chargingRates.length == 0)
        {
          props.setRecordableEventTypesToChargingRates(recordableEventTypesToChargingRates.filter(x => x.recordableEventType != updatingRecordableEventType!.recordableEventType));
        }
        else {
          recordableEventTypesToChargingRates[index] = updatingRecordableEventType; 
          props.setRecordableEventTypesToChargingRates([...recordableEventTypesToChargingRates]);
        }
      }
      modalActions.toggleModalShownStatus();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  }

  const openCreateRecordableEventTypeToChargingRate = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Create Recordable Event Type',
      children: <CreateRecordableEventTypeToChargingRate
        eventTypeId={props.recordableEventTypeToChargingRate.recordableEventType.id}
        chargingSchemeId={props.recordableEventTypeToChargingRate.chargingScheme.id}
        setRecordableEventTypesToChargingRates={props.setRecordableEventTypesToChargingRates}
      />
    });
  };
  
  return (
    <>
      <Title type="section" title={"Charging Rates"}>
        <Button onClick={openCreateRecordableEventTypeToChargingRate} className="btn-icon" variant="success">
          <MdAdd />
        </Button>
      </Title>
      <ListChargingRates
        listItems={props.recordableEventTypeToChargingRate.chargingRates}
        listButtons={
          [
            {
              type: ActionButtonTypes.Delete,
              callback: deleteModal
            }
          ]
        }
        loadState={props.isLoading}
      >
        <>
          <div>{"Activity"}</div>
          <div>{"Fee Earner Level"}</div>
          <div></div>
        </>
      </ListChargingRates>
    </>
  );
}