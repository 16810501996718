import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { vestResolver } from '@hookform/resolvers/vest';
import { Form, Button, Col, Row, InputGroup } from 'react-bootstrap';
import useSlidingPanelActions from 'actions/slidingPanel';
import CustomSelect from 'components/Select/Select';
import Loader from 'components/Loader';
import { getValidationSuite } from './validationSuite';
import { removeEmptyFields } from 'utils/form';
import { CreateParticipatingEntityModel } from 'models/create/CreateParticipatingEntityModel';
import { createParticipatingEntity } from 'actions/matter';
import {
  getBailConditions,
  getBailStatuses,
  getEntitiesSummary,
  getEntityById
} from 'actions/entity';
import EntityCreateForm from 'containers/Entity/CreateEntity/EntityCreateForm';
import useGridActions from 'actions/grid';
import { useAppSelector } from 'hooks/appSelector';
import { EntityRoleIds } from 'enums/EntityRoleIds';
import DatePicker from 'react-datepicker';
import { DateFormat } from 'utils/constants';
import { BailStatusIds } from 'enums/BailStatusIds';
import Title from 'components/Title/index';
import { MdAdd, MdClose, MdWarning } from 'react-icons/md';
import { MatterTypeIds } from 'enums/MatterTypeIds';
import store from 'state/store';
import { ModalState } from 'state/modalSlice';
import useModalActions from 'actions/modal';
import { getDateOnly } from 'utils/date';
import { getInvoiceDueDateSettingTypes } from 'actions/lte';
import { getEntityRolesSummary } from 'actions/settings';

type Props = {
  matterId: string;
  matterTypeId: string;
  entityId?: string;
};

export default function CreateParticipatingEntityForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFields, setShowFields] = useState<boolean>(
    props?.entityId !== undefined
  );
  const [roleId, setRoleId] = useState<string>('');
  const [bailStatusId, setBailStatusId] = useState<string>('');
  const [showCreateNewEntity, setShowCreateNewEntity] = useState<boolean>(
    props?.entityId === undefined
  );
  const slidingPanelActions = useSlidingPanelActions();
  const gridActions = useGridActions();
  const grid = useAppSelector((state) => state.grid);
  const modalActions = useModalActions();

  const isThereAnyDataOrAnyDefaultClient = () => {
    const gridState = store.getState().grid;

    if (!gridState.rowData || gridState?.rowData.length === 0) {
      return false;
    }

    if(!grid.rowData.some(x => x.isDefaultClient == true))
    {
      return false;
    }

    return true;
  };

  const hasAInstructingSolicitor = () => {
    const gridState = store.getState().grid;
    return gridState?.rowData.some(x => x.entityRoleId == EntityRoleIds.InstructingSolicitorId);
  }

  const createParticipatingEntityCallback = (
    data: CreateParticipatingEntityModel,
    fromModal: boolean = true
  ) => {
    setIsLoading(true);
    if(fromModal)
    {
      modalActions.toggleModalLoading();
    }
    createParticipatingEntity(data)
      .then((response) => {
        if (
          data.isDefaultClient == true &&
          grid.rowData.some(x => x.isDefaultClient == true)
        ) {
          const newData = grid.rowData.map((obj) => {
            if (obj.isDefaultClient == true) {
              return { ...obj, isDefaultClient: false };
            } else {
              return obj;
            }
          });
          gridActions.setGridRowData(newData.concat(response.data));
        } else {
          gridActions.setGridRowData(grid.rowData.concat(response.data));
        }
        slidingPanelActions.clearSlidingPanel();
        reset();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
        if(fromModal)
        {
          modalActions.toggleModalLoading();
          modalActions.toggleModalShownStatus();
        }
      });
  };

  const showWarningIfOtherLayClients = (
    data: CreateParticipatingEntityModel
  ) => {
    var bodyMessage: React.ReactNode = (
      <div className="lp-modal-warning">
        <MdWarning />
        This Matter already has a Lay Client that is set as the Default Client. It will be replaced as the Default Client by this one.
        <br />
        Are you sure you want to do that? If not, uncheck the Default Client
        checkbox.
      </div>
    );
    let modal: ModalState = {
      title: 'Add confirmation',
      body: bodyMessage,
      actionText: 'Add',
      onAction: () => createParticipatingEntityCallback(data),
      show: false,
    };
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const showWarningIfInstructingSolicitorAndThereAreExistingLayClients = (
    data: CreateParticipatingEntityModel
  ) => {
    var bodyMessage: React.ReactNode = (
      <div className="lp-modal-warning">
        <MdWarning />
        This Matter already has a Lay Client that is set as the Default Client. It will be replaced as the Default Client by this Instructing Solicitor.
        <br />
        Are you sure you want to do that?
      </div>
    );
    let modal: ModalState = {
      title: 'Add confirmation',
      body: bodyMessage,
      actionText: 'Add',
      onAction: () => createParticipatingEntityCallback(data),
      show: false,
    };
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const {
    register,
    reset,
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateParticipatingEntityModel>({
    resolver: vestResolver(
      getValidationSuite(props.matterId, props.matterTypeId)
    ),
    defaultValues: {
      entityId: props?.entityId ?? '',
      createMatterCriminalBailConditions: [
        {
          bailConditionId: '',
          additionalText: '',
        },
      ],
    },
  });

  useEffect(() => {
    //if the role is not lay client or instructing solicitor set the default client flag to false
    if(roleId != EntityRoleIds.LayClientId && roleId != EntityRoleIds.InstructingSolicitorId)
    {
      setValue('isDefaultClient', false);
    }
    else 
    {
      setValue('isInvoicingParty', true);

      setValue(
        'isDefaultClient',
          !hasAInstructingSolicitor() && (
          (!isThereAnyDataOrAnyDefaultClient() && EntityRoleIds.LayClientId == roleId) ||
          EntityRoleIds.InstructingSolicitorId == roleId)
      );
    }
  }, [roleId]);

  useEffect(() => {
    if (props?.entityId !== undefined) {
      onChangeEntity(props.entityId);
    }
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateParticipatingEntityModel) {
    removeEmptyFields(data);
    data.matterId = props.matterId;
    if (data.isDefaultClient == true &&
      data.entityRoleId == EntityRoleIds.LayClientId &&
      !grid.rowData.some(x => x.entityRoleId == EntityRoleIds.InstructingSolicitorId) &&
      grid.rowData.some(x => x.isDefaultClient == true)
      ) 
    {
      showWarningIfOtherLayClients(data);
      return;
    }
    if (
      data.entityRoleId == EntityRoleIds.InstructingSolicitorId &&
      grid.rowData.some(x => x.entityRoleId == EntityRoleIds.LayClientId && x.isDefaultClient == true) &&
      !grid.rowData.some(x => x.entityRoleId == EntityRoleIds.InstructingSolicitorId)
      ) 
    {
      showWarningIfInstructingSolicitorAndThereAreExistingLayClients(data);
      return;
    }
    createParticipatingEntityCallback(data, false);
  }

  const onChangeEntity = (id?: string) => {
    setIsLoading(true);
    setShowCreateNewEntity(false);
    getEntityById(id ?? '', true)
      .then((response) => {
        if (response.data) {
          setValue('entityRoleId', response.data.defaultRoleId);
          setRoleId(response.data.defaultRoleId);
          setShowFields(true);
        } else {
          setValue('entityRoleId', '');
          setRoleId('');
          setShowFields(false);
        }
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  };

  const goToCreateNewEntity = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Add Entity',
      children: (
        <EntityCreateForm submitCallback={submitCallbackCreateEntity} />
      ),
    });
  };

  const submitCallbackCreateEntity = (callbackEntityId: string) => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Add Participating Entity',
      children: (
        <CreateParticipatingEntityForm
          matterId={props?.matterId}
          matterTypeId={props.matterTypeId}
          entityId={callbackEntityId}
        />
      ),
    });
  };

  const { fields, append, remove } = useFieldArray({
    name: 'createMatterCriminalBailConditions',
    control,
    rules: {
      required: 'Please append at least 1 item',
    },
  });

  const appendNewLevel = () => {
    append(
      {
        bailConditionId: '',
        additionalText: '',
      },
      { shouldFocus: false }
    );
  };

  const resetBailStatus = () => {
    setValue('bailStatusId', '');
  };

  const resetCustodyTimeLimitExpiryDate = () => {
    setValue('custodyTimeLimitExpiryDate', undefined);
  };

  const resetBailConditions = () => {
    setValue('createMatterCriminalBailConditions', [
      {
        bailConditionId: '',
        additionalText: '',
      },
    ]);
  };

  const onChangeBailCondition = (bailCondition: any, index: number) => {
    setValue(
      `createMatterCriminalBailConditions.${index}.additionalText`,
      bailCondition?.additionalText ?? ''
    );
  };

  const onChangeIsInvoicingParty = (value: boolean) => {
    if(!value) {
      setValue("purchaseOrderReference", undefined);
      setValue("invoiceDueDateSettingNumber", undefined);
      setValue("invoiceDueDateSettingTypeId", undefined);
    }
  }

  const onChangeBailStatus = (value: any) => {
    setBailStatusId(value?.id);
    resetBailConditions();
    if(value?.id != BailStatusIds.RemandedInCustodyId) {
      setValue("custodyTimeLimitExpiryDate", undefined);
    }
  };

  const onChangeEntityRole = (val: any) => {
    setRoleId(val);
    setBailStatusId('');
    resetBailStatus();
    resetBailConditions();
    resetCustodyTimeLimitExpiryDate();
  };

  const onChangeInvoiceDueDateSettingType = (val: any) => {
    if(!val){
      setValue('invoiceDueDateSettingNumber', undefined);
    }
  };

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-4" controlId="entityId">
          <Form.Label className="required">Select existing entity</Form.Label>
          <Controller
            control={control}
            name="entityId"
            shouldUnregister={true}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                id="entityId"
                endpointCall={getEntitiesSummary}
                value={value}
                onChange={(val) => {
                  onChange(val?.id ?? null);
                  onChangeEntity(val?.id);
                }}
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.entityId?.message && errors.entityId.message}
          </Form.Text>
        </Form.Group>

        {showCreateNewEntity ? (
          <>
            <div className="mb-4">OR</div>
            <Button variant="primary" onClick={goToCreateNewEntity}>
              Create New Entity
            </Button>
          </>
        ) : (
          <>
            {showFields && (
              <>
                <Form.Group className="mb-4" controlId="entityRoleId">
                  <Form.Label className="required">Role</Form.Label>
                  <Controller
                    control={control}
                    name="entityRoleId"
                    shouldUnregister={true}
                    render={({ field: { onChange, value } }) => (
                      <CustomSelect
                        id="entityRoleId"
                        endpointCall={getEntityRolesSummary}
                        value={value}
                        onChange={(val) => {
                          onChange(val?.id ?? null);
                          onChangeEntityRole(val?.id);
                        }}
                      />
                    )}
                  />
                  <Form.Text className="lp-error">
                    {errors?.entityRoleId?.message &&
                      errors.entityRoleId.message}
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-4" controlId="entityMatterReference">
                  <Form.Label>Matter Reference</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.notes?.message ? 'invalid' : ''}`}
                    {...register('entityMatterReference', {
                      shouldUnregister: true,
                    })}
                  />
                  <Form.Text className="lp-error">
                    {errors?.entityMatterReference?.message &&
                      errors.entityMatterReference.message}
                  </Form.Text>
                </Form.Group>

                <Row>
                  {(watch("entityRoleId") == EntityRoleIds.LayClientId || watch("entityRoleId") == EntityRoleIds.InstructingSolicitorId) &&
                    <Form.Group as={Col} className="mb-4" controlId="isDefaultClient">
                      <Form.Label>Is Default Client</Form.Label>
                      <Controller
                        control={control}
                        name="isDefaultClient"
                        shouldUnregister={true}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Form.Check type="switch" id="isDefaultClient">
                            <Form.Check.Input
                              className="form-check-input"
                              disabled={
                                (!isThereAnyDataOrAnyDefaultClient() && EntityRoleIds.LayClientId == roleId) ||
                                hasAInstructingSolicitor() ||
                                EntityRoleIds.InstructingSolicitorId == roleId
                              }
                              ref={ref}
                              checked={value}
                              onChange={(ev: any) => onChange(ev.target.checked)}
                            />
                          </Form.Check>
                        )}
                      />
                    </Form.Group>
                  }
                  <Form.Group as={Col} className="mb-4" controlId="isInvoicingParty">
                    <Form.Label>Is Invoicing Party</Form.Label>
                    <Controller
                      control={control}
                      name="isInvoicingParty"
                      shouldUnregister={true}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Form.Check type="switch" id="isInvoicingParty">
                          <Form.Check.Input
                            className="form-check-input"
                            ref={ref}
                            checked={value}
                            onChange={(ev: any) => { onChange(ev.target.checked); onChangeIsInvoicingParty(ev.target.checked);}}
                          />
                        </Form.Check>
                      )}
                    />
                  </Form.Group>
                </Row>

                {watch("isInvoicingParty") &&
                  <>
                    <Form.Group className="mb-4" controlId="purchaseOrderReference">
                      <Form.Label>Purchase Order Reference</Form.Label>
                      <Form.Control
                        type="text"
                        className={`${errors?.purchaseOrderReference?.message ? 'invalid' : ''}`}
                        {...register("purchaseOrderReference", {shouldUnregister: true})}
                      />
                      <Form.Text className="lp-error">
                        {errors?.purchaseOrderReference?.message && (errors.purchaseOrderReference.message)}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label htmlFor="invoiceDueDateSettingNumber">Invoice Due Date</Form.Label>
                      <InputGroup className="lp-invoice-due-date">
                        <Form.Control
                          type="number"
                          className={`${errors?.invoiceDueDateSettingNumber?.message ? 'invalid' : ''}`}
                          {...register("invoiceDueDateSettingNumber", {shouldUnregister: true})}
                          min="0"
                          step="1"
                          onWheel={e => e.currentTarget.blur()}
                        />
                        <Controller
                          control={control}
                          name={`invoiceDueDateSettingTypeId`}
                          shouldUnregister={true}
                          render={({ field: { onChange, value, name, ref } }) => (
                            <CustomSelect
                              id="invoiceDueDateSettingTypeId"
                              inputRef={ref}
                              className={`lp-select${errors?.invoiceDueDateSettingTypeId?.message ? ' invalid' : ''}`}
                              endpointCall={getInvoiceDueDateSettingTypes}
                              value={value}
                              onChange={val => { onChange(val?.id ?? null); onChangeInvoiceDueDateSettingType(val?.id); }}
                              isClearable
                            />
                          )}
                        />
                      </InputGroup>
                      <Form.Text className="lp-error">
                        {errors?.invoiceDueDateSettingNumber?.message && (errors.invoiceDueDateSettingNumber.message)}
                        {errors?.invoiceDueDateSettingTypeId?.message && (errors.invoiceDueDateSettingTypeId.message)}
                      </Form.Text>
                    </Form.Group>
                  </>
                }

                <Form.Group className="mb-4" controlId="notes">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    className={`${errors?.notes?.message ? 'invalid' : ''}`}
                    {...register('notes', { shouldUnregister: true })}
                  />
                  <Form.Text className="lp-error">
                    {errors?.notes?.message && errors.notes.message}
                  </Form.Text>
                </Form.Group>

                {roleId === EntityRoleIds.LayClientId &&
                  props.matterTypeId === MatterTypeIds.CrimeId && (
                    <div className="lp-bails">
                      <div className="lp-bail-status">
                        <Form.Group className="mb-4" controlId="bailStatusId">
                          <Form.Label className="required">
                            Bail Status
                          </Form.Label>
                          <Controller
                            control={control}
                            name="bailStatusId"
                            shouldUnregister={true}
                            render={({ field: { onChange, value } }) => (
                              <CustomSelect
                                id="bailStatusId"
                                endpointCall={getBailStatuses}
                                value={value}
                                onChange={(val) => {
                                  onChange(val?.id ?? null);
                                  onChangeBailStatus(val);
                                }}
                              />
                            )}
                          />
                          <Form.Text className="lp-error">
                            {errors?.bailStatusId?.message &&
                              errors.bailStatusId.message}
                          </Form.Text>
                        </Form.Group>

                        {bailStatusId === BailStatusIds.RemandedInCustodyId &&
                          <Form.Group className="mb-4" controlId="custodyTimeLimitExpiryDate">
                            <Form.Label className={bailStatusId === BailStatusIds.RemandedInCustodyId ? 'required' : ''}>
                              Custody Time Limit Expiry Date
                            </Form.Label>
                            <Controller
                              control={control}
                              name="custodyTimeLimitExpiryDate"
                              shouldUnregister={true}
                              render={({ field: { onChange, value } }) => (
                                <DatePicker
                                  className={`${errors?.custodyTimeLimitExpiryDate?.message ? 'invalid' : ''}`}
                                  id="custodyTimeLimitExpiryDate"
                                  dateFormat={DateFormat.Datepicker}
                                  selected={value ? getDateOnly(value) : null}
                                  onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                                  showMonthDropdown
                                  showYearDropdown
                                  autoComplete="off"
                                />
                              )}
                            />
                            <Form.Text className="lp-error">
                              {errors?.custodyTimeLimitExpiryDate?.message &&
                                errors.custodyTimeLimitExpiryDate.message}
                            </Form.Text>
                          </Form.Group>
                        }
                      </div>
                      {(bailStatusId ===
                        BailStatusIds.RemandedOnConditionalBailId ||
                        bailStatusId ===
                          BailStatusIds.ReleasedOnConditionalPoliceBailId) && (
                        <div className="lp-bail-conditions">
                          <Title type="section" title={'Bail Conditions'}>
                            <Button
                              onClick={appendNewLevel}
                              className="btn-icon"
                              variant="success"
                            >
                              <MdAdd />
                            </Button>
                          </Title>

                          {fields.map((field, index) => {
                            return (
                              <section
                                key={field.id}
                                className="lp-bail-condition-item mb-4"
                              >
                                <Button
                                  variant="danger"
                                  onClick={() => remove(index)}
                                  className={`delete-item btn-icon${fields.length === 1 ? ' disabled' : ''}`}
                                >
                                  <MdClose />
                                </Button>

                                <Form.Group
                                  className="mb-4"
                                  controlId={`createMatterCriminalBailConditions.${index}.bailConditionId`}
                                >
                                  <Form.Label className="required">
                                    Bail Condition
                                  </Form.Label>
                                  <Controller
                                    control={control}
                                    name={`createMatterCriminalBailConditions.${index}.bailConditionId`}
                                    shouldUnregister={true}
                                    render={({
                                      field: { onChange, value, name, ref },
                                    }) => (
                                      <CustomSelect
                                        id={`createMatterCriminalBailConditions.${index}.bailConditionId`}
                                        inputRef={ref}
                                        className={`lp-select${
                                          errors
                                            ?.createMatterCriminalBailConditions?.[
                                            index
                                          ]?.bailConditionId?.message
                                            ? ' invalid'
                                            : ''
                                        }`}
                                        endpointCall={getBailConditions}
                                        value={value}
                                        onChange={(val) => {
                                          onChange(val?.id ?? null);
                                          onChangeBailCondition(val, index);
                                        }}
                                        menuPlacement="top"
                                      />
                                    )}
                                  />
                                  <Form.Text className="lp-error">
                                    {errors?.createMatterCriminalBailConditions?.[index]?.bailConditionId?.message &&
                                      errors?.createMatterCriminalBailConditions?.[index]?.bailConditionId?.message}
                                  </Form.Text>
                                </Form.Group>

                                <Form.Group
                                  controlId={`createMatterCriminalBailConditions.${index}.additionalText`}
                                >
                                  <Form.Label className="required">
                                    Additional Text
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={5}
                                    className={`${errors?.createMatterCriminalBailConditions?.[index]?.additionalText?.message ? 'invalid' : ''}`}
                                    {...register(
                                      `createMatterCriminalBailConditions.${index}.additionalText`,
                                      { shouldUnregister: true }
                                    )}
                                  />
                                  <Form.Text className="lp-error">
                                    {errors?.createMatterCriminalBailConditions?.[index]?.additionalText?.message &&
                                      errors?.createMatterCriminalBailConditions?.[index]?.additionalText?.message}
                                  </Form.Text>
                                </Form.Group>
                              </section>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )}

                <Form.Group className="d-flex justify-content-between">
                  <Button variant="success" type="submit">
                    Create
                  </Button>
                  <Button variant="secondary-400" onClick={cancelForm}>
                    Cancel
                  </Button>
                </Form.Group>
              </>
            )}
          </>
        )}
      </Form>
    </>
  );
}
