import {
  getLteUserWithEntity,
  getRegulators,
  updateUserWelcomeInfo,
  useUserActions,
} from "actions/user";
import { useAppSelector } from "hooks/appSelector";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { vestResolver } from "@hookform/resolvers/vest";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import Loader from "components/Loader";
import "./style.scss";
import { getSexes, getGenderPronouns, getEthnicities, getLanguages } from "actions/entity";
import { validationSuite } from "./validationSuite";
import { UpdateUserPersonalInfoModel } from "models/update/UpdateUserPersonalInfoModel";
import { removeEmptyFields } from "utils/form";
import DatePicker from "react-datepicker";
import { DateFormat } from "utils/constants";
import CustomSelect from "components/Select/Select";
import { RegulatorTypeIds } from "enums/RegulatorTypeIds";
import PhoneInput from "react-phone-input-2";
import { getDateOnly } from "utils/date";

function WelcomeUser() {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const userActions = useUserActions();
  const user = useAppSelector((state) => state.user);
  const [genericErrors, setGenericErrors] = useState(null);
  let navigate = useNavigate();

  const { register, reset, trigger, handleSubmit, watch, setValue, clearErrors, control, formState: { errors } } = useForm<UpdateUserPersonalInfoModel>({
    resolver: vestResolver(validationSuite)
  });

  useEffect(() => {
    getLteUserWithEntity(user.userId!)
      .then((response) => {
        if (response?.data.languagesSpoken) {
          response.data.languagesSpokenIds = response.data.languagesSpoken.map((a: any) => a.id);
        }

        reset(response.data);
      }
      )
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: UpdateUserPersonalInfoModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    data.isFeeEarner = user.isFeeEarner ?? false;
    updateUserWelcomeInfo(user.userId!, data)
      .then((response) => {
        userActions.setNewUserData(response.data);
        navigate("/");
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const onBlurFirstName = () => {
    if (watch("firstName") == '' || watch("firstName") == undefined) {
      return;
    }
    if (watch("salutation") == '' || watch("salutation") == undefined) {
      setValue('salutation', watch("firstName"));
      trigger('salutation');
    }
  }

  const onBlurLastName = () => {
    if (watch("lastName") == '' || watch("lastName") == undefined) {
      return;
    }
    if (watch("lastNameAtBirth") == '' || watch("lastNameAtBirth") == undefined) {
      setValue('lastNameAtBirth', watch("lastName"));
      trigger('lastNameAtBirth');
    }
  }

  const onChangeRegulator = () => {
    clearErrors('admissionOrCallDate');
    clearErrors('regulatorReference');
  }

  return (
    <>
      {isLoading && <Loader />}

      <>
        <div className="lp-welcome-title">
          <h1>Welcome</h1>
          <div className="email">{user.email}</div>
          <p>Please complete your profile</p>
        </div>

        <div className="lp-welcome-form">
          <Card>
            <Card.Body>
              {genericErrors && (
                <div className="lp-errors">
                  {genericErrors}
                </div>
              )}

              <Form onSubmit={onSubmit}>
                <Row className="multiple">
                  <Form.Group as={Col} sm={6} xxl={4} controlId="firstName">
                    <Form.Label className="required">First Name</Form.Label>
                    <Form.Control
                      type="text"
                      className={`${errors?.firstName?.message ? 'invalid' : ''}`}
                      {...register("firstName", {shouldUnregister: true})}
                      onBlur={onBlurFirstName}
                    />
                    <Form.Text className="lp-error">
                      {errors?.firstName?.message && (errors.firstName.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="middleName">
                    <Form.Label>Middle Name</Form.Label>
                    <Form.Control
                      type="text"
                      {...register("middleName", {shouldUnregister: true})}
                    />
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="lastName">
                    <Form.Label className="required">Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      className={`${errors?.lastName?.message ? 'invalid' : ''}`}
                      {...register("lastName", {shouldUnregister: true})}
                      onBlur={onBlurLastName}
                    />
                    <Form.Text className="lp-error">
                      {errors?.lastName?.message && (errors.lastName.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="lastNameAtBirth">
                    <Form.Label>Last Name At Birth</Form.Label>
                    <Form.Control
                      type="text"
                      className={`${errors?.lastNameAtBirth?.message ? 'invalid' : ''}`}
                      {...register("lastNameAtBirth", {shouldUnregister: true})}
                    />
                    <Form.Text className="lp-error">
                      {errors?.lastNameAtBirth?.message && (errors.lastNameAtBirth.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="dateOfBirth">
                    <Form.Label>Date Of Birth</Form.Label>
                    <Controller
                      control={control}
                      name="dateOfBirth"
                      shouldUnregister={true}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          className={`${errors?.dateOfBirth?.message ? 'invalid' : ''}`}
                          dateFormat={DateFormat.Datepicker}
                          selected={value ? getDateOnly(value) : null}
                          onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                          showMonthDropdown
                          showYearDropdown
                          autoComplete="off"
                        />
                      )}
                    />
                    <Form.Text className="lp-error">
                      {errors?.dateOfBirth?.message && (errors.dateOfBirth.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="sexId">
                    <Form.Label className="required">Sex</Form.Label>
                    <Controller
                      control={control}
                      name="sexId"
                      shouldUnregister={true}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <CustomSelect
                          id="sexId"
                          inputRef={ref}
                          className={`lp-select${errors?.sexId?.message ? ' invalid' : ''}`}
                          endpointCall={getSexes}
                          value={value}
                          onChange={val => onChange(val?.id ?? null)}
                        />
                      )}
                    />
                    <Form.Text className="lp-error">
                      {errors?.sexId?.message && (errors.sexId.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="genderPronounId">
                    <Form.Label className="required">Gender Pronoun</Form.Label>
                    <Controller
                      control={control}
                      name="genderPronounId"
                      shouldUnregister={true}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <CustomSelect
                          id="genderPronoun"
                          inputRef={ref}
                          className={`lp-select${errors?.genderPronounId?.message ? ' invalid' : ''}`}
                          endpointCall={getGenderPronouns}
                          value={value}
                          onChange={val => onChange(val?.id ?? null)}
                        />
                      )}
                    />
                    <Form.Text className="lp-error">
                      {errors?.genderPronounId?.message && (errors.genderPronounId.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="prefix">
                    <Form.Label>Prefix</Form.Label>
                    <Form.Control
                      type="text"
                      {...register("prefix", {shouldUnregister: true})}
                    />
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="suffix">
                    <Form.Label>Suffix</Form.Label>
                    <Form.Control
                      type="text"
                      {...register("suffix", {shouldUnregister: true})}
                    />
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="salutation">
                    <Form.Label className="required">Salutation</Form.Label>
                    <Form.Control
                      type="text"
                      className={`${errors?.salutation?.message ? 'invalid' : ''}`}
                      {...register("salutation", {shouldUnregister: true})}
                    />
                    <Form.Text className="lp-error">
                      {errors?.salutation?.message && (errors.salutation.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="ethnicityId">
                    <Form.Label>Ethnicity</Form.Label>
                    <Controller
                      control={control}
                      name="ethnicityId"
                      shouldUnregister={true}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <CustomSelect
                          id="ethnicityId"
                          inputRef={ref}
                          endpointCall={getEthnicities}
                          value={value}
                          isClearable
                          onChange={val => onChange(val?.id ?? null)}
                        />
                      )}
                    />
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="nationalInsuranceNo">
                    <Form.Label>National Insurance No.</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="PP-NNNNNN-S"
                      className={`${errors?.nationalInsuranceNo?.message ? 'invalid' : ''}`}
                      {...register("nationalInsuranceNo", {shouldUnregister: true})}
                    />
                    <Form.Text className="lp-error">
                      {errors?.nationalInsuranceNo?.message && (errors.nationalInsuranceNo.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="regulatorId">
                    <Form.Label className="required">Regulator</Form.Label>
                    <Controller
                      control={control}
                      name="regulatorId"
                      shouldUnregister={true}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <CustomSelect
                          id="regulatorId"
                          inputRef={ref}
                          className={`lp-select${errors?.regulatorId?.message ? ' invalid' : ''}`}
                          endpointCall={getRegulators}
                          value={value}
                          onChange={val => { onChange(val?.id ?? null); onChangeRegulator(); }}
                          menuPlacement="top"
                        />
                      )}
                    />
                    <Form.Text className="lp-error">
                      {errors?.regulatorId?.message && (errors.regulatorId.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="admissionOrCallDate">
                    <Form.Label
                      className={`${(watch("regulatorId") && watch("regulatorId") != RegulatorTypeIds.UnregulatedId) ? " required" : ""}`}
                    >
                      Admission Or Call Date
                    </Form.Label>
                    <Controller
                      control={control}
                      name="admissionOrCallDate"
                      shouldUnregister={true}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          className={`${errors?.admissionOrCallDate?.message ? 'invalid' : ''}`}
                          dateFormat={DateFormat.Datepicker}
                          selected={value ? getDateOnly(value) : null}
                          onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                          showMonthDropdown
                          showYearDropdown
                          autoComplete="off"
                        />
                      )}
                    />
                    <Form.Text className="lp-error">
                      {errors?.admissionOrCallDate?.message && (errors.admissionOrCallDate.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="regulatorReference">
                    <Form.Label
                      className={`${(watch("regulatorId") && watch("regulatorId") != RegulatorTypeIds.UnregulatedId) ? " required" : ""}`}
                    >
                      Regulator Registration Reference
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className={`${errors?.regulatorReference?.message ? 'invalid' : ''}`}
                      {...register("regulatorReference", {shouldUnregister: true})}
                    />
                    <Form.Text className="lp-error">
                      {errors?.regulatorReference?.message && (errors.regulatorReference.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="practicingCertificateNotes">
                    <Form.Label>Practicing Certificate Notes</Form.Label>
                    <Form.Control
                      type="text"
                      {...register("practicingCertificateNotes", {shouldUnregister: true})}
                    />
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="emergencyContactName">
                    <Form.Label className="required">Emergency Contact Name</Form.Label>
                    <Form.Control
                      type="text"
                      className={`${errors?.emergencyContactName?.message ? 'invalid' : ''}`}
                      {...register("emergencyContactName", {shouldUnregister: true})}
                    />
                    <Form.Text className="lp-error">
                      {errors?.emergencyContactName?.message && (errors.emergencyContactName.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="emergencyContactPhone">
                    <Form.Label className="required">Emergency Contact Phone</Form.Label>
                    <Controller
                      control={control}
                      name="emergencyContactPhone"
                      shouldUnregister={true}
                      render={({ field: { onChange, value } }) => (
                        <PhoneInput
                          containerClass={`lp-phone-input${errors?.emergencyContactPhone?.message ? ' invalid' : ''}`}
                          autoFormat={false}
                          inputProps={{ name: 'emergencyContactPhone' }}
                          country={'gb'}
                          placeholder="Emergency Contact Phone"
                          value={value}
                          onChange={val => onChange('+' + val)}
                        />
                      )}
                    />
                    <Form.Text className="lp-error">
                      {errors?.emergencyContactPhone?.message && (errors.emergencyContactPhone.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="emergencyContactEmail">
                    <Form.Label>Emergency Contact Email</Form.Label>
                    <Form.Control
                      type="text"
                      className={`${errors?.emergencyContactEmail?.message ? 'invalid' : ''}`}
                      {...register("emergencyContactEmail", {shouldUnregister: true})}
                    />
                    <Form.Text className="lp-error">
                      {errors?.emergencyContactEmail?.message && (errors.emergencyContactEmail.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="relationshipToEmergencyContact">
                    <Form.Label className="required">Relationship To Emergency Contact</Form.Label>
                    <Form.Control
                      type="text"
                      className={`${errors?.relationshipToEmergencyContact?.message ? 'invalid' : ''}`}
                      {...register("relationshipToEmergencyContact", {shouldUnregister: true})}
                    />
                    <Form.Text className="lp-error">
                      {errors?.relationshipToEmergencyContact?.message && (errors.relationshipToEmergencyContact.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={4} controlId="languagesSpokenIds">
                    <Form.Label>Languages Spoken</Form.Label>
                    <Controller
                      control={control}
                      name="languagesSpokenIds"
                      shouldUnregister={true}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <CustomSelect
                          id="languagesSpokenIds"
                          inputRef={ref}
                          endpointCall={getLanguages}
                          isMulti
                          isClearable
                          menuPlacement="top"
                          value={value}
                          onChange={val => onChange(val?.map((x: { id: any; }) => x.id) ?? null)}
                        />
                      )}
                    />
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group className="d-flex justify-content-between">
                    <Button variant="success" type="submit">Save</Button>
                    <Button variant="secondary-400" onClick={() => reset()}>Reset</Button>
                  </Form.Group>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </>
    </>
  );
}

export default WelcomeUser;
