import { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { DetailGridInfo, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { DEFAULT_DETAIL_COLUMN_DEFINITIONS } from 'components/Grid/DefaultDetailColumnDefinitions';
import { getMatterRecordableItemsForInvoice, getMatterIncidentalExpensesForInvoice, getMatterDisbursementsForInvoice } from 'actions/matter';
import { getColumnDefsIncidentalExpenseDetailForInvoice } from './columnDefsIncidentalExpenseDetailForInvoice';
import { getColumnDefsRecordableEventItemDetailForInvoice } from './columnDefsRecordableEventItemDetailForInvoice';
import { getColumnDefsDisbursementDetailForInvoice } from './columnDefsDisbursementDetailForInvoice';

type Props = {
  matterId: string,
  rendererParams: ICellRendererParams
}

export default function InvoiceDetailCellRenderer(props: Props) {
  const rowId = props.rendererParams.node.id!;
  const [rowDataRecordableItems, setRowDataRecordableItems] = useState<any[]>([]);
  const [rowDataIncidentalExpenses, setRowDataIncidentalExpenses] = useState<any[]>([]);
  const [rowDataDisbursements, setRowDataDisbursements] = useState<any[]>([]);
  
  useEffect(() => {
    return () => {
      // the detail grid is automatically destroyed as it is a React component
      props.rendererParams.api.removeDetailGridInfo(`${rowId}_recordableItems`);
      props.rendererParams.api.removeDetailGridInfo(`${rowId}_incidentalExpenses`);
      props.rendererParams.api.removeDetailGridInfo(`${rowId}_disbursements`);
    };
  }, []);

  const onRecordableItemsGridReady = (params: GridReadyEvent) => {
    const gridInfo: DetailGridInfo = {
      id: rowId,
      api: params.api,
      columnApi: params.columnApi,
    };

    props.rendererParams.api.addDetailGridInfo(`${rowId}_recordableItems`, gridInfo);

    getMatterRecordableItemsForInvoice(props.matterId, props.rendererParams.data.matterInvoiceId!).then((response) => {
      setRowDataRecordableItems(response.data);
    });
  };

  const onIncidentalExpensesGridReady = (params: GridReadyEvent) => {
    const gridInfo: DetailGridInfo = {
      id: rowId,
      api: params.api,
      columnApi: params.columnApi,
    };

    props.rendererParams.api.addDetailGridInfo(`${rowId}_incidentalExpenses`, gridInfo);

    getMatterIncidentalExpensesForInvoice(props.matterId, props.rendererParams.data.matterInvoiceId!).then((response) => {
      setRowDataIncidentalExpenses(response.data);
    });
  };

  const onDisbursementsGridReady = (params: GridReadyEvent) => {
    const gridInfo: DetailGridInfo = {
      id: rowId,
      api: params.api,
      columnApi: params.columnApi,
    };

    props.rendererParams.api.addDetailGridInfo(`${rowId}_disbursements`, gridInfo);

    getMatterDisbursementsForInvoice(props.matterId, props.rendererParams.data.matterInvoiceId!).then((response) => {
      setRowDataDisbursements(response.data);
    });
  };

  return (
    <div className="ag-details-row">
      <div className="lp-details-grid-title">Items</div>
      <AgGridReact
        data-id="detailGrid-recordableItems"
        className="ag-details-grid"
        columnDefs={getColumnDefsRecordableEventItemDetailForInvoice()}
        defaultColDef={DEFAULT_DETAIL_COLUMN_DEFINITIONS}
        rowData={rowDataRecordableItems}
        onGridReady={onRecordableItemsGridReady}
        suppressRowClickSelection={true}
        suppressContextMenu={true}
        enableCellTextSelection={true}
      />
      <div className="lp-details-grid-title mt-4">Incidental Expenses</div>
      <AgGridReact
        data-id="detailGrid-incidentalExpenses"
        className="ag-details-grid"
        columnDefs={getColumnDefsIncidentalExpenseDetailForInvoice()}
        defaultColDef={DEFAULT_DETAIL_COLUMN_DEFINITIONS}
        rowData={rowDataIncidentalExpenses}
        onGridReady={onIncidentalExpensesGridReady}
        suppressRowClickSelection={true}
        suppressContextMenu={true}
        enableCellTextSelection={true}
      />
      <div className="lp-details-grid-title mt-4">Disbursements</div>
      <AgGridReact
        data-id="detailGrid-disbursements"
        className="ag-details-grid"
        columnDefs={getColumnDefsDisbursementDetailForInvoice()}
        defaultColDef={DEFAULT_DETAIL_COLUMN_DEFINITIONS}
        rowData={rowDataDisbursements}
        onGridReady={onDisbursementsGridReady}
        suppressRowClickSelection={true}
        suppressContextMenu={true}
        enableCellTextSelection={true}
      />
    </div>
  );
};
