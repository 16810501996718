import { useEffect, useState } from "react";
import Title from "components/Title";
import { deleteEntityEmail, getEntityEmails } from "actions/entity";
import { Button } from 'react-bootstrap';
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { MdAdd } from "react-icons/md";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState } from "state/modalSlice";
import { EntityEmailModel } from "models/view/EntityEmailModel";
import CreateEntityEmailForm from "./CreateEntityEmail/CreateEntityEmailForm";
import EditEntityEmailForm from "./EditEntityEmail/EditEntityEmailForm";
import ListContact from "components/List/ListContact";
import EditEntityEmailOnlyIsPreferredForm from "./EditEntityEmailOnlyIsPreferred/EditEntityEmailOnlyIsPreferredForm";

type Props = {
  entityId?: string,
  edit: boolean
}

export default function EntityEmailList(props: Props) {
  const [entityEmails, setEntityEmails] = useState<any[]>();
  const [isLoadingEntityEmails, setIsLoadingEntityEmails] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();

  const getAllEntityEmails = () => {
    setIsLoadingEntityEmails(true);
    getEntityEmails(props.entityId!).then((response) => {
      const mappedEmails = response.data.map((e: EntityEmailModel) => 
        ({
          id: e.id,
          contact: e.email,
          type: e.emailType?.name,
          isPreferred: e.isPreferred,
          isLinked: e.sourceEntityEmailId != undefined
        })
      );
      setEntityEmails(mappedEmails);
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingEntityEmails(false);
    });
  }

  useEffect(() =>
  {
    if(props.entityId) {
      getAllEntityEmails();
    }
  }, [props.entityId]);

  const openCreateEntityEmailSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Create Email Address',
      children: <CreateEntityEmailForm entityId={props.entityId ?? ''} submitCallback={getAllEntityEmails} />
    });
  };

  const openEditEntityEmailSlidingPanel = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Edit Email Address',
      children: <EditEntityEmailForm
                  entityId={props.entityId ?? ''}
                  emailId={id}
                  submitCallback={getAllEntityEmails}
                />
    });
  };

  const openEditEntityEmailOnlyIsPreferredSlidingPanel = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Edit Email Address',
      children: <EditEntityEmailOnlyIsPreferredForm
                  entityId={props.entityId ?? ''}
                  emailId={id}
                  submitCallback={getAllEntityEmails}
                />
    });
  };

  const deleteCallbackEntityEmail = (id: string) => {
    modalActions.toggleModalLoading();
    deleteEntityEmail(id).then(() => {
      getAllEntityEmails();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      modalActions.toggleModalShownStatus();
    });
  };

  const deleteEntityEmailModal = (id: string) => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this email address?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallbackEntityEmail(id),
      show: true
    }
    modalActions.setModal(modal);
  };

  const unlinkCallbackEntityEmail = (id: string) => {
    modalActions.toggleModalLoading();
    deleteEntityEmail(id).then(() => {
      getAllEntityEmails();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      modalActions.toggleModalShownStatus();
    });
  };

  const unlinkEntityEmailModal = (id: string) => {
    let modal: ModalState = {
      title: "Unlink confirmation",
      body: "Are you sure you want to unlink this email address?",
      actionText: "Unlink",
      actionVariant: "danger",
      onAction: () => unlinkCallbackEntityEmail(id),
      show: true
    }
    modalActions.setModal(modal);
   };

  return (
    <>
      <Title type="section" title={"Email Addresses"}>
        <Button onClick={openCreateEntityEmailSlidingPanel} className="btn-icon" variant="success">
          <MdAdd />
        </Button>
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}
      
      <ListContact
        listItems={entityEmails ?? []}
        listButtons={
          [
            {
              type: ActionButtonTypes.Email
            },
            {
              type: ActionButtonTypes.Edit,
              callback: (id: string) => openEditEntityEmailSlidingPanel(id)
            },
            {
              type: ActionButtonTypes.EditOnlyIsPreferred,
              callback: (id: string) => openEditEntityEmailOnlyIsPreferredSlidingPanel(id)
            },
            {
              type: ActionButtonTypes.Delete,
              callback: (id: string) => deleteEntityEmailModal(id)
            },
            {
              type: ActionButtonTypes.Unlink,
              callback: (id: string) => unlinkEntityEmailModal(id)
            }
          ]
        }
        loadState={isLoadingEntityEmails}
      >
        <>
          <div>{"Email Address"}</div>
          <div>{"Type"}</div>
          <div></div>
        </>
      </ListContact>
    </>
  );
}
