import { validateLteTaxonomyTermDisplayName } from "actions/taxonomy";
import { create, test, enforce } from "vest";

export const getValidationSuite = (taxonomyId: string, taxonomyTermId: string, taxonomyIsHierarchical?: boolean) => {
  return create((data = {}) => {
    test('displayName', 'Display Name is required', () => {
      enforce(data.displayName).isNotEmpty();
      enforce(data.displayName).isString();
    });

    test.memo('displayName', 'Display Name must be unique', async () => {
      enforce((await validateLteTaxonomyTermDisplayName(taxonomyId, data.displayName, taxonomyTermId)).data).isTruthy();
    }, [data.displayName]);

    test('matterTypeIds', 'Please select at least a Matter Type', () => {
      enforce(data.matterTypeIds).isArray();
      enforce(data.matterTypeIds).isNotEmpty();
    });

    if(taxonomyIsHierarchical){
      test('sequence', 'Sequence is required', () => {
        enforce(data.sequence).isNotEmpty();
      });
    }
  });
}