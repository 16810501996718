import { useEffect, useState } from "react";
import { MenuPlacement } from "react-select";
import CustomSelect from "./Select";
import { getMatterFilesSummary, uploadFilesToSharepoint } from "actions/matter";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { MdAdd, MdOutlineClose, MdSave } from "react-icons/md";
import Upload from "components/Upload";
import Loader from "components/Loader";
import { MatterFileModel } from "models/view/MatterFileModel";
import "./style.scss";

type Props = {
  matterId: string,
  id: string,
  onChange?: (value: any) => any,
  onBlur?: (event: any) => any,
  onInputChange?: (keyword: any) => void,
  className?: string,
  classNamePrefix?: string,
  value?: string | string[],
  defaultValue?: string | string[],
  isMulti?: boolean,
  isClearable?: boolean,
  isDisabled?: boolean,
  closeMenuOnSelect?: boolean,
  menuPlacement?: MenuPlacement,
  placeholder?: string,
  inputRef?: any,
  styles?: any,
  menuPortalTarget?: any,
  canUploadNewFiles?: boolean
}

export default function MatterFileSelect(props: Props) {
  const [options, setOptions] = useState<any[] | undefined>([]);
  const [value, setValue] = useState<string | string[] | undefined>(props.value);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openUploadFiles, setOpenUploadFiles] = useState<boolean>(false);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const [uploadGenericErrors, setUploadGenericErrors] = useState(null);

  useEffect(() => {
    loadMatterFiles();
  }, []);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const loadMatterFiles = () => {
    setIsLoading(true);
    getMatterFilesSummary(props.matterId).then((response) => {
      setOptions(response.data);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const uploadFiles = () => {
    if(newFiles.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < newFiles.length; i++) {
        formData.append('files', newFiles[i]);
      }

      setIsUploadLoading(true);
      uploadFilesToSharepoint(props.matterId, formData)
        .then((response) => {
          const newFileIds = response.data.map((x: MatterFileModel) => x.id);
          if(Array.isArray(value)) {
            const newValues = [...value, ...newFileIds];
            setValue(newValues);
            if(props.onChange) {
              props.onChange(newValues);
            }
          }
          else {
            setValue(newFileIds);
            if(props.onChange) {
              props.onChange(newFileIds);
            }
          }

          setOpenUploadFiles(false);
          loadMatterFiles();
        })
        .catch((error) => {
          setUploadGenericErrors(error.response?.data?.Message ?? error.message);
        })
        .finally(() => {
          setIsUploadLoading(false);
        });
    }
    else {
      setOpenUploadFiles(false);
    }
  }

  const cancelUploadFiles = () => {
    setNewFiles([]);

    setOpenUploadFiles(false);
  }

  const onChange = (val: any) => {
    if(props.onChange) {
      if(props.isMulti) {
        props.onChange!(val?.map((x: { id: any; }) => x.id) ?? null);
      }
      else {
        props.onChange!(val?.id ?? null);
      }
    }
  }

  return (
    <>
      <div className="lp-matter-file-select">
        <CustomSelect
          id={props.id}
          value={value}
          inputRef={props.inputRef}
          options={options}
          getOptionLabel={(x: DropDownOptionModel) => x.name}
          getOptionValue={(x: DropDownOptionModel) => x.id}
          onChange={onChange}
          onBlur={props.onBlur}
          onInputChange={props.onInputChange}
          className={props.className ?? 'lp-select'}
          classNamePrefix={props.classNamePrefix ?? 'lp-select'}
          defaultValue={props.defaultValue}
          isDisabled={props.isDisabled || isLoading}
          isMulti={props.isMulti ?? false}
          isClearable={props.isClearable}
          closeMenuOnSelect={props.closeMenuOnSelect}
          isLoading={isLoading}
          menuPlacement={props.menuPlacement}
          menuPortalTarget={props.menuPortalTarget}
          styles={props.styles}
          placeholder={props.placeholder ?? ""}
        />
        {props.canUploadNewFiles && !openUploadFiles &&
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip id="tooltip-left">Add new files</Tooltip>
            }
          >
            <Button variant={"success"} onClick={() => setOpenUploadFiles(true)} className="btn-icon">
              <MdAdd />
            </Button>
          </OverlayTrigger>
        }
        {props.canUploadNewFiles && openUploadFiles &&
          <>
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="tooltip-left">Upload</Tooltip>
              }
            >
              <Button variant={"success"} onClick={uploadFiles} className="btn-icon">
                <MdSave />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="tooltip-left">Cancel</Tooltip>
              }
            >
              <Button variant={"danger"} onClick={cancelUploadFiles} className="btn-icon">
                <MdOutlineClose />
              </Button>
            </OverlayTrigger>
          </>
        }
      </div>

      {props.canUploadNewFiles && openUploadFiles &&
        <>
          {isUploadLoading && <Loader inlineLoader />}
          
          {uploadGenericErrors && <div className="lp-errors">{uploadGenericErrors}</div>}

          <Upload
            onChange={(val) => setNewFiles(val ?? [])}
            isMulti
          />
        </>
      }
    </>
  );
}
