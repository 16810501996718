import isEmail from "validator/lib/isEmail";
import { enforce, test, create, omitWhen, each } from "vest";

export const validationSuite = create((data = {}) => {
  enforce.extend({isEmail});

  test('subject', 'Subject is required', () => {
    enforce(data.subject).isNotEmpty();
    enforce(data.subject).isString();
  });

  test('toRecipients', 'At least one recipient is required', () => {
    enforce(data.toRecipients).isNotEmpty();
  });

  omitWhen(!data.toRecipients || data.toRecipients.length == 0, () => {
    each(data.toRecipients, (field: string, index: number) => {
      test(`toRecipients`, 'One of the Email Addresses is not valid.', () => {
        enforce(field).isEmail();
      });
    });
  });

  omitWhen(!data.ccRecipients || data.ccRecipients.length == 0, () => {
    each(data.ccRecipients, (field: string, index: number) => {
      test(`ccRecipients`, 'One of the Email Addresses is not valid.', () => {
        enforce(field).isEmail();
      });
    });
  });

  omitWhen(!data.bccRecipients || data.bccRecipients.length == 0, () => {
    each(data.bccRecipients, (field: string, index: number) => {
      test(`bccRecipients`, 'One of the Email Addresses is not valid.', () => {
        enforce(field).isEmail();
      });
    });
  });
});