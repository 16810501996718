import { configureStore } from '@reduxjs/toolkit'
import userReducer from './userSlice';
import gridReducer from './gridSlice';
import modalReducer from './modalSlice';
import slidingPanelReducer from './slidingPanelSlice';
import notificationReducer from './notificationSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    grid: gridReducer,
    modal: modalReducer,
    slidingPanel: slidingPanelReducer,
    notification: notificationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
