import { create, test, enforce, omitWhen } from "vest";
import isDate from "validator/lib/isDate";
import { ReturnMoneyReasonIds } from "enums/ReturnMoneyReasonIds";

export const validationSuite = create((data = {}) => {
  enforce.extend({ isDate });

  test('matterId', 'Please select a Matter', () => {
    enforce(data.matterId).isNotEmpty();
    enforce(data.matterId).isString();
  });

  test('matterParticipatingEntityId', 'Please select a Client', () => {
    enforce(data.matterParticipatingEntityId).isNotEmpty();
    enforce(data.matterParticipatingEntityId).isString();
  });

  test('date', 'Please select a Date', () => {
    enforce(data.date).isDate({ strictMode: false });
  });

  test('accountId', 'Please select an Account', () => {
    enforce(data.accountId).isNotEmpty();
    enforce(data.accountId).isString();
  });

  test('returnedById', 'Please select a Returned By', () => {
    enforce(data.returnedById).isNotEmpty();
    enforce(data.returnedById).isString();
  });

  test('returnedTo', 'Returned To is required', () => {
    enforce(data.returnedTo).isNotEmpty();
    enforce(data.returnedTo).isString();
  });

  test('amount', 'Amount is required', () => {
    enforce(data.amount).isNotEmpty();
  });

  test('amount', 'Amount needs to be a number > 0', () => {
    enforce(data.amount).greaterThan(0);
  });

  test('reasonId', 'Please select a Reason', () => {
    enforce(data.reasonId).isNotEmpty();
    enforce(data.reasonId).isString();
  });

  omitWhen(data.reasonId != ReturnMoneyReasonIds.ReturnForAnotherReasonId, () => {
    test('description', 'Description is required', () => {
      enforce(data.description).isNotEmpty();
      enforce(data.description).isString();
    });
  });
});