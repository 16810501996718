import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col, Button } from "react-bootstrap";
import { createMatterInvoiceDocument, createMatterInvoiceReceipt, deleteMatterInvoice, getMatterActivityHistory, getMatterInvoiceById, markMatterInvoiceAsDraft } from "actions/matter";
import moment from "moment";
import { DateFormat } from "utils/constants";
import { MatterInvoiceModel } from "models/view/MatterInvoiceModel";
import { formatCurrency } from "utils/misc";
import { MatterInvoiceStatusesIds } from "enums/MatterInvoiceStatusesIds";
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";
import CustomModal from "components/Modal/Modal";
import ViewMatterInvoicePayments from "../ViewMatterInvoicePayments/ViewMatterInvoicePayments";
import { useAppSelector } from "hooks/appSelector";
import EditMatterInvoiceForm from "../EditMatterInvoice/EditMatterInvoiceForm";
import store from "state/store";
import { GridIds } from "enums/GridIds";
import useGridActions from "actions/grid";
import PreviewMatterFile from "../../MatterFile/PreviewMatterFile/PreviewMatterFile";
import MatterInvoiceWriteOffForm from "../MatterInvoiceWriteOff/MatterInvoiceWriteOffForm";
import InvoiceStatusField from "components/Fields/InvoiceStatusField";
import BooleanField from "components/Fields/BooleanField";
import MatterInvoiceAbatementForm from "../MatterInvoiceAbatement/MatterInvoiceAbatementForm";

type Props = {
  matterId: string,
  matterInvoiceId: string,
  showActions?: boolean,
  onMarkAsDraftCallback?: Function,
  onDeleteCallback?: Function,
  onEditCallback?: Function,
  onWriteOffCallback?: Function,
  onAbatementCallback?: Function,
  onRecordPaymentCallback?: Function,
  showDocumentActions?: boolean
}

function ViewMatterInvoice(props: Props){
  const [isLoading, setIsLoading] = useState(true);
  const [matterInvoice, setMatterInvoice] = useState<MatterInvoiceModel>();
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const gridActions = useGridActions();

  useEffect(() => {
    loadMatterInvoice();
  }, []);

  const loadMatterInvoice = () => {
    setIsLoading(true);
    getMatterInvoiceById(props.matterId, props.matterInvoiceId).then((response) => {
      setMatterInvoice(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const onClickCreateDocument = (detailedDocument: boolean = false) => {
    setIsLoading(true);
    createMatterInvoiceDocument(props.matterId, props.matterInvoiceId, detailedDocument).then(() => {
      loadMatterInvoice();

      const gridState = store.getState().grid;
      if (gridState.id == `${GridIds.ActivityHistory}/${props.matterId}`) {
        getMatterActivityHistory(props.matterId).then((response2) => {
          gridActions.setGridRowData(response2.data);
        });
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });  
  }

  const onClickCreateReceipt = () => {
    setIsLoading(true);
    createMatterInvoiceReceipt(props.matterId, props.matterInvoiceId).then(() => {
      loadMatterInvoice();

      const gridState = store.getState().grid;
      if (gridState.id == `${GridIds.ActivityHistory}/${props.matterId}`) {
        getMatterActivityHistory(props.matterId).then((response2) => {
          gridActions.setGridRowData(response2.data);
        });
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });  
  }

  function onClickViewDocument(fileId: string) {
    //close View Matter Invoice sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open Preview Matter File sliding panel having onCancel event to reopen 
    //Preview Matter File sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Preview Matter File",
      children: <PreviewMatterFile matterId={props.matterId} fileId={fileId} />,
      width: "80rem",
      onCancel: onCancelOpenMatterInvoice
    });
  }

  function onClickMarkAsDraft() {
    setIsLoading(true);
    let modal: ModalState = {
      title: "Mark as Draft confirmation",
      body: "Are you sure you want to mark as Draft this Matter Invoice?",
      actionText: "Mark as Draft",
      onAction: () => markAsDraftCallback(props.matterId, props.matterInvoiceId),
      onClose: () => {setIsLoading(false);},
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();  
  }

  function markAsDraftCallback(matterId: string, matterInvoiceId: string) {
    markMatterInvoiceAsDraft(matterId, matterInvoiceId).then(() => {
      slidingPanelActions.clearSlidingPanel();
      modalActions.toggleModalShownStatus();

      props.onMarkAsDraftCallback && props.onMarkAsDraftCallback();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });     
  }

  function onClickDelete() {
    setIsLoading(true);
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Matter Invoice?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.matterInvoiceId),
      onClose: () => {setIsLoading(false);},
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();  
  }

  const deleteCallback = (matterId: string, matterInvoiceId: string) => {
    modalActions.toggleModalLoading();
    deleteMatterInvoice(matterId, matterInvoiceId).then(() => {
      slidingPanelActions.clearSlidingPanel();
      modalActions.toggleModalShownStatus(); 

      props.onDeleteCallback && props.onDeleteCallback();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      setIsLoading(false);
    });
  };

  function onClickRecordPayment() {
    //close View Matter Invoice sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open View Matter Invoice Payments sliding panel having onCancel event to reopen 
    //View Matter Invoice Payments sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      width: "55rem",
      title: "Invoice Payments",
      children: <ViewMatterInvoicePayments
          matterId={props.matterId}
          matterInvoiceId={props.matterInvoiceId}
        />,
      onCancel: onCancelMatterInvoicePaymnents
    });
  }

  function onCancelMatterInvoicePaymnents() {
    //open back View Matter Invoice sliding panel with onRecordPaymentCallback on onCancel event
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState,
      onCancel: props.onRecordPaymentCallback
    });
  }

  function onClickEdit() {
    //close View Matter Invoice sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open Edit Matter Invoice sliding panel having onCancel event to reopen 
    //Edit Matter Invoice sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      width: "70rem",
      title: "Edit Matter Invoice",
      children: <EditMatterInvoiceForm
        matterId={props.matterId}
        matterInvoiceId={props.matterInvoiceId}
        onSubmitCallback={onSubmitEditMatterInvoice}
      />,
      onCancel: onCancelOpenMatterInvoice
    });
  }

  function onSubmitEditMatterInvoice() {
    //open back View Matter Invoice sliding panel with onEditCallback on onCancel event
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState,
      onCancel: props.onEditCallback
    });
  }

  function onCancelOpenMatterInvoice() {
    //open back View Matter Invoice sliding panel
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState
    });
  }

  function onClickWriteOff() {
    //close View Matter Invoice sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open View Matter Invoice WriteOff sliding panel having onCancel event to reopen 
    //View Matter Invoice sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Invoice Write-Off",
      children: <MatterInvoiceWriteOffForm
          matterId={props.matterId}
          matterInvoiceId={props.matterInvoiceId}
          onSubmitCallback={onSubmitMatterInvoiceWriteOff}
          onDeleteCallback={onSubmitMatterInvoiceWriteOff}
        />,
      onCancel: onCancelMatterInvoiceWriteOff
    });
  }

  function onSubmitMatterInvoiceWriteOff() {
    //open back View Matter Invoice sliding panel with onWriteOffCallback on onCancel event
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState,
      onCancel: props.onWriteOffCallback
    });
  }

  function onCancelMatterInvoiceWriteOff() {
    //open back View Matter Invoice sliding panel
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState
    });
  }

  function onClickAbatement() {
    //close View Matter Invoice sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open View Matter Invoice Abatement sliding panel having onCancel event to reopen 
    //View Matter Invoice sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Invoice Abatement",
      children: <MatterInvoiceAbatementForm
          matterId={props.matterId}
          matterInvoiceId={props.matterInvoiceId}
          onSubmitCallback={onSubmitMatterInvoiceAbatement}
          onDeleteCallback={onSubmitMatterInvoiceAbatement}
        />,
      onCancel: onCancelMatterInvoiceAbatement
    });
  }

  function onSubmitMatterInvoiceAbatement() {
    //open back View Matter Invoice sliding panel with onAbatementCallback on onCancel event
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState,
      onCancel: props.onAbatementCallback
    });
  }

  function onCancelMatterInvoiceAbatement() {
    //open back View Matter Invoice sliding panel
    slidingPanelActions.setSlidingPanel(
    { 
      ...currentSlidingPanelState
    });
  }

  return(
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Clients"}
            value={matterInvoice?.matterParticipatingEntities?.map(x => x.name)?.join(", ") ?? "—"}
          />
        </Col>
        <Col>
          <Field
            label={"Invoicing Party"}
            value={matterInvoice?.invoicingParty?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Date"}
            value={matterInvoice?.date ? moment(matterInvoice?.date).format(DateFormat.Moment) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Invoice Ref Number"}
            value={matterInvoice?.invoiceRefNumber}
          />
        </Col>
        <Col>
          <Field
            label={"Display Name"}
            value={matterInvoice?.displayName}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Description"}
            value={matterInvoice?.description}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {matterInvoice?.matterInvoiceStatus?.name ? 
            <InvoiceStatusField
              label={"Status"}
              value={matterInvoice?.matterInvoiceStatus}
            />
            :
            <Field
              label={"Status"}
              value={"—"}
            />
          }
        </Col>
        <Col>
          <Field
            label={"VAT Rate"}
            value={matterInvoice?.vatRate?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Amount Paid"}
            value={formatCurrency(matterInvoice?.amountPaid)}
          />
        </Col>
        <Col>
          <Field
            label={"Amount Due"}
            value={formatCurrency(matterInvoice?.amountDue)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Due Date"}
            value={matterInvoice?.dueDate ? moment(matterInvoice?.dueDate).format(DateFormat.Moment) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <BooleanField
            label={'Show On Matter Summary'}
            value={matterInvoice?.showOnMatterSummary ?? false}
          />
        </Col>
      </Row>

      {matterInvoice?.writeOffAmount &&
        <>
          <Row>
            <Col>
              <Field
                label={"Write-Off Date"}
                value={matterInvoice?.writeOffDate ? moment(matterInvoice?.writeOffDate).format(DateFormat.Moment) : "—"}
              />
            </Col>
            <Col>
              <Field
                label={"Write-Off Amount"}
                value={matterInvoice?.writeOffAmount ? formatCurrency(matterInvoice?.writeOffAmount) : "—"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                label={"Write-Off Description"}
                value={matterInvoice?.writeOffDescription}
              />
            </Col>
          </Row>
          {matterInvoice?.writeOffForSingleClient &&
            <Row>
              <Col>
                <Field
                  label={"Write-Off Client"}
                  value={matterInvoice?.writeOffMatterParticipatingEntity?.name ?? "—"}
                />
              </Col>
            </Row>
          }
        </>
      }

      <div className="lp-color-title primary full-width mt-4">Total Values</div>
      <Row>
        <Col className="mb-4">
          <Field
            label={"Gross Value"}
            value={formatCurrency(matterInvoice?.totalGrossValue)}
          />
        </Col>
        <Col className="mb-4">
          <Field
            label={"VAT Value"}
            value={formatCurrency(matterInvoice?.totalVATValue)}
          />
        </Col>
        <Col className="mb-4">
          <Field
            label={"Net Value"}
            value={formatCurrency(matterInvoice?.totalNetValue)}
          />
        </Col>
      </Row>

      <div className="lp-color-title primary full-width">Invoice Values</div>
      <Row>
        <Col className="mb-4">
          <Field
            label={"Gross Value"}
            value={formatCurrency(matterInvoice?.invoicedTotalGrossValue)}
          />
        </Col>
        <Col className="mb-4">
          <Field
            label={"VAT Value"}
            value={formatCurrency(matterInvoice?.invoicedTotalVATValue)}
          />
        </Col>
        <Col className="mb-4">
          <Field
            label={"Net Value"}
            value={formatCurrency(matterInvoice?.invoicedTotalNetValue)}
          />
        </Col>
      </Row>

      {props.showDocumentActions && !matterInvoice?.matter?.isClosed &&
        <>
          <Row>
            <Col className="d-flex flex-row-reverse justify-content-between">
              {matterInvoice?.detailedDocumentId ?
                <Button onClick={() => onClickViewDocument(matterInvoice?.detailedDocumentId!)} variant="secondary-400">
                  {matterInvoice?.matterInvoiceStatusId != MatterInvoiceStatusesIds.DraftId ? "View detailed document" : "Preview detailed document"}
                </Button>
                :
                <Button onClick={() => onClickCreateDocument(true)} variant="secondary-400">
                  Create detailed document
                </Button>
              }
              {matterInvoice?.summaryDocumentId ?
                <Button onClick={() => onClickViewDocument(matterInvoice?.summaryDocumentId!)} variant="secondary-400">
                  {matterInvoice?.matterInvoiceStatusId != MatterInvoiceStatusesIds.DraftId ? "View summary document" : "Preview summary document"}
                </Button>
                :
                <Button onClick={() => onClickCreateDocument(false)} variant="secondary-400">
                  Create summary document
                </Button>
              }
            </Col>
          </Row>
          {matterInvoice?.hasPayments &&
            <Row>
              <Col className="d-flex justify-content-between">
                {matterInvoice?.latestReceiptDocumentId && matterInvoice?.allowNewReceiptCreation == false ?
                  <Button onClick={() => onClickViewDocument(matterInvoice?.latestReceiptDocumentId!)} variant="secondary-400">
                    View Latest Receipt
                  </Button>
                  :
                  <Button onClick={() => onClickCreateReceipt()} variant="secondary-400">
                    Create Receipt
                  </Button>
                }
              </Col>
            </Row>
          }
        </>
      }

      {props.showActions && !matterInvoice?.matter?.isClosed &&
        <Row>
          <Col className="d-flex flex-row-reverse justify-content-between">
            <Button onClick={onClickDelete} variant="danger">
              Delete
            </Button>
            {matterInvoice?.matterInvoiceStatusId == MatterInvoiceStatusesIds.AwaitingPaymentId &&
              <Button onClick={onClickAbatement} variant="secondary-400">
                Abate
              </Button>
            }
            {matterInvoice?.matterInvoiceStatusId != MatterInvoiceStatusesIds.DraftId &&
              <Button onClick={onClickWriteOff} variant="secondary-400">
                Write-Off
              </Button>
            }
            {matterInvoice?.matterInvoiceStatusId != MatterInvoiceStatusesIds.DraftId &&
              <Button onClick={onClickRecordPayment} variant="primary">
                Payment
              </Button>
            }
            {matterInvoice?.matterInvoiceStatusId == MatterInvoiceStatusesIds.AwaitingPaymentId &&
              <Button onClick={onClickMarkAsDraft} variant="secondary-400">
                Mark as Draft
              </Button>
            }
            {matterInvoice?.matterInvoiceStatusId == MatterInvoiceStatusesIds.DraftId &&
              <Button onClick={onClickEdit} variant="secondary-400">
                Edit
              </Button>
            }
          </Col>
        </Row>
      }

      <CustomModal />
    </>
  );
}

export default ViewMatterInvoice;
