import { enforce, test, create, omitWhen } from "vest";

export const validationSuite = create((data = {}) => {
  test('templateId', 'Please select a Template', () => {
    enforce(data.templateId).isNotEmpty();
    enforce(data.templateId).isString();
  });

  test('fileName', 'File Name is required', () => {
    enforce(data.fileName).isNotEmpty();
    enforce(data.fileName).isString();
  });

  omitWhen(!data.useParticipatingEntity, () => {
    test('matterParticipatingEntityId', 'Please select a Participating Entity', () => {
      enforce(data.matterParticipatingEntityId).isNotEmpty();
      enforce(data.matterParticipatingEntityId).isString();
    });
  });

});