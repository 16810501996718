import CustomSelect from "components/Select/Select";
import { TabModel } from "models/view/TabModel";
import React, { useEffect, useState } from "react";
import { Tabs, TabList, Tab } from "react-tabs";
import { InputGroup } from "react-bootstrap";
import './style.scss';
import useWindowSize from "hooks/windowSize";

type Props = {
  tabList: TabModel[]
}

export default function CustomTabs(props: Props) {
  const [width, height] = useWindowSize();
  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        {width < 1200 &&
          <div className="lp-tabs-mobile">
            <InputGroup>
              <InputGroup.Text id="basic-addon1">Select tab</InputGroup.Text>
              <CustomSelect
                id="tabs"
                options={
                  props.tabList.map((x: TabModel, index: number) => {return {id: index, name: x.name};})
                }
                value={tabIndex}
                onChange={val => setTabIndex(val?.id)}
              />
            </InputGroup>
          </div>
        }
        <TabList>
          {props.tabList.map((x: TabModel, index: number) => <Tab key={index}>{x.name}</Tab>)}
        </TabList>
        {props.tabList.map((x: TabModel, index: number) => <React.Fragment key={index}>{x.panel}</React.Fragment>)}
      </Tabs>
    </>
  );
}
