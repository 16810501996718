import { useDispatch } from "react-redux";
import { ModalModel } from "models/ModalModel";
import { setModalData, toggleModal, toggleModalLoadingState, setModalErrors } from "../state/modalSlice";

const useModalActions = () => {
  const dispatch = useDispatch();

  const setModal = (modalData: ModalModel) => {
    dispatch(setModalData(modalData));
  };

  const toggleModalShownStatus = () => {
    dispatch(toggleModal());
  };

  const toggleModalLoading = () => {
    dispatch(toggleModalLoadingState());
  };

  const setErrorsForModal = (error: string) => {
    dispatch(setModalErrors(error));
  };

  return {
    setModal,
    toggleModalLoading,
    toggleModalShownStatus,
    setErrorsForModal
  };
}

export default useModalActions;
