import { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { DetailGridInfo, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { DEFAULT_DETAIL_COLUMN_DEFINITIONS } from 'components/Grid/DefaultDetailColumnDefinitions';
import { getMatterRecordableItems, getMatterIncidentalExpensesForRecordableEvent } from 'actions/matter';
import { getColumnDefsIncidentalExpenseDetail } from './columnDefsIncidentalExpenseDetail';
import { getColumnDefsRecordableEventItemDetail } from './columnDefsRecordableEventItemDetail';

type Props = {
  matterId: string,
  rendererParams: ICellRendererParams
}

export default function RecordableEventDetailCellRenderer(props: Props) {
  const rowId = props.rendererParams.node.id!;
  const [rowDataItems, setRowDataItems] = useState<any[]>([]);
  const [rowDataIncidentalExpenses, setRowDataIncidentalExpenses] = useState<any[]>([]);
  
  useEffect(() => {
    return () => {
      // the detail grid is automatically destroyed as it is a React component
      props.rendererParams.api.removeDetailGridInfo(`${rowId}_items`);
      props.rendererParams.api.removeDetailGridInfo(`${rowId}_incidentalExpenses`);
    };
  }, []);

  const onItemsGridReady = (params: GridReadyEvent) => {
    const gridInfo: DetailGridInfo = {
      id: rowId,
      api: params.api,
      columnApi: params.columnApi,
    };

    props.rendererParams.api.addDetailGridInfo(`${rowId}_items`, gridInfo);

    getMatterRecordableItems(props.matterId, props.rendererParams.data.matterRecordableEventId!).then((response) => {
      setRowDataItems(response.data);
    });
  };

  const onIncidentalExpensesGridReady = (params: GridReadyEvent) => {
    const gridInfo: DetailGridInfo = {
      id: rowId,
      api: params.api,
      columnApi: params.columnApi,
    };

    props.rendererParams.api.addDetailGridInfo(`${rowId}_incidentalExpenses`, gridInfo);

    getMatterIncidentalExpensesForRecordableEvent(props.matterId, props.rendererParams.data.matterRecordableEventId!).then((response) => {
      setRowDataIncidentalExpenses(response.data);
    });
  };

  return (
    <div className="ag-details-row">
      <div className="lp-details-grid-title">Items</div>
      <AgGridReact
        data-id="detailGrid-items"
        className="ag-details-grid"
        columnDefs={getColumnDefsRecordableEventItemDetail()}
        defaultColDef={DEFAULT_DETAIL_COLUMN_DEFINITIONS}
        rowData={rowDataItems}
        onGridReady={onItemsGridReady}
        suppressRowClickSelection={true}
        suppressContextMenu={true}
        enableCellTextSelection={true}
      />
      <div className="lp-details-grid-title mt-4">Incidental Expenses</div>
      <AgGridReact
        data-id="detailGrid-incidentalExpenses"
        className="ag-details-grid"
        columnDefs={getColumnDefsIncidentalExpenseDetail(props.rendererParams.data.matter?.isClosed ?? false)}
        defaultColDef={DEFAULT_DETAIL_COLUMN_DEFINITIONS}
        rowData={rowDataIncidentalExpenses}
        onGridReady={onIncidentalExpensesGridReady}
        suppressRowClickSelection={true}
        suppressContextMenu={true}
        enableCellTextSelection={true}
      />
    </div>
  );
};
