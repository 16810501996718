import { useCallback } from "react";
import useGridActions from 'actions/grid';
import Grid from "components/Grid";
import { columnDefs } from "./columnDefs";
import { MdAddCircle } from 'react-icons/md';
import Title from 'components/Title';
import SlidingPanel from 'components/SlidingPanel';
import LawPageTradingEntityForm from 'containers/LawPageTradingEntity/CreateLawPageTradingEntity/LawPageTradingEntityCreateForm';
import { Card, Button } from 'react-bootstrap';
import { getLte } from "actions/lte";
import CustomModal from "components/Modal/Modal";
import useSlidingPanelActions from "actions/slidingPanel";

function LawPageTradingEntityList() {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();

  const onGridReady = useCallback(() =>
  {
    gridActions.setGridColumnDefs([...columnDefs]);
    getLte().then((response) => 
    {
      gridActions.setGridRowData(response.data);
    })
  }, []);

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Add new LTE",
        children: <LawPageTradingEntityForm />
      });
  };

  return (
    <div className="lp-page-content">
      <Title
        type="page"
        title="Trading Entities"
      >
        <Button variant="success" onClick={toggleSlidingPanel}>
          <MdAddCircle />
          Add Trading Entity
        </Button>
      </Title>

      <SlidingPanel />

      <Card className="with-grid">
        <Card.Body>
          <Grid
            onGridReady={onGridReady}
          />
        </Card.Body>
      </Card>

      <CustomModal />
    </div>
  );
}

export default LawPageTradingEntityList;
