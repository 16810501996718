import "./style.scss";
import { Button } from "react-bootstrap";
import { MdOutlineContentCopy } from "react-icons/md";

type Props = {
  label: string,
  value?: any,
  className?: string,
  canBeCopied?: boolean
}

export default function Field(props: Props) {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.value);
  };

  return (
    <div className={`lp-label-value ${props.className ?? ''}`}>
      <div className="lp-label">
        {props.label}
      </div>
      <div className="lp-value">
        {props.value ?? "—"}
        {props.value && (props.canBeCopied ?? false) &&
          <Button variant={'secondary-400'} onClick={copyToClipboard} className="btn-icon">
            <MdOutlineContentCopy />
          </Button>
        }
      </div>
    </div> 
  );
}
