import Title from "components/Title/index";
import { LawPageTradingEntityModel } from "models/view/LawPageTradingEntityModel";
import { Dispatch, useState } from "react";
import { MdEdit } from "react-icons/md";
import { useParams } from "react-router-dom";
import ViewLawPageTradingEntitySummary from "./LawPageTradingEntitySummary/ViewLawPageTradingEntitySummary/ViewLawPageTradingEntitySummary";
import EditLawPageTradingEntitySummary from "./LawPageTradingEntitySummary/EditLawPageTradingEntitySummary/EditLawPageTradingEntitySummary";
import LteBankAccountList from "./LteBankAccounts/LteBankAccountList";
import SlidingPanel from "components/SlidingPanel/index";
import CustomModal from "components/Modal/Modal";
import { Row, Col, Button } from "react-bootstrap";
import ViewLawPageTradingEntityOrganisation from "./LawPageTradingEntityOrganisation/ViewLawPageTradingEntityOrganisation";
import LtePhoneNumberList from "./LtePhoneNumbers/LtePhoneNumberList";
import { useAppSelector } from "hooks/appSelector";
import LteAddressList from "./LteAddresses/LteAddressList";

type Props = {
  lte: LawPageTradingEntityModel | undefined,
  edit: boolean,
  setLte: Dispatch<React.SetStateAction<LawPageTradingEntityModel | undefined>>
}

export default function ViewLawPageTradingEntityDetails(props: Props) {
  const [edit, setEdit] = useState<boolean>(props.edit ?? false);
  const user = useAppSelector((state) => state.user);
  const {id: lteId} = useParams();

  return (
    <>
      <Row>
        <Col>
          <Title type="section" title={"Summary"}>
            {edit !== true &&
              <Button onClick={() => setEdit(true)} className="btn-icon" variant="primary">
                <MdEdit />
              </Button>
            }
          </Title>
          {props.lte &&
            <>
              {edit === true ? (
                <EditLawPageTradingEntitySummary
                  lte={props.lte}
                  setLte={props.setLte}
                  setEdit={setEdit}
                />
              ) : (
                <ViewLawPageTradingEntitySummary
                  lte={props.lte}
                />
              )}
            </>
          }
        </Col>
      </Row>
      <Row>
        <Col>
          <LteBankAccountList
            lteId={lteId ?? user.lawPageTradingEntityId!}
          />
        </Col>
      </Row>
      <Row> 
        <ViewLawPageTradingEntityOrganisation
          lteId={lteId ?? user.lawPageTradingEntityId!}
          edit={props.lte?.isActive}
        />
      </Row>
      <Row>
        <Col xxl={6}>
          <LtePhoneNumberList
            lteId={lteId ?? user.lawPageTradingEntityId!}
            edit={props.lte?.isActive}
          />
        </Col>
        <Col xxl={6} className="mt-4 mt-xxl-0">
          <LteAddressList
            lteId={lteId ?? user.lawPageTradingEntityId!}
            edit={props.lte?.isActive}
          />
        </Col>
      </Row>

      <SlidingPanel />
      
      <CustomModal />
    </>
  );
}
