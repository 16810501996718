import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import useGridActions from "actions/grid";
import { useAppSelector } from "hooks/appSelector";
import { Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader/index";
import {validationSuite} from "./validationSuite";
import { addFeeEarnerLevel } from "actions/settings";
import { CreateOrUpdateFeeEarnerLevelModel } from "models/create/CreateOrUpdateFeeEarnerLevelModel";

export default function CreateFeeEarnerLevelForm() {
  const [genericErrors, setGenericErrors] = useState(null);
  const gridActions = useGridActions();
  const grid = useAppSelector((state) => state.grid);
  const slidingPanelActions = useSlidingPanelActions();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function submitData(data: CreateOrUpdateFeeEarnerLevelModel) {
    setIsLoading(true);
    addFeeEarnerLevel(data).then((response) => {
      gridActions.setGridRowData(grid.rowData.concat(response.data));
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const {register, reset, control, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateFeeEarnerLevelModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-4" controlId="code">
          <Form.Label className="required">Code</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.code?.message ? 'invalid' : ''}`}
            {...register("code", {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.code?.message && (errors.code.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="name">
          <Form.Label className="required">Name</Form.Label>
          <Form.Control
            type="text"
            className={`${errors?.name?.message ? 'invalid' : ''}`}
            {...register("name", {shouldUnregister: true})}
          />
          <Form.Text className="lp-error">
            {errors?.name?.message && (errors.name.message)}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            {...register("description", {shouldUnregister: true})}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="active">
          <Form.Label>Active</Form.Label>
          <Controller
            control={control}
            name="active"
            shouldUnregister={true}
            render={({field: { onChange, value, name, ref }}) => (
              <Form.Check 
                type="switch"
                id="active">
                  <Form.Check.Input
                    className= "form-check-input"
                    ref={ref}
                    checked={value ?? false}
                    onChange={(ev: any) => onChange(ev.target.checked)}
                  />
              </Form.Check>
            )}
          />
        </Form.Group>

        <Form.Group className="d-flex justify-content-between">
          <Button variant="success" type="submit">Create</Button>
          <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
        </Form.Group>
      </Form>
    </>
  );
}
