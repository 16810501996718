import { CreateOrUpdateAccountModel } from "models/create/CreateOrUpdateAccountModel";
import { CreateOrUpdateAccountTypeCategoryModel } from "models/create/CreateOrUpdateAccountTypeCategoryModel";
import { CreateOrUpdateAccountTypeModel } from "models/create/CreateOrUpdateAccountTypeModel";
import { CreateOrUpdateActivityModel } from "models/create/CreateOrUpdateActivityModel";
import { CreateOrUpdateDefaultAccountModel } from "models/create/CreateOrUpdateDefaultAccountModel";
import { CreateOrUpdateDefaultAccountTypeCategoryModel } from "models/create/CreateOrUpdateDefaultAccountTypeCategoryModel";
import { CreateOrUpdateDefaultAccountTypeModel } from "models/create/CreateOrUpdateDefaultAccountTypeModel";
import { CreateOrUpdateDefaultActivityModel } from "models/create/CreateOrUpdateDefaultActivityModel";
import { CreateOrUpdateDefaultEntityRoleModel } from "models/create/CreateOrUpdateDefaultEntityRoleModel";
import { CreateOrUpdateDefaultFeeEarnerLevelModel } from "models/create/CreateOrUpdateDefaultFeeEarnerLevelModel";
import { CreateOrUpdateEntityRoleModel } from "models/create/CreateOrUpdateEntityRoleModel";
import { CreateOrUpdateFeeEarnerLevelModel } from "models/create/CreateOrUpdateFeeEarnerLevelModel";
import { CreateOrUpdateTemplateTypeModel } from "models/create/CreateOrUpdateTemplateTypeModel";
import { UpdateTemplateTaxonomyTermsModel } from "models/update/UpdateTemplateTaxonomyTermsModel";
import { AccountModel } from "models/view/AccountModel";
import { AccountTypeCategoryModel } from "models/view/AccountTypeCategoryModel";
import { AccountTypeModel } from "models/view/AccountTypeModel";
import { ActivityModel } from "models/view/ActivityModel";
import { DefaultAccountModel } from "models/view/DefaultAccountModel";
import { DefaultAccountTypeCategoryModel } from "models/view/DefaultAccountTypeCategoryModel";
import { DefaultAccountTypeModel } from "models/view/DefaultAccountTypeModel";
import { DefaultActivityModel } from "models/view/DefaultActivityModel";
import { DefaultEntityRoleModel } from "models/view/DefaultEntityRoleModel";
import { DefaultFeeEarnerLevelModel } from "models/view/DefaultFeeEarnerLevelModel";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { EntityRoleModel } from "models/view/EntityRoleModel";
import { FeeEarnerLevelModel } from "models/view/FeeEarnerLevelModel";
import { TaxonomyTermModel } from "models/view/TaxonomyTermModel";
import { TemplateModel } from "models/view/TemplateModel";
import { TemplateResourceInformationModel } from "models/view/TemplateResourceInformationModel";
import { TemplateTypeModel } from "models/view/TemplateTypeModel";
import { ValidateFieldModel } from "models/view/ValidateFieldModel";
import { VatRateModel } from "models/view/VatRateModel";
import { del, get, post, put, setHeader } from "utils/request";

const getDefaultFeeEarnerLevels = async (): Promise<any> => {
  const response = await get<DefaultFeeEarnerLevelModel[]>('/management/defaultFeeEarnerLevel');
  return response;
}

const getDefaultFeeEarnerLevelsSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/defaultFeeEarnerLevel/summary');
  return response;
}

const getDefaultFeeEarnerLevelById = async (defaultFeeEarnerLevelId: string): Promise<any> => {
  const response = await get<DefaultFeeEarnerLevelModel>(`/management/defaultFeeEarnerLevel/${defaultFeeEarnerLevelId}`);
  return response;
}

const addDefaultFeeEarnerLevel = async (model: CreateOrUpdateDefaultFeeEarnerLevelModel): Promise<any> => {
  const response = await post<CreateOrUpdateDefaultFeeEarnerLevelModel, DefaultFeeEarnerLevelModel>('/management/defaultFeeEarnerLevel', model);
  return response;
}

const validateDefaultFeeEarnerLevelCode = async (code: string, defaultFeeEarnerLevelId?: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/defaultFeeEarnerLevel/code/validate`, {
    "value": code,
    "currentId": defaultFeeEarnerLevelId
  });
  return response;
}

const updateDefaultFeeEarnerLevel = async (defaultFeeEarnerLevelId: string, model: CreateOrUpdateDefaultFeeEarnerLevelModel): Promise<any> => {
  var response = await put<CreateOrUpdateDefaultFeeEarnerLevelModel, DefaultFeeEarnerLevelModel>(`/management/defaultFeeEarnerLevel/${defaultFeeEarnerLevelId}`, model);
  return response;
}

const changeDefaultFeeEarnerLevelStatus = async (defaultFeeEarnerLevelId: string): Promise<any> => {
  const response = await post(`/management/defaultFeeEarnerLevel/${defaultFeeEarnerLevelId}/changeStatus`, undefined);
  return response;
}

const deleteDefaultFeeEarnerLevel = async (defaultFeeEarnerLevelId: string): Promise<any> => {
  await del(`/management/defaultFeeEarnerLevel/${defaultFeeEarnerLevelId}`);
}

const getDefaultEntityRoles = async (): Promise<any> => {
  const response = await get<DefaultEntityRoleModel[]>('/management/defaultEntityRole');
  return response;
}

const getDefaultEntityRolesSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/defaultEntityRole/summary');
  return response;
}

const getDefaultEntityRoleById = async (defaultEntityRoleId: string): Promise<any> => {
  const response = await get<DefaultEntityRoleModel>(`/management/defaultEntityRole/${defaultEntityRoleId}`);
  return response;
}

const addDefaultEntityRole = async (model: CreateOrUpdateDefaultEntityRoleModel): Promise<any> => {
  const response = await post<CreateOrUpdateDefaultEntityRoleModel, DefaultEntityRoleModel>('/management/defaultEntityRole', model);
  return response;
}

const validateDefaultEntityRoleName = async (name: string, defaultEntityRoleId?: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/defaultEntityRole/name/validate`, {
    "value": name,
    "currentId": defaultEntityRoleId
  });
  return response;
}

const updateDefaultEntityRole = async (defaultEntityRoleId: string, model: CreateOrUpdateDefaultEntityRoleModel): Promise<any> => {
  var response = await put<CreateOrUpdateDefaultEntityRoleModel, DefaultEntityRoleModel>(`/management/defaultEntityRole/${defaultEntityRoleId}`, model);
  return response;
}

const changeDefaultEntityRoleStatus = async (defaultEntityRoleId: string): Promise<any> => {
  const response = await post(`/management/defaultEntityRole/${defaultEntityRoleId}/changeStatus`, undefined);
  return response;
}

const deleteDefaultEntityRole = async (defaultEntityRoleId: string): Promise<any> => {
  await del(`/management/defaultEntityRole/${defaultEntityRoleId}`);
}

const getDefaultActivities = async (): Promise<any> => {
  const response = await get<DefaultActivityModel[]>('/management/defaultActivity');
  return response;
}

const getDefaultActivitiesSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/defaultActivity/summary');
  return response;
}

const getDefaultActivityById = async (defaultActivityId: string): Promise<any> => {
  const response = await get<DefaultActivityModel>(`/management/defaultActivity/${defaultActivityId}`);
  return response;
}

const addDefaultActivity = async (model: CreateOrUpdateDefaultActivityModel): Promise<any> => {
  const response = await post<CreateOrUpdateDefaultActivityModel, DefaultActivityModel>('/management/defaultActivity', model);
  return response;
}

const validateDefaultActivityCode = async (code: string, defaultActivityId?: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/defaultActivity/code/validate`, {
    "value": code,
    "currentId": defaultActivityId
  });
  return response;
}

const updateDefaultActivity = async (defaultActivityId: string, model: CreateOrUpdateDefaultActivityModel): Promise<any> => {
  var response = await put<CreateOrUpdateDefaultActivityModel, DefaultActivityModel>(`/management/defaultActivity/${defaultActivityId}`, model);
  return response;
}

const changeDefaultActivityStatus = async (defaultActivityId: string): Promise<any> => {
  const response = await post(`/management/defaultActivity/${defaultActivityId}/changeStatus`, undefined);
  return response;
}

const deleteDefaultActivity = async (defaultActivityId: string): Promise<any> => {
  await del(`/management/defaultActivity/${defaultActivityId}`);
}

const getFeeEarnerLevels = async (): Promise<any> => {
  const response = await get<FeeEarnerLevelModel[]>('/management/feeEarnerLevel');
  return response;
}

const getFeeEarnerLevelsForLte = async (lteId: string): Promise<any> => {
  const response = await get<FeeEarnerLevelModel[]>(`/management/lte/${lteId}/feeEarnerLevel`);
  return response;
}

const getFeeEarnerLevelsSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/feeEarnerLevel/summary');
  return response;
}

const getFeeEarnerLevelsSummaryForLte = async (lteId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/lte/${lteId}/feeEarnerLevel/summary`);
  return response;
}

const getFeeEarnerLevelById = async (feeEarnerLevelId: string): Promise<any> => {
  const response = await get<FeeEarnerLevelModel>(`/management/feeEarnerLevel/${feeEarnerLevelId}`);
  return response;
}

const addFeeEarnerLevel = async (model: CreateOrUpdateFeeEarnerLevelModel): Promise<any> => {
  const response = await post<CreateOrUpdateFeeEarnerLevelModel, FeeEarnerLevelModel>('/management/feeEarnerLevel', model);
  return response;
}

const validateFeeEarnerLevelCode = async (code: string, feeEarnerLevelId?: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/feeEarnerLevel/code/validate`, {
    "value": code,
    "currentId": feeEarnerLevelId
  });
  return response;
}

const updateFeeEarnerLevel = async (feeEarnerLevelId: string, model: CreateOrUpdateFeeEarnerLevelModel): Promise<any> => {
  var response = await put<CreateOrUpdateFeeEarnerLevelModel, FeeEarnerLevelModel>(`/management/feeEarnerLevel/${feeEarnerLevelId}`, model);
  return response;
}

const changeFeeEarnerLevelStatus = async (feeEarnerLevelId: string): Promise<any> => {
  const response = await post(`/management/feeEarnerLevel/${feeEarnerLevelId}/changeStatus`, undefined);
  return response;
}

const deleteFeeEarnerLevel = async (feeEarnerLevelId: string): Promise<any> => {
  await del(`/management/feeEarnerLevel/${feeEarnerLevelId}`);
}

const getEntityRoles = async (): Promise<any> => {
  const response = await get<EntityRoleModel[]>('/management/entityRole');
  return response;
}

const getEntityRolesForLte = async (lteId: string): Promise<any> => {
  const response = await get<EntityRoleModel[]>(`/management/lte/${lteId}/entityRole`);
  return response;
}

const getEntityRolesSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/entityRole/summary');
  return response;
}

const getEntityRolesSummaryForLte = async (lteId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/lte/${lteId}/entityRole/summary`);
  return response;
}

const getEntityRoleById = async (entityRoleId: string): Promise<any> => {
  const response = await get<EntityRoleModel>(`/management/entityRole/${entityRoleId}`);
  return response;
}

const addEntityRole = async (model: CreateOrUpdateEntityRoleModel): Promise<any> => {
  const response = await post<CreateOrUpdateEntityRoleModel, EntityRoleModel>('/management/entityRole', model);
  return response;
}

const validateEntityRoleName = async (name: string, entityRoleId?: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/entityRole/name/validate`, {
    "value": name,
    "currentId": entityRoleId
  });
  return response;
}

const updateEntityRole = async (entityRoleId: string, model: CreateOrUpdateEntityRoleModel): Promise<any> => {
  var response = await put<CreateOrUpdateEntityRoleModel, EntityRoleModel>(`/management/entityRole/${entityRoleId}`, model);
  return response;
}

const changeEntityRoleStatus = async (entityRoleId: string): Promise<any> => {
  const response = await post(`/management/entityRole/${entityRoleId}/changeStatus`, undefined);
  return response;
}

const deleteEntityRole = async (entityRoleId: string): Promise<any> => {
  await del(`/management/entityRole/${entityRoleId}`);
}

const getActivities = async (): Promise<any> => {
  const response = await get<ActivityModel[]>('/management/activity');
  return response;
}

const getActivitiesForLte = async (lteId: string): Promise<any> => {
  const response = await get<ActivityModel[]>(`/management/lte/${lteId}/activity`);
  return response;
}

const getActivitiesSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/activity/summary');
  return response;
}

const getActivitiesSummaryForLte = async (lteId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/lte/${lteId}/activity/summary`);
  return response;
}

const getActivityById = async (activityId: string): Promise<any> => {
  const response = await get<ActivityModel>(`/management/activity/${activityId}`);
  return response;
}

const addActivity = async (model: CreateOrUpdateActivityModel): Promise<any> => {
  const response = await post<CreateOrUpdateActivityModel, ActivityModel>('/management/activity', model);
  return response;
}

const validateActivityCode = async (code: string, activityId?: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/activity/code/validate`, {
    "value": code,
    "currentId": activityId
  });
  return response;
}

const updateActivity = async (activityId: string, model: CreateOrUpdateActivityModel): Promise<any> => {
  var response = await put<CreateOrUpdateActivityModel, ActivityModel>(`/management/activity/${activityId}`, model);
  return response;
}

const changeActivityStatus = async (activityId: string): Promise<any> => {
  const response = await post(`/management/activity/${activityId}/changeStatus`, undefined);
  return response;
}

const deleteActivity = async (activityId: string): Promise<any> => {
  await del(`/management/activity/${activityId}`);
}

const getActivityTypes = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/activityType/summary');
  return response;
}

const getDefaultAccountTypeCategories = async (): Promise<any> => {
  const response = await get<DefaultAccountTypeCategoryModel[]>('/management/defaultAccountTypeCategory');
  return response;
}

const getDefaultAccountTypeCategoriesSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/defaultAccountTypeCategory/summary');
  return response;
}

const getDefaultAccountTypeCategoryById = async (defaultAccountTypeCategoryId: string): Promise<any> => {
  const response = await get<DefaultAccountTypeCategoryModel>(`/management/defaultAccountTypeCategory/${defaultAccountTypeCategoryId}`);
  return response;
}

const updateDefaultAccountTypeCategory = async (defaultAccountTypeCategoryId: string, model: CreateOrUpdateDefaultAccountTypeCategoryModel): Promise<any> => {
  var response = await put<CreateOrUpdateDefaultAccountTypeCategoryModel, DefaultAccountTypeCategoryModel>(`/management/defaultAccountTypeCategory/${defaultAccountTypeCategoryId}`, model);
  return response;
}

const getDefaultAccountTypes = async (): Promise<any> => {
  const response = await get<DefaultAccountTypeModel[]>('/management/defaultAccountType');
  return response;
}

const getDefaultAccountTypesSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/defaultAccountType/summary');
  return response;
}

const getDefaultAccountTypeById = async (defaultAccountTypeId: string): Promise<any> => {
  const response = await get<DefaultAccountTypeModel>(`/management/defaultAccountType/${defaultAccountTypeId}`);
  return response;
}

const updateDefaultAccountType = async (defaultAccountTypeId: string, model: CreateOrUpdateDefaultAccountTypeModel): Promise<any> => {
  var response = await put<CreateOrUpdateDefaultAccountTypeModel, DefaultAccountTypeModel>(`/management/defaultAccountType/${defaultAccountTypeId}`, model);
  return response;
}

const getDefaultAccounts = async (): Promise<any> => {
  const response = await get<DefaultAccountModel[]>('/management/defaultAccount');
  return response;
}

const getDefaultAccountsSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/defaultAccount/summary');
  return response;
}

const getDefaultAccountById = async (defaultAccountId: string): Promise<any> => {
  const response = await get<DefaultAccountModel>(`/management/defaultAccount/${defaultAccountId}`);
  return response;
}

const updateDefaultAccount = async (defaultAccountId: string, model: CreateOrUpdateDefaultAccountModel): Promise<any> => {
  var response = await put<CreateOrUpdateDefaultAccountModel, DefaultAccountModel>(`/management/defaultAccount/${defaultAccountId}`, model);
  return response;
}

const getAccountTypeCategories = async (): Promise<any> => {
  const response = await get<AccountTypeCategoryModel[]>('/management/accountTypeCategory');
  return response;
}

const getAccountTypeCategoriesForLte = async (lteId: string): Promise<any> => {
  const response = await get<AccountTypeCategoryModel[]>(`/management/lte/${lteId}/accountTypeCategory`);
  return response;
}

const getAccountTypeCategoriesSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/accountTypeCategory/summary');
  return response;
}

const getAccountTypeCategoriesSummaryForLte = async (lteId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/lte/${lteId}/accountTypeCategory/summary`);
  return response;
}

const getAccountTypeCategoryById = async (accountTypeCategoryId: string): Promise<any> => {
  const response = await get<AccountTypeCategoryModel>(`/management/accountTypeCategory/${accountTypeCategoryId}`);
  return response;
}

const updateAccountTypeCategory = async (accountTypeCategoryId: string, model: CreateOrUpdateAccountTypeCategoryModel): Promise<any> => {
  var response = await put<CreateOrUpdateAccountTypeCategoryModel, AccountTypeCategoryModel>(`/management/accountTypeCategory/${accountTypeCategoryId}`, model);
  return response;
}

const getAccountTypes = async (): Promise<any> => {
  const response = await get<AccountTypeModel[]>('/management/accountType');
  return response;
}

const getAccountTypesForLte = async (lteId: string): Promise<any> => {
  const response = await get<AccountTypeModel[]>(`/management/lte/${lteId}/accountType`);
  return response;
}

const getAccountTypesSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/accountType/summary');
  return response;
}

const getAccountTypesSummaryForLte = async (lteId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/lte/${lteId}/accountType/summary`);
  return response;
}

const getAccountTypeById = async (accountTypeId: string): Promise<any> => {
  const response = await get<AccountTypeModel>(`/management/accountType/${accountTypeId}`);
  return response;
}

const updateAccountType = async (accountTypeId: string, model: CreateOrUpdateAccountTypeModel): Promise<any> => {
  var response = await put<CreateOrUpdateAccountTypeModel, AccountTypeModel>(`/management/accountType/${accountTypeId}`, model);
  return response;
}

const getAccounts = async (): Promise<any> => {
  const response = await get<AccountModel[]>('/management/account');
  return response;
}

const getAccountsForLte = async (lteId: string): Promise<any> => {
  const response = await get<AccountModel[]>(`/management/lte/${lteId}/account`);
  return response;
}

const getAccountsSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/account/summary');
  return response;
}

const getAccountsSummaryForLte = async (lteId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/lte/${lteId}/account/summary`);
  return response;
}

const getAccountById = async (accountId: string): Promise<any> => {
  const response = await get<AccountModel>(`/management/account/${accountId}`);
  return response;
}

const updateAccount = async (accountId: string, model: CreateOrUpdateAccountModel): Promise<any> => {
  var response = await put<CreateOrUpdateAccountModel, AccountTypeModel>(`/management/account/${accountId}`, model);
  return response;
}

const getVATRatesForPurchases = async (date?: Date): Promise<any> => {
  let url = '/management/vatRate/purchase';
  if(date){
    url += `?date=${date}`;
  }
  const response = await get<VatRateModel[]>(url);
  return response;
}

const getVATRatesForSales = async (date?: Date): Promise<any> => {
  let url = '/management/vatRate/sale';
  if(date){
    url += `?date=${encodeURIComponent(date.toISOString().split('T')[0])}`;
  }
  const response = await get<VatRateModel[]>(url);
  return response;
}

const getLTETemplates = async (): Promise<any> => {
  const response = await get<TemplateModel[]>(`/management/template/lteTemplates`);
  return response;
}

const getTemplateFieldsInformation = async (): Promise<any> => {
  const response = await get<TemplateResourceInformationModel[]>(`/management/template/fieldsInfo`);
  return response;
}

const getMatterTemplates = async (): Promise<any> => {
  const response = await get<TemplateModel[]>(`/management/template/matterTemplates`);
  return response;
}

const getTemplate = async (templateId: string): Promise<any> => {
  const response = await get<TemplateModel[]>(`/management/template/${templateId}`);
  return response;
}

const getHeaderAndFooterTemplate = async (): Promise<any> => {
  const response = await get<TemplateModel>(`/management/template/headerAndFooterTemplate`);
  return response;
}

const getAllTemplateTypesSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/templateType/summary`);
  return response;
}

const getLTEHeaderFooterTypesSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/templateType/summary?includeOnOther=false`);
  return response;
}

const getMatterTemplateTypesSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/templateType/summary?includeOnOther=true`);
  return response;
}

const getMatterTemplateTypes = async (): Promise<any> => {
  const response = await get<TemplateTypeModel[]>(`/management/templateType?includeOnOther=true`);
  return response;
}

const getTemplateTypeById = async (templateTypeId: string): Promise<any> => {
  const response = await get<TemplateTypeModel>(`/management/templateType/${templateTypeId}`);
  return response;
}

const addTemplateType = async (model: CreateOrUpdateTemplateTypeModel): Promise<any> => {
  const response = await post<CreateOrUpdateTemplateTypeModel, TemplateTypeModel>('/management/templateType', model);
  return response;
}

const validateTemplateTypeName = async (name: string, templateTypeId?: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/templateType/name/validate`, {
    "value": name,
    "currentId": templateTypeId
  });
  return response;
}

const updateTemplateType = async (templateTypeId: string, model: CreateOrUpdateTemplateTypeModel): Promise<any> => {
  var response = await put<CreateOrUpdateTemplateTypeModel, TemplateTypeModel>(`/management/templateType/${templateTypeId}`, model);
  return response;
}

const changeTemplateTypeStatus = async (templateTypeId: string): Promise<any> => {
  const response = await post(`/management/templateType/${templateTypeId}/changeStatus`, undefined);
  return response;
}

const deleteTemplateType = async (templateTypeId: string): Promise<any> => {
  await del(`/management/templateType/${templateTypeId}`);
}

const addTemplate = async (formData: FormData): Promise<any> => {
  const response = await post<FormData, TemplateModel>('/management/template', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
}

const updateTemplate = async (templateId: string, formData: FormData): Promise<any> => {
  setHeader('Content-Type', 'multipart/form-data');
  const response = await put<FormData, TemplateModel>(`/management/template/${templateId}`, formData);
  return response;
}

const downloadTemplate = async (templateId: string): Promise<any> => {
  const response = await get<any>(`/management/template/${templateId}/download`, {responseType: 'blob'});
  return response;
}

const deleteTemplate = async (templateId: string): Promise<any> => {
  await del(`/management/template/${templateId}`);
}

const getTemplateTaxonomyTerms = async (templateId: string): Promise<any> => {
  const response = await get<TaxonomyTermModel[]>(`/management/template/${templateId}/taxonomyTerm`);
  return response;
}

const updateTemplateTaxonomyTerms = async (templateId: string, model: UpdateTemplateTaxonomyTermsModel): Promise<any> => {
  const response = await put<UpdateTemplateTaxonomyTermsModel, TemplateModel>(`/management/template/${templateId}/taxonomyTerm`, model);
  return response;
}

export { 
  getDefaultFeeEarnerLevels,
  getDefaultFeeEarnerLevelsSummary,
  getDefaultFeeEarnerLevelById,
  addDefaultFeeEarnerLevel,
  validateDefaultFeeEarnerLevelCode,
  updateDefaultFeeEarnerLevel,
  changeDefaultFeeEarnerLevelStatus,
  deleteDefaultFeeEarnerLevel,

  getDefaultEntityRoles,
  getDefaultEntityRolesSummary,
  getDefaultEntityRoleById,
  addDefaultEntityRole,
  validateDefaultEntityRoleName,
  updateDefaultEntityRole,
  changeDefaultEntityRoleStatus,
  deleteDefaultEntityRole,

  getDefaultActivities,
  getDefaultActivitiesSummary,
  getDefaultActivityById,
  addDefaultActivity,
  validateDefaultActivityCode,
  updateDefaultActivity,
  changeDefaultActivityStatus,
  deleteDefaultActivity,

  getFeeEarnerLevels,
  getFeeEarnerLevelsForLte,
  getFeeEarnerLevelsSummary,
  getFeeEarnerLevelsSummaryForLte,
  getFeeEarnerLevelById,
  addFeeEarnerLevel,
  validateFeeEarnerLevelCode,
  updateFeeEarnerLevel,
  changeFeeEarnerLevelStatus,
  deleteFeeEarnerLevel,

  getEntityRoles,
  getEntityRolesForLte,
  getEntityRolesSummary,
  getEntityRolesSummaryForLte,
  getEntityRoleById,
  addEntityRole,
  validateEntityRoleName,
  updateEntityRole,
  changeEntityRoleStatus,
  deleteEntityRole,

  getActivities,
  getActivitiesForLte,
  getActivitiesSummary,
  getActivitiesSummaryForLte,
  getActivityById,
  addActivity,
  validateActivityCode,
  updateActivity,
  changeActivityStatus,
  deleteActivity,

  getActivityTypes,

  getDefaultAccountTypeCategories,
  getDefaultAccountTypeCategoriesSummary,
  getDefaultAccountTypeCategoryById,
  updateDefaultAccountTypeCategory,

  getDefaultAccountTypes,
  getDefaultAccountTypesSummary,
  getDefaultAccountTypeById,
  updateDefaultAccountType,

  getDefaultAccounts,
  getDefaultAccountsSummary,
  getDefaultAccountById,
  updateDefaultAccount,

  getAccountTypeCategories,
  getAccountTypeCategoriesForLte,
  getAccountTypeCategoriesSummary,
  getAccountTypeCategoriesSummaryForLte,
  getAccountTypeCategoryById,
  updateAccountTypeCategory,

  getAccountTypes,
  getAccountTypesForLte,
  getAccountTypesSummary,
  getAccountTypesSummaryForLte,
  getAccountTypeById,
  updateAccountType,

  getAccounts,
  getAccountsForLte,
  getAccountsSummary,
  getAccountsSummaryForLte,
  getAccountById,
  updateAccount,

  getVATRatesForPurchases,
  getVATRatesForSales,

  getLTETemplates,
  getMatterTemplates,
  getTemplate,
  getTemplateFieldsInformation,
  getHeaderAndFooterTemplate,
  getAllTemplateTypesSummary,
  getLTEHeaderFooterTypesSummary,
  getMatterTemplateTypesSummary,
  getMatterTemplateTypes,
  getTemplateTypeById,
  addTemplateType,
  validateTemplateTypeName,
  updateTemplateType,
  changeTemplateTypeStatus,
  deleteTemplateType,
  addTemplate,
  updateTemplate,
  downloadTemplate,
  deleteTemplate,

  getTemplateTaxonomyTerms,
  updateTemplateTaxonomyTerms
};
