import { HeaderProps } from "react-big-calendar";

export default function WeekHeader(props: HeaderProps) {
  return (
    <>
      <strong>
        {props.label.split(" ")[0]}
      </strong>
      <span>
        {props.label.split(" ")[1]}
      </span>
    </>
  )
}