import useNotificationActions from "actions/notification";
import { useAppSelector } from "hooks/appSelector";
import { NotificationMessageModel } from "models/NotificationMessageModel";
import { Accordion, Button } from "react-bootstrap";
import { IoMdCloseCircle } from "react-icons/io";
import useSlidingPanelActions from "actions/slidingPanel";

export default function NotificationSlidingPanel() {
  const notifications = useAppSelector((state) => state.notification);
  const notificationActions = useNotificationActions();
  const slidingPanelActions = useSlidingPanelActions();

  const dismissButton = (index: number) => {
    notificationActions.removeNotificationMessage(index);
  }

  const clearAll = () => {
    notificationActions.clearNotificationMessages();
  }

  const closeSlidingPanel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
  }

  return (
    <>
      {notifications.notificationMessages.length === 0 ? (
        <div className="lp-empty">
          <div className="image"></div>
          <div className="text">No notifications found!</div>
        </div>
      ) : (
        <>
          <Accordion>
            {notifications.notificationMessages.map((el: NotificationMessageModel, index: number) => (
              <Accordion.Item eventKey={index.toString()} key={index} className={`lp-accordion-${el.type}`}>
                <Accordion.Header>
                  <div className="lp-accordion-title">
                    {el.title}
                  </div>
                  {el.isDismissable && (
                    <div onClick={() => dismissButton(index)} className="lp-notification-dismiss">
                      <IoMdCloseCircle />
                    </div>
                  )}
                </Accordion.Header>
                <Accordion.Body>
                  {el.body}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </>
      )}
      {notifications.notificationMessages.length != 0 && 
        <div className="d-flex justify-content-between mt-4">
          <Button variant="primary" onClick={clearAll} type="submit">Clear All</Button>
          <Button variant="secondary-400" onClick={closeSlidingPanel}>Close</Button>
        </div>
      }
    </>
  );
}