import { ColDef } from "ag-grid-community";
import { MatterIncidentalExpenseModel } from "models/view/MatterIncidentalExpenseModel";
import { DateFormatter } from "components/Grid/ValueFormatters/DateFormatter";
import MatterIncidentalExpenseNameCellRenderer from "components/Grid/MatterIncidentalExpenseNameCellRenderer";
import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";

export const getColumnDefsIncidentalExpenseDetailForInvoice = () => {
  const columnDefs: ColDef<MatterIncidentalExpenseModel>[] = [
    { field: 'date', headerName: "Date", valueFormatter: DateFormatter },
    { field: 'displayName', cellRenderer: MatterIncidentalExpenseNameCellRenderer, headerName: "Name", flex: 1 },
    { field: 'grossValue', headerName: "Gross Value", valueFormatter: CurrencyFormatter },
    { field: 'netValue', headerName: "Net Value", valueFormatter: CurrencyFormatter },
    { field: 'vatValue', headerName: "VAT Value", valueFormatter: CurrencyFormatter },
    { field: 'writeOffAmount', headerName: "Write-Off Amount", valueFormatter: CurrencyFormatter },
    { field: 'grossValueOnInvoice', headerName: "Invoiced Gross Value", valueFormatter: CurrencyFormatter },
    { field: 'vatValueOnInvoice', headerName: "Invoiced VAT Value", valueFormatter: CurrencyFormatter }
  ];

  return columnDefs;
}