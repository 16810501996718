import Loader from 'components/Loader';
import useModalActions from 'actions/modal';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import "./style.scss";

export default function CustomModal() {
  const modalState = useSelector((state: RootState) => state.modal);
  const modalActions = useModalActions();

  const handleSecondAction = () => {
    modalState.onSecondAction !== undefined && modalState?.onSecondAction();
  };

  const handleClose = () => {
    modalState.onClose !== undefined && modalState?.onClose();
    modalActions.toggleModalShownStatus();
  };
  
  const doAction = () => {
    modalState.onAction();
  };

  return (
    <>
      <Modal
        show={modalState.show}
        onHide={handleClose}
        dialogClassName="lp-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalState.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalState.loading && <Loader inlineLoader />}
          {modalState.errors && (
            <div className="lp-errors">
              {modalState.errors}
            </div>
          )}
          {modalState.body}
        </Modal.Body>
        <Modal.Footer>
          <Button variant={modalState.actionVariant ?? 'success'} onClick={doAction}>
            {modalState.actionText ?? "Confirm"}
          </Button>
          {modalState.secondActionText !== undefined &&
            <Button variant={modalState.secondActionVariant ?? 'success'} onClick={handleSecondAction}>
              {modalState.secondActionText ?? "Cancel"}
            </Button>
          }
          <Button variant="secondary-400" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
