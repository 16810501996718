import { ChargingBasisIds } from "enums/ChargingBasisIds";
import { CreateOrUpdateMatterIncidentalExpenseModel } from "models/create/CreateOrUpdateMatterIncidentalExpenseModel";
import { CreateOrUpdateMatterRecordableItemModel } from "models/create/CreateOrUpdateMatterRecordableItemModel";
import isDate from "validator/lib/isDate";
import isInt from "validator/lib/isInt";
import { create, each, enforce, omitWhen, test } from "vest";

export const validationSuite = create((data = {}) => {
  enforce.extend({ isDate });
  enforce.extend({ isInt });

  test('date', 'Please select a Date.', () => {
    enforce(data.date).isDate({ strictMode: false });
  });

  test('displayName', 'Display Name is required', () => {
    enforce(data.displayName).isNotEmpty();
    enforce(data.displayName).isString();
  });

  test('description', 'Description is required', () => {
    enforce(data.description).isNotEmpty();
    enforce(data.description).isString();
  });

  test('userId', 'Please select a Fee Earner.', () => {
    enforce(data.userId).isNotEmpty();
    enforce(data.userId).isString();
  });

  test('userId', 'Fee Earner has no level.', () => {
    enforce(data.feeEarnerLevelId).isNotEmpty();
    enforce(data.feeEarnerLevelId).isString();
  });

  test('matterParticipatingEntityId', 'Please select a Client', () => {
    enforce(data.matterParticipatingEntityId).isNotEmpty();
    enforce(data.matterParticipatingEntityId).isString();
  });

  test('chargingSchemeId', 'Please select a Charging Scheme.', () => {
    enforce(data.chargingSchemeId).isNotEmpty();
    enforce(data.chargingSchemeId).isString();
  });

  each(data.recordableItems, (field: CreateOrUpdateMatterRecordableItemModel, index: number) => {
    test(`recordableItems.${index}.activityId`, 'Please select an Activity', () => {
      enforce(field.activityId).isNotEmpty();
      enforce(field.activityId).isString();
    });

    omitWhen(field.chargingBasis == ChargingBasisIds.FixedId || field.chargingBasis == undefined, () => {
      test(`recordableItems.${index}.numberOfMinutes`, 'Number of Minutes is required.', () => {
        enforce(field.numberOfMinutes).isNotEmpty();
      });
  
      test(`recordableItems.${index}.numberOfMinutes`, 'Number of Minutes needs to be a number > 0.', () => {
        enforce(field.numberOfMinutes?.toString()).isInt().greaterThan(0);
      });

      omitWhen(!field.endTime, () => {
        test(`recordableItems.${index}.startTime`, 'Start Time is required.', () => {
          enforce(field.startTime).isNotEmpty();
          enforce(field.startTime).isString();
        });
      });

      omitWhen(!field.startTime, () => {
        test(`recordableItems.${index}.endTime`, 'End Time is required.', () => {
          enforce(field.endTime).isNotEmpty();
          enforce(field.endTime).isString();
        });
      });
    })

    omitWhen(field.chargingBasis == ChargingBasisIds.TimeId, () => {
      test(`recordableItems.${index}.numberOfUnits`, 'Number of Units is required.', () => {
        enforce(field.numberOfUnits).isNotEmpty();
      });

      test(`recordableItems.${index}.numberOfUnits`, 'Number of Units needs to be a number > 0.', () => {
        enforce(field.numberOfUnits?.toString()).isInt().greaterThan(0);
      });
    });
  });

  omitWhen(!data.hasIncidentalExpenses, () => {
    each(data.incidentalExpenses, (field: CreateOrUpdateMatterIncidentalExpenseModel, index: number) => {
      test(`incidentalExpenses.${index}.date`, 'Please select a Date', () => {
        enforce(field.date).isDate({ strictMode: false });
      });
    
      test(`incidentalExpenses.${index}.displayName`, 'Display Name is required', () => {
        enforce(field.displayName).isNotEmpty();
        enforce(field.displayName).isString();
      });
    
      test(`incidentalExpenses.${index}.description`, 'Description is required', () => {
        enforce(field.description).isNotEmpty();
        enforce(field.description).isString();
      });
    
      test(`incidentalExpenses.${index}.grossValue`, 'Gross Value is required', () => {
        enforce(field.grossValue).isNotEmpty();
      });
    
      test(`incidentalExpenses.${index}.grossValue`, 'Gross Value needs to be a number > 0', () => {
        enforce(field.grossValue).greaterThan(0);
      });
    
      omitWhen(!field.vatValue, () => {
        test(`incidentalExpenses.${index}.vatValue`, 'VAT Value needs to be a number > 0', () => {
          enforce(field.vatValue).greaterThan(0);
        });
      });
    
      test(`incidentalExpenses.${index}.grossValue`, 'Gross Value needs to be higher than VAT Value', () => {
        var netValue = (field.grossValue ?? 0) - (field.vatValue ?? 0);
        enforce(netValue).greaterThan(0);
      });
    });
  });
});