import { Controller, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { vestResolver } from '@hookform/resolvers/vest';
import { Form, Button, InputGroup } from 'react-bootstrap';
import useSlidingPanelActions from 'actions/slidingPanel';
import Loader from 'components/Loader';
import { removeEmptyFields } from 'utils/form';
import { createDraftEmail, getEmailSuggestions } from 'actions/matter';
import { CreateDraftEmailModel } from 'models/create/CreateDraftEmailModel';
import MatterFileSelect from 'components/Select/MatterFileSelect';
import { validationSuite } from './validationSuite';
import { useAppSelector } from 'hooks/appSelector';
import CustomCreatableSelect from 'components/Select/CreatableSelect';
import { openUrlInNewtab } from 'utils/misc';

type Props = {
  matterId: string,
  matterFileNumber: string,
  attachmentsIds?: string[]
};

export default function CreateDraftEmail(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const user = useAppSelector((state) => state.user);

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateDraftEmailModel>({
    resolver: vestResolver(validationSuite),
    defaultValues: {
      matterFilesIds: props.attachmentsIds,
      toRecipients: [],
      ccRecipients: [],
      bccRecipients: []
    }
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateDraftEmailModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    createDraftEmail(props.matterId, data, user.msalAccessToken!)
      .then((response) => {
        openUrlInNewtab(response.data);
        slidingPanelActions.clearSlidingPanel();
        reset();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  };

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-4" controlId="note">
          <Form.Label className="required">Subject</Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">{`[LPID#${props.matterFileNumber}]`}</InputGroup.Text>
            <Form.Control
              type="text"
              className={`${errors?.subject?.message ? 'invalid' : ''}`}
              {...register('subject', { shouldUnregister: true })}
            />
          </InputGroup>
          <Form.Text className="lp-error">
            {errors?.subject?.message && errors.subject.message}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="toRecipients">
          <Form.Label className="required">To</Form.Label>
          <Controller
            control={control}
            name="toRecipients"
            shouldUnregister={true}
            render={({field: { value, onChange, onBlur }}) => (
              <CustomCreatableSelect
                id="toRecipients"
                className={`lp-select${errors?.toRecipients?.message ? ' invalid' : ''}`}
                endpointCall={() => getEmailSuggestions(props.matterId)}
                onChange={(selectedOptions) =>
                  onChange(selectedOptions ? selectedOptions.map((option: any) => option.value) : [])
                }
                onBlur={() => onBlur()}
                value={value}
                isClearable
                isMulti
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.toRecipients?.message && errors.toRecipients.message}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="ccRecipients">
          <Form.Label>Cc</Form.Label>
          <Controller
            control={control}
            name="ccRecipients"
            shouldUnregister={true}
            render={({field: { value, onChange, onBlur }}) => (
              <CustomCreatableSelect
                id="ccRecipients"
                className={`lp-select${errors?.ccRecipients?.message ? ' invalid' : ''}`}
                endpointCall={() => getEmailSuggestions(props.matterId)}
                onChange={(selectedOptions) =>
                  onChange(selectedOptions ? selectedOptions.map((option: any) => option.value) : [])
                }
                onBlur={() => onBlur()}
                value={value}
                isClearable
                isMulti
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.ccRecipients?.message && errors.ccRecipients.message}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="bccRecipients">
          <Form.Label>Bcc</Form.Label>
          <Controller
            control={control}
            name="bccRecipients"
            shouldUnregister={true}
            render={({field: { value, onChange, onBlur }}) => (
              <CustomCreatableSelect
                id="bccRecipients"
                className={`lp-select${errors?.bccRecipients?.message ? ' invalid' : ''}`}
                endpointCall={() => getEmailSuggestions(props.matterId)}
                onChange={(selectedOptions) =>
                  onChange(selectedOptions ? selectedOptions.map((option: any) => option.value) : [])
                }
                onBlur={() => onBlur()}
                value={value}
                isClearable
                isMulti
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.bccRecipients?.message && errors.bccRecipients.message}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="matterFilesIds">
          <Form.Label>Attachments</Form.Label>
          <Controller
            control={control}
            name={`matterFilesIds`}
            shouldUnregister={true}
            render={({ field: { onChange, value, name, ref } }) => (
              <MatterFileSelect
                id="matterFilesIds"
                matterId={props.matterId}
                inputRef={ref}
                className={`lp-select${
                  errors?.matterFilesIds?.message ? ' invalid' : ''
                }`}
                value={value}
                onChange={(val) => onChange(val ?? null)}
                isMulti
                isClearable
                canUploadNewFiles
              />
            )}
          />
          <Form.Text className="lp-error">
            {errors?.matterFilesIds?.message && errors.matterFilesIds?.message}
          </Form.Text>
        </Form.Group>

        <Form.Group className="d-flex justify-content-between">
          <Button variant="success" type="submit">
            Create
          </Button>
          <Button variant="secondary-400" onClick={cancelForm}>
            Cancel
          </Button>
        </Form.Group>
      </Form>
    </>
  );
}
