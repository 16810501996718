import { Accordion, Button } from 'react-bootstrap';
import useSlidingPanelActions from 'actions/slidingPanel';
import { TemplateResourceInformationModel } from 'models/view/TemplateResourceInformationModel';
import { useEffect, useState } from 'react';
import { getTemplateFieldsInformation } from 'actions/settings';
import { getTemplateResourceTypeName } from 'utils/enums';
import { TemplateFieldInformationModel } from 'models/view/TemplateFieldInformationModel';
import Field from 'components/Fields/Field';
import './style.scss';

export default function TemplateFieldsInfo() {
  const slidingPanelActions = useSlidingPanelActions();
  const [mailMergeFieldsInfo, setMailMergeFieldsInfo] = useState<TemplateResourceInformationModel[]>();
  
  useEffect(() => {
    getTemplateFieldsInformation().then((response) => {
      setMailMergeFieldsInfo(response.data);
    })
  }, []);

  const closeSlidingPanel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
  };

  return (
    <>
      <Accordion>
        {mailMergeFieldsInfo?.map(
          (el: TemplateResourceInformationModel, index: number) => (
            <Accordion.Item
              eventKey={index.toString()}
              key={index}
              className={'lp-accordion-success'}
            >
              <Accordion.Header>
                <div className="lp-accordion-title">{getTemplateResourceTypeName(el.templateResource)}</div>
              </Accordion.Header>
              <Accordion.Body>
                {
                  el.fields.map((x: TemplateFieldInformationModel, index: number) => (
                    <div key={index} className={'lp-template-field-item'}>
                      <Field
                        label={'Field Name'}
                        value={x.name}
                      />
                      <Field
                        label={'Description'}
                        value={x.description}
                      />
                    </div>
                  ))
                }
              </Accordion.Body>
            </Accordion.Item>
          )
        )}
      </Accordion>
      <div className="d-flex justify-content-between mt-4">
        <Button variant="secondary-400" onClick={closeSlidingPanel}>
          Close
        </Button>
      </div>
    </>
  );
}
