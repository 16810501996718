import jwtDecode, { JwtPayload } from "jwt-decode";

const checkIfTokenNotExpired = (token: string | null) => {
  if(!token) {
    return false;
  }

  const dateNow = new Date();
  const deserialized = jwtDecode<JwtPayload>(decodeURIComponent(token ?? ""));
  const { exp } = deserialized;
  if(!exp) {
    return false;
  }
  
  //if the current time is less than the expire time, it means the token is valid
  return Math.floor(dateNow.getTime() / 1000) < exp;
}

const getTokenTimeoutInterval = (token: string | null) => {
  if(!token) {
    return 0;
  }

  const dateNow = new Date();
  const deserialized = jwtDecode<JwtPayload>(decodeURIComponent(token ?? ""));
  const { exp } = deserialized;
  if(!exp) {
    return 0;
  }
  return (exp - Math.floor(dateNow.getTime() / 1000)) * 1000;
}

export { 
  checkIfTokenNotExpired,
  getTokenTimeoutInterval 
};