import { useEffect, useState } from "react";
import Loader from "components/Loader";
import { Row, Col, Button } from "react-bootstrap";
import { createForwardDraftEmail, createReplyAllDraftEmail, createReplyDraftEmail, getMatterEmailPreview } from "actions/matter";
import { BiLinkExternal } from 'react-icons/bi';
import { useAppSelector } from "hooks/appSelector";
import { MdReply, MdReplyAll } from 'react-icons/md';
import "./style.scss";
import { openUrlInNewtab } from "utils/misc";

type Props = {
  matterId: string,
  emailId: string
}

function PreviewMatterEmail(props: Props){
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [matterEmailPreviewUrl, setMatterEmailPreviewUrl] = useState<string>();

  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    setIsLoading(true);
    getMatterEmailPreview(props.matterId, props.emailId).then((response) => {
      setMatterEmailPreviewUrl(response.data.getUrl);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  },[]);

  function createDraftReplyEmailHandler() {
    setIsLoading(true);
    createReplyDraftEmail(props.matterId, props.emailId, user.msalAccessToken!)
      .then((response) => {
        openUrlInNewtab(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function createDraftReplyAllEmailHandler() {
    setIsLoading(true);
    createReplyAllDraftEmail(props.matterId, props.emailId, user.msalAccessToken!)
      .then((response) => {
        openUrlInNewtab(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function createDraftForwardEmailHandler() {
    setIsLoading(true);
    createForwardDraftEmail(props.matterId, props.emailId, user.msalAccessToken!)
      .then((response) => {
        openUrlInNewtab(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return(
    <div className="lp-iframe-panel">
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      {matterEmailPreviewUrl &&
        <>
          <Row>
            <Col className="lp-email-action-btns">
              <Button onClick={createDraftReplyEmailHandler} variant="secondary-400">
                <MdReply />
                <span>Reply</span>
              </Button>
              <Button onClick={createDraftReplyAllEmailHandler} variant="secondary-400">
                <MdReplyAll />
                <span>Reply all</span>
              </Button>
              <Button onClick={createDraftForwardEmailHandler} variant="secondary-400 reverse-icon">
                <MdReply />
                <span>Forward</span>
              </Button>
            </Col>
          </Row>

          <Row className="full-height">
            <Col>
              <iframe src={matterEmailPreviewUrl} title="Matter Email Preview" height="100%" width="100%"></iframe> 
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="d-flex justify-content-between">
                <Button variant="primary" onClick={() => openUrlInNewtab(matterEmailPreviewUrl)}>
                  <BiLinkExternal />
                  Open in new tab
                </Button>
              </div>
            </Col>
          </Row>
        </>
      }
    </div>
  );
}

export default PreviewMatterEmail;
