import { create, test, enforce } from "vest";

export const validationSuite = create((data = {}) => {
  test('addressLine1', 'Address Line 1 is required', () => {
    enforce(data.addressLine1).isNotEmpty();
    enforce(data.addressLine1).isString();
  });
  
  test('postCode', 'Post Code is required', () => {
    enforce(data.postCode).isNotEmpty();
    enforce(data.postCode).isString();
  });

  test('addressTypeId', 'Please select an Address Type', () => {
    enforce(data.addressTypeId).isNotEmpty();
    enforce(data.addressTypeId).isString();
  });

  test('countryId', 'Please select a Country', () => {
    enforce(data.countryId).isNotEmpty();
    enforce(data.countryId).isString();
  });
});