import { CreateOrUpdateTaxonomyModel } from "models/create/CreateOrUpdateTaxonomyModel";
import { CreateOrUpdateTaxonomyTermModel } from "models/create/CreateOrUpdateTaxonomyTermModel";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { DropDownOptionWithChildrenModel } from "models/view/DropDownOptionWithChildrenModel";
import { TaxonomyModel } from "models/view/TaxonomyModel";
import { TaxonomyNextSequenceModel } from "models/view/TaxonomyNextSequenceModel";
import { TaxonomyTermModel } from "models/view/TaxonomyTermModel";
import { ValidateFieldModel } from "models/view/ValidateFieldModel";
import { del, get, post, put } from "utils/request";

const getLteTaxonomiesSummary = async (includeInactive?: boolean, forTemplate?: boolean): Promise<any> => {
  let url = '/management/taxonomy/summary';
  let queryParams: string[] = [];
  if(includeInactive){
    queryParams.push(`includeInactive=${includeInactive}`);
  }

  if(forTemplate){
    queryParams.push(`forTemplate=${forTemplate}`);
  }

  if(queryParams.length > 0){
    url += `?${queryParams.join('&')}`;
  }
  
  const response = await get<DropDownOptionModel[]>(url);
  return response;
}

const getMatterTaxonomiesSummary = async (matterId: string, includeInactive?: boolean): Promise<any> => {
  let url = `/management/matter/${matterId}/taxonomy/summary`;
  if(includeInactive){
    url += `?includeInactive=${includeInactive}`;
  }
  
  const response = await get<DropDownOptionModel[]>(url);
  return response;
}

const getLteTaxonomies = async (includeInactive?: boolean, forTemplate?: boolean): Promise<any> => {
  let url = '/management/taxonomy';
  let queryParams: string[] = [];
  if(includeInactive){
    queryParams.push(`includeInactive=${includeInactive}`);
  }

  if(forTemplate){
    queryParams.push(`forTemplate=${forTemplate}`);
  }

  if(queryParams.length > 0){
    url += `?${queryParams.join('&')}`;
  }

  const response = await get<TaxonomyModel[]>(url);
  return response;
}

const getMatterTaxonomies = async (matterId: string, includeInactive?: boolean): Promise<any> => {
  let url = `/management/matter/${matterId}/taxonomy`;
  if(includeInactive){
    url += `?includeInactive=${includeInactive}`;
  }

  const response = await get<TaxonomyModel[]>(url);
  return response;
}

const getLteTaxonomyById = async (taxonomyId: string): Promise<any> => {
  const response = await get<TaxonomyModel>(`/management/taxonomy/${taxonomyId}`);
  return response;
}

const getMatterTaxonomyById = async (matterId: string, taxonomyId: string): Promise<any> => {
  const response = await get<TaxonomyModel>(`/management/matter/${matterId}/taxonomy/${taxonomyId}`);
  return response;
}

const getTaxonomyNextSequence = async (taxonomyId: string, parentTermId?: string, excludeTermId?: string): Promise<any> => {
  let url = `/management/taxonomy/${taxonomyId}/nextSequence`;
  let queryParams: string[] = [];

  if(parentTermId){
    queryParams.push(`parentTermId=${parentTermId}`);
  }

  if(excludeTermId){
    queryParams.push(`excludeTermId=${excludeTermId}`);
  }

  if(queryParams.length > 0){
    url += `?${queryParams.join('&')}`;
  }
  
  const response = await get<TaxonomyNextSequenceModel>(url);
  return response;
}

const validateLteTaxonomyDisplayName = async (name: string, taxonomyId?: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/taxonomy/displayName/validate`, {
    "value": name,
    "currentId": taxonomyId
  });
  return response;
}

const validateMatterTaxonomyDisplayName = async (matterId: string, name: string, taxonomyId?: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/matter/${matterId}/taxonomy/displayName/validate`, {
    "value": name,
    "currentId": taxonomyId
  });
  return response;
}

const addLteTaxonomy = async (data: CreateOrUpdateTaxonomyModel): Promise<any> => {
  const response = await post<CreateOrUpdateTaxonomyModel, TaxonomyModel>(`/management/taxonomy`, data);
  return response;
}

const addMatterTaxonomy = async (matterId: string, data: CreateOrUpdateTaxonomyModel): Promise<any> => {
  const response = await post<CreateOrUpdateTaxonomyModel, TaxonomyModel>(`/management/matter/${matterId}/taxonomy`, data);
  return response;
}

const updateLteTaxonomy = async (taxonomyId: string, data: CreateOrUpdateTaxonomyModel): Promise<any> => {
  const response = await put<CreateOrUpdateTaxonomyModel, TaxonomyModel>(`/management/taxonomy/${taxonomyId}`, data);
  return response;
}

const updateMatterTaxonomy = async (matterId: string, taxonomyId: string, data: CreateOrUpdateTaxonomyModel): Promise<any> => {
  const response = await put<CreateOrUpdateTaxonomyModel, TaxonomyModel>(`/management/matter/${matterId}/taxonomy/${taxonomyId}`, data);
  return response;
}

const changeLteTaxonomyStatus = async (taxonomyId: string): Promise<any> => {
  const response = await post(`/management/taxonomy/${taxonomyId}/changeStatus`, undefined);
  return response;
}

const changeMatterTaxonomyStatus = async (matterId: string, taxonomyId: string): Promise<any> => {
  const response = await post(`/management/matter/${matterId}/taxonomy/${taxonomyId}/changeStatus`, undefined);
  return response;
}

const deleteLteTaxonomy = async (taxonomyId: string): Promise<any> => {
  const response = await del(`management/taxonomy/${taxonomyId}`);
  return response;
}

const deleteMatterTaxonomy = async (matterId: string, taxonomyId: string): Promise<any> => {
  const response = await del(`management/matter/${matterId}/taxonomy/${taxonomyId}`);
  return response;
}

const getLteTaxonomyTermsSummary = async (taxonomyId: string, includeInactive?: boolean, ignoreTermId?: string): Promise<any> => {
  let url = `/management/taxonomy/${taxonomyId}/term/summary`;
  let queryParams: string[] = [];
  if(includeInactive){
    queryParams.push(`includeInactive=${includeInactive}`);
  }

  if(ignoreTermId){
    queryParams.push(`ignoreTermId=${ignoreTermId}`);
  }

  if(queryParams.length > 0){
    url += `?${queryParams.join('&')}`;
  }

  const response = await get<DropDownOptionWithChildrenModel[]>(url);
  return response;
}

const getMatterTaxonomyTermsSummary = async (matterId: string, taxonomyId: string, includeInactive?: boolean, ignoreTermId?: string): Promise<any> => {
  let url = `/management/matter/${matterId}/taxonomy/${taxonomyId}/term/summary`;
  let queryParams: string[] = [];
  if(includeInactive){
    queryParams.push(`includeInactive=${includeInactive}`);
  }
  
  if(ignoreTermId){
    queryParams.push(`ignoreTermId=${ignoreTermId}`);
  }

  if(queryParams.length > 0){
    url += `?${queryParams.join('&')}`;
  }
  
  const response = await get<DropDownOptionWithChildrenModel[]>(url);
  return response;
}

const getLteTaxonomyTerms = async (taxonomyId: string): Promise<any> => {
  const response = await get<TaxonomyTermModel[]>(`/management/taxonomy/${taxonomyId}/term`);
  return response;
}

const getMatterTaxonomyTerms = async (matterId: string, taxonomyId: string): Promise<any> => {
  const response = await get<TaxonomyTermModel[]>(`/management/matter/${matterId}/taxonomy/${taxonomyId}/term`);
  return response;
}

const getLteTaxonomyTermById = async (taxonomyId: string, termId: string): Promise<any> => {
  const response = await get<TaxonomyTermModel>(`/management/taxonomy/${taxonomyId}/term/${termId}`);
  return response;
}

const getMatterTaxonomyTermById = async (matterId: string, taxonomyId: string, termId: string): Promise<any> => {
  const response = await get<TaxonomyTermModel>(`/management/matter/${matterId}/taxonomy/${taxonomyId}/term/${termId}`);
  return response;
}

const validateLteTaxonomyTermDisplayName = async (taxonomyId: string, name: string, termId?: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/taxonomy/${taxonomyId}/term/displayName/validate`, {
    "value": name,
    "currentId": termId
  });
  return response;
}

const validateMatterTaxonomyTermDisplayName = async (matterId: string, taxonomyId: string, name: string, termId?: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/matter/${matterId}/taxonomy/${taxonomyId}/term/displayName/validate`, {
    "value": name,
    "currentId": termId
  });
  return response;
}

const addLteTaxonomyTerm = async (taxonomyId: string, data: CreateOrUpdateTaxonomyTermModel): Promise<any> => {
  const response = await post<CreateOrUpdateTaxonomyTermModel, TaxonomyTermModel>(`/management/taxonomy/${taxonomyId}/term`, data);
  return response;
}

const addMatterTaxonomyTerm = async (matterId: string, taxonomyId: string, data: CreateOrUpdateTaxonomyTermModel): Promise<any> => {
  const response = await post<CreateOrUpdateTaxonomyTermModel, TaxonomyTermModel>(`/management/matter/${matterId}/taxonomy/${taxonomyId}/term`, data);
  return response;
}

const updateLteTaxonomyTerm = async (taxonomyId: string, termId: string, data: CreateOrUpdateTaxonomyTermModel): Promise<any> => {
  const response = await put<CreateOrUpdateTaxonomyTermModel, TaxonomyTermModel>(`/management/taxonomy/${taxonomyId}/term/${termId}`, data);
  return response;
}

const updateMatterTaxonomyTerm = async (matterId: string, taxonomyId: string, termId: string, data: CreateOrUpdateTaxonomyTermModel): Promise<any> => {
  const response = await put<CreateOrUpdateTaxonomyTermModel, TaxonomyTermModel>(`/management/matter/${matterId}/taxonomy/${taxonomyId}/term/${termId}`, data);
  return response;
}

const changeLteTaxonomyTermStatus = async (taxonomyId: string, termId: string): Promise<any> => {
  const response = await post(`/management/taxonomy/${taxonomyId}/term/${termId}/changeStatus`, undefined);
  return response;
}

const changeMatterTaxonomyTermStatus = async (matterId: string, taxonomyId: string, termId: string): Promise<any> => {
  const response = await post(`/management/matter/${matterId}/taxonomy/${taxonomyId}/term/${termId}/changeStatus`, undefined);
  return response;
}

const deleteLteTaxonomyTerm = async (taxonomyId: string, termId: string): Promise<any> => {
  const response = await del(`management/taxonomy/${taxonomyId}/term/${termId}`);
  return response;
}

const deleteMatterTaxonomyTerm = async (matterId: string, taxonomyId: string, termId: string): Promise<any> => {
  const response = await del(`management/matter/${matterId}/taxonomy/${taxonomyId}/term/${termId}`);
  return response;
}

export { 
  getLteTaxonomiesSummary,
  getMatterTaxonomiesSummary,
  getLteTaxonomies,
  getMatterTaxonomies,
  getLteTaxonomyById,
  getMatterTaxonomyById,
  getTaxonomyNextSequence,
  validateLteTaxonomyDisplayName,
  validateMatterTaxonomyDisplayName,
  addLteTaxonomy,
  addMatterTaxonomy,
  updateLteTaxonomy,
  updateMatterTaxonomy,
  changeLteTaxonomyStatus,
  changeMatterTaxonomyStatus,
  deleteLteTaxonomy,
  deleteMatterTaxonomy,

  getLteTaxonomyTermsSummary,
  getMatterTaxonomyTermsSummary,
  getLteTaxonomyTerms,
  getMatterTaxonomyTerms,
  getLteTaxonomyTermById,
  getMatterTaxonomyTermById,
  validateLteTaxonomyTermDisplayName,
  validateMatterTaxonomyTermDisplayName,
  addLteTaxonomyTerm,
  addMatterTaxonomyTerm,
  updateLteTaxonomyTerm,
  updateMatterTaxonomyTerm,
  changeLteTaxonomyTermStatus,
  changeMatterTaxonomyTermStatus,
  deleteLteTaxonomyTerm,
  deleteMatterTaxonomyTerm
};