import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback } from "react";
import { MdAdd } from "react-icons/md";
import { columnDefs } from "./columnDefs";
import CreateActivityForm from "./CreateActivity/CreateActivityForm";
import { Card, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Title from "components/Title/index";
import { getActivities } from "actions/settings";
import SlidingPanel from "components/SlidingPanel/index";
import CustomModal from "components/Modal/Modal";

export default function ActivityList() {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();

  const onGridReady = useCallback(() =>
  {
    gridActions.setGridColumnDefs([...columnDefs]);
    getActivities().then((response) => 
    {
      gridActions.setGridRowData(response.data as any)
    })
  }, []); 

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Add Activity",
        children: <CreateActivityForm />
      });
  }

  return (
    <>
      <Title type="section" title={"Activities"}>
        <Button onClick={toggleSlidingPanel} className="btn-icon" variant="success">
          <MdAdd />
        </Button>
      </Title>
      <Card className="with-grid">
        <Card.Body>
          <Grid 
            onGridReady={onGridReady}
          />
        </Card.Body>
      </Card>

      <SlidingPanel />
      
      <CustomModal />
    </>
  );
}
