import { create, test, enforce, omitWhen } from "vest";
import isDate from "validator/lib/isDate";

export const getValidationSuite = () => {
  return create((data = {}) => {
    enforce.extend({ isDate });

    test('matterDestructionDateSetting', 'Matter Data Destruction Date is required', () => {
      enforce(data.matterDestructionDateSetting).isNotEmpty();
    });

    test("matterDestructionDateSetting", 'Matter Data Destruction Date needs to be a number > 0', () => {
      enforce(data.matterDestructionDateSetting).greaterThan(0);
    });

    omitWhen(!data.invoiceDueDateSettingNumber, () => {
      test('invoiceDueDateSettingNumber', 'Number of days has to be at least 0', () => {
        enforce(data.invoiceDueDateSettingNumber).greaterThanOrEquals(0);
      });
    });

    omitWhen(!data.invoiceDueDateSettingNumber || data.invoiceDueDateSettingNumber < 0, () => {
      test('invoiceDueDateSettingTypeId', 'Please select a Type', () => {
        enforce(data.invoiceDueDateSettingTypeId).isNotEmpty();
        enforce(data.invoiceDueDateSettingTypeId).isString();
      });
    });

    test('financialYearEnd', 'Please select a Financial Year End', () => {
      enforce(data.financialYearEnd).isDate({ strictMode: false });
    });
  });
}