import Title from "components/Title/index";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { useAppSelector } from "hooks/appSelector";
import { LteUserModel } from "models/view/LteUserModel";
import { Dispatch, SetStateAction, useState } from "react";
import { Button } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import { useLocation } from "react-router-dom";
import EditLteInfo from "./EditUserLteInfo/EditUserLteInfo";
import ViewLteInfo from "./ViewUserLteInfo/ViewUserLteInfo";

type Props = {
  user: LteUserModel | undefined,
  setUserData: Dispatch<SetStateAction<LteUserModel | undefined>>
}

type State = {
  edit: boolean
}

export default function UserLteInfo(props: Props) {
  const loggedInUser = useAppSelector((state) => state.user);

  const location = useLocation();
  const state = location.state as State ?? undefined;
  const [edit, setEdit] = useState<boolean>(state?.edit ?? false);

  return (
    <>
      <Title type="section" title={"LTE Details"}>
        {edit !== true && loggedInUser.userPermissions?.find(a => a == UserPermissionsNames.EditUserLTEInfo) &&
          <Button onClick={() => setEdit(true)} className="btn-icon" variant="primary">
            <MdEdit />
          </Button>
        }
      </Title>
      {edit === true ?
        <EditLteInfo
          user={props.user}
          setEdit={setEdit}
          setUserData={props.setUserData}
        />
      :
        <ViewLteInfo user={props.user} />
      }
    </>
  );
}
