import { ColDef } from "ag-grid-community";
import store from "state/store";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { setRowData } from "state/gridSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { ChargingRateModel } from "models/view/ChargingRateModel";
import { deleteChargingRate, getChargingBasis } from "actions/chargingSchemes";
import UpdateChargingRate from "containers/ChargingScheme/UpdateChargingRate/UpdateChargingRate";
import ViewChargingRate from "containers/ChargingScheme/ViewChargingRate/ViewChargingRate";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { getActivitiesSummaryForLte, getFeeEarnerLevels } from "actions/settings";
import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";

const viewCallback = (id: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "View Charge Rate",
        children: <ViewChargingRate
          chargingRateId={id}
        />
      }
    )
  );
}

const editCallback = (id: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Edit Charge Rate",
        children: <UpdateChargingRate
          chargingRateId={id}
        />
      }
    )
  );
}

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Charge Rate?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteChargingRate(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const columnDefs: (ColDef<ChargingRateModel> | {excludeFromExport: boolean})[] = [
  {
    excludeFromExport: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    suppressMenu: true,
    suppressSizeToFit: true,
    floatingFilter: false,
    resizable: false,
    sortable: false,
    minWidth: 48,
    maxWidth: 48,
    width: 48,
    lockPosition: true,
    pinned: 'left'
  },
  {
    headerName: 'Activity',
    field: 'activity.name',
    filter: DropdownFilter,
    filterParams: {
      property: 'activityId',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: DropdownFloatingFilter,
    floatingFilterComponentParams: {
      endpointCall: () => getActivitiesSummaryForLte(store.getState().user.lawPageTradingEntityId ?? "")
    },
    minWidth: 220,
    lockPosition: true,
    pinned: 'left'
  },
  {
    headerName: 'Fee Earner Level',
    field: 'feeEarnerLevel.name',
    filter: DropdownFilter,
    valueFormatter: (params) => params.value == null || params.value == undefined ? '[All Levels]' : params.value,
    filterParams: {
      property: 'feeEarnerLevelId',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: DropdownFloatingFilter,
    floatingFilterComponentParams: {
      endpointCall: getFeeEarnerLevels
    },
    minWidth: 220,
    lockPosition: true,
    pinned: 'left'
  },
  {
    headerName: 'Charging Basis',
    field: 'chargingBasis.name',
    filter: DropdownFilter,
    filterParams: {
      property: 'chargingBasisId',
      suppressFilterButtons: false,
    },
    floatingFilterComponent: DropdownFloatingFilter,
    floatingFilterComponentParams: {
      endpointCall: getChargingBasis
    },
    minWidth: 220
  },
  {
    headerName: 'Charge Rate',
    field: 'chargeRate',
    filter: 'agNumberColumnFilter',
    valueFormatter: CurrencyFormatter,
    suppressSizeToFit: true,
    minWidth: 100,
    width: 100
  },
  {
    headerName: 'Minutes per Unit',
    field: 'minutesPerUnit',
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    minWidth: 100,
    width: 100
  },
  {
    excludeFromExport: true,
    headerName: 'Actions',
    cellClass: 'lp-actions-cell',
    cellRenderer: ActionsCellRenderer,
    cellRendererParams:
    {
      buttonsData: [
        { type: ActionButtonTypes.View, callback: viewCallback },
        { type: ActionButtonTypes.Edit, callback: editCallback },
        { type: ActionButtonTypes.Delete, callback: deleteModal }
      ]
    },
    suppressSizeToFit: true,
    floatingFilter: false,
    sortable: false,
    minWidth: 145,
    maxWidth: 145,
    width: 145,
    lockPosition: true,
    pinned: 'right',
    resizable: false
  }
];
