import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { MatterFeeEarnerModel } from "models/view/MatterFeeEarnerModel";
import IconButtonGrid from "components/Buttons/IconButtonGrid";
import { getInitialsForName } from "utils/form";

type Props = {
  matterFeeEarner: MatterFeeEarnerModel,
  editAction?: Function,
  viewAction?: Function,
  showRole?: boolean
}

export default function ViewMatterFeeEarnerProfile(props: Props) {

  return (
    <div className="lp-entity-profile">
      <div className="lp-entity-photo">
        {getInitialsForName(props.matterFeeEarner?.user?.displayName)}
      </div>
      <div className="lp-entity-name">
        {props.matterFeeEarner?.user?.displayName}
      </div>
      {props.showRole && 
        <div className="lp-entity-role">
          {props.matterFeeEarner.isCaseManager ? 'CASE MANAGER' : 'MANAGER'}
        </div>
      }
      <div className="lp-entity-actions">
        {props.viewAction && <IconButtonGrid
          type={ActionButtonTypes.View}
          callback={() => props.viewAction!(props.matterFeeEarner.id)}
        />}
        {props.editAction && <IconButtonGrid
          type={ActionButtonTypes.Edit}
          callback={() => props.editAction!(props.matterFeeEarner.id)}
        />}
      </div>
    </div>
  );
}
