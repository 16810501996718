import { ColDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import store from "state/store";
import { deleteParticipatingEntity } from 'actions/matter';
import { setRowData } from "state/gridSlice";
import { ParticipatingEntityModel } from "models/view/ParticipatingEntityModel";
import ViewParticipatingEntity from "./ViewParticipatingEntity/ViewParticipatingEntity"
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import EditParticipatingEntityForm from "./EditParticipatingEntity/EditParticipatingEntityForm";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { getEntitiesSummary } from "actions/entity";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import ParticipatingEntityNameCellRenderer from "components/Grid/ParticipatingEntityNameCellRenderer";
import { getEntityRolesSummary } from "actions/settings";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";

const viewCallback = (id: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Participating Entity Details",
        children: <ViewParticipatingEntity participatingEntityId={id} />
      }
    )
  );
}

const editCallback = (id: string, matterTypeId: string,) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Participating Entity Details",
        children: <EditParticipatingEntityForm participatingEntityId={id} matterTypeId={matterTypeId} />
      }
    )
  );
};

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Participating Entity?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteParticipatingEntity(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const getColumnDefs = (matterTypeId: string) => {
  const columnDefs: (ColDef<ParticipatingEntityModel>| {excludeFromExport: boolean})[] = [
    {
      excludeFromExport: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      suppressMenu: true,
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 48,
      maxWidth: 48,
      width: 48,
      lockPosition: true,
      pinned: 'left'
    },
    {
      headerName: 'NAME',
      field: 'entity.displayName',
      filter: DropdownFilter,
      filterParams: {
        property: 'entityId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getEntitiesSummary
      },
      cellRenderer: ParticipatingEntityNameCellRenderer,
      lockPosition: true,
      wrapHeaderText: true,
      suppressMenu: true,
      pinned: 'left',
      minWidth: 220
    },
    {
      headerName: 'ROLE',
      field: 'entityRole.name',
      filter: DropdownFilter,
      filterParams: {
        property: 'entityRoleId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getEntityRolesSummary
      },
      suppressMenu: true,
      wrapHeaderText: true,
      minWidth: 220
    },
    {
      headerName: 'MATTER REFERENCE',
      field: 'entityMatterReference',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      wrapHeaderText: true
    },
    {
      headerName: 'IS INVOICING PARTY',
      field: 'isInvoicingParty',
      filter: BooleanFilter,
      filterParams: {
        property: 'isInvoicingParty',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
        ]
      },
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter,
      suppressMenu: true,
      wrapHeaderText: true,
      minWidth: 100
    },
    { 
      headerName: 'Purchase Order Reference',
      field: 'purchaseOrderReference',
      filter: 'agTextColumnFilter',
      suppressMenu: true
    },
    {
      headerName: 'NOTES',
      field: 'notes',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      wrapHeaderText: true
    },
    {
      excludeFromExport: true,
      headerName: 'Actions',
      cellClass: 'lp-actions-cell',
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return {
          buttonsData: [
            {
              type: ActionButtonTypes.View,
              callback: (id: string) => viewCallback(id)
            },
            !params.data.matter.isClosed &&
            {
              type: ActionButtonTypes.Edit,
              callback: (id: string) => editCallback(id, matterTypeId)
            },
            !params.data.matter.isClosed &&
            {
              type: ActionButtonTypes.Delete,
              callback: (id: string) => deleteModal(id)
            }
          ]
        };
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 145,
      maxWidth: 145,
      width: 145,
      lockPosition: true,
      pinned: 'right',
      resizable: false
    }
  ];

  return columnDefs;
}
