import Title from "components/Title/index";
import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import useSlidingPanelActions from "actions/slidingPanel";
import { ModalState } from "state/modalSlice";
import { Card, Button } from "react-bootstrap";
import { MatterFeeEarnerModel } from "models/view/MatterFeeEarnerModel";
import { deleteMatterFeeEarner, getMatterFeeEarnersForMatter } from "actions/matter";
import Loader from "components/Loader/index";
import React from "react";
import ViewCaseManagerMatterFeeEarner from "./ViewCaseManagerMatterFeeEarner";
import CreateMatterFeeEarnerForm from "./CreateMatterFeeEarner/CreateMatterFeeEarnerForm";
import EditMatterFeeEarnerForm from "./EditMatterFeeEarner/EditMatterFeeEarnerForm";
import ViewMatterFeeEarner from "./ViewMatterFeeEarner/ViewMatterFeeEarner";
import MoreButton from "components/Buttons/MoreButton";
import useModalActions from "actions/modal";

type Props = {
  matterId: string,
  matterIsClosed?: boolean
}

export default function MatterFeeEarnerList(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [caseManagerMatterFeeEarners, setCaseManagerMatterFeeEarners] = useState<MatterFeeEarnerModel[]>([]);
  const [otherMatterFeeEarners, setOtherMatterFeeEarners] = useState<MatterFeeEarnerModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const modalActions = useModalActions();
  const slidingPanelActions = useSlidingPanelActions();

  const loadMatterFeeEarners = () => {
    if(props.matterId) {
      setIsLoading(true);
      getMatterFeeEarnersForMatter(props.matterId)
        .then((response) => {
          setCaseManagerMatterFeeEarners(response.data.filter((x: MatterFeeEarnerModel) => x.isCaseManager == true));
          setOtherMatterFeeEarners(response.data.filter((x: MatterFeeEarnerModel) => x.isCaseManager == false));
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  useEffect(() =>
  {
    loadMatterFeeEarners();
  }, [props.matterId]);

  const openCreateMatterFeeEarnerSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Add Fee Earner',
      children: <CreateMatterFeeEarnerForm
                  matterId={props.matterId}
                  submitCallback={loadMatterFeeEarners}
                />
    });
  };

  const openEditMatterFeeEarnerSlidingPanel = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Edit Fee Earner',
      children: <EditMatterFeeEarnerForm 
                  matterId={props.matterId}
                  matterFeeEarnerId={id}
                  submitCallback={loadMatterFeeEarners}
                  isCurrentCaseManager={caseManagerMatterFeeEarners.find(x => x.id == id)?.isCaseManager ?? false}
                />
    });
  };

  const openViewMatterFeeEarnerSlidingPanel = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'View Fee Earner',
      children: <ViewMatterFeeEarner 
                  matterFeeEarnerId={id}
                />
    });
  };

  const deleteMatterFeeEarnerModal = (id: string) => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Fee Earner?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteMatterFeeEarnerCallback(id),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };
  
  const deleteMatterFeeEarnerCallback = (id: string) => {
    modalActions.toggleModalLoading();
    deleteMatterFeeEarner(id)
      .then(() => {
        loadMatterFeeEarners();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);

        modalActions.toggleModalLoading();
        modalActions.toggleModalShownStatus();
      });
  };

  return (
    <>
      <Title type="section" title={"Fee Earners"}>
        {!props.matterIsClosed &&
          <Button onClick={openCreateMatterFeeEarnerSlidingPanel} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>
      <Card className="managers-list">
        <Card.Body>
          {isLoading && <Loader inlineLoader={isLoading} />}

          {genericErrors && (
            <div className="lp-errors">
              {genericErrors}
            </div>
          )}

          <div>
            {caseManagerMatterFeeEarners.length === 0 &&
              <div className="lp-list-empty case-manager">No case manager is assigned to this matter</div>
            }

            {caseManagerMatterFeeEarners.map((x: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <ViewCaseManagerMatterFeeEarner 
                    matterFeeEarner={x}
                    editAction={!props.matterIsClosed ? openEditMatterFeeEarnerSlidingPanel : undefined}
                    viewAction={openViewMatterFeeEarnerSlidingPanel}
                  />
                </React.Fragment>
              )
            })}
          </div>

          <div className="lp-simple-list">
            <div className="lp-color-title secondary">Other users</div>
            <ul>
              {otherMatterFeeEarners.length === 0 &&
                <li className="lp-list-empty">No other users found</li>
              }
              {otherMatterFeeEarners.map((x: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <li>
                      <span className="lp-simple-list-name">{x?.user?.displayName}</span>
                      <div className="lp-actions-btns">
                        <MoreButton 
                          id={x.id}
                          listButtons={
                            [
                              {
                                type: ActionButtonTypes.View,
                                callback: (id: string) => openViewMatterFeeEarnerSlidingPanel(id)
                              },
                              ...(!props.matterIsClosed ? [
                                {
                                  type: ActionButtonTypes.Edit,
                                  callback: (id: string) => openEditMatterFeeEarnerSlidingPanel(id)
                                },
                                {
                                  type: ActionButtonTypes.Delete,
                                  callback: (id: string) => deleteMatterFeeEarnerModal(id)
                                }
                              ] : [])
                            ]
                          }
                        />
                      </div>
                    </li>
                  </React.Fragment>
                )
              })}
            </ul>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
