import { useEffect, useState } from "react";
import { getLteTeam } from "actions/lte";
import { LteTeamModel } from "models/view/LteTeamModel";
import Loader from "components/Loader/index";
import Field from "components/Fields/Field";
import { Row, Col } from "react-bootstrap";

type Props = {
  teamId: string,
}

export default function ViewLteTeam(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [lteTeam, setLteTeam] = useState<LteTeamModel | undefined>(undefined);

  useEffect(() => {
    setIsLoading(true);
    getLteTeam(props.teamId)
      .then((response) => {
        setLteTeam(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
      
  return (
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Name"}
            value={lteTeam?.name ?? ""}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Department"}
            value={lteTeam?.lteDepartment?.name ?? ""}
          />
        </Col>
      </Row>
    </>
  );
}
