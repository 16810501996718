import { useState } from "react";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ListItemButtonGrid from "./ListItemButtonGrid";
import Tippy from "@tippyjs/react";
import { TbDots } from "react-icons/tb";
import { Button } from "react-bootstrap";

type Props = {
  id: string,
  listButtons: Array<{ type: ActionButtonTypes, callback: Function }>
}

export default function MoreButton(props: Props) {
  const [showMore, setShowMore] = useState(false);

  const tippyContent = (
    <ul className="lp-actions-more-menu">
      {
        props.listButtons.map((el: { type: ActionButtonTypes, callback: Function }) =>
          ListItemButtonGrid({
            ...el, callback: () => {
              closeMoreMenu();
              el.callback(props.id);
            }
          })
        )
      }
    </ul>
  );

  const toggleMoreMenu = () => {
    setShowMore(!showMore);
  };

  const closeMoreMenu = () => {
    setShowMore(false);
  };

  return (
    <Tippy
      content={tippyContent}
      visible={showMore}
      onClickOutside={closeMoreMenu}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      duration={100}
      placement="bottom-end"
    >
      <Button variant="secondary-400" className="btn btn-icon" onClick={toggleMoreMenu}>
        <TbDots />
      </Button>
    </Tippy>
  );
}
