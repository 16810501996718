import Tippy from '@tippyjs/react';
import { CalendarEventModel } from 'models/CalendarEventModel';
import { EventProps } from 'react-big-calendar';
import { MdLock, MdRefresh } from 'react-icons/md';

export default function CustomEvent(props: EventProps) {
  return (
    <Tippy
      content={props.title}
      className="lp-calendar-event-tooltip"
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      duration={100}
      placement="bottom-start"
      trigger="mouseenter"
    >
      {/* title="" disables the tooltip */}
      <div title="">
        <span>{props.title}</span>
        {(props.event as CalendarEventModel).private && <MdLock />}
        {!(props.event as CalendarEventModel).isSingleInstance && <MdRefresh />}
      </div>
    </Tippy>
  );
}
